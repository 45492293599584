import _ from 'lodash';
import { getEditPermissions } from '@utils/permission';
import { highlightService } from '@/react-app/common/utils/highlightService';

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .component('evaJobs', {
      bindings: {
        domain: '<',
        state: '<',
        template: '<'
      },
      template: require('./jobs.html'),
      controller: Controller,
      controllerAs: 'vm'
    });

  function Controller(ApiAd, $q, JobModel, Enum, EvaLogger, groupJobsByRemoteId, getManualSetting, showJobError,
    getFeedback, getDomainRelatedData, $timeout, JobGroupManager, Notifier, ApiService,
    $modal, tFilter, getUserPreviousItemsCount,
    createIntersectionObserver, ApiDictionaries, $scope, findJobsWithFields, getModerationNotesForJob) {
    'ngInject';

    //Private

    const vm = this;

    let decisionSet = [];
    const DEFAULT_DISPLAY_PER_PAGE = 20;//this number will be used when get the manual setting failed
    // when one job comes to the view port, load 10 subsequent jobs' images
    const PRELOAD_JOBS_IMAGES_NUMBER = 10;
    const navigationBarHeight = 100;
    const bottomBarHeight = 50;
    const debouncedMoveIndicatorToActiveAd = _.debounce(moveIndicatorToActiveAd, 50);
    let intersections = {};
    let activeAdIndexHistory = [];
    const thresholds = Array.from(Array(10).keys()).map((i)=> i*0.1);
    vm.initialize = () => {
      populateMetadata()
        .then(loadJobsAndDuplicates, handleLoadingJobsError);
    };

    vm.$onInit = ()=> {
      vm.submittable = false;
      vm.templates = Enum.TEMPLATE;
      vm.displayPerPage = DEFAULT_DISPLAY_PER_PAGE;
      vm.availableQueues = [];
      vm.initialize();
    };

    vm.$onDestroy = () => {
      ApiService.unlockAds(vm.domain);
      if (vm.unobserveCallback) {
        vm.unobserveCallback();
      }
    };

    vm.onStatusChange = function(index) {
      if (index === vm.jobGroups.length - 1) {
        $timeout(()=> {
          // when approving the last ad, wait for the page to finish scrolling
          if ($('.js-eva-jobs-submit-btn button')) {
            $('.js-eva-jobs-submit-btn button').setFocus();
          }
        }, 200);
      } else {
        vm.bottomIndicatorPressed(index + 1);
      }

      vm.decisionTypeArray = vm.jobGroups.map((jobGroup) => jobGroup.jobs[0].selectedDecision.type);
      if (vm.isPreApproved) {
        return;
      }

      updateDecisionCount();
      vm.submittable = vm.decisionCount > 0;
    };

    vm.bottomIndicatorPressed = function(index) {
      moveToIndicator(index);
      vm.updateFocusedJobGroup(index);
      scrollToJobGroup(index);
    };

    vm.openKeyboardHelpModal = () => {
      $modal.open({
        template: require('@partial/modals/keyHelp/keyHelp.html'),
        controller: 'KeyHelpCtrl',
        controllerAs: 'vm',
        windowClass: 'tall-modal'
      });
    };

    vm.updateFocusedJobGroup = function(index) {
      if (index !== vm.focusedJobGroupIndex) {
        vm.focusedJobGroupIndex = index;
      }
      const jobGroupElement = $(`.job-group:eq(${index})`);
      if (!jobGroupElement.hasClass('eva-keyboard-focused') && (jobGroupElement.find('.eva-keyboard-focused').length === 0)) {
        // if this job group doesn't have focus and its components doesn't have focus neither
        $('.eva-keyboard-focused').closeDropDown();
        jobGroupElement.setFocus();
      }
    };

    vm.onSubmit = function() {
      if (!vm.submittable) {
        return;
      }
      vm.loading = true;

      // vm.state is the queueId
      JobGroupManager.submitDecision(vm.domain, vm.jobGroups, vm.queueName, vm.state)
        .then(undefined, handleSubmitDecisionError)
        .then(loadJobsAndDuplicates);

      function handleSubmitDecisionError(err) {
        Notifier.display(err?.data?.message || tFilter('MODERATION.QUEUE_VIEW.FAILED_TO_SUBMIT'), {type: 'failure', ttl: 6000}, err);
      }
    };

    vm.getSubmitButtonTooltip = () => {
      if (vm.isPreApproved && !vm.submittable) {
        return tFilter('MODERATION.QUEUE_VIEW.STATUS_BAR.SUBMIT_BUTTON_DISABLED_TOOLTIP');
      }
      return tFilter('MODERATION.QUEUE_VIEW.STATUS_BAR.SUBMIT_BUTTON_ENABLED_TOOLTIP', {
        COUNT: vm.decisionCount}, 'messageformat');
    };

    vm.newModerationNoteCreated = (query) => {
      const matchedJobs = findJobsWithFields(query, JobGroupManager.getLatestJobs(vm.jobGroups));
      matchedJobs.forEach((job)=> {
        getModerationNotesForJob(vm.domain, job);
      });
    };

    vm.retryGettingModerationNotes = (index)=> {
      getModerationNotesForJob(vm.domain, vm.jobGroups[index].jobs[0]);
    };

    function highlightJob(jobTohighlight) {
      const promises = [ new Promise((resolve) => {
        highlightService({
          text: jobTohighlight.ad?.current?.content.title,
          rules: jobTohighlight.ad?.current.processingResults,
          adFieldsToHighlight: '$title,$text'
        },
        EvaLogger, {
          domain: vm.domain,
          itemId: jobTohighlight.ad?.remoteId.id,
          type: 'title'
        }).then((titlehighlightParts) => {
          jobTohighlight.ad.current.content.titlehighlightParts = titlehighlightParts;
          resolve(titlehighlightParts);
        });

      }),
      new Promise((resolve) => {
        highlightService({
          text: jobTohighlight.ad?.current?.content.body,
          rules: jobTohighlight.ad?.current.processingResults,
          adFieldsToHighlight: '$body,$text'
        }, EvaLogger, {
          domain: vm.domain,
          itemId: jobTohighlight.ad?.remoteId.id,
          type: 'body'
        }).then((bodyhighlightParts) => {
          jobTohighlight.ad.current.content.bodyhighlightParts = bodyhighlightParts;
          resolve(bodyhighlightParts);
        });
      })];
      return $q.all(promises);
    }

    function handleLoadingJobsSuccess() {
      vm.loading = false;
      vm.hasErrorLoadingJobs = false;
      vm.submittable = false;
      if (_.size(vm.jobGroups) === 0) {
        return;
      }
      // Set current decisions in the bottom decision status bar
      vm.decisionTypeArray = vm.jobGroups.map((jobGroup) => jobGroup.jobs[0].selectedDecision.type);
      vm.jobGroups.forEach((jobGroup)=> {
        const job = jobGroup.jobs[0];
        job.currentQueueName = vm.queueName;
      });

      // update decision count
      updateDecisionCount();

      $timeout(() => {
        intersections = {};
        activeAdIndexHistory = [];

        if (vm.unobserveCallback) {
          vm.unobserveCallback(); // if there's existing observer, cancel it before registering a new one
        }
        const nodes = document.querySelectorAll('.js-job-group');
        vm.unobserveCallback = createIntersectionObserver(nodes,
          ({target, isIntersecting, intersectionRatio})=> {
            const index = Array.prototype.slice.call(nodes).indexOf(target);
            angular.extend(intersections, {[index]: {isIntersecting, intersectionRatio}});
            const activeAdIndex = getCurrentActiveAdIndex(intersections);
            const hasScrolledToLastAd = ((index === nodes.length -1) && isIntersecting && (intersectionRatio === 1))
            || (activeAdIndex === nodes.length -1);

            if (activeAdIndex < 0) {
              return;
            }

            if (!vm.programmaticScrolling) {
            // move ad index indicator (move focus in bottom bar and add a purple border to active ad)
              debouncedMoveIndicatorToActiveAd(activeAdIndex);
            }

            $scope.$apply(()=> {
              if (!vm.submittable && hasScrolledToLastAd && vm.isPreApproved) {
                vm.submittable = true;
              }

              if (activeAdIndexHistory.indexOf(activeAdIndex) !== -1) {
                return;
              }
              vm.jobGroups &&
              vm.jobGroups.slice(activeAdIndex, activeAdIndex + PRELOAD_JOBS_IMAGES_NUMBER + 1).forEach((jobGroup)=> {
                const job = jobGroup.jobs[0];
                if (job.shouldDeferLoadingImages) {
                  job.shouldDeferLoadingImages = false;
                }
                if (!job.userCountFetched) {
                  $timeout(()=> { // put the requests for getting counts after the requests for getting images
                    getUserPreviousItemsCount(vm.domain, job);
                  });
                }
                if (!job.moderationNotes) {
                  getModerationNotesForJob(vm.domain, job);
                }
              });
            });
            activeAdIndexHistory.push(activeAdIndex);
          }, {rootMargin: `-${navigationBarHeight}px 0px -${bottomBarHeight}px 0px`,
            threshold: thresholds});
      });
    }

    function getCurrentActiveAdIndex(intersections) {
      let maxRatio = 0;
      let maxRatioIndex = -1;
      _.forEach(_.keys(intersections), (key) => {
        const value = intersections[key];
        if (value.isIntersecting && value.intersectionRatio > maxRatio) {
          maxRatio = value.intersectionRatio;
          maxRatioIndex = key;
        }
      });
      return parseInt(maxRatioIndex);
    }

    function moveIndicatorToActiveAd(activeAdIndex) {
      if (activeAdIndex >= 0) {
        moveToIndicator(activeAdIndex);
        vm.updateFocusedJobGroup(activeAdIndex);
      }
    }

    const populateDuplicateJobsCount = async () => {
      if(vm.jobs.length > 0){
        const remoteIds = vm.jobs.map((job) => job.ad.remoteId.id);
        const dedupHashes = vm.jobs.map((job) => job.ad.dedupHash);
        const duplicatesRequests = _.zipWith(remoteIds,dedupHashes, (remoteId, dedupHash) => {
          return {remoteId: remoteId, dedupHash: dedupHash};
        });
        vm.jobGroups.forEach((jobGroup) => 
          jobGroup.jobs.forEach((job) => {
            job.loadingDuplicates = true;
          }));
        try {
          const duplicatesCounts = await ApiAd.getDuplicateAdsCount(vm.domain,duplicatesRequests);
          vm.jobGroups.forEach((jobGroup) => {
            const remoteId = jobGroup.remoteId;
            jobGroup.jobs.forEach((job) => {
              job.duplicatesCount = duplicatesCounts[remoteId];
            });
          });
        } catch (err) {
          vm.jobGroups.forEach((jobGroup) => 
            jobGroup.jobs.forEach((job) => {
              job.hasErrorLoadingDuplicates = true;
            }));
          showJobError('loadDuplicationJobs', err);
        }
        vm.jobGroups.forEach((jobGroup) => 
          jobGroup.jobs.forEach((job) => {
            job.loadingDuplicates = false;
          }));
      }
      $scope.$apply();
    };


    function populateDecisionSet() {
      return ApiAd.getAdDecisionSet(vm.domain).then((data) => decisionSet = data, (error) => {
        showJobError('loadDecisionSet');
        return $q.reject(error);
      });
    }

    function populateQueueList() {
      function excludeCurrentQueue(queues) {
        const currentQueue = _.find(queues, (q) => q.id === vm.state);
        vm.queueName = _.get(currentQueue, 'label');
        vm.queueNameText = tFilter('MODERATION.QUEUE_VIEW.STATUS_BAR.CURRENT_QUEUE', {QUEUE_NAME: vm.queueName}, 'messageformat');
        return queues.filter((q) => q.id !== vm.state);
      }
      return ApiAd.getAvailableQueues(vm.domain).then((data) => vm.availableQueues = excludeCurrentQueue(data), (error) => {
        showJobError('loadQueues');
        return $q.reject(error);
      });
    }

    function populateMetadata() {
      vm.loading = true;
      vm.hasErrorLoadingJobs = false;
      const promises = [
        populateDecisionSet(),
        populateQueueList(),
        getFeedback(vm.domain).then((data) => vm.feedbackSetArray = data),
        getManualSetting(vm.domain).then((data) => {
          vm.isPreApproved = data.default_preapprove;
          vm.displayPerPage = data.display_per_page;
          vm.template = vm.template || data.template;
          vm.editPermissions = getEditPermissions(data);
        }),
        getDomainRelatedData(vm.domain)
          .then((data) => {
            vm.types = data.types;
            vm.categories = data.categories;
          }),
        getDictionaries(vm.domain)
      ];

      return $q.all(promises);
    }

    function getDictionaries(domain) {
      ApiDictionaries.get(domain).then((data)=> {
        vm.dictionaries = data.map((item)=> ({text: item.name, id: item.name}));
      });
      // always return a resolved promise, because the success of GET /dictionaries is not a requirement
      return true;
    }

    function populateJobs() {
      vm.jobs = [];
      return new Promise((resolve, reject) => {
        try{
          ApiAd.getLockedAds(vm.domain, vm.displayPerPage, vm.state).then(function(data) {

            const jobPromises = data.results.map(function(jobData) {
              const job = new JobModel();
              job.setDataWithJobAndDecision(jobData, decisionSet, vm.isPreApproved);
              job.shouldDeferLoadingImages = true;

              return highlightJob(job).then(() => {
                return job;
              });
            });
            $q.all(jobPromises).then((jobs) => {
              vm.jobs = jobs;
              vm.jobGroups = groupJobsByRemoteId(vm.jobs);

              const isValidFeedbackArray = Array.isArray(vm.feedbackSetArray) && vm.feedbackSetArray.length > 0;
              if(isValidFeedbackArray){
                vm.jobGroups.forEach((jobGroup) => {
                  const job = jobGroup.jobs[0];
                  const isValidFeedbackIds = Array.isArray(job.selectedFeedbackIds) && job.selectedFeedbackIds.length > 0;
                  if(isValidFeedbackIds){
                    job.selectedFeedback = vm.feedbackSetArray.filter((feedback) =>
                      job.selectedFeedbackIds.includes(feedback.feedbackId))
                      .map(({id})=> ({id}));
                  }
                });
              }
              resolve();
            });


          });
        }catch (err) {
          reject(err);
        }

      });

    }


    function scrollToJobGroup(index) {
      if (index < 0 || index > vm.jobGroups.length) {
        return;
      }
      const element = $(`#job-group-container-${index} > div.job-group`);
      if (element.length === 0) {
        return;
      }
      const elementTop = element.offset().top;
      const jobMarginTop = 40;
      const previousJobShadowHeight = 3;
      vm.programmaticScrolling = true;
      angular.element('html, body')
        .animate({scrollTop: elementTop - navigationBarHeight - jobMarginTop + previousJobShadowHeight},
          ()=> {
            vm.programmaticScrolling = false;
          });
    }

    function handleLoadingJobsError() {
      vm.hasErrorLoadingJobs = true;
      vm.loading = false;
    }

    function loadJobsAndDuplicates() {
      vm.loading = true;
      vm.hasErrorLoadingJobs = false;
      vm.jobs = null;
      vm.jobGroups = null;
      populateJobs()
        .then(handleLoadingJobsSuccess, handleLoadingJobsError)
        .then(populateDuplicateJobsCount);
    }

    function moveToIndicator(index) {
      const itemWidth = 30;
      const marginLeft = 3;
      const progressPosition = index * itemWidth + marginLeft;
      $('.eva-jobs-progress-bar-overlay').css({left: -progressPosition});
      $('.eva-jobs-progress-bar-overlay-mask').css({left: progressPosition});
    }

    function updateDecisionCount() {
      vm.decisionCount = (vm.jobs.filter((job) => !!job.selectedDecision && !!job.selectedDecision.type)).length;
    }
  }
})();
