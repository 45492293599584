"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var scaleImageSize = function (originalImageSize, containerSize) {
    var scaleX = containerSize.width / originalImageSize.width;
    var scaleY = containerSize.height / originalImageSize.height;
    var scale = scaleX < scaleY ? scaleX : scaleY;
    return {
        width: Math.round(originalImageSize.width * scale),
        height: Math.round(originalImageSize.height * scale),
    };
};
var removeOrigin = function (url) {
    // localhost
    if (url === null || url === void 0 ? void 0 : url.startsWith('/api')) {
        return url.slice(4);
    }
    try {
        var urlObject = new URL(url);
        var origin_1 = urlObject.origin;
        return url.replace(origin_1, '');
    }
    catch (e) {
        return url;
    }
};
var getSizeQuery = function (requestImageSize) { return "?width=".concat(requestImageSize.width, "&height=").concat(requestImageSize.height); };
var getImageRequestUrl = function (imageType, imageServiceUrl, originalSize) {
    var shouldRequestBySize = imageType === 'sizedImage';
    var imageServiceUrlRemovedOrigin = removeOrigin(imageServiceUrl);
    // console.log('shouldRequestBySize', shouldRequestBySize);
    // console.log('shouldDeferLoadingImages', shouldDeferLoadingImages);
    // console.log('imageServiceUrl', imageServiceUrl);
    // console.log('originalSize', originalSize);
    // console.log('containerSize', containerSize);
    if (shouldRequestBySize && originalSize) {
        var scaledImageSize = scaleImageSize(originalSize, { width: 80, height: 80 });
        // console.log('scaledImageSize', scaledImageSize);
        var sizeQuery = shouldRequestBySize ? getSizeQuery(scaledImageSize) : '';
        // console.log('sizeQuery', sizeQuery);
        return "".concat(imageServiceUrlRemovedOrigin).concat(sizeQuery);
    }
    return imageServiceUrlRemovedOrigin;
};
exports.default = getImageRequestUrl;
