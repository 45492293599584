import * as Sentry from '@sentry/browser';

(function() {
  'use strict';
  angular.module('eva2-angular').service('EvaLogger', function() {
    function safeStringify(obj) {
      const cache = [];
      const hiddenAttributes = ['password', 'auth'];
      return JSON.stringify(obj, (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (cache.indexOf(value) !== -1) {
            return '[circular reference or duplicate object]';
          }
          cache.push(value);
        }
        if (hiddenAttributes.indexOf(key.toLowerCase()) !== -1) {
          return '<hidden>';
        }
        return value;
      });
    }

    /**
     * Send message to Sentry
     * @param message{string}
     */
    function captureMessage(...args) {
      console.error('Sentry ->', ...args);
      Sentry.captureMessage(...args);
    }

    function setUser(sentryUser) {
      if(!sentryUser) {
        Sentry.setUser();
      }
      // Sentry.setUser is supporting only one value
      Sentry.setUser({id:`${sentryUser.username}:${sentryUser.id}`});
    }

    function removeUser() {
      Sentry.setUser();
    }

    function captureException(...args) {
      Sentry.captureException(...args);
    }

    window.sentryService = Sentry;

    return {
      safeStringify,
      captureMessage,
      setUser,
      removeUser,
      captureException
    };
  });
})();
