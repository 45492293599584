import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').service('getDeleteAllContentStatus',
    function(ApiService, EvaLogger, createDeleteAllContentJob, DeleteAllContentJobsManager, $rootScope) {
      return function(domain) {
        return ApiService.getContentDeletionStatus(domain).then((data)=> {
          const ongoingJobs = _.filter(data, function(item) {
            return (item.type === 'ALL_DATA') && (item.state === 'READY' || item.state === 'IN_PROGRESS');
          });
          if (ongoingJobs.length > 1) {
            EvaLogger.captureMessage(`There should be only one ongoing delete-all-content job, 
          but there are actually ${ongoingJobs.length} in domain ${domain}`);
          }
          return ongoingJobs[0] ? createDeleteAllContentJob(ongoingJobs[0]) : null;
        }).then((job)=> {
          DeleteAllContentJobsManager.update(domain, job);
          $rootScope.showDeleteAllContentNotice = !!job;
          return job;
        });
      };
    });
})();
