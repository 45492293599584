(function() {
  'use strict';

  function Controller(tFilter) {
    'ngInject';
    const MAX_NUMBER_OF_DIVIDERS = 4;
    const vm = this;

    vm.res = {
      linkText: {
        toExpand: tFilter('MODERATION.QUEUE_VIEW.JOB_VERSION.SHOW_OLDER_VERSION_LINK'),
        toHide: tFilter('MODERATION.QUEUE_VIEW.JOB_VERSION.HIDE_OLDER_VERSION_LINK')
      },
      tooltip: tFilter('MODERATION.QUEUE_VIEW.JOB_VERSION.TOOLTIP')
    };

    this.$onInit = () => {
      vm.hideReviewBar = true;
      vm.jobs.forEach((job) => {job.isEditDisabled = true;});
      vm.toggleJobs = () => {
        vm.shwOldJobs = !vm.shwOldJobs;
        vm.isToggled({showOldJobs:vm.shwOldJobs});
      };

      vm.index = vm.index * 100;
      vm.getJobNumberText = () => {
        if (!vm.jobs) {
          return '';
        }
        return tFilter('MODERATION.QUEUE_VIEW.JOB_VERSION.NUMBER_OF_VERSIONS', {COUNT: vm.jobs.length}, 'messageformat');
      };

      vm.getNumberOfDividers = () => {
        const number = vm.jobs.length > MAX_NUMBER_OF_DIVIDERS ? MAX_NUMBER_OF_DIVIDERS : vm.jobs.length;
        return new Array(number);
      };
    };
  }

  angular
    .module('eva2-angular')
    .component('olderJobVersions', {
      template: require('./olderJobVersions.html'),
      controller: Controller,
      bindings: {
        template: '<',
        jobs: '<',
        showVersionInfo: '<',
        isToggled: '&',
        scrollToLatestJob: '&',
        isNotesExpanded: '<',
        index: '<'
      }
    });
})();
