const {bigNumberFormatter} = require('@utils/number');

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .filter('bigNumber', bigNumber);

  bigNumber.$inject = ['$filter'];

  function bigNumber() {
    return function(num) {
      return bigNumberFormatter(num);
    };
  }
})();

