"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chainedModel = exports.classification = exports.detection = void 0;
var createBoundingBox_1 = require("./createBoundingBox");
var detection = function (modelName, getNoticeText, tagText) {
    var noticeId = modelName;
    return {
        id: noticeId,
        getNoticeText: getNoticeText,
        tagText: tagText,
        getAugBoxes: function (imageAug) {
            var _a, _b;
            var boundingBoxes = [];
            (((_b = (_a = imageAug.result) === null || _a === void 0 ? void 0 : _a[modelName]) === null || _b === void 0 ? void 0 : _b.value) || []).forEach(function (item) {
                boundingBoxes.push({
                    noticeId: noticeId,
                    rectangle: (0, createBoundingBox_1.createBoundingBox)(item.bounding_box),
                    tagText: tagText,
                });
            });
            return boundingBoxes;
        },
    };
};
exports.detection = detection;
var classification = function (modelName, classificationValue, tagText) {
    var noticeId = "".concat(modelName, ":").concat(classificationValue);
    return {
        id: noticeId,
        getNoticeText: function () { return tagText; },
        tagText: tagText,
        getAugBoxes: function (imageAug) {
            var _a, _b, _c;
            return ((_c = (_b = (_a = imageAug.result) === null || _a === void 0 ? void 0 : _a[modelName]) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.best_match) === classificationValue
                ? [
                    {
                        noticeId: noticeId,
                        rectangle: null,
                        tagText: tagText,
                    },
                ]
                : [];
        },
    };
};
exports.classification = classification;
var chainedModel = function (parentModelName, chainedModelName, chainedModelClassificationValue, getNoticeText, tagText) {
    var noticeId = "".concat(parentModelName, ":").concat(chainedModelName, ":").concat(chainedModelClassificationValue);
    return {
        id: noticeId,
        getNoticeText: getNoticeText,
        tagText: tagText,
        getAugBoxes: function (imageAug) {
            var _a, _b;
            var boundingBoxes = [];
            (((_b = (_a = imageAug.result) === null || _a === void 0 ? void 0 : _a[parentModelName]) === null || _b === void 0 ? void 0 : _b.value) || []).forEach(function (item) {
                var _a, _b;
                if (((_b = (_a = item === null || item === void 0 ? void 0 : item[chainedModelName]) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.best_match) === chainedModelClassificationValue) {
                    boundingBoxes.push({
                        noticeId: noticeId,
                        rectangle: (0, createBoundingBox_1.createBoundingBox)(item.bounding_box),
                        tagText: tagText,
                    });
                }
            });
            return boundingBoxes;
        },
    };
};
exports.chainedModel = chainedModel;
