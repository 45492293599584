import MediaGalleryMedium from '../../../../react-app/features/moderationItem/mediaGallery/components/MediaGalleryMedium';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../../../../redux/store';
import { Provider } from 'react-redux';


(function () {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('evaMediaGallery', MediaGallery);

  function MediaGallery() {
    return {
      restrict: 'E',
      template: require('./mediagallery.html'),
      controller: Controller,
      scope: {},
      bindToController: {
        images: '=',
        isDisabled: '<',
        imageCroppingAllowed: '<',
        imageRotationAllowed: '<',
        preSelectedIndex: '<',
        updateSelectedIndex: '&',
        showBoundingBox: '<',
        shouldDeferLoadingImages: '<',
        videos: '=',
        mainVideoPlayer: '<',
        index: '<',
        adId: '<',
        isNotesExpanded: '<'
      },
      controllerAs: 'vm'
    };
  }

  function Controller($scope, $timeout, $state) {
    'ngInject';
    const vm = this;

    let reactRoot = null;



    vm.$onInit = () => {
      const containsMedia = (vm.images && vm.images.length > 0) || (vm.videos && vm.videos.length > 0);
      vm.mediaGalleryMediumId = `media-gallery-${vm.index}`;

      const disableVideo = (id) => {
        $timeout(() => {
          const isDeleted = !!vm.videos.find((image) => image.id === id).deleted;
          vm.videos = vm.videos.map((video) => {
            return video.id === id ? { ...video, deleted: !isDeleted } : video;
          });
        });
      };


      const handleImageDeletion = (id) => {
        $timeout(() => {
          const isDeleted = !!vm.images.find((image) => image.id === id).deleted;
          vm.images = vm.images.map((image) => {
            return image.id === id ? { ...image, deleted: !isDeleted } : image;
          });
        });
      };


      const handleImageTransformations = (id, transformations) => {
        $timeout(() => {
          vm.images = vm.images.map((image) => {
            return image.id === id ? { ...image, transformations: transformations } : image;
          });
        });
      };


      const renderMediaGalleryMedium = () => {

        $timeout(() => {
          if (!reactRoot) {
            reactRoot = createRoot(document.querySelector(`#${vm.mediaGalleryMediumId}`));
          }
          reactRoot.render(<Provider store={store}>
            {containsMedia &&
              <MediaGalleryMedium
                angularJsDependencies={{ $state, handleImageDeletion, handleImageTransformations }}
                angularJsUIRouterService={$state}
                showBoundingBox={vm.showBoundingBox}
                actionContainerStyle={vm.actionContainerStyle}
                isEditModeEnabled={!vm.isDisabled}
                videosMetadata={vm.videos || []}
                disableVideo={disableVideo}
                imagesMetadata={vm.images || []}
                isDisabled={vm.isDisabled}
                imageCroppingAllowed={vm.imageCroppingAllowed}
                imageRotationAllowed={vm.imageRotationAllowed}
                shouldDeferLoadingImages={vm.shouldDeferLoadingImages || false}
                modalParentElementSelector={`#${vm.mediaGalleryMediumId}`}
                adId={vm.adId}
                isModerationNotesExpanded={vm.isNotesExpanded}
              />}
          </Provider>);
        });
      };

      $scope.$watch('vm.isDisabled', () => {
        renderMediaGalleryMedium();
      }, true);

      $scope.$watch('vm.videos', () => {
        renderMediaGalleryMedium();
      }, true);

      $scope.$watch('vm.showBoundingBox', () => {
        renderMediaGalleryMedium();
      }, true);

      $scope.$watch('vm.images', () => {
        renderMediaGalleryMedium();
      }, true);

      $scope.$watch('vm.isNotesExpanded', () => {
        renderMediaGalleryMedium();
      }, true);

    };

  }
})();
