angular.module('eva2-angular').filter('findInArray', function() {
  'use strict';

  return function(input, arg) {
    if (!input || !arg || Array.isArray(input) === false) {
      return null;
    }

    const key = Object.keys(arg)[0];
    const value = arg[key];

    return input.reduce(function(object, current) {
      if (current[key] === value) {
        object = current;
      }
      return object;
    }, null);
  };
});
