(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .filter('getTranslatedRoleLabel', getTranslatedRoleLabel);
  function getTranslatedRoleLabel(tFilter) {
    const ROLE_NAME_MAPPING = {
      'Moderator': tFilter('SETTING.TEAM_MEMBER.ROLES.MODERATOR'),
      'agent': tFilter('SETTING.TEAM_MEMBER.ROLES.MODERATOR'),
      'Admin': tFilter('SETTING.TEAM_MEMBER.ROLES.ADMIN'),
      'Automation specialist': tFilter('SETTING.TEAM_MEMBER.ROLES.AUTOMATION_SPECIALIST'),
      'Specialist': tFilter('SETTING.TEAM_MEMBER.ROLES.AUTOMATION_SPECIALIST'),
      'Spectator': tFilter('SETTING.TEAM_MEMBER.ROLES.SPECTATOR'),
      'No access': tFilter('SETTING.TEAM_MEMBER.ROLES.NO_ACCESS')
    };

    return function(role) {
      return ROLE_NAME_MAPPING[role] || role;
    };
  }
})();
