import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').service('groupJobsByRemoteId', function(EvaLogger) {
    'ngInject';

    function sortJobsInJobGroup(jobGroup) {
      // sort data in descending order
      const compare = (job1, job2) => new Date(job2.ad.current.meta.timeEntered)
        - new Date(job1.ad.current.meta.timeEntered);
      jobGroup.jobs.sort(compare);
    }

    /**
     * @param {Array} jobs.
     * @returns {Array} an array of objects. Each object is like: {remoteId: 'XXX', jobs: [...]}
     */
    function groupJobs(jobs) {
      if (!jobs) {
        return;
      }
      const allRemoteIds = jobs.map((job) => {
        if (!!job && job.ad && !!job.ad.remoteId) {
          return job.ad.remoteId.id;
        } else {
          EvaLogger.captureMessage('Job does not have remote id', job);
        }
      });

      const uniqueRemoteIds = _.uniq(allRemoteIds);
      const jobGroups = uniqueRemoteIds.map((id) => ({remoteId: id, jobs: []}));

      jobs.forEach((job) => {
        const jobGroup = _.find(jobGroups, (jobGroup) => jobGroup.remoteId === job.ad.remoteId.id);
        if (jobGroup) {
          jobGroup.jobs.push(job);
        }
      });

      jobGroups.forEach((jobGroup) => sortJobsInJobGroup(jobGroup));
      return jobGroups;
    }

    return groupJobs;
  });
})();
