angular.module('eva2-angular').service('ApiDecisions', function(ajax) {
  'use strict';

  const ApiDecisions = {
    getByDomain: function getByDomain(domain) {
      return ajax.get(`/sites/${domain}/decisions`);
    }
  };

  return ApiDecisions;
});
