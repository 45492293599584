import validatePasswordFormat from '@utils/validatePasswordFormat';
(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('passwordFormat', passwordFormat);

  passwordFormat.$inject = [];

  function passwordFormat() {
    const directive = {
      require: 'ngModel',
      link: function(scope, element, attributes, ngModelCtrl) {
        ngModelCtrl.$validators.passwordFormat = validatePasswordFormat;
      }
    };
    return directive;
  }
})();
