(function() {
  'use strict';
  angular.module('eva2-angular').service('getManualSetting', function(ApiService, showJobError, $q) {
    function getManualSetting(domain) {
      return ApiService.getManualSetting(domain)
        .then((data) => {
          return data;
        },
        (error) => {
          showJobError('loadManualSetting', error);
          return $q.reject(error);
        });
    }
    return getManualSetting;
  });
})();
