'use strict';

angular.module('eva2-angular')
  .directive('loadingTracker', function() {
    return {
      restrict: 'A',
      transclude: true,
      template: require('./loadingTracker.html'),
      controller: function($scope, LoadingTracker) {
        $scope.tracker = LoadingTracker;
      },
    };
  });
