angular.module('eva2-angular.storage').service('storage', function($window, Notifier, tFilter) {
  'use strict';

  const errMsg = tFilter('OTHER_NOTIFIER.ERROR.LOCAL_STORAGE');

  function hasSupport() {
    try {
      return ('localStorage' in $window && $window.localStorage !== null);
    } catch (ex) {
      return false;
    }
  }

  function value(val) {
    return (val === 'undefined' || val === 'null' || val === '') ? null : val;
  }

  function Storage() {
    return this;
  }

  function manageItem(action, key, val) {
    try {
      switch (action) {
      case 'GET':
        return $window.localStorage.getItem(key);
      case 'SET':
        $window.localStorage.setItem(key, val);
        break;
      case 'REMOVE':
        $window.localStorage.removeItem(key);
        break;
      }
    } catch (e) {
      if (e.name === 'NS_ERROR_FILE_CORRUPTED') {
        Notifier.display(errMsg, {type: 'failure', ttl: 6000}, e);
      }
    }
  }

  Storage.prototype.getItem = function(key) {
    if (!hasSupport()) return null;
    return value(manageItem('GET', key));
  };

  Storage.prototype.setItem = function(key, val) {
    if (!hasSupport()) return this;
    manageItem('SET', key, val);
    return this;
  };

  Storage.prototype.removeItem = function(key) {
    if (!hasSupport()) return this;
    manageItem('REMOVE', key);
    return this;
  };

  Storage.prototype.clear = function() {
    if (!hasSupport()) return this;
    $window.localStorage.clear();
    return this;
  };

  return new Storage();
});
