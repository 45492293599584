angular.module('eva2-angular').service('ApiAuthentication', function(CurrentUser, Invite, getUserBySessionToken, ApiService) {
  'use strict';

  const Authentication = {
    login: function login(username, password, inviteToken) {
      return getUserBySessionToken()
        .then(undefined, createNewSession)
        .then(loginWithData);

      function createNewSession() {
        if (inviteToken) {
          return Invite.login({username: username, password: password, token: inviteToken});
        }
        return ApiService.login(username, password);
      }

      function loginWithData(data) {
        CurrentUser.logIn(data);
      }
    },
    logout: function logout() {
      return ApiService.logout();
    }

  };

  return Authentication;
});
