(function() {
  'use strict';
  function Controller() {
    'ngInject';
  }

  angular
    .module('eva2-angular')
    .component('evaModerationNotesIndicator', {
      bindings: {
        count: '<',
        onClicked: '&'
      },

      template: require('./moderationNotesIndicator.html'),
      controller: Controller,
      controllerAs: 'vm'
    });
})();

