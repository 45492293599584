import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').service('mergeAugBoxes', function() {
    return function(augBoxes, showBoundingBox) {
      if (!Array.isArray(augBoxes) || !showBoundingBox) {
        return;
      }
      const result = [];
      const map = new Map();
      for (let i=0; i<augBoxes.length; i++) {
        const augBox = augBoxes[i];
        if (showBoundingBox[augBox.noticeId]) {
          result.push(augBox);
        }
        // TO DO we replaced boundingBox with rectangle so we need to test it in the other part of the app
        const key = generateKeyForBoundingBoxCoordinates(augBox.rectangle);
        if (!map.has(key)) {
          map.set(key, augBox.tagText);
        } else {
          map.set(key, map.get(key)+ ', ' + augBox.tagText);
        }
      }

      return _.uniqWith(result.map((augBox)=> {
        const tagText = map.get(generateKeyForBoundingBoxCoordinates(augBox.rectangle));
        return {rectangle: augBox.rectangle, tagText};
      }), _.isEqual);

      function generateKeyForBoundingBoxCoordinates(rectangle) {
        return rectangle ? rectangle.toString() : '';
      }
    };
  });
})();
