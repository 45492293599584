"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatDatePicker = void 0;
var react_1 = __importStar(require("react"));
var DateRangePicker_1 = require("@common/components/DatePicker/DateRangePicker");
var date_fns_1 = require("date-fns");
var statDateUtil_1 = require("@features/Statistic/components/DatePicker/statDateUtil");
var date_1 = require("@utils/date");
exports.StatDatePicker = react_1.default.memo(function (props) {
    var onChangeCb = props.onChangeCb, angularJsDependencies = props.angularJsDependencies;
    var _a = __read((0, react_1.useState)(null), 2), overviewFromDate = _a[0], setOverviewFromDate = _a[1];
    var _b = __read((0, react_1.useState)(null), 2), overviewToDate = _b[0], setOverviewToDate = _b[1];
    var defaultToValue = (0, date_fns_1.set)(new Date(), __assign({ hours: 23, minutes: 59 }, date_1.FIFTY_NINE_SECONDS));
    var earliestDateAllowed = (0, date_fns_1.set)((0, date_fns_1.sub)(new Date(), { years: 5 }), __assign({ hours: 0, minutes: 0 }, date_1.ZERO_SECONDS));
    (0, react_1.useEffect)(function () {
        // First date init from url params
        var _a = __read((0, statDateUtil_1.getDefaultValues)(window.location.search), 2), urlFromValue = _a[0], urlToValue = _a[1];
        if (urlFromValue &&
            urlToValue &&
            // We check the date integrity to avoid the case where the user has modified the url
            (0, date_fns_1.isBefore)(urlFromValue, urlToValue) &&
            ((0, date_fns_1.isBefore)(urlToValue, defaultToValue) || (0, date_fns_1.isEqual)(urlToValue, defaultToValue)) &&
            ((0, date_fns_1.isAfter)(urlFromValue, earliestDateAllowed) || (0, date_fns_1.isEqual)(urlFromValue, earliestDateAllowed)) &&
            (0, date_fns_1.isBefore)(urlFromValue, new Date())) {
            setOverviewFromDate(urlFromValue);
            setOverviewToDate(urlToValue);
            onChangeCb && onChangeCb(urlFromValue, urlToValue);
        }
    }, []);
    var changeRange = function (fromDate, toDate) {
        var _a = __read((0, statDateUtil_1.getDefaultValues)(window.location.search), 2), urlFromValue = _a[0], urlToValue = _a[1];
        if (!(0, date_fns_1.isEqual)(urlFromValue, fromDate) || !(0, date_fns_1.isEqual)(urlToValue, toDate)) {
            angularJsDependencies
                .go('app.assignment.overview.statistics', {
                from: (0, date_fns_1.formatISO)(fromDate),
                to: (0, date_fns_1.formatISO)(toDate),
            }, {
                notify: false,
            })
                .then(function () {
                onChangeCb && onChangeCb(fromDate, toDate);
            });
        }
    };
    var onRangeChange = function (from, to) {
        if (to && from) {
            changeRange(from, to);
        }
    };
    return (react_1.default.createElement(react_1.default.Fragment, null, overviewFromDate && overviewToDate && (react_1.default.createElement(DateRangePicker_1.DateRangePicker, { defaultFromValue: overviewFromDate, defaultToValue: overviewToDate, defaultMinValue: earliestDateAllowed, defaultMaxValue: defaultToValue, onChangeCb: onRangeChange, allowNull: false }))));
});
