(function() {
  'use strict';
  angular.module('eva2-angular').controller('AddQueueCtrl', function($scope, $modalInstance, ApiService, domain,
    existingQueueNames, currentQueueId, currentQueueName) {
    const vm = this;
    vm.editMode = !!currentQueueName;
    vm.queueName = currentQueueName || '';
    vm.confirm = function() {
      const promise = vm.editMode ? ApiService.editModerationQueue(domain, currentQueueId, vm.queueName)
        : ApiService.addModerationQueue(domain, vm.queueName);
      promise.then(()=> {
        $modalInstance.close({success: true});
      }).catch((error)=> {
        $modalInstance.close({success: false, error});
      });
    };

    $scope.$watch('vm.queueName', ()=> {
      vm.isQueueNameDuplicate = (existingQueueNames.indexOf(vm.queueName) !== -1) && (vm.queueName !== currentQueueName);
      vm.isQueueNameEmpty = !vm.queueName;
    });

    vm.cancel = ()=> {
      $modalInstance.dismiss('cancel');
    };
  });
})();

