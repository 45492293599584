(function() {
  'use strict';
  angular.module('eva2-angular').service('showJobError', function(HttpErrorManager, Notifier, tFilter) {
    const res = {
      loadFeedback: tFilter('MODERATION.QUEUE_VIEW.ERROR_TO_LOAD.FEEDBACK'),
      loadDuplicationJobs: tFilter('MODERATION.QUEUE_VIEW.ERROR_TO_LOAD.DUPLICATES'),
      loadManualSetting: tFilter('MODERATION.QUEUE_VIEW.ERROR_TO_LOAD.MANUAL_SETTING'),
      loadDecisionSet: tFilter('MODERATION.QUEUE_VIEW.ERROR_TO_LOAD.DECISION_SET'),
      loadQueues: tFilter('MODERATION.QUEUE_VIEW.ERROR_TO_LOAD.QUEUES'),
      loadQueue: tFilter('MODERATION.QUEUE_VIEW.ERROR_TO_LOAD.QUEUE')
    };

    function showJobError(msgKey, error) {
      if (!error || (!!error && HttpErrorManager.shouldDisplayErrorMessage(error.status))) {
        Notifier.display(res[msgKey], {type: 'failure', ttl: 6000});
      }
    }

    return showJobError;
  });
})();
