(function() {
  'use strict';

  function Controller(EditValueInTreeModal) {
    'ngInject';
    const vm = this;
    const defaultDisabledStatus = false;

    vm.$onInit = function() {
      if (vm.disabled === undefined) {
        vm.disabled = defaultDisabledStatus;
      }
    };

    //links
    vm.onClick = function() {
      if (vm.disabled) return;

      const list = vm.list;
      const current = vm.value;

      if (!Array.isArray(list) || list.length === 0 || !current) {
        return;
      }

      EditValueInTreeModal.open(list, current, vm.label, vm.expandedNodes).then((result) => {
        if (!result) {
          return;
        }
        vm.value = result;
        vm.valueUpdated({value: result});
      });
    };
  }

  angular
    .module('eva2-angular')
    .component('editByTree', {
      bindings: {
        label: '@',
        list: '<',
        value: '<',
        disabled: '<',
        valueUpdated: '&',
        expandedNodes: '<'
      },
      template: require('./editByTree.html'),
      controller: Controller
    });
})();
