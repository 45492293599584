import { dataStoringApi } from '@common/services/dataStoring';
import { minDateFromPeriod } from '@features/ItemLog/util/fromDateUtil';

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('evaNavbar', EvaNavbar);

  EvaNavbar.$inject = [];

  function EvaNavbar() {
    return {
      replace: true,
      restrict: 'E',
      transclude: true,
      template: require('./navbar.html'),
      controller: Controller,
      bindToController: {},
      controllerAs: 'vm'
    };
  }

  function Controller($scope, $stateParams, $state,$ngRedux, CurrentUser, ApiAuthentication,
    UserPermissionManager, Intercom, $rootScope, getDeleteAllContentStatus) {
    'ngInject';
    const vm = this;

    vm.CurrentUser = CurrentUser;
    vm.state = $state;

    /* Storing the value for site domain locally within the navbar's oqn scope allows for circumventing issue with a possible
     temporary reset of of the value of domain as specified under state (domain set to null) during a state transition
     might result in broken transition due to a rendered URL path containing "//" for "/$(domain)/"
     (was earlier an issue that arose from race condition when triggering a series of quick state transitions from
     menu on a slow throttled connection) */
    vm.siteDomain = null;

    vm.nav = {
      primary: [],
      secondary: []
    };

    const onCurrentStateDataChange = function(data) {
      vm.ref = (data && data.ref) ? data.ref : '';
      const sec = (data && data.nav && data.nav.secondary && data.nav.secondary.length > 0) ? data.nav.secondary : [];
      // To avoid the go back issue in ItemLog we must compute the minDate before creating the link
      // dataStoringApi.endpoints.getDataStoringOption is stored in cache so we only call it once per domain
      // Must be deleted when we will move to a react router
      if ($stateParams?.domain) {
        $ngRedux.dispatch(
          dataStoringApi.endpoints.getDataStoringOption.initiate($stateParams.domain)
        ).then((response) => {
          const period = response?.data?.period;
          vm.minDate = period && minDateFromPeriod(response.data.period).getTime();
          vm.nav.secondary = sec.map((item) => {
            if (item.state === 'app.assignment.overview.recentItems' && vm.minDate) {
              return { ...item, params: { from: vm.minDate } };
            } else {
              return item;
            }
          });
        });
      }

    };

    function onDomainChange(domain) {
      $rootScope.showDeleteAllContentNotice = false;
      if (domain) {
        // when domain is changed, first remove the banner, then display with it.
        // so the banner component is initialized again.
        getDeleteAllContentStatus(domain);
      }
    }

    const onStateParamsChange = function(params) {
      if (params && params.domain) {
        vm.siteDomain = params.domain;
      } else {
        vm.siteDomain = null;
      }
      vm.userHasAccessToStatistics = UserPermissionManager.hasPermissionToEnterRoute('app.assignment.overview.statistics', vm.siteDomain);
      vm.userHasAccessToAutomation = UserPermissionManager.hasPermissionToEnterRoute('app.assignment.automation.filters', vm.siteDomain);
      vm.userHasAccessToManual = UserPermissionManager.hasPermissionToEnterRoute('app.assignment.manual.queues', vm.siteDomain);
      vm.userHasAccessToSearchDocuments = UserPermissionManager.hasPermissionToSearchDocuments(vm.siteDomain);
      vm.userHasAccessToCSVImporter = UserPermissionManager.hasPermissionToEnterRoute('app.assignment.csvImporter.step0', $stateParams.domain);
      vm.userHasAccessToTeamSettings = UserPermissionManager.hasPermissionToEnterRoute('app.assignment.settings.teamMembers', $stateParams.domain);
      vm.userHasAccessToDataDeletion = UserPermissionManager.hasPermissionToEnterRoute('app.assignment.settings.deleteAllContent', $stateParams.domain);
    };

    vm.logout = function() {
      function onError() {
        // IMP-1099 Always clear the local session anyway, even if the API call fails.
        CurrentUser.logOut();
      }

      function onSuccess() {
        CurrentUser.logOut();
      }

      ApiAuthentication
        .logout()
        .then(onSuccess)
        .catch(onError);
    };

    $scope.$watch('vm.state.current.data', onCurrentStateDataChange);
    $scope.$watch('vm.state.params', onStateParamsChange);
    $scope.$watch('vm.state.params.domain', onDomainChange);
  }
})();
