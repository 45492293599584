(function() {
  'use strict';
  angular.module('eva2-angular').service('getRequestImageSize', function() {
    return function(originalImageSize, containerSize) {
      if (!originalImageSize || !containerSize) {
        return;
      }
      const scaleX = containerSize.width / originalImageSize.width;
      const scaleY = containerSize.height / originalImageSize.height;
      const scale = scaleX < scaleY ? scaleX : scaleY;
      return {
        width: Math.round(originalImageSize.width * scale),
        height: Math.round(originalImageSize.height * scale),
      };
    };
  });
})();
