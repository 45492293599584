(function() {
  'use strict';

  function Controller() {}

  angular
    .module('eva2-angular')
    .component('evaPrimaryButton', {
      bindings: {
        name: '@',
        text: '@',
        isDisabled: '<',
        isSelected: '<',
        onClick: '&',
        buttonType: '@',
        heightAsParent: '<',
        widthAsParent: '<',
        keyboardFocusable: '<'
      },
      transclude: {
        leftIcon: '?leftIcon',
        rightIcon: '?rightIcon'
      },
      template: require('./primaryButton.html'),
      controller: Controller,
      controllerAs: 'vm'
    });
})();


