'use strict';

angular.module('eva2-angular').factory('BoundingBox', function() {
  'ngInject';

  class BoundingBox {
    constructor(x, y, width, height) {
      this.x = x;
      this.y = y;
      this.width = width;
      this.height = height;
    }

    toString() {
      return `${this.x}:${this.y}:${this.width}:${this.height}`;
    }

    static scale(box, value) {
      return new BoundingBox(box.x * value, box.y * value, box.width * value, box.height * value);
    }

    static rotate(box, rotation, canvasSize) {
      const newBox = angular.copy(box);
      rotation = normalizeAngle(rotation);
      if ([90, 270].indexOf(rotation) !== -1) {
        [newBox.width, newBox.height] = [newBox.height, newBox.width];
      }

      if (rotation === 90) {
        [newBox.x, newBox.y] = [canvasSize.width - newBox.y - newBox.width, newBox.x];
      }

      if (rotation === 180) {
        [newBox.x, newBox.y] = [canvasSize.width - newBox.x - newBox.width, canvasSize.height - newBox.y - newBox.height];
      }

      if (rotation === 270) {
        [newBox.x, newBox.y] = [newBox.y, canvasSize.height - newBox.x - newBox.height];
      }
      return newBox;

      function normalizeAngle(angle) {
        angle = angle % 360;
        return angle < 0 ? angle + 360 : angle;
      }
    }
  }

  return BoundingBox;
})
;
