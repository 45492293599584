(function() {
  'use strict';

  angular.module('eva2-angular').service('strArr', function() {
    return {
      hasDuplicateStrInArr: function(arr) {
        // You can define the comparing function here.
        // JS by default uses a crappy string compare.
        // (we use slice to clone the array so the
        // original array won't be modified)
        const results = [];
        if (!!arr && arr.length > 0) {
          const sortedArr = arr.slice().sort();

          for (let i = 0; i < arr.length - 1; i++) {
            if (sortedArr[i + 1] == sortedArr[i]) {
              results.push(sortedArr[i]);
            }
          }
        }

        return ((!!results) && (results.length > 0));
      },
      removeEmptyStrInArr: function(arr) {
        return arr.filter(function(item) {
          return !!item;
        });
      }
    };
  });
})();
