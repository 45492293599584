'use strict';

angular.module('eva2-angular').service('ApiPassword', (ajax) => {
  const ApiPassword = {
    requestPasswordResetEmail: function requestPasswordResetEmail(data) {
      return ajax.post('/password_reset', {email: data.email});
    },

    verifyPasswordToken: function verifyPasswordToken(data) {
      return ajax.post('/password_reset/verify', {token: data.token});
    },

    setNewPassword: function setNewPassword(data) {
      return ajax.post('/password_reset/new_password', {
        token: data.token,
        password: data.password,
        confirmPassword: data.confirmPassword
      });
    }
  };

  return ApiPassword;
});
