import _ from 'lodash';
(function() {
  'use strict';
      
  angular
    .module('eva2-angular')
    .controller('GalleryWithVideoCtrl', GalleryWithVideoCtrl);
      
      
  function GalleryWithVideoCtrl($scope, vm, $timeout, mainVideoPlayer, videoThumbnails) {
    const YT_VIDEO_IS_PLAYING = 1;
    $scope.$on('youtube.player.ready', (_, player) => {
      vm.ytPlayer = player;
      if(vm.videoCurrentTimeYoutube){
        vm.ytPlayer.seekTo(vm.videoCurrentTimeYoutube);
        vm.videoCurrentTimeYoutube = 0;
      }
      if(vm.playYtVideoOnReady){
        vm.ytPlayer.playVideo();
        vm.setPlaying(vm.selectedKey, true);  
        vm.playYtVideoOnReady = false;
      }
    });

    $scope.$on('youtube.player.paused', () => {
      vm.setPlaying(vm.selectedKey, false);
    });

    $scope.$on('youtube.player.playing', () => {
      vm.setPlaying(vm.selectedKey, true);
    });
    
    const onErrorListener = (key)=> {
      $timeout(()=> {
        vm.videoMap[key].error = true;
      });
    };
    
    const stopOtherVideos = ()=> {
      const elements = document.getElementsByClassName('main-video is-playing');
      Array.prototype.forEach.call(elements, (element)=> {
        if (element.id !== vm.mainVideoPlayerId) {
          element.pause();
        }
      });
    };
    
    const setPlaying = (key, value)=> {
      const video = vm.videoMap[key];
      if (video) {
        vm.videoMap[key].playing = value;
      } 
    };
    
    const onPlayListener = ()=> {
      stopOtherVideos();
      $timeout(()=> {
        setPlaying(vm.selectedKey, true);
      });
    };
      
    const onPauseListener = ()=> {
      $timeout(()=> {
        //this is needed because onPauseListener is called
        //every time entering modal
        if(vm.firePauseListener === false){
          vm.firePauseListener = true;
          return;
        }
        setPlaying(vm.selectedKey, false);
      });
    };
    
    vm.setPlaying = setPlaying;
    vm.stopOtherVideos = stopOtherVideos;
    
    vm.createMaps = ()=> {
      let index = 0;
      vm.imageMap = {};
      _.forEach(vm.images, (image)=> {
        vm.imageMap[`${index}`] = image;
        index = index + 1;
      });
      vm.videoMap = {};
      _.forEach(vm.videos, (video)=> {
      
        vm.videoMap[`${index}`] = video;
        index = index + 1;
      });
    };
    
    vm.addErrorListeners = ()=> {
      videoThumbnails.each(function () {
        $(this)[0].addEventListener('error', ()=>onErrorListener(
          $(this).data('key')
        ));
      });
    };
    
    $scope.$onDestroy = ()=> {
      if (videoThumbnails) {
        videoThumbnails.each(function () {
          $(this)[0].removeEventListener('error');
        });
      } 
      if (vm.mainVideoPlayer) {
        vm.mainVideoPlayer.removeEventListener('play', onPlayListener);
        vm.mainVideoPlayer.removeEventListener('pause', onPauseListener);
      }
    };

    vm.selectGalleryItem = (key) => {
      if (key === vm.selectedKey) {
        return;
      }
      vm.playYtVideoOnReady = false;
      if (vm.videoMap[vm.selectedKey]) {
        vm.setPlaying(vm.selectedKey, false);
        vm.mainVideoPlayer && vm.mainVideoPlayer.pause();
      }
      vm.selectedKey = key;
      if (vm.updateSelectedIndex) {
        vm.updateSelectedIndex({index: parseInt(key)});
      }
    };

    vm.toggleVideo = ()=> {
      const selectedVideo = vm.videoMap[vm.selectedKey];
      selectedVideo.deleted = !selectedVideo.deleted;
    };

    vm.onVideoThumbnailClicked = (key)=> {
      if (key !== vm.selectedKey) {
        vm.selectGalleryItem(key);
      } 
      vm.playYtVideoOnReady = true;
      const currentVideo = vm.videoMap[vm.selectedKey];
      const provider = _.get(currentVideo, 'provider');
      if(provider === 'youtube' && vm.ytPlayer){
        const state = vm.ytPlayer.getPlayerState();
        if(state === YT_VIDEO_IS_PLAYING){
          vm.ytPlayer.pauseVideo();
          vm.setPlaying(key, false);  
        } else {
          vm.ytPlayer.playVideo();
          vm.setPlaying(key, true);  
        }
      } 
      if(provider !== 'youtube'){
        $timeout(()=>{
          if (currentVideo.playing) {
            vm.mainVideoPlayer.pause();
            vm.setPlaying(key, false);  
          } else {
            vm.setPlaying(key, true);
            vm.stopOtherVideos();
            vm.mainVideoPlayer.play();
          }
        });
      }
    };

    vm.mainVideoPlayerId = `video-player-${$scope.$id}`;
    vm.createMaps();
    vm.addErrorListeners();
    vm.mainVideoPlayer = mainVideoPlayer;
    if (vm.mainVideoPlayer) {
      vm.mainVideoPlayer.addEventListener('play', onPlayListener);
      vm.mainVideoPlayer.addEventListener('pause', onPauseListener);
    }
    if(vm.videoCurrentTime){
      vm.mainVideoPlayer.currentTime = vm.videoCurrentTime;
      const currentVideo = vm.videoMap[vm.selectedKey];
      $timeout(()=> {
        if(currentVideo.playing){
          vm.mainVideoPlayer.play();
          setPlaying(vm.selectedKey, true);
          vm.firePauseListener = true;
        }  
      });
    }

    $scope.$watchGroup(['vm.images', 'vm.videos'], ()=> {
      vm.createMaps();
    });
  }
})();
      