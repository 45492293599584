/*!
 * eva-job-decision
 * Description: eva job decision component
 */

(function() {
  'use strict';
  function Controller(NgTableParams, tFilter) {
    'ngInject';
    const vm = this;
    const resources = {
      title: tFilter('SETTING.CSV_IMPORTER.PREVIEW_DATA.TITLE'),
      item: tFilter('SETTING.CSV_IMPORTER.PREVIEW_DATA.ITEM'),
      items: tFilter('SETTING.CSV_IMPORTER.PREVIEW_DATA.ITEMS')
    };
    vm.SHOW_NUM = 5;

    vm.$onInit = function() {
      vm.resources = resources;
      if (!!vm.data && vm.length > vm.SHOW_NUM) {
        const num = vm.length - vm.SHOW_NUM;
        vm.moreText = tFilter('SETTING.CSV_IMPORTER.PREVIEW_DATA.MORE_ITEM_TEXT',
          {NUM: num}, 'messageformat');
      } else {
        vm.moreText = '';
      }
    };
  }

  angular
    .module('eva2-angular')
    .component('csvPreview', {
      bindings: {
        data: '<',
        length: '<'
      },
      template: require('./preview.html'),
      controller: Controller,
      controllerAs: 'vm'
    });
})();
