"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEditPermissions = void 0;
var getEditPermissions = function (data) {
    return {
        imageCroppingAllowed: data.image_cropping_allowed,
        imageRotationAllowed: data.image_rotation_allowed,
        titleEditAllowed: data.title_edit_allowed,
        bodyEditAllowed: data.body_edit_allowed,
        categoryEditAllowed: data.category_edit_allowed,
        typeEditAllowed: data.type_edit_allowed,
        priceEditAllowed: data.price_edit_allowed,
        usernameEditAllowed: data.username_edit_allowed,
        customContentEditAllowed: data.custom_content_edit_allowed,
        customUserEditAllowed: data.custom_user_edit_allowed,
        customLocationEditAllowed: data.custom_location_edit_allowed,
    };
};
exports.getEditPermissions = getEditPermissions;
