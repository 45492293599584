(function() {
  'use strict';
  angular.module('eva2-angular').service('getTableCurrentDisplayedInfo', function(thousandSeparatorFilter) {
    return function(currentOffset, displayedItemsCount, totalNumber) {
      const range = isNaN(currentOffset) || isNaN(displayedItemsCount) ? '0' :
        `${thousandSeparatorFilter(currentOffset + 1)} - ${thousandSeparatorFilter(currentOffset + displayedItemsCount)}`;

      const total = isNaN(totalNumber) ? '0' : thousandSeparatorFilter(totalNumber);
      return {
        range,
        total
      };
    };
  });
})();

