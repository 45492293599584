(function() {
  'use strict';

  function createIntersectionObserver(observedElements, intersectionCallback, options) {
    const observer = new IntersectionObserver(function(entries) {
      entries.forEach(function(entry) {
        const {target, isIntersecting, intersectionRatio} = entry;
        intersectionCallback({target, isIntersecting, intersectionRatio});
      });
    }, options);

    [].slice.call(observedElements).forEach(function(element) {
      observer.observe(element);
    });
    return ()=> {
      [].slice.call(observedElements).forEach(function(element) {
        observer.unobserve(element);
      });
    };
  }

  angular
    .module('eva2-angular')
    .service('createIntersectionObserver', function() {
      return createIntersectionObserver;
    });
})();
