angular.module('eva2-angular')
  .service('ApiDictionaries', ['ajax', function(ajax) {
    'use strict';

    function cleanFilter(filter) {
      if(filter.description === ''){
        filter.description = null;
      }
      return filter;
    }

    const ApiDictionaries = {
      get: function get(domain) {
        return ajax.get(`/sites/${domain}/filters/dictionaries`)
          .then((data) => data.results);
      },
      show: function index(options) {
        return ajax.get(`/sites/${options.domain}/filters/dictionaries/${options.name}`);
      },
      create: function create(options, dictionary) {
        return ajax.post(`/sites/${options.domain}/filters/dictionaries`, cleanFilter(dictionary));
      },
      edit: function edit(options, dictionary) {
        return ajax.patch(`/sites/${options.domain}/filters/dictionaries/${dictionary.name}`, cleanFilter(dictionary));
      },
      delete: function(options, dictionary) {
        return ajax.del(`/sites/${options.domain}/filters/dictionaries/${dictionary.name}`);
      }
    };

    return ApiDictionaries;
  }]);
