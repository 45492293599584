// TO DO verify if this component is still used
(function() {
  'use strict';

  function Controller($state) {
    const vm = this;
    vm.thumbnailContainerSize = {
      width: 260,
      height: 200
    };

    vm.imageClicked = () => {
      $state.go('app.assignment.ad', {id: vm.job.ad.id, domain: vm.job.ad.remoteId.domain});
    };
  }

  angular
    .module('eva2-angular')
    .component('singleDuplicateJob', {
      template: require('./singleDuplicateJob.html'),
      controller: Controller,
      bindings: {
        job: '<'
      }
    });
})();
