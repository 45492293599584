import _ from 'lodash';
import { getEditPermissions } from '@utils/permission';
import { highlightService } from '@/react-app/common/utils/highlightService';

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('EvaJobCtrl', EvaJobCtrl);

  function EvaJobCtrl(
    $scope,
    $stateParams,
    ApiAd,
    JobModel,
    getDomainRelatedData,
    getFeedback,
    showJobError,
    submitDecisionForOneJob,
    Notifier,
    $timeout,
    getManualSetting,
    $q,
    tFilter,
    getUserPreviousItemsCount,
    ApiDictionaries,
    getModerationNotesForJob,
    getCurrentQueueNameAndId,
    EvaLogger
  ) {
    'ngInject';

    const vm = this;

    const jobId = $stateParams.id;
    const domain = $stateParams.domain;
    let jobForRevert;
    vm.initialize = () => {
      populateMetaData()
        .then(populateJob)
        .then(undefined, handleLoadingJobError)
        .then(() => {
          return getModerationNotesForJob(domain, vm.job);
        })
        .then(() => {
          populateDuplicateJobsCount();
          getUserPreviousItemsCount(domain, vm.job);


        })
        .finally(() => {
          vm.loading = false;
        });
    };

    //item details PAGE (/item/g3q8AugAed4S...)
    // we need the state of isNotesExpanded in the parent component
    // because the position of eva-jobs-submit-cancel-btn-wrapper depends on it
    // and also older version row columns depend on it
    // when migrating to react we can rework the html properly to make it more flexible
    // or we can use the same html structure and use Redux to manage isNotesExpanded state
    vm.setIsNotesExpanded = (value) => {
      vm.isNotesExpanded = value;
    };

    vm.$onInit = () => {
      vm.job = new JobModel();
      vm.domain = domain;
      vm.getModerationNotesForJob = getModerationNotesForJob;
      vm.availableQueues = [];
      vm.initialize();
    };


    vm.enterEditMode = () => {
      setEditMode(true);
      $timeout(() => {
        // Scroll to the bottom of the page to show the decision buttons
        angular.element('html').animate({ scrollTop: $(document).height() });
      }, 100);
    };

    vm.updateSubmittable = () => {
      if (vm.isInEditMode) {
        vm.submittable = true;
        if ($('.js-eva-jobs-submit-btn button')) {
          $('.js-eva-jobs-submit-btn button').setFocus();
        }
      }
    };

    vm.mainButtonClicked = () => {
      if (!vm.isInEditMode) {
        vm.enterEditMode();
      } else {
        vm.submitDecision(vm.job);
      }
    };

    vm.cancelChanges = ()=> {
      $timeout(() => {        
        vm.job = angular.copy(jobForRevert);  
        vm.submittable = false;
        setEditMode(false);
      });
    };

    vm.getButtonText = () => {
      if (vm.isInEditMode) {
        return tFilter('MODERATION.SINGLE_ITEM_VIEW.SUBMIT_BUTTON');
      }
      return vm.updating
        ? tFilter('MODERATION.SINGLE_ITEM_VIEW.UPDATING_TEXT')
        : tFilter('MODERATION.SINGLE_ITEM_VIEW.EDIT_BUTTON');
    };

    vm.updateSelectedImageIndex = (index) => {
      vm.selectedImageIndex = index;
    };

    /**
     * Function that calls API to make decision on a job
     * @param job
     */
    vm.submitDecision = (job) => {
      vm.submittable = false;
      vm.updating = true;
      setEditMode(false);
      submitDecisionForOneJob(job, domain, job.currentQueueName, job.currentQueueId)
        .then(showSuccessMessage, whenError)
        .then(loadJob);

      function showSuccessMessage() {
        Notifier.display(tFilter('MODERATION.SINGLE_ITEM_VIEW.SUBMIT_SUCCESS'), {
          type: 'success',
          ttl: 1800,
        });
      }

      function loadJob() {
        const WAITING_TIME = 3000;
        return $q(function (resolve) {
          $timeout(
            () =>
              resolve(
                job.load(domain, jobId).then(() => {
                  const status = vm?.job?.ad?.current?.meta?.status;
                  if (['reviewable', 'deferred'].includes(status)) {
                    const [currentQueueName, currentQueueId] = getCurrentQueueNameAndId(_.get(vm.job.ad, 'history[0]'), vm.queues);
                    vm.availableQueues = vm.allQueuesData.filter((q) => !(q.id === currentQueueId));
                    job.currentQueueId = currentQueueId;
                    job.currentQueueName = currentQueueName;
                  } else {
                    vm.availableQueues = vm.allQueuesData;
                    job.currentQueueId = undefined;
                    job.currentQueueName = undefined;
                  }
                  jobForRevert = angular.copy(job);
                  job.selectedImageIndex = vm.selectedImageIndex;
                  vm.updating = false;
                  vm.job.isPostReviewing = vm.isPostReviewEnabled && vm.job.isDecisionMade;
                }),
              ),
            WAITING_TIME,
          );
        });
      }

      function whenError(error) {
        vm.job = angular.copy(jobForRevert);
        const errorMessage =
          error && error.data && error.data.message ? error.data.message : tFilter('MODERATION.SINGLE_ITEM_VIEW.PROCESS_ERROR');
        Notifier.display(errorMessage, { type: 'failure', ttl: 6000 }, error);
      }
    };

    function setEditMode(flag) {
      vm.isInEditMode = flag;
      vm.job.isEditDisabled = !flag;
      if (flag) {
        const currentDecisionType = _.get(vm.job, 'selectedDecision.type');
        if (currentDecisionType === 'approve' || currentDecisionType === 'refuse') {
          vm.updateSubmittable();
        }
      } else {
        $('.eva-keyboard-focused').removeClass('eva-keyboard-focused');
        $('.eva-multi-select-with-search').removeClass('open');
      }
    }

    function populateMetaData() {
      vm.hasErrorLoadingJob = false;
      vm.loading = true;

      const promises = [
        getDomainRelatedData(domain).then((data) => {
          vm.types = data.types;
          vm.categories = data.categories;
        }),
        getFeedback(domain).then((data) => {
          vm.feedbackSetArray = data;
        }),
        getManualSetting(domain).then((data) => {
          vm.template = data.template;
          vm.isPostReviewEnabled = data.post_review_enabled;
          vm.editPermissions = getEditPermissions(data);
        }),
        getDictionaries(),
        populateQueueList(),

      ];

      return $q.all(promises);
    }

    function populateJob() {
      vm.hasErrorLoadingJob = false;
      vm.loading = true;

      return new Promise((resolve) => {
        vm.job.load(domain, jobId).then(
          () => {
            setEditMode(false);
            const status = vm?.job?.ad?.current?.meta?.status;
            if (['reviewable', 'deferred'].includes(status)) {
              const [currentQueueName, currentQueueId] = getCurrentQueueNameAndId(_.get(vm.job.ad, 'history[0]'), vm.queues);
              vm.availableQueues = vm.allQueuesData.filter((q) => !(q.id === currentQueueId));
              vm.job.currentQueueName = currentQueueName;
              vm.job.currentQueueId = currentQueueId;
            } else {
              vm.availableQueues = vm.allQueuesData;
            }

            jobForRevert = angular.copy(vm.job);
            vm.job.isPostReviewing = vm.isPostReviewEnabled && vm.job.isDecisionMade;

            const isValidFeedbackIds = Array.isArray(vm.job.selectedFeedbackIds) && vm.job.selectedFeedbackIds.length > 0;
            const isValidFeedbackArray = Array.isArray(vm.feedbackSetArray) && vm.feedbackSetArray.length > 0;
            if (isValidFeedbackIds && isValidFeedbackArray) {
              vm.job.selectedFeedback = vm.feedbackSetArray
                .filter((feedback) => vm.job.selectedFeedbackIds.includes(feedback.feedbackId))
                .map(({ id }) => ({ id }));
            }
          },
          (error) => {
            setEditMode(false);
            if (error && error.status === 404) {
              Notifier.display(tFilter('MODERATION.SINGLE_ITEM_VIEW.NO_ITEM_WTH_ID_ERROR'), { type: 'failure', ttl: 6000 });
            }
            return $q.reject(error);
          },
        ).then(() => {
          const promises = [ new Promise((resolveTitle) => {
            highlightService({
              text: vm.job?.ad?.current?.content.title,
              rules: vm.job?.ad?.current.processingResults,
              adFieldsToHighlight: '$title,$text'
            },
            EvaLogger, { domain: vm.domain, itemId: vm?.ad?.remoteId.id, type: 'title' }).then((titlehighlightParts) => {
              vm.job.ad.current.content.titlehighlightParts = titlehighlightParts;
              resolveTitle(titlehighlightParts);
            });

          }),
          new Promise((resolveBody) => {
            highlightService({
              text: vm.job?.ad?.current?.content.body,
              rules: vm.job?.ad?.current.processingResults,
              adFieldsToHighlight: '$body,$text'
            },
            EvaLogger, { domain: vm.domain, itemId: vm?.job?.ad?.remoteId.id, type: 'body' }).then((bodyhighlightParts) => {
              vm.job.ad.current.content.bodyhighlightParts = bodyhighlightParts;
              resolveBody(bodyhighlightParts);
            });
          })];
          Promise.all(promises).then(() => {
            resolve();
          });
        });
      });

    }

    const populateDuplicateJobsCount = async () => {
      try {
        vm.job.loadingDuplicates = true;
        const remoteId = vm.job.ad.remoteId.id;
        const dedupHash = vm.job.ad.dedupHash;
        const duplicatesCounts = await ApiAd.getDuplicateAdsCount(domain, [{ remoteId: remoteId, dedupHash: dedupHash }]);
        vm.job.duplicatesCount = duplicatesCounts[remoteId];
      } catch (err) {
        vm.job.hasErrorLoadingDuplicates = true;
        showJobError('loadDuplicationJobs', err);
      }
      vm.job.loadingDuplicates = false;
    };

    function populateQueueList() {
      return ApiAd.getAvailableQueues(domain).then(
        (data) => {
          vm.allQueuesData = data;
          vm.queues = {};
          data.map((item) => {
            vm.queues[item.id] = item.label;
          });
        },
        (error) => {
          showJobError('loadQueues');
          return $q.reject(error);
        },
      );
    }

    function getDictionaries() {
      ApiDictionaries.get(domain).then((data) => {
        vm.dictionaries = data.map((item) => ({ text: item.name, id: item.name }));
      });
    }

    function handleLoadingJobError() {
      vm.hasErrorLoadingJob = true;
      vm.loading = false;
    }
  }
})();
