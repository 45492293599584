(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .filter('getImageHashFromQuery', function() {
      const PHASH_REGEX = /imagesInfo.content.phash.value[\s]*:[\s]*([^\s])+/g;
      return function(queryText) {
        const matches = queryText.match(PHASH_REGEX);
        return matches && matches[0] ? matches[0].split(':')[1].trim(): null;
      };
    });
})();
