(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('evaNavbarPrimary', navbarPrimary);

  navbarPrimary.$inject = [];

  function navbarPrimary() {
    return {
      restrict: 'E',
      replace: true,
      scope: false,
      template: require('./navbar-primary.html'),
      controller: Controller,
    };
  }

  function Controller($scope, CurrentUser, hasCurrentAssignmentFilter, $rootScope,
    $stateParams, $modal, $state, $timeout, ApiService, getTeamsInPicker,
    EvaLogger, Intercom) {
    'ngInject';

    const _this = this;


    _this.userDomains = CurrentUser.getDomains();

    function setSearch(param) {
      const showSearch = !!param.domain;
      $scope.showSearch = showSearch;
      if (showSearch) {
        $scope.text = param.text || '';
      }
    }

    setSearch($stateParams);

    $scope.hasMoreThanOneAssignment = function() {
      return CurrentUser && _this.userDomains && _this.userDomains.length > 1;
    };
    $scope.openUserIntroduction = function() {
      $modal.open({
        template: require('@partial/modals/userIntroduction/userIntroduction.html'),
        controller: 'UserIntroductionModalCtrl',
        windowClass: 'no-fade',
        backdrop: 'static',
        resolve: {
          showFullIntroduction: () => false
        }
      });
    };

    $scope.assignmentName = CurrentUser.getAssignmentNameFromDomain($stateParams.domain);
    $scope.hasCurrentAssignment = hasCurrentAssignmentFilter();
    $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams,) {
      $scope.hasCurrentAssignment = hasCurrentAssignmentFilter();
      $scope.assignmentName = CurrentUser.getAssignmentNameFromDomain(toParams.domain);
      setSearch(toParams);
      updateTeamsInTeamPicker();
    });
    $rootScope.$on('$teamNameChanged', function() {
      $scope.sites = CurrentUser.getDomains();
      $scope.assignmentName = CurrentUser.getAssignmentNameFromDomain($stateParams.domain);
      updateTeamsInTeamPicker();
    });

    $scope.goToStatisticsPageOfCurrentTeam = () => {
      if ($stateParams.domain) {
        $state.go('app.assignment.overview.statistics', {domain: $stateParams.domain});
      }
    };

    $scope.toggleTeamPicker = () => {
      $scope.showTeamPicker = !$scope.showTeamPicker;
    };

    $scope.closeTeamPicker = () => {
      $scope.showTeamPicker = false;
    };

    $scope.showIntercomMessenger = ()=> {
      Intercom.show();
    };

    function updateTeamsInTeamPicker() {
      const teamsUserHasAccessTo = CurrentUser.getTeamsUserHasAccessTo();
      $scope.teamsInPicker = getTeamsInPicker(teamsUserHasAccessTo, $stateParams.domain);
    }
    $timeout(()=> {
      const navIcons = angular.element('.eva-nav-settings > ul > li');
      navIcons.on('mouseenter', function(e) {
        angular.element(this).addClass('hovered');
        $scope.implioStatusText = $scope.implioStatusText || 'Fetching status';
        if ($(e.target).closest('#nav-settings-support').length !== 0) { // when hover inside #nav-settings-support
          ApiService.getImplioStatus().then((data)=> {
            if (!data.status) {
              EvaLogger.captureMessage('Status is invalid from https://implio.statuspage.io/api/v2/status.json');
              return;
            }
            $scope.implioStatusIndicator = data.status.indicator;
            $scope.implioStatusText = data.status.description;
          }).catch(()=> {
            $scope.implioStatusIndicator = 'unknown';
            $scope.implioStatusText = 'Unknown status';
            EvaLogger.captureMessage('Failed to get Implio status from https://implio.statuspage.io/api/v2/status.json');
          });
        }
      });
      navIcons.on('mouseleave', function() {
        angular.element(this).removeClass('hovered');
      });

      $(document).on('click',function(e) {
        if ($scope.showTeamPicker && $(e.target).closest('#eva-current-team').length === 0) { // when clicking outside #eva-current-team
          $timeout($scope.closeTeamPicker);
        }
      });
    });

    ApiService.getSites().then((data)=> {
      $scope.successToLoadTeams = true;
      $scope.sites = data.results;
      updateTeamsInTeamPicker();
    });
  }
})();
