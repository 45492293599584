"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.minDateFromPeriod = exports.maxFromDate = void 0;
var date_fns_1 = require("date-fns");
var date_1 = require("@utils/date");
var maxFromDate = function (minDate, lastModified) {
    return lastModified && minDate && (0, date_fns_1.set)((0, date_fns_1.max)([new Date(lastModified), new Date(minDate)]), date_1.ZERO_SECONDS).getTime();
};
exports.maxFromDate = maxFromDate;
var minDateFromPeriod = function (period) {
    return (0, date_fns_1.set)((0, date_fns_1.sub)(new Date(), { months: period }), date_1.ZERO_SECONDS);
};
exports.minDateFromPeriod = minDateFromPeriod;
