/* The main difference between this component and evaSelect is that this component is a list element (<ul> and <li>), not a select
* element. This difference makes it easy to customize the option CSS styles.
*
* Example of an item (or predefined-item):
* {label: 'foo', disabled: true}
* */

(function() {
  'use strict';

  function Controller($timeout, $element, $scope, tFilter) {
    const vm = this;

    this.$onInit = () => {
      if (!vm.placeholder) {
        vm.placeholder = tFilter('COMMON.SELECT_LIST.PLACEHOLDER');
      }

      if (vm.preselectedItem) {
        vm.selectedItem = vm.preselectedItem;
      }

      $scope.$watch('$ctrl.isDisabled', function() {
        $timeout(()=> {
          if (vm.isDisabled) {
            $element.find('.dropdown-toggle').prop('data-toggle', false );
          } else {
            $element.find('.dropdown-toggle').attr('data-toggle', 'dropdown');
          }
        });
      });

      $scope.$watch('$ctrl.updatedItem', function(newVal, oldVal) {
        if (newVal !== oldVal) {
          vm.selectedItem = vm.updatedItem;
        }
      });

      vm.selectItem = (item) => {
        if (item.disabled) {
          return;
        }
        vm.selectedItem = item;
        vm.normalItemClicked({item});
      };
    };
  }

  angular
    .module('eva2-angular')
    .component('evaSelectList', {
      bindings: {
        name: '@',
        placeholder: '@',
        items: '<',
        preselectedItem: '<', // optional
        updatedItem: '<', // optional
        normalItemClicked: '&',
        separatedItemName: '@', // optional
        separatedItemClicked: '&', // optional
        isDisabled: '<',
        selectedItemCustomSuffix: '<', // optional
      },
      template: require('./selectList.html'),
      controller: Controller
    });
})();
