import _ from 'lodash';
(function() {
  'use strict';

  function Controller(Mapper, csvImporter, MapperManager, tFilter) {
    'ngInject';
    const vm = this;
    let attributeInputIndex = 0;

    vm.res = {
      addNewColumn: {
        text: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.NEW_COLUMN_BTN')
      }
    };

    this.$onInit = ()=> {
      initializeColumns();
      initializeAttributes();
      initializeMappers();
      validateNumberOfMappers();
      vm.MapperManager = MapperManager;
    };

    vm.addMapper = () => {
      if (vm.canAddMoreMappers) {
        MapperManager.optionalMappers.push(
          new Mapper(csvImporter.MAPPER_TYPE.OPTIONAL, undefined, undefined, 'CustomAttribute' + attributeInputIndex));
        attributeInputIndex++;
      }
      validateNumberOfMappers();
    };

    vm.removeMapper = (mapperToRemove) => {
      const mapperIndex = _.findIndex(MapperManager.optionalMappers, (mapper) => mapper.id == mapperToRemove.id);
      vm.selectedColumnChanged(mapperToRemove, undefined, false);
      vm.decidedAttributeChanged(mapperToRemove, undefined, false);
      MapperManager.optionalMappers.splice(mapperIndex, 1);
      // after a mapper is removed, the other mappers that had duplicate custom attribute should be updated
      MapperManager.optionalMappers.forEach((mapper) =>
        mapper.setError());
      validateNumberOfMappers();
    };

    vm.selectedColumnChanged = (mapper, columnId, isSetError = true) => {
      mapper.releasePreviouslySelectedColumn();
      if (columnId === undefined) {
        mapper.selectedColumn = undefined;
      } else {
        mapper.updateColumn(columnId, vm.columns);
      }
      updateColumnData(mapper);
      if (isSetError) {
        MapperManager.optionalMappers.forEach((mapper) =>
          mapper.setError());
      }
    };

    vm.decidedAttributeChanged = (mapper, decidedAttribute, isSetError = true) => {
      mapper.releasePreviouslySelectedAttribute();
      mapper.resetError();

      if (!decidedAttribute) {
        mapper.decidedAttribute = undefined;
      } else {
        if (decidedAttribute.isCustomValue) {
          mapper.updateAttributeByCustomValue(decidedAttribute);
        } else {
          mapper.updateAttributeBySelectedItem(decidedAttribute, vm.optionalAttributes);
        }
      }

      if (isSetError) {
        MapperManager.optionalMappers.forEach((mapper) =>
          mapper.setError());
      }
    };

    function initializeMappers() {
      // initialize optional mappers
      if (!MapperManager.optionalMappers) {
        const matches = getAttributeColumnMatch(vm.optionalAttributes, vm.columns);
        MapperManager.optionalMappers = matches.map((match) => {
          const mapper = new Mapper(csvImporter.MAPPER_TYPE.OPTIONAL, match.attribute, match.column, attributeInputIndex);
          attributeInputIndex = attributeInputIndex + 1;
          return mapper;
        });
        MapperManager.optionalMappers.forEach((mapper) => {
          updateColumnData(mapper);
          mapper.setError();
        });
      }
      // initialize mandatory mappers
      if (!MapperManager.mandatoryMappers) {
        MapperManager.mandatoryMappers = [];
        const matches = getAttributeColumnMatch(vm.mandatoryAttributes, vm.columns);
        matches.forEach((match) => {
          MapperManager.mandatoryMappers.push(new Mapper(csvImporter.MAPPER_TYPE.MANDATORY, match.attribute, match.column));
        });
        const matchedAttributedIds = matches.map((match) => match.attribute.id);
        vm.mandatoryAttributes.forEach((attribute)=> {
          if (matchedAttributedIds.indexOf(attribute.id) === -1) {
            MapperManager.mandatoryMappers.push(new Mapper(csvImporter.MAPPER_TYPE.MANDATORY, attribute));
          }
        });
        MapperManager.mandatoryMappers.forEach((mapper) => {
          updateColumnData(mapper);
        });
      }
    }

    function initializeColumns() {
      if (!vm.columns) {
        const data = vm.data;
        if (!!data && data.length > 0) {
          vm.columns = [];
          Object.keys(data[0]).forEach((key, index)=> {
            if (key !== '') {
              vm.columns.push({label: key, id: index, takenBy: undefined});
            }
          });
        }
      }
    }

    function initializeAttributes() {
      if (!vm.optionalAttributes) {
        vm.optionalAttributes = csvImporter.optionalAttributes.map((attr)=>
          (angular.extend({}, attr, {takenBy: undefined})));
      }
      if (!vm.mandatoryAttributes) {
        vm.mandatoryAttributes = csvImporter.mandatoryAttributes.map((attr)=>
          (angular.extend({}, attr, {takenBy: undefined})));
      }
    }

    function getColumnData(column) {
      const data = vm.data;
      if (!!column && column.label !== undefined) {
        return data.map((obj)=> obj[column.label]);
      }
    }

    function updateColumnData(mapper) {
      if (mapper.selectedColumn === undefined) {
        mapper.columnDataDisplayed = undefined;
        return;
      }
      const columnData = getColumnData(mapper.selectedColumn);
      mapper.columnDataDisplayed = columnData.slice(0, csvImporter.NUMBER_OF_COLUMN_DATA_TO_DISPLAY);
      mapper.numberOfColumnDataNotDisplayed = columnData.length - mapper.columnDataDisplayed.length;
    }

    function validateNumberOfMappers() {
      if (!vm.columns) {
        vm.canAddMoreMappers = false;
      } else {
        if (!!MapperManager.optionalMappers && !!MapperManager.mandatoryMappers) {
          vm.canAddMoreMappers = MapperManager.optionalMappers.length
            + MapperManager.mandatoryMappers.length < vm.columns.length;
        } else {
          vm.canAddMoreMappers = true;
        }
      }
    }

    /**
     * This function returns an array of objects, where each object contains a pair of attribute and column whose labels are the same
     * Example: [{attribute: {label: 'foo'}, column: {label: 'foo'}}]
     * @param attributes
     * @param columns
     * @returns {Array}
     */

    function getAttributeColumnMatch(attributes, columns) {
      const match = [];
      if (!attributes || !columns) {
        return [];
      }
      attributes.forEach((attribute) => {
        columns.forEach((column) => {
          if (attribute.label === column.label) {
            match.push({attribute, column});
            return;
          }
        });
      });
      return match;
    }
  }

  angular
    .module('eva2-angular')
    .component('csvMapping', {
      template: require('./csvMapping.html'),
      controller: Controller,
      bindings: {
        columns: '=',
        optionalAttributes: '=',
        mandatoryAttributes: '=',
        data: '<',
        submitted: '<'
      }
    });
})();
