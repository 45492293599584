(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('EvaJobsCtrl', EvaJobsCtrl);

  function EvaJobsCtrl($stateParams) {
    'ngInject';

    const vm = this;
    vm.domain = $stateParams.domain;
    vm.state = $stateParams.queueId || null; // default state is null
  }
})();
