(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);

  appConfig.$inject = ['$stateProvider'];

  function appConfig($stateProvider) {
    $stateProvider.state('app.login', {
      url: 'login',
      data: {
        title: 'PAGE_TITLE.LOGIN',
        transitionPolicy: {
          allowLoggedIn: false,
          requireAuth: false
        }
      },
      params: {
        returnState: {
          squash: true
        },
        returnParams: {
          squash: true
        },
        message: null,
        inviteParams: {
          email: '',
          teamname: '',
          token: '',
          domain: ''
        }
      },
      views: {
        'main@': {
          template: require('@partial/start/start.html')
        },
        'content@app.login': {
          template: require('./login.html'),
          controller: 'LoginCtrl'
        }
      }
    });
  }
})();
