"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VARIABLES_REF = void 0;
// From old angularjs code
exports.VARIABLES_REF = {
    title: {
        adPath: 'content.title',
        fieldType: 'string',
    },
    body: {
        adPath: 'content.body',
        fieldType: 'text',
    },
    text: {
        adPath: function (ad) {
            if (ad.content && ad.content.title && ad.content.body) {
                return "".concat(ad.content.title || '', " ").concat(ad.content.body);
            }
            return '';
        },
        fieldType: 'none',
        relatedFields: ['title', 'body'],
    },
    email: {
        adPath: 'user.emailAddresses[0]',
        fieldType: 'string',
    },
    phoneNumber: {
        adPath: 'user.phoneNumbers[0]',
        fieldType: 'string',
    },
    categoryName: {
        adPath: 'content.category.name',
        fieldType: 'string',
    },
    categoryId: {
        adPath: 'content.category.categoryId',
        fieldType: 'string',
    },
    price: {
        adPath: 'content.price',
        fieldType: 'integer',
    },
    status: {
        adPath: 'meta.statusOnSite.name',
        fieldType: 'string',
    },
    currency: {
        adPath: 'content.priceCurrency',
        fieldType: 'string',
    },
    type: {
        adPath: 'content.type.name',
        fieldType: 'string',
    },
    professional: {
        adPath: 'content.professional',
        fieldType: 'boolean',
    },
    userId: {
        adPath: 'user.id',
        fieldType: 'string',
    },
    userName: {
        adPath: 'user.name',
        fieldType: 'string',
    },
    city: {
        adPath: 'content.address.city',
        fieldType: 'string',
    },
    postalCode: {
        adPath: 'content.address.postcode',
        fieldType: 'string',
    },
    region: {
        adPath: 'content.address.region',
        fieldType: 'string',
    },
    countryCode: {
        adPath: 'content.address.countryCode',
        fieldType: 'string',
    },
    ip: {
        adPath: 'user.ipAddress',
        fieldType: 'string',
    },
};
