import store from '@store';
import { default as React } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import ItemLog from '@features/ItemLog';

(function () {
  'use strict';

  function Controller($scope, $rootScope, $state, $window, $stateParams, MomentUtil, UserPermissionManager, $q, $timeout) {
    'ngInject';
    const vm = this;

    const root = createRoot(document.querySelector('#item-log'));

    vm.$onInit = function() {
      renderEvaList();
    };

    vm.$onDestroy = function() {
      root.unmount();
    };


    const renderEvaList = () => {
      const filterViewDefaultStartDate = vm.filterViewDefaultStartDate && new Date(vm.filterViewDefaultStartDate);
      $timeout(() => {
        try{
          root.render(
            <Provider store={store}>
              <ItemLog
                domain={$stateParams.domain}
                filterViewDefaultStartDate={filterViewDefaultStartDate}
                filterId={vm.filterId}
                userHasAccessToCSVImporter={vm.userHasAccessToCSVImporter}
                angularJsDependencies={
                  {
                    go: $state.go,
                    href: $state.href,
                    userPermissionManager: UserPermissionManager,
                    previousState:$rootScope.previousState,
                  }
                }
                paginate={vm.paginate}
                openImageModal={vm.openImageModal}
                openRulesAndHistoryModal={vm.openRulesAndHistoryModal}
              />
            </Provider>
          );
        }catch (ex){// During the go back process with have a dom render issue
        }

      });
    };
  }

  angular.module('eva2-angular').component('evaItemsList', {
    bindings: {
      filterId: '<',
      showDates: '<',
      showId: '<',
      filterViewDefaultStartDate: '@',
    },
    template: require('./items.html'),
    controller: Controller,
    controllerAs: 'vm',
  });
})();
