(function() {
  'use strict';

  function Controller() {
    'ngInject';
  }

  angular
    .module('eva2-angular')
    .component('evaDeleteContentExplanation', {
      controllerAs: 'vm',
      bindings: {
        willBeDeleted: '<',
        willNotBeDeleted: '<'
      },
      template: require('./deleteContentExplanation.html'),
      controller: Controller
    });
})();
