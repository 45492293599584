(function() {
  'use strict';
  angular.module('eva2-angular').controller('CsvProcessCtrl', function($modal, $modalInstance, ApiService, Notifier, $q, postData, domainName, $timeout, tFilter) {
    const vm = this;
    vm.domain = domainName;
    vm.jobBatches = splitJobsIntoBatches(postData);
    vm.totalJobNumber = postData.length;
    initializeStatistics();
    getApiKey(vm.domain).then((apiKey)=> {
      sendJobs(vm.jobBatches, apiKey);
    }, (error) => {
      Notifier.display(tFilter('SETTING.CSV_IMPORTER.ERROR.API_KEY_FAIL'), {type: 'failure', ttl: 6000}, error);
    });

    function getApiKey(domain) {
      return ApiService.getDomainData(domain)
        .then((data) => data.apiKey);
    }

    /**
     * Send jobs to external api.
     * One request is sent only if the previous request has succeeded.
     * There is 100 second waiting time between each request, due to limit of document service
     * @param jobBatches{Array} example: [[...1000 jobs], [...1000 jobs], [...200 jobs]]
     * @param apiKey {String}
     */

    function sendJobs(jobBatches, apiKey) {
      const WAITING_TIME_BETWEEN_REQUESTS = 12500;
      const responseDataArray = [];
      const promise = jobBatches.reduce(function(lastPromise, batch, index) {
        return lastPromise.then(function(responseData) {
          updateStatistics(responseData.accepted.length, responseData.rejected.length, vm.totalJobNumber);
          responseDataArray.push(responseData);
          if (index === jobBatches.length - 1) {
            return lastPromise;
          }
          const delayedNewPromise = $q(function(resolve) {
            $timeout(()=> resolve(ApiService.postJobsFromCSV(jobBatches[index + 1], apiKey)), WAITING_TIME_BETWEEN_REQUESTS);
          });
          return delayedNewPromise;
        });
      }, ApiService.postJobsFromCSV(jobBatches[0], apiKey));

      promise.then(() => {handleSuccess(responseDataArray);}, handleFailure);
    }

    function initializeStatistics() {
      vm.numberOfJobSent = 0;
      vm.numberOfJobAccepted = 0;
      vm.percentageOfJobSent = 0;
      vm.numberOfJobRejected = 0;
    }

    function updateStatistics(newlyAccepted, newlyRejected, totalJobNumber) {
      vm.numberOfJobSent = vm.numberOfJobSent + newlyAccepted + newlyRejected;
      vm.percentageOfJobSent = Math.ceil(vm.numberOfJobSent / totalJobNumber * 100);
      vm.numberOfJobAccepted = vm.numberOfJobAccepted + newlyAccepted;
      vm.numberOfJobRejected = vm.numberOfJobRejected + newlyRejected;
      angular.element('.csv-process-modal .progress-bar').css('width', `${vm.percentageOfJobSent}%`);
    }

    function handleFailure(error) {
      $modalInstance.close();
      Notifier.display(tFilter('SETTING.CSV_IMPORTER.ERROR.IMPORT_INTERRUPTED', {numberOfJobSent: vm.numberOfJobSent}, 'messageformat'), {type: 'failure', ttl: 60000}, error);
    }

    function handleSuccess(responseDataArray) {
      const rejectedArray = responseDataArray.reduce((acc, val) => acc.concat(val.rejected), []);
      const errorNum = rejectedArray.length;
      const errorMsg = getErrorMsg(rejectedArray);

      $modalInstance.close();
      $modal.open({
        template: require('@partial/modals/csv/csvComplete/csvComplete.html'),
        controller: 'CsvCompleteCtrl',
        controllerAs: 'vm',
        windowClass: 'no-fade',
        backdrop: 'static',
        resolve: {
          errorNum: () => errorNum,
          totalNum: () => postData ? postData.length : 0,
          domain: () => vm.domain,
          errorMsg: () => errorMsg
        }
      });
    }

    /**
     * Split jobs into multiple batches, each batch has the size of 1000 (the last array could have less than 1000 jobs)
     * @param jobs {Array} an array of job objects. example: [{_id: '1'}, {_id: '2'}]
     * @returns {Array} an array of arrays. example: [[...1000 jobs], [...1000 jobs], [...200 jobs]]
     */

    function splitJobsIntoBatches(jobs) {
      const defaultBatchSize = 100;
      const results = [];
      let i = 0;
      let slicedBatch;
      do {
        slicedBatch = jobs.slice(i * defaultBatchSize, (i + 1) * defaultBatchSize);
        if (slicedBatch.length !== 0) {
          results.push(slicedBatch);
        }
        i++;
      } while (slicedBatch.length === defaultBatchSize);

      return results;
    }

    function getErrorMsg(rejectData) {
      const firstError = !!rejectData && !!rejectData[0] && !!rejectData[0].errors && rejectData[0].errors[0];
      if (firstError) {
        return 'Error: ' + parsePath(firstError.dataPath) + firstError.message;
      }
      return '';

      function parsePath(dataPath) {
        if (typeof dataPath === 'string') {
          const arr = dataPath.split('/');
          arr.shift();
          return '[' + arr.join(':') + '] ';
        } else {
          return '';
        }
      }
    }
  });
})();
