(function() {
  'use strict';
    
  angular
    .module('eva2-angular')
    .controller('GalleryWithZoomModalCtrl', GalleryWithZoomModalCtrl);
    
    
  function GalleryWithZoomModalCtrl(vm, $modal, $timeout) {
    vm.openZoomModal = () => {
      $timeout(()=>{
        if(vm.mainVideoPlayer){
          vm.mainVideoPlayer.muted = true;
        }
        vm.ytPlayer && vm.ytPlayer.pauseVideo();
        vm.firePauseListener = false;
      });
      const videoCurrentTimeYoutube = vm.ytPlayer ? vm.ytPlayer.getCurrentTime() : 0;
      const videoCurrentTime = vm.mainVideoPlayer ? vm.mainVideoPlayer.currentTime : 0;
      const modalInstance = $modal.open({
        template: require('@directive/moderation/mediagallery/modal/mediagallerymodal.html'),
        controller: 'MediaGalleryModalCtrl',
        controllerAs: 'vm',
        bindToController: true,
        windowClass: 'media-gallery-modal',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          images: () => vm.images,
          videos: ()=> vm.videos,
          selectedKey: ()=> vm.selectedKey,
          isDisabled: () => vm.isDisabled,
          showBoundingBox: () => vm.showBoundingBox,
          videoCurrentTime: () => videoCurrentTime,
          videoCurrentTimeYoutube: () => videoCurrentTimeYoutube
        }
      });
  
      modalInstance.result.then(function(result = {}) { 
        const videoCurrentTimeYoutube = result.ytPlayer ? result.ytPlayer.getCurrentTime() : 0;
        const videoCurrentTime = result.mainVideoPlayer ? result.mainVideoPlayer.currentTime : 0;
        vm.selectGalleryItem(result.selectedKey);
        if(videoCurrentTime){
          vm.mainVideoPlayer.currentTime = videoCurrentTime;
          vm.mainVideoPlayer.muted = false;
        }
        if(videoCurrentTimeYoutube){
          vm.ytPlayer.seekTo(videoCurrentTimeYoutube);    
          vm.setPlaying(vm.selectedKey,false);    
        }
      });
    };
  }
})();
    