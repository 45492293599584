
(function() {
  'use strict';

  function Controller($timeout, $scope) {
    let vm = this;
    let videoPlayer;
    vm.$onInit = () => {

      const videoPlayerOld = document.getElementById(vm.playerId);
   
        
      if (videoPlayer) {
        videoPlayer.el().addEventListener( 'loadedmetadata', onLoad, false);
        videoPlayerOld.addEventListener( 'loadedmetadata', onLoad, false);
      }  
    };
    vm.toggleIconClicked = (event)=> {
      event.stopPropagation();
      vm.toggleVideo();
    };
    vm.ytVideoParameters = {modestbranding: 1,rel:0};
    const getActionContainerStyle = (containerSize, contentSize)=> {
      const widthRatio = contentSize.width / containerSize.width;
      const heightRatio = contentSize.height / containerSize.height;
      const isWidthRatioBigger = widthRatio > heightRatio;
      const ratio =  isWidthRatioBigger? widthRatio : heightRatio;
      const displayedContentSize = {
        width: contentSize.width / ratio, 
        height: contentSize.height / ratio
      };
      return isWidthRatioBigger ? {
        top: (containerSize.height - displayedContentSize.height) / 2 + 'px',
        right: '0px'
      }: {
        top: '0px',
        right: (containerSize.width - displayedContentSize.width) / 2 + 'px'
      };
    };

    function onLoad() {
      const width = vm.videoWidth;
      const height = vm.videoHeight;
      const contentSize = {width, height};
      $timeout(()=> {
        vm.actionContainerStyle = getActionContainerStyle(vm.containerSize, contentSize);
      });
    }


    vm.$onDestroy = ()=> {
      if (videoPlayer) {
        videoPlayer.el().removeEventListener( 'loadedmetadata', onLoad, false);
      }
    };

    $scope.$watch('vm.url', ()=> {
      vm.actionContainerStyle = null;
    });
    
  }

  angular
    .module('eva2-angular')
    .component('evaGalleryVideoPlayer', {
      bindings: {
        isDisabled: '<',
        deleted: '<',
        playing: '<',
        url: '<',
        toggleVideo: '&',
        playerId: '@',
        containerSize: '<',
        showActionIcons: '<',
        openZoomModal: '&',
        isYoutubeVideo: '<',
        sourceType: '<'
      },
      template: require('./galleryVideoPlayer.html'),
      controller: Controller,
      controllerAs: 'vm'
    });
})();
