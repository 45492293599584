import _ from 'lodash';

(function() {
  'use strict';
  angular.module('eva2-angular').directive('manualModerationKeyboardControl', function(KeyboardCode, $state, $rootScope, $timeout, ScrollUtils) {
    'ngInject';
    const bottomBarHeight = 50;
    const topNavigationHeight = 100;
    const jobMarginTop = 40;
    const previousJobShadowHeight = 3;
    const ANIMATION_DURATION = 100;
    const keyboardFocusClass = 'eva-keyboard-focused';
    const refuseOrEscalateButtonClass = 'eva-drop-down-with-search';
    const feedbackButtonClass = 'eva-multi-select-with-search';
    const jobGroupClass = 'job-group';
    const approveButtonClass = 'btn-approve';
    const DEBOUNCE_SCROLL_TIME = 50;
    const refusalListItemsClass = '.eva-drop-down-with-search.refuse .eva-dropdown-container li';
    const escalateListItemsClass= '.eva-drop-down-with-search.escalate .eva-dropdown-container li';
    const refuseButtonClass = `.refuse.${refuseOrEscalateButtonClass}`;
    const escalateButtonClass = `.escalate.${refuseOrEscalateButtonClass}`;

    return {
      restrict: 'CA',
      link: function(scope, element) {
        const isInSingleAdView = $state.current.name === 'app.assignment.ad';
        function keyDownListener($event) {
          if (isDisplayingAModal()) {
            return;
          }
          if (isInSingleAdView && $('.edit-mode .eva-job').length === 0) {
            return;
          }
          if ($('.quick-add-to-list--open:visible').length > 0 || $('.moderation-notes__create-note__text-area:focus').length > 0) {
            return;
          }

          const focusedComponentsThatShouldOnlyRespondToESCKeys = $('.eva-keyboard-esc-only:focus');
          if (focusedComponentsThatShouldOnlyRespondToESCKeys.length > 0) {
            if ($event.keyCode === KeyboardCode.ESC) {
              $timeout(()=> {
                focusedComponentsThatShouldOnlyRespondToESCKeys.scope().$ctrl.resetToOriginalValue();
              });
            }           
            return;
          }

          const allFocusableComponents = element.find('.eva-keyboard-focusable');
          const openedHoverComponent = element.find(`.open.refuse.${refuseOrEscalateButtonClass},
            .open.escalate.${refuseOrEscalateButtonClass}`).first();
          const openedFeedbackComponent = element.find(`.open.${feedbackButtonClass}`).first();
          const focusedApproveButton = element.find(`.${approveButtonClass}.${keyboardFocusClass}`).first();
          const focusedSubmitButton = element.find('.js-eva-jobs-submit-btn button.eva-keyboard-focused').first();
          const allJobGroups = $(`.${jobGroupClass}`);

          switch ($event.keyCode) {
          case (KeyboardCode.ARROW_LEFT):
          case (KeyboardCode.ARROW_RIGHT): {
            $event.stopPropagation();
            $event.preventDefault();
            debounced(allFocusableComponents, $event, KeyboardCode); break;
          }
          case (KeyboardCode.ENTER):
            handleEnterKey(focusedSubmitButton, focusedApproveButton, openedHoverComponent,
              openedFeedbackComponent); break;
          case (KeyboardCode.ARROW_UP):
          case (KeyboardCode.ARROW_DOWN):
            handleUpDownKey(openedHoverComponent, openedFeedbackComponent, $event, KeyboardCode); break;
          case (KeyboardCode.ESC):
            handleEscKey(); break;
          case (KeyboardCode.APPROVE):
            handleApproveKey(allJobGroups); break;
          case (KeyboardCode.REFUSE):
            handleRefuseKey($event, allJobGroups, refusalListItemsClass, refuseButtonClass); break;
          case (KeyboardCode.SEND_TO_QUEUE):
            handleSendToQueueKey($event, allJobGroups, escalateListItemsClass, escalateButtonClass); break;
          case (KeyboardCode.HIDE_MENU):
            handleHideMenuKey($event); break;
          default:
          }
        }

        function keyUpListener($event) {
          // releasing the hide menu key means to unhide the menu
          if ($('.quick-add-to-list--open:visible').length > 0) {
            return;
          }
          if ($event.keyCode === KeyboardCode.HIDE_MENU) {
            const currentFocusedComponent = getFocused();
            if (currentFocusedComponent.isFeedbackComponent() || currentFocusedComponent.isRefuseOrEscalateButton()) {
              currentFocusedComponent.addClass('open');
            }
          }
        }

        $(document).on('keydown', keyDownListener);
        $(document).on('keyup', keyUpListener);
        scope.$on('$destroy', ()=> {
          $(document).off('keydown', keyDownListener);
          $(document).off('keyup', keyUpListener);
        });

        const debounced = _.debounce(moveBetweenFocusableComponent, DEBOUNCE_SCROLL_TIME);

        function moveBetweenFocusableComponent(items, $event, KeyboardCode) {
          const currentFocusedComponent = getFocused();
          let currentFocusIndex = items.index(currentFocusedComponent);

          if (currentFocusedComponent.isFeedbackComponent() || currentFocusedComponent.isRefuseOrEscalateButton()) {
            currentFocusedComponent.closeDropDown();
          }

          switch ($event.keyCode) {
          case (KeyboardCode.ARROW_LEFT): {
            if (currentFocusIndex === -1) {
              currentFocusIndex = items.length - 1;
            } else if (currentFocusIndex > 0) {
              currentFocusIndex--;
            }
            break;
          }
          case (KeyboardCode.ARROW_RIGHT): {
            if (currentFocusIndex === -1) {
              currentFocusIndex = 0;
            } else if (currentFocusIndex < items.length - 1) {
              currentFocusIndex++;
            }
            break;
          }
          default:
          }

          const nextFocusedComponent = items.eq(currentFocusIndex);
          if (nextFocusedComponent) {
            nextFocusedComponent.setFocus();
          }
          if (nextFocusedComponent.isAJobGroup()) {
            // if the next focused item is an ad, go to the top of that ad
            const jobTop = nextFocusedComponent.offset().top;
            if (!ScrollUtils.isFullyDisplayedInQueueView(jobTop, jobTop + nextFocusedComponent.height())) {
              $('html, body').animate({
                scrollTop:
                    jobTop - topNavigationHeight - jobMarginTop + previousJobShadowHeight
              },
              ANIMATION_DURATION);
            }
          } else {
            const closetJob = nextFocusedComponent.getClosestJobGroup();
            if (closetJob.length > 0 && !ScrollUtils
              .isFullyDisplayedInQueueView(closetJob.offset().top, closetJob.offset().top + closetJob.height())) {
              scrollToBottomOfJob(closetJob);
            }
          }

          if ((nextFocusedComponent.isFeedbackComponent() && !nextFocusedComponent.hasClass('disabled'))
            || nextFocusedComponent.isRefuseOrEscalateButton()) {
            nextFocusedComponent.openDropDown();
          }
        }

        function handleEnterKey(focusedSubmitButton, focusedApproveButton, openedHoverComponent, openedFeedbackComponent) {
          // when focusing on the submit button
          if (focusedSubmitButton.length > 0) {
            focusedSubmitButton.trigger('click');
            return;
          }

          // when focusing on the feedback component
          if (openedFeedbackComponent.length > 0) {
            const items = openedFeedbackComponent.find('.eva-dropdown-container li');
            const currentFocusedItem = items.filter('.focused');
            if (items.length === 1) {
              items.first().find('input').trigger('click');
            } else {
              currentFocusedItem.find('input').trigger('click');
            }
            return;
          }

          const currentFocusedComponent = getFocused();

          // when focusing on the approve button
          if (focusedApproveButton.length > 0) {
            focusedApproveButton.trigger('click');
            return;
          }

          // when focusing on the refuse/escalate button
          if (openedHoverComponent.length > 0) {
            const items = openedHoverComponent.find('.eva-dropdown-container li');
            const currentFocusedItem = items.filter('.focused');
            if (items.length === 1) {
              items.first().trigger('click');
              currentFocusedComponent.closeDropDown();
            } else if (currentFocusedItem.length === 1) {
              currentFocusedItem.trigger('click');
              currentFocusedComponent.closeDropDown();
            }
          }
        }

        function handleUpDownKey(openedHoverComponent, openedFeedbackComponent, $event, KeyboardCode) {
          if (openedHoverComponent.length > 0) {
            handleUpDownKeyForHoverComponent(openedHoverComponent, $event);
          } else if (openedFeedbackComponent.length > 0) {
            handleUpDownKeyForFeedbackComponent(openedFeedbackComponent, $event);

          }

          function handleUpDownKeyForFeedbackComponent(component, $event) {
            const items = component.find('.eva-dropdown-container li');
            const allItemsHeight = items.map(function() {
              return $(this).outerHeight();
            }).get();
            const currentFocusedItem = items.filter('.focused');
            const currentFocusIndex = items.index(currentFocusedItem);
            const itemsContainer = component.find('.eva-dropdown-container');

            updateFocusedItemInDropDownMenu(items, currentFocusIndex, itemsContainer, allItemsHeight, $event);
          }


          function handleUpDownKeyForHoverComponent(component, $event) {
            return handleUpDownKeyForFeedbackComponent(component, $event);
          }

          function updateFocusedItemInDropDownMenu(items, currentFocusIndex, itemsContainer, allItemsHeight, $event) {
            items.eq(currentFocusIndex).removeClass('focused');

            switch ($event.keyCode) {
            case (KeyboardCode.ARROW_UP): {
              if (currentFocusIndex === -1) {
                currentFocusIndex = items.length - 1;
              } else if (currentFocusIndex > 0) {
                currentFocusIndex--;
              }
              break;
            }
            case (KeyboardCode.ARROW_DOWN): {
              if (currentFocusIndex === -1) {
                currentFocusIndex = 0;
              } else if (currentFocusIndex < items.length - 1) {
                currentFocusIndex++;
              }
              break;
            }
            default:
            }

            items.eq(currentFocusIndex).addClass('focused');
            scrollToTheFocusedItemInDropDownMenu(itemsContainer, currentFocusIndex, allItemsHeight, items);
            $event.stopPropagation();
            $event.preventDefault();
          }
          function scrollToTheFocusedItemInDropDownMenu(itemsContainer, currentFocusIndex, allItemsHeight, items) {
            items.css('pointer-events', 'none');
            const itemsContainerHeight = itemsContainer.height();
            const containerScrollTop = itemsContainer.scrollTop();
            const itemTop = currentFocusIndex === 0 ? 0 :
              allItemsHeight.slice(0, currentFocusIndex).reduce((acc, cur)=> acc + cur);
            const itemHeight = allItemsHeight[currentFocusIndex];

            if (itemTop < containerScrollTop) {
              // when item is hidden from the top of the drop down menu
              itemsContainer.animate({scrollTop: itemTop}, 0, function() {
              });
            } else if (itemTop + itemHeight > containerScrollTop + itemsContainerHeight) {
              // when item is hidden in the bottom of the drop down menu
              itemsContainer.animate({scrollTop: itemTop + itemHeight - itemsContainerHeight}, 0, function() {
              });
            }
            $timeout(()=> {
              items.css('pointer-events', 'auto');
            }, 80);
          }
        }


        function handleEscKey() {
          getFocused().find('input').val('');
          $rootScope.$emit('escKeyPressed');
        }

        function handleApproveKey(allJobGroups) {
          if (isWritingInInput()) {
            return;
          }
          const focusedJob = getFocusedJob();
          if (focusedJob.length === 0) {
            return;
          }
          const approveButton = focusedJob.getApproveButton();
          if (allJobGroups.index(focusedJob) === allJobGroups.length - 1) {
            // normally when you press A for an ad, it makes a decision and scrolls to the next ad
            // however when it's the last ad, there is no "next ad", so we scroll to the bottom of the page.
            $('html, body').animate({scrollTop: document.body.scrollHeight},
              ANIMATION_DURATION);
          }
          approveButton.trigger('click');
        }

        function handleRefuseKey($event, allJobGroups, listItemClass, buttonClass) {
          if (isWritingInInput()) {
            return;
          }
          const focusedJob = getFocusedJob();
          if (focusedJob.length === 0) {
            return;
          }

          const buttons = focusedJob.find(buttonClass);
          const listItems = focusedJob.find(listItemClass);
          if ((buttons.length === 1) && (listItems.length === 1)) {
            // if there is only one button, and this button only has one item in the drop down list,
            // select the item directly
            listItems.eq(0).trigger('click');
          } else {
            const firstButton = buttons.first();
            if (firstButton) {
              firstButton.setFocus();
              firstButton.openDropDown();
              if (!isInSingleAdView) {
                scrollToBottomOfJob(focusedJob);
              }
            }
          }

          if (allJobGroups.index(focusedJob) === allJobGroups.length -1) {
            // when it's the last ad, there is no "next ad", so we scroll to the bottom of the page.
            $('html, body').animate({scrollTop: document.body.scrollHeight},
              ANIMATION_DURATION);
          }

          // prevent propagation
          $event.stopPropagation();
          $event.preventDefault();
        }

        function handleSendToQueueKey($event, allJobGroups, listItemClass, buttonClass) {
          return handleRefuseKey($event, allJobGroups, listItemClass, buttonClass);
        }

        // ------------------------- internal methods -----------------------------------------------
        function scrollToBottomOfJob(job) {
          if (!job || job.length === 0) {
            return;
          }
          $('html, body').animate({scrollTop:
              job.offset().top + job.height() - window.innerHeight + bottomBarHeight},
          ANIMATION_DURATION);
        }

        $.fn.getApproveButton = function() {
          return this.find('.btn-approve').first();
        };

        function getFocused() {
          return $(`.${keyboardFocusClass}`);
        }

        function getFocusedJob() {
          if (isInSingleAdView) {
            return $('.edit-mode .eva-job');
          }
          const focused = getFocused();
          return focused.hasClass(jobGroupClass) ? focused : focused.closest(`.${jobGroupClass}`);
        }

        function isWritingInInput() {
          return $('input:focus, textarea:focus').length > 0;
        }

        function isDisplayingAModal() {
          return $('body > .modal').length > 0 || document.querySelectorAll('.ReactModal__Content').length > 0;
        }

        function handleHideMenuKey() {
          const focused = getFocused();
          if (getFocused().hasClass('open')) {
            focused.removeClass('open');
            focused.find('input').blur();
          }
        }

        $.fn.setFocus = function() {
          getFocused().removeClass(keyboardFocusClass);
          this.addClass(keyboardFocusClass);
        };

        $.fn.isFeedbackComponent = function() {
          return this.hasClass(feedbackButtonClass);
        };

        $.fn.isRefuseButton= function() {
          return this.hasClass(refuseOrEscalateButtonClass) && this.hasClass('refuse');
        };

        $.fn.isEscalateButton= function() {
          return this.hasClass(refuseOrEscalateButtonClass) && this.hasClass('escalate');
        };

        $.fn.isRefuseOrEscalateButton = function() {
          return this.hasClass(refuseOrEscalateButtonClass) && (this.hasClass('refuse') || this.hasClass('escalate'));
        };

        $.fn.openDropDown = function() {
          this.addClass('open');
          $timeout(()=> {
            this.find('input.search-input').focus();
          }, ANIMATION_DURATION + 100);
        };

        $.fn.closeDropDown = function() {
          /**
           * fix for firefox bug: https://bugzilla.mozilla.org/show_bug.cgi?id=27771
           * The bug is: when you have focus in an input, the page won't scroll anymore
           * Fix: blur the input
           */
          this.find('input.search-input').blur();
          return this.removeClass('open');
        };

        $.fn.isAJobGroup = function() {
          return this.hasClass(jobGroupClass);
        };

        $.fn.getClosestJobGroup = function() {
          return this.closest(`.${jobGroupClass}`);
        };
      }
    };
  });
})();


