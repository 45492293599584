angular.module('eva2-angular').service('MomentUtil', function MomentUtil() {
  'use strict';

  this.getMoment = function(date) {
    if (moment.isMoment(date)) {
      return date;
    }
    if (typeof date === 'number') {
      return moment(date);
    }
    if (typeof date === 'string' || date instanceof Date) {
      const dateObject = moment(new Date(date));
      if (dateObject.isValid()) {
        return dateObject;
      }
    }
  };
});
