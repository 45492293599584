(function() {
  'use strict';
  angular.module('eva2-angular').service('treeDataHelper', function(Enum) {
    function getValueDepth(data) {
      let level = 0;
      if (Array.isArray(data) && data.length > 0) {
        level = 1;
        data.forEach((item) => {
          if (!Object.prototype.hasOwnProperty.call(item, 'subCategories')) return;
          const depth = getValueDepth(item.subCategories) + 1;
          level = Math.max(depth, level);
        });
      }
      return level;
    }

    function getOpenMode(data) {
      const depth = getValueDepth(data);
      switch (depth) {
      case 0:
        return Enum.TREE_OPEN_MODE.NO_DATA;
      case 1:
        return Enum.TREE_OPEN_MODE.ONE_LEVEL_DROPDOWN;
      case 2:
        return Enum.TREE_OPEN_MODE.TWO_LEVEL_DROPDOWN;
      default:
        return Enum.TREE_OPEN_MODE.TREE;
      }
    }

    function mapValueToDropDownData(arr) {
      return arr
        .filter((item) => Object.prototype.hasOwnProperty.call(item, 'id') 
        && Object.prototype.hasOwnProperty.call(item, 'name'))
        .map((item) => {
          const obj = {id: item.id, text: item.name};
          if (Object.prototype.hasOwnProperty.call(item, 'subCategories')) {
            obj.children = mapValueToDropDownData(item.subCategories);
          }
          return obj;
        });
    }

    return {
      getOpenMode: getOpenMode,
      mapValueToDropDownData: mapValueToDropDownData
    };
  });
})();
