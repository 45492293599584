import sampleCSVFile from '../../../../res/sample_csv_implio.csv';
(function() {
  'use strict';

  function Controller($modal, $state, $stateParams, tFilter) {
    const vm = this;
    vm.resources = {
      button: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.NEXT_STEP_BTN'),
      needHelp: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.NEED_HELP_TEXT'),
      howToFormat: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.FORMAT_CSV_FILE_LINK'),
      downloadSample: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.DOWNLOAD_SAMPLE_LINK'),
      exportExcelLink: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.EXPORT_CSV_TO_EXCEL_LINK'),
      csvHelp: {
        title: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.FORMAT_CSV_FILE_LINK'),
        text: [
          tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.FORMAT_CSV_FILE_MODAL_TEXT1'),
          tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.FORMAT_CSV_FILE_MODAL_TEXT2'),
          tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.FORMAT_CSV_FILE_MODAL_TEXT3'),
          tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.FORMAT_CSV_FILE_MODAL_TEXT4'),
          tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.FORMAT_CSV_FILE_MODAL_TEXT5'),
          tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.FORMAT_CSV_FILE_MODAL_TEXT6')
        ]
      }
    };

    vm.sampleCSVFile = sampleCSVFile;

    vm.FILE_UPLOAD_STATES = {
      EMPTY: 0,
      HAS_FILE: 1,
      ERROR: 2,
      READY: 3
    };

    if ($stateParams.data && $stateParams.file) {
      vm.data = $stateParams.data;
      vm.file = $stateParams.file;
    }

    vm.resetFile = $stateParams.resetFile;

    vm.goToNextStep = function() {
      $state.go('app.assignment.csvImporter.step1');
    };


    vm.openExplanationModal = () => {
      $modal.open({
        template: require('@partial/modals/info/info.html'),
        controller: 'InfoModalCtrl',
        controllerAs: 'vm',
        windowClass: 'no-fade',
        backdrop: 'static',
        resolve: {
          data: () => vm.resources.csvHelp
        }
      });
    };
  }

  angular
    .module('eva2-angular')
    .component('upload', {
      bindings: {
        data: '=',
        file: '=',
        reset: '&'
      },
      template: require('./upload.html'),
      controller: Controller,
      controllerAs: 'vm'
    });
})();

