(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('evaList', evaList);

  function evaList() {
    return {
      restrict: 'E',
      transclude: {
        noData: '?noData',
        noResults: '?noResults',
        hasData: 'hasData',
        isLoading: 'isLoading',
        hasError: 'hasError'
      },
      replace: true,
      template: require('./list.html'),
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        data: '<',
        emptyQueryFn: '&'
      }
    };
  }


  function Controller($scope) {
    const vm = this;

    function getState(data) {
      const isLoading = data.loading;
      const hasError = data.error;

      let hasData = false;
      let hasNoData = false;
      let hasNoResults = false;

      if (Array.isArray(data.items)) {
        const total = parseInt(data.total === -1 ? data.items.length : data.total || data.items.length);
        const emptyQuery = $scope.emptyQueryFn();
        if (total === 0) {
          if (emptyQuery && !isLoading) {
            hasNoData = true;
          } else {
            hasNoResults = true;
          }
        } else {
          hasData = true;
        }
      }

      return {
        isLoading,
        hasError,
        hasNoData,
        hasNoResults,
        hasData
      };
    }

    function update(data) {
      angular.extend(vm, getState(data || {}));
    }

    $scope.$watch('data', function(newData, oldData) {
      if (newData === oldData) {
        return;
      }
      update(newData);
    });

    update($scope.data);
  }
})();
