(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('LoginCtrl', LoginCtrl);

  function LoginCtrl($scope, $state, $stateParams, ApiAuthentication, Notifier, storage, goToDefault,
    tFilter,ApiService, $timeout, checkTeamCreationStatus) {
    'ngInject';

    let authenticated;
    let isAccountCreatedPromise;
    // get invite params
    const teamname = $stateParams.inviteParams.teamname;
    const inviteToken = $stateParams.inviteParams.token;
    const inviteEmail = $stateParams.inviteParams.email;
    const inviteDomain = $stateParams.inviteParams.domain;
    $scope.loginBtnMessage = tFilter('LOG_IN.LOG_IN_BTN');

    let msg = {
      default: tFilter('LOG_IN.TITLE'),
      fromInvite: tFilter('LOG_IN.INVITATION_TEXT', {TEAM_NAME: teamname}, 'messageformat')
    };

    //configure the UI depends on from invite or not
    $scope.titleMsg = (inviteToken) ? msg.fromInvite : msg.default;
    $scope.fromInvite = true;
    if (!!inviteEmail && !!teamname && !!inviteToken) {
      //watch handeler for ngmodel, and set the predined/unchangable value to email and name
      const unwatch = $scope.$watch('login', function(model) {
        if (!model) {
          return;
        }
        unwatch();
        $scope.login.username = inviteEmail;
      });
    } else {
      $scope.fromInvite = false;
    }

    Notifier.display($stateParams.message, {type: 'error', ttl: 8000});


    const handleSuccessAndRedirect = function() {
      const disallowHistoryBack = {location: 'replace'};

      if ($stateParams.returnState) {
        $state.go($stateParams.returnState, $stateParams.returnParams);
      } else if (inviteDomain) {
        //  $state.go('app.assignment.jobs', { domain: inviteDomain }, disallowHistoryBack);
        $state.go('app.assignment.overview.statistics', {domain: inviteDomain, inviteParams: {inviteTeamName: teamname}}, disallowHistoryBack);
      } else {
        goToDefault();
      }
    };

    const handleError = function(err) {
      if (!err) {
        return;
      }

      const wrongCredentialsError = err.status && err.status === 401;
      if (wrongCredentialsError) {
        return;
      }

      let msg = tFilter('LOG_IN.ERROR.SERVER_ERROR');

      if (err.status === -1) {
        msg = tFilter('LOG_IN.ERROR.CONNECT_ERROR');
      }

      Notifier.display(msg, {type: 'error', ttl: 8000});
    };

    const setLoadingToFalse = function() {
      $scope.loading = false;
    };

    const login = () => {
      ApiAuthentication.login($scope.login.username, $scope.login.password, inviteToken)
        .then(handleSuccessAndRedirect)
        .catch(handleError)
        .finally(setLoadingToFalse);
    };

    const onTeamCreationSuccess = () => login();

    const onTeamCreationFailure = (err) => {
      $scope.loading = false;
      $scope.teamIsBeingCreated = false;
      if(err){
        Notifier.display(tFilter('SIGN_UP.ERROR.TEAM_CREATION_ERROR'), {type: 'error', ttl: 8000}, err);
      } else {
        Notifier.display(tFilter('SIGN_UP.ERROR.TEAM_CREATION_ERROR'), {type: 'error', ttl: 8000});
      }
    };

    $scope.login = async function() {
      $scope.loading = true;
      authenticated = storage.getItem('authenticated');
      if(authenticated){
        const user = await ApiService.getUser();
        const domain = user.authenticated.domains[0].domain;
        $scope.teamIsBeingCreated = true;
        checkTeamCreationStatus(domain,20, onTeamCreationSuccess, onTeamCreationFailure);
      }  else {
        login();
      }
    };

    $scope.validateServerSideFields = function() {
      // NOTE: This is a page/view specific work-around, since the username fields only does client-side validation it's assumed to validate according to angular but
      // our logic is that we group login/password credentials errors into one and attach to the password input it doesn't trigger re-validation.
      if (!!$scope.loginForm && Object.prototype.hasOwnProperty.call($scope.loginForm, 'password')) $scope.loginForm.password.$validate();
    };

    $scope.$watch('teamIsBeingCreated', function(newVal, oldVal) {
      if (oldVal !== newVal && newVal) {
        $scope.loginBtnMessage = tFilter('SIGN_UP.TEAM_CREATION_TEXT');
      }
      if (oldVal !== newVal && newVal === false) {
        $scope.loginBtnMessage = tFilter('LOG_IN.LOG_IN_BTN');
      }
    });

    $scope.$on('$destroy', function(){
      if (isAccountCreatedPromise) {
        $timeout.cancel(isAccountCreatedPromise);
      }
    });
  }
})();
