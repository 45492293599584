(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .service('htmlSanitizer', htmlSanitizer);

  /**
   * @param html
   * @param allowed {string} all the html tags allowed
   * @description use "PHP's strip_tags in JavaScript" to allow certain html tags http://locutus.io/php/strings/strip_tags/
   */
  function filterHTMLTag(html, allowed) {
    allowed = (((allowed || '') + '')
      .toLowerCase()
      .match(/<[a-z][a-z0-9]*>/g) || [])
      .join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
    const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
    return html.replace(commentsAndPhpTags, '')
      .replace(tags, function($0, $1) {
        return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
      });
  }

  function htmlSanitizer($sce) {
    'ngInject';
    function sanitize(str) {
      if (!str) return;
      try {
        return $sce.getTrustedHtml(filterHTMLTag(str, '<i><b><br><ul><li><ol><strong><small><strike><span>')) // use $sce to prevent XSS attacks
          .replace(/{/g, '{<!---->') //escape curly braces in the string
          .replace(/}/g, '<!---->}');
      } catch (e) {
        // $sce.getTrustedHtml expects function param to be valid html
        // so if it's not valid html, escape the "<" ">" characters
        return str.replace(/</g, '&lt;').replace(/>/g, '&gt;');
      }
    }

    return {
      sanitize
    };
  }
})();
