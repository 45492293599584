(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .filter('includesCurrentState', includesCurrentState);

  function includesCurrentState($state) {
    return function(allStatesUnderNavTab) {
      if (!Array.isArray(allStatesUnderNavTab)) {
        return;
      }
      return allStatesUnderNavTab.includes($state.current.name);
    };
  }
})();


