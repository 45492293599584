(function() {
  'use strict';
  angular.module('eva2-angular').service('sortCategories', function() {
    function sortCategories(categories) {
      sort(categories);
      return categories;

      function sort(categories) {
        if (!Array.isArray(categories)) {
          return;
        }
        categories.sort(compare);
        categories.forEach((item) => {
          if (Object.prototype.hasOwnProperty.call(item, 'subCategories')) {
            sort(item.subCategories);
          }
        });
      }

      function compare(a, b) {
        return a.name < b.name ? -1 : 1;
      }
    }

    return sortCategories;
  });
})();
