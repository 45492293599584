import _ from 'lodash';
(function() {
  'use strict';

  function Controller($scope, tFilter) {
    'ngInject';
    const vm = this;

    //private
    let selectedRefuseDecisionKey;

    const DECISION_TYPE = {
      APPROVE: 'approve',
      REFUSE: 'refuse',
      DEFER: 'defer'
    };
    vm.$onInit = () => {
      vm.DECISION_TYPE = DECISION_TYPE;
    };
    vm.$onDestroy = () => {
      vm.onSelectDecision({decision: null, decisionText: null});
    };

    $scope.$watch('$ctrl.reset', (newVal, oldValue)=> {
      if (!oldValue && newVal) {
        vm.selectedDecision = null;
        vm.onSelectDecision({decision: null, decisionText: null});
      }
    });



    vm.approve = function() {
      vm.selectedDecision = {type: vm.DECISION_TYPE.APPROVE};
      vm.onSelectDecision({decision: vm.selectedDecision, decisionText: getText(vm.DECISION_TYPE.APPROVE)});
    };

    /**
     * decisionSet is like: [
     * { key: 'scam',
     *   values: [{id: 1, name: 'refusal reason 1'},{id: 2, name: 'refusal reason 2'}]
     * },
     * { key: 'illegal',
     *   values: [{id: 3, name: 'refusal reason 3'},{id: 4, name: 'refusal reason 4'}]
     * },
     * ]
     *
     * refuseDecisionKey is the button text of a refuse button (in this case, "scam" and "illegal")
     */
    vm.refuse = function(refuseDecisionKey, reason) {
      selectedRefuseDecisionKey = refuseDecisionKey;
      vm.selectedDecision = {type: vm.DECISION_TYPE.REFUSE, reason: reason};
      vm.onSelectDecision({decision: vm.selectedDecision, decisionText:
          getText(vm.DECISION_TYPE.REFUSE, reason.name)});
    };

    vm.isSelectedDecision = function(decisionType, refuseDecisionKey) {
      const selectedDecisionType = _.get(vm.selectedDecision, 'type');
      if (decisionType === selectedDecisionType) {
        if (decisionType === vm.DECISION_TYPE.REFUSE) {
          return selectedRefuseDecisionKey === refuseDecisionKey;
        }
        return true;
      }
    };

    function getText(decisionType, refuseReasonName) {
      return tFilter('SEARCH.HEADER.DECISION_TEXT', {
        DECISION: decisionType,
        REFUSE_REASON: refuseReasonName
      }, 'messageformat');
    }
  }

  angular
    .module('eva2-angular')
    .component('evaMassActionDecision', {
      bindings: {
        decisionSet: '<',
        onSelectDecision: '&',
        reset: '<'
      },

      template: require('./massActionDecision.html'),
      controller: Controller
    });
})();
