import _ from 'lodash';

(function() {
  'use strict';

  function Controller(treeDataHelper, Enum, $scope, findNodeByCategoryId, getNodeFamily, $stateParams) {
    'ngInject';

    const vm = this;
    vm.domain = $stateParams.domain;


    vm.$onInit = function() {
      vm.OPEN_MODE = Enum.TREE_OPEN_MODE;
      $scope.$watch('$ctrl.list', function() {
        //set the mode after getting the list
        vm.showMode = treeDataHelper.getOpenMode(vm.list);
        if (vm.showMode === vm.OPEN_MODE.ONE_LEVEL_DROPDOWN || vm.showMode === vm.OPEN_MODE.TWO_LEVEL_DROPDOWN) {
          vm.dropdownList = treeDataHelper.mapValueToDropDownData(vm.list);
          vm.mergedList = mergeSubLists(vm.list);
        }
      });
      $scope.$watch('$ctrl.list', function() {
        vm.listMode = vm.list && vm.list.length;
      });

      $scope.$watch('$ctrl.value', function() {
        const nodeInfo = getNodeInfo(vm.value, vm.list);
        vm.selectedValueTooltip = nodeInfo.nodePath;
        if (vm.showMode === vm.OPEN_MODE.ONE_LEVEL_DROPDOWN || vm.showMode === vm.OPEN_MODE.TWO_LEVEL_DROPDOWN) {
          if (vm.value) {
            vm.initialValue = {text: vm.value.name, id: vm.value.id};
          }
        }
        if (vm.showMode === vm.OPEN_MODE.TREE) {
          vm.node = nodeInfo.node;
          vm.expandedNodes = nodeInfo.nodeFamily.slice(0, -1);
        }
      });
    };

    function mergeSubLists(list) {
      let acc = [];
      _.forEach(list, (x) => {
        if (Array.isArray(x.subCategories)) {
          acc = acc.concat(x.subCategories);
        }
      });
      return acc.concat(list);
    }

    function getNodeInfo(value, list) {
      const node = findNodeByCategoryId(value.categoryId, list) || value;
      const nodeFamily = getNodeFamily(node, list);
      return {
        node,
        nodeFamily,
        nodePath: _.join(_.map(_.filter(nodeFamily, (o) => !!o), (o) => o.name), ' > ')
      };
    }

    vm.updateSelection = (value) => {
      vm.selectedValueTooltip = getNodeInfo(value, vm.list).nodePath;
      vm.valueUpdated({value: _.find(vm.mergedList, (x) => `${x.id}` === value)});
    };

    vm.treeValueUpdated = (value) => {
      vm.selectedValueTooltip = getNodeInfo(value, vm.list).nodePath;
      vm.valueUpdated({value: {categoryId: value.categoryId, name: value.name}});
    };
  }

  angular
    .module('eva2-angular')
    .component('editTreeData', {
      bindings: {
        label: '<',
        list: '<',
        value: '<',
        isDisabled: '<',
        valueUpdated: '&',
        dictionaries: '<',
        name:'@'
      },
      template: require('./editTreeData.html'),
      controller: Controller
    });
})();
