(function() {
  'use strict';

  function Controller() {
    'ngInject';
  }

  angular
    .module('eva2-angular')
    .component('evaJobsProgressBar', {
      bindings: {
        decisionTypeArray: '<',
        focusedIndex: '<',
        onIndicatorClicked: '&'
      },

      template: require('./progressBar.html'),
      controller: Controller
    });
})();
