(function () {
  'use strict';

  function Controller($element) {
    'ngInject';
    const vm = this;

    this.$onInit = () => {
      vm.latestJob = vm.jobGroup.jobs[0];
      vm.olderJobs = vm.jobGroup.jobs.slice(1);
    };

    vm.isOlderJobVersionToggled = (showOldJobs) => {
      vm.shouldDisplayVersionInfo = showOldJobs;
      if (showOldJobs) {
        vm.olderJobs.forEach((job) => {
          job.shouldDeferLoadingImages = false;
        });
      }
    };

    vm.scrollToLatestJob = () => {
      const elementTop = $element.find('.eva-job').offset().top;
      const navigationBarHeight = 100;
      const jobVersionInfoHeight = 40;

      angular.element('html, body').animate({ scrollTop: elementTop - navigationBarHeight - jobVersionInfoHeight - 10 });
    };

    vm.onNewModerationNoteCreated = (query) => {
      vm.newModerationNoteCreated({ query });
    };

    // QUEUE PAGE (/manual/queueName/)
    // we need the state of isNotesExpanded in the parent component
    // because the position of eva-jobs-submit-cancel-btn-wrapper depends on it
    // and also older version row columns depend on it
    // when migrating to react we can rework the html properly to make it more flexible
    // or we can use the same html structure and use Redux to manage isNotesExpanded state
    vm.setIsNotesExpanded = (value) => {
      vm.isNotesExpanded = value;
    };
  }

  angular
    .module('eva2-angular')
    .component('jobGroup', {
      template: require('./jobGroup.html'),
      controller: Controller,
      bindings: {
        template: '<',
        jobGroup: '<',
        categories: '<',
        types: '<',
        feedbackList: '<',
        availableQueues: '<',
        onSelectDecision: '&',
        editPermissions: '<',
        dictionaries: '<',
        newModerationNoteCreated: '&',
        failedToLoadModerationNotes: '&',
        index: '<',
      }
    });
})();
