angular.module('eva2-angular').filter('timeAgo', function() {
  'use strict';

  (function(moment) {
    const STRINGS = {
      nodiff: '',
      year: 'year',
      years: 'years',
      month: 'month',
      months: 'months',
      day: 'day',
      days: 'days',
      hour: 'hour',
      hours: 'hours',
      minute: 'minute',
      minutes: 'minutes',
      second: 'second',
      seconds: 'seconds',
      delimiter: ' '
    };

    moment.fn.preciseDiff = function(d2) {
      return moment.preciseDiff(this, d2);
    };

    moment.preciseDiff = function(d1, d2, hide) {
      let m1 = moment(d1);
      let m2 = moment(d2);

      if (m1.isSame(m2)) {
        return STRINGS.nodiff;
      }
      if (m1.isAfter(m2)) {
        const tmp = m1;
        m1 = m2;
        m2 = tmp;
      }

      let yDiff = m2.year() - m1.year();
      let mDiff = m2.month() - m1.month();
      let dDiff = m2.date() - m1.date();
      let hourDiff = m2.hour() - m1.hour();
      let minDiff = m2.minute() - m1.minute();
      let secDiff = m2.second() - m1.second();

      if (secDiff < 0) {
        secDiff = 60 + secDiff;
        minDiff--;
      }
      if (minDiff < 0) {
        minDiff = 60 + minDiff;
        hourDiff--;
      }
      if (hourDiff < 0) {
        hourDiff = 24 + hourDiff;
        dDiff--;
      }
      if (dDiff < 0) {
        const daysInLastFullMonth = moment(m2.year() + '-' + (m2.month() + 1), 'YYYY-MM').subtract(1, 'M').daysInMonth();
        if (daysInLastFullMonth < m1.date()) { // 31/01 -> 2/03
          dDiff = daysInLastFullMonth + dDiff + (m1.date() - daysInLastFullMonth);
        } else {
          dDiff = daysInLastFullMonth + dDiff;
        }
        mDiff--;
      }
      if (mDiff < 0) {
        mDiff = 12 + mDiff;
        yDiff--;
      }

      function pluralize(num, word) {
        return num + ' ' + STRINGS[word + (num === 1 ? '' : 's')];
      }

      function show(type) {
        return !(hide && hide[type]);
      }

      const result = [];

      if (yDiff && show('year')) {
        result.push(pluralize(yDiff, 'year'));
      }
      if (mDiff && show('month')) {
        result.push(pluralize(mDiff, 'month'));
      }
      if (dDiff && show('day')) {
        result.push(pluralize(dDiff, 'day'));
      }
      if (hourDiff && show('hour')) {
        result.push(pluralize(hourDiff, 'hour'));
      }
      if (minDiff && show('minute')) {
        result.push(pluralize(minDiff, 'minute'));
      }
      if (secDiff && show('second')) {
        result.push(pluralize(secDiff, 'second'));
      }

      return result.join(STRINGS.delimiter);
    };
  }(moment));

  return function(input, hide) {
    const now = moment();
    const date = moment(input);

    return moment.preciseDiff(now, date, hide);
  };
});
