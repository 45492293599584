angular.module('eva2-angular').directive('evaLocalScroll', function(localMouseWheel) {
  'use strict';

  return {
    restrict: 'CA',
    link: function(scope, element) {
      // The purpose of this directive, is to prevent scrolling when scrolling has reached a dropdown menu's top and end.
      // When users scroll inside a dropdown menu, and the scrolling reaches the menu's top or end,
      // the page will start to scroll, which is annoying. We want to only scroll inside the dropdown menu.
      const localScrollTarget = element && element[0];
      if (localScrollTarget) {
        localScrollTarget.addEventListener('wheel', (event) => {
          localMouseWheel(event, localScrollTarget);
        });
      }

      scope.$on('$destroy', function() {
        if (localScrollTarget) {
          localScrollTarget.removeEventListener('wheel');
        }
      });
    }
  };
});
