
(function () {
  'use strict';
  angular.module('eva2-angular').service('getCurrentQueueNameAndId', function () {
    function getCurrentQueueNameAndId(mostRecentHistory, queues) {

      const filteredProcessingResults = mostRecentHistory?.processingResults?.filter((r) => r.result !== 'NOTHING');
      // Be careful when rewriting the service because queueId and queueName can be undefined
      const queueId = mostRecentHistory?.routingQueue || filteredProcessingResults?.[0]?.routingQueue;
      const queueName =
        mostRecentHistory?.routingQueueName || filteredProcessingResults?.[0]?.routingQueueName;

      const currentQueueName = queues ? queues?.[queueId] : queueName;
      return [currentQueueName, queueId];
    }
    return getCurrentQueueNameAndId;
  });
})();
