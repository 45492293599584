(function() {
  'use strict';
  /**
   * Use this service when you need to include token in the request header
   */
  angular.module('eva2-angular').service('ajax', function(BASE_URL, $http, $q) {
    function handleError(error) {
      return $q.reject(error);
    }

    function get(path, config) {
      return $http.get(`${BASE_URL}${path}`, config)
        .then((response) => response.data, handleError);
    }

    function post(path, data, config) {
      return $http.post(`${BASE_URL}${path}`, data, config)
        .then((response) => response.data, handleError);
    }

    function put(path, data, config) {
      return $http.put(`${BASE_URL}${path}`, data, config)
        .then((response) => response.data, handleError);
    }

    function patch(path, data, config) {
      return $http.patch(`${BASE_URL}${path}`, data, config)
        .then((response) => response.data, handleError);
    }

    function del(path, config) {
      return $http.delete(`${BASE_URL}${path}`, config)
        .then((response) => response.data, handleError);
    }

    return {
      get,
      post,
      put,
      patch,
      del
    };
  });
})();
