(function() {
  'use strict';

  /**
   * Element directive wrapping angular's wrapped bootstrap tooltip function launching popup with markup having a header showing the main text
   * and an underlying section with an optional link having a customizable label (or a default fallback label if not given).
   * The link is usually to give user option to "read more" about a certain concept for which the tooltip is used in the first place.
   * <br>
   *   Example Use:  <read-more-tooltip text="This is concept A of ours" link="http://more-info-on-concept-a" link-label="Click here for more info" ></read-more-tooltip>
   *
   */
  angular
    .module('eva2-angular')
    .directive('moreInfoTooltip', moreInfoTooltip);

  moreInfoTooltip.$inject = [];

  function link(scope, element, attrs) {
    const popoverContent = generatePopoverContent(attrs.link, attrs.linkLabel);
    element.popover({
      animation: false,
      trigger: 'manual',
      html: true,
      title: attrs.text,
      content: popoverContent,
      template: '<div class="popover more-info-tooltip" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
    }).on('mouseenter', function() {
      element.popover('show');
      angular.element('.popover').on('mouseleave', function() {
        element.popover('hide');
      });
    }).on('mouseleave', function() {
      setTimeout(function() {
        if (!angular.element('.popover:hover').length) {
          element.popover('hide');
        }
      }, 100);
    });
  }

  function moreInfoTooltip() {
    const directive = {
      controller: Controller,
      controllerAs: 'vm',
      restrict: 'E',
      replace: true,
      template: require('./moreInfoTooltip.html'),
      bindToController: {
        text: '@',
        link: '@',
        linkLabel: '@'
      },
      scope: {},
      link: link
    };
    return directive;
  }

  function generatePopoverContent(link, linkLabel) {
    let content = '';
    if (link) {
      content = '<a class="link" href="' + link + '" target="_blank">' +
        (linkLabel || 'Click for more info') + '</a>';
    }
    return content;
  }

  Controller.$inject = ['$scope', '$sce'];
  function Controller() {
  }
})();
