import _ from 'lodash';

(function() {
  'use strict';
  angular.module('eva2-angular').service('getTeamsInPicker', function() {
    function getTeamsInPicker(sites, currentDomain) {
      return _.map(sites, (site)=> (_.extend({}, site, {selected: site.domain === currentDomain})));
    }
    return getTeamsInPicker;
  });
})();
