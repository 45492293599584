import { percentage } from '@utils/formatter';

angular
  .module('eva2-angular')
  .filter('percentage', function() {
    return function(input, decimals) {
      return percentage(input, decimals);
    };
  });

