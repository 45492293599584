(function() {
  'use strict';
  angular.module('eva2-angular')
    .service('processedBy', function() {
      function getProcessedBy(history) {
        if (!history || !history.by) {
          return undefined;
        }
        if (history.domain === 'agent') {
          return `by user ${history.by}`;
        } else {
          return `by ${history.domain}/${history.by}`;
        }
      }

      return getProcessedBy;
    });
})();
