(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('expressionToReadable', expressionToReadable);

  /* This directive converts an expression object to a human readable text explaining this expression.
   * An typical expression object can contains these properties: actualValue, expectedValue, matches, operator, variable, expectedRangeValue, expression
   *
   * When operator is "CONTAINS" or "NOT CONTAINS", the text result is a bit special, as explained below:
   *  When expected value is a list name (starts with @),
   *  - "contains": $variable contains keyword [matches] from list
   *  - "not contains": $variable does not contain keyword from list
   *
   *  When expected value is an array of words (for example: $text CONTAINS ("apple", "oranges"), $title CONTAINS /./)
   *  - "contains" : $variable contains keyword [matches]
   *  - "not contains" : $variable does not contain keyword [expectedValue] (when expectedValue is an array, show the array; otherwise show the value)
   *
   * */

  expressionToReadable.$inject = [];

  function expressionToReadable() {
    const directive = {
      bindToController: {
        expression: '<',
        domain: '<'
      },
      controller: Controller,
      controllerAs: 'vm',
      restrict: 'E',
      template: require('./expressionToReadable.html'),
      scope: {
      }
    };
    return directive;
  }

  Controller.$inject = [];

  function Controller() {
    const vm = this;
    vm.getActualValue = (blangExp) => {
      const specialOperatorList = ['CONTAINS', 'NOT CONTAINS', 'NOT EQUALS', 'EQUALS'];
      if (specialOperatorList.indexOf(blangExp.operator) !== -1) {
        // When the operator is one of the specials, the actual value can be a long text, thus we do not show the actual value.
        return '';
      }
      return blangExp.actualValue;
    };

    vm.isArray = (value) => !!value && value.constructor === Array;

    vm.isList = (value, expression) => {
      if (Array.isArray(value)) {
        return true;
      }
      if (typeof value !== 'string') {
        return false;
      }
      if (value.charAt(0) === '@') {
        if (!expression || typeof expression !== 'string') {
          return true;
        }
        // Ugly way of making sure that when the expectedValue is thought to be list, check in the expression if
        // it's actually a list or if it's a keyword. If it's a keyword, the expression will be:
        //  $x CONTAINS "@list"
        // otherwise it will be
        //  $x CONTAINS @list
        return expression.indexOf(` "${value}"`) === -1;
      }
    };

    vm.isInlineList = (value) => Array.isArray(value);

    vm.getUniqueMatchedWords = (matches) => {
      const matchedWords = matches.map((match)=> match.word);
      return getUniqueWords(matchedWords);
    };

    vm.getUniqueExpectedWords = (expectedValue) => {
      // expectedValue can be a string, or an array of strings
      if (vm.isArray(expectedValue)) {
        return getUniqueWords(expectedValue);
      }
      return [expectedValue];
    };

    function getUniqueWords(words) {
      const uniqueWords = [];
      words.forEach((word) => {
        if (uniqueWords.indexOf(word) === -1) {
          uniqueWords.push(word);
        }
      });
      return uniqueWords;
    }

    vm.operatorToText = function(operator) {
      const translate = {
        '<': 'is less than',
        '<=': 'is less than or equal to',
        '>': 'is greater than',
        '>=': 'is greater than or equal to',
        'BETWEEN': 'is between',
        'NOT BETWEEN': 'is not between',
        'EQUALS': 'is equal to',
        'NOT EQUALS': 'is not equal to',
        'CONTAINS': 'contains keyword',
        'NOT CONTAINS': 'does not contain keyword'
      };
      return translate[operator];
    };
  }
})();
