'use strict';

import _ from 'lodash';
angular
  .module('eva2-angular')
  .directive('localSearchList', function() {
    return {
      scope: {
        tableParams: '=',
        data: '=',
        resetSearch: '=',
        count: '<',
        searchText: '<',
        searchFields: '<',
        sorting: '<',
        entity: '<',
        domain: '<'
      },
      replace: true,
      controller: 'LocalSearchListCtrl'
    };
  })
  .controller('LocalSearchListCtrl', function($scope, NgTableParams, userSetting) {
    $scope.tableParams = new NgTableParams({
      count: $scope.count || 50,
      sorting: $scope.sorting || {},
    }, {
      counts: [],
      getData: function(params) {
        if (!$scope.data) {
          return;
        }
        const sorting = params.sorting();
        const page = params.page();
        const limit = params.count();
        const skip = (page - 1) * limit;
        params.errorLoadingStats = false;
        params.isLoading = true;

        return $scope.data.then(function(result) {
          if ($scope.searchText) {
            result = _.filter(result, (item)=>
              _.some($scope.searchFields, (field) => item[field]
              && item[field].toLowerCase().indexOf($scope.searchText.toLowerCase()) !== -1)
            );
          }

          params.total(result.length);
          if($scope.entity && $scope.domain) {
            const entitySorting=userSetting.getUserSetting(`${$scope.entity}Sorting`);
            userSetting.saveUserSetting(`${$scope.entity}Sorting`, {
              ...entitySorting,
              [$scope.domain]: sorting
            });
          }

          result = _.orderBy(result, _.keys(sorting), _.values(sorting));

          result = _.slice(result, skip, (page * limit));
          // if current page has no records to show, then go back to the first page
          if (result.length === 0 && page > 1) {
            params.page(1);
          }
          return result;
        }).catch(() => {
          params.errorLoadingStats = true;
          params.total(0);
          return null;
        }).finally(()=> {
          params.isLoading = false;
        });
      },
      defaultSort: 'asc'
    });

    $scope.reload = function() {
      $scope.tableParams.reload();
    };

    $scope.resetSearch = function() {
      $scope.searchText = '';
    };

    // Debounced reload search when typing.
    const debounceReload = _.debounce(function() {
      $scope.reload();
    }, 150);

    $scope.$watch('data', function watchData(newData, oldData) {
      if (newData == oldData) {
        return;
      }
      $scope.reload();
    });

    $scope.$watch('searchText', function(newValue, oldValue) {
      if (newValue != oldValue) {
        debounceReload();
      }
    });
  });

