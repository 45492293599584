"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var d3_array_1 = require("d3-array");
var d3_scale_1 = require("d3-scale");
var d3_selection_1 = require("d3-selection");
var d3_shape_1 = require("d3-shape");
var LINE_CHART_WIDTH = 100;
var LINE_CHART_HEIGHT = 15;
// Set the dimensions of the canvas / graph
var margin = { top: 1, right: 1, bottom: 1, left: 1 };
var width = LINE_CHART_WIDTH - margin.left - margin.right;
var height = LINE_CHART_HEIGHT - margin.top - margin.bottom;
function LineChart(_a) {
    var data = _a.data;
    if (!data) {
        return null;
    }
    var areaRef = (0, react_1.useRef)(null);
    // Set the ranges and domains
    var xConverter = (0, d3_scale_1.scaleTime)();
    var yConverter = (0, d3_scale_1.scaleLinear)();
    xConverter.range([0, width]);
    xConverter.domain(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (0, d3_array_1.extent)(data, function (d) {
        return d.date;
    }));
    yConverter.range([height, 0]);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yConverter.domain([
        0,
        (0, d3_array_1.max)(data, function (d) {
            return d.close;
        }),
    ]);
    var area = (0, d3_shape_1.area)()
        .x(function (d) {
        return xConverter(d.date);
    })
        .y0(height)
        .y1(function (d) {
        return yConverter(d.close);
    });
    var valueLine = (0, d3_shape_1.line)()
        .x(function (d) {
        return xConverter(d.date);
    })
        .y(function (d) {
        return yConverter(d.close);
    });
    var average = data
        .map(function (d) { return parseInt(String(d.close)); })
        .reduce(function (accumulator, currentValue) { return accumulator + currentValue; }) / data.length;
    var averageLine = (0, d3_shape_1.line)()
        .x(function (d) {
        return xConverter(d.date);
    })
        .y(function () {
        return yConverter(average);
    });
    (0, react_1.useEffect)(function () {
        (0, d3_selection_1.select)(areaRef.current).datum(data).attr('class', 'area').attr('d', area);
    }, [data]);
    return (react_1.default.createElement("div", { className: "stat-line-chart" },
        react_1.default.createElement("svg", { viewBox: "0 0 100 15" },
            react_1.default.createElement("g", { width: "100%", height: "100%", transform: "translate(".concat(margin.left, ",").concat(margin.top, ")") },
                react_1.default.createElement("path", { className: "area", ref: areaRef }),
                react_1.default.createElement("path", { className: "value-line", d: valueLine(data) || undefined }),
                react_1.default.createElement("path", { className: "average-line", d: averageLine(data) || undefined })))));
}
exports.default = LineChart;
