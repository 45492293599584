(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);

  function appConfig($stateProvider) {
    $stateProvider.state('app.assignment.automation.filterView', {
      url: '/filters/:filterId/view?cursor&status&order&sort&from&to&perPage&type',
      data: {
        title: 'PAGE_TITLE.VIEW_RULE'
      },
      views: {
        content: {
          template: require('./filterView.html'),
          controller: 'filterViewCtrl',
          controllerAs: 'vm'
        }
      }
    });
  }
})();
