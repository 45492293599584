angular.module('eva2-angular').service('defineBlangMode', function($window) {
  return function() {
    $window.CodeMirror.defineSimpleMode('blang', {
      start: [
        {regex: /"[^/,](?:[^\\]|\\.)*?"/g, token: 'string'},
        {regex: /BETWEEN|NOT BETWEEN|>=|<=|>|<|NOT|AND|OR|NOT EQUALS|EQUALS|NOT CONTAINS|CONTAINS|EXISTS|NOT EXISTS/, token: 'keyword'},
        {regex: /true|false/, token: 'boolean'},
        {regex: /[0-9]+\.?[0-9]*/, token: 'number'},
        {regex: /\/([\s\S]*?)\/[igmsuy]*/g, token: 'regex'},
        {regex: /\$[0-9a-zA-Z_]+/, token: 'variable'},
        {regex: /@[0-9a-zA-Z_]+/, token: 'list'},
        {regex: /#.*$/, token: 'comment'}
      ]
    });
  };
});
