(function() {
  'use strict';
  angular.module('eva2-angular').service('getAugBoxesToOneImage', function(IMAGE_AUG_NOTICES) {
    return function(imageAugResult) {
      let augBoxes = [];
      IMAGE_AUG_NOTICES.forEach((notice)=> {
        augBoxes = augBoxes.concat(notice.getAugBoxes(imageAugResult));
      });

      return augBoxes;
    };
  });
})();
