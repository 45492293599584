(function() {
  'use strict';

  angular.module('eva2-angular')
    .controller('CSVImporterController', function($stateParams, $state, $modal, $timeout, csvMap, MapperManager, tFilter) {
      'ngInject';

      const vm = this;
      const domain = $stateParams.domain;

      vm.domain = domain;
      vm.data = [];
      vm.file = null;

      function resetMapping() {
        MapperManager.reset();
        vm.columns = undefined;
        vm.optionalAttributes = undefined;
        vm.mandatoryAttributes = undefined;
        vm.submitted = false;
      }

      function resetData() {
        vm.postData = undefined;
        vm.previewData = undefined;
        vm.dataLength = 0;
      }

      vm.reset = function() {
        resetData();
        resetMapping();
      };

      vm.startMapping = function() {
        const mapping = MapperManager.getMapping();
        vm.postData = csvMap.reformateData(mapping, vm.data);
        vm.previewData = csvMap.previewData(mapping, vm.data);

        if (vm.data) vm.dataLength = vm.data.length;
        else vm.dataLength = 0;

        $state.go('app.assignment.csvImporter.step2');
      };

      vm.importCsv = function() {
        const textConfirmModal = {
          title: tFilter('SETTING.CSV_IMPORTER.IMPORT_DATA.MODAL_TITLE', {dataLength: vm.dataLength}, 'messageformat'),
          content: tFilter('SETTING.CSV_IMPORTER.IMPORT_DATA.MODAL_TEXT'),
          btnOk: tFilter('SETTING.CSV_IMPORTER.IMPORT_DATA.IMPORT_BTN'),
          btnCancel: tFilter('SETTING.CSV_IMPORTER.IMPORT_DATA.CANCEL_BTN')
        };

        $modal.open({
          template: require('@partial/modals/confirm/confirm.html'),
          controller: 'ConfirmCtrl',
          controllerAs: 'vm',
          windowClass: 'no-fade',
          backdrop: 'static',
          resolve: {
            resources: () => textConfirmModal
          }
        }).result.then(proceedCallBack);
      };

      vm.isEnabledNextButtonClicked = () => {
        if ($state.current.name === 'app.assignment.csvImporter.step1') {
          vm.submitted = true;
        }
      };

      function proceedCallBack() {
        $modal.open({
          controller: 'CsvProcessCtrl',
          controllerAs: 'vm',
          template: require('@partial/modals/csv/csvProcess/csvProcess.html'),
          windowClass: 'no-fade',
          backdrop: 'static',
          resolve: {
            postData: () => vm.postData,
            domainName: () => vm.domain
          }
        });
      }
    });
})();
