(function() {
  'use strict';
  angular.module('eva2-angular').service('getDomainRelatedData', function(ApiQueue, showJobError, EvaLogger, $q, sortCategories) {
    /**
     * return all domain related data (types, categories)
     * @param domain {String}
     * @returns {Promise}
     */

    function getDomainRelatedData(domain) {
      const query = {
        domain
      };

      return ApiQueue
        .index(query)
        .then((results) => ({
          categories: sortCategories(results[0]),
          types: results[1]
        }), (error) => {
          showJobError('loadQueue', error);
          return $q.reject(error);
        });
    }

    return getDomainRelatedData;
  });
})();
