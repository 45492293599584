import _ from 'lodash';
(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .filter('getJobStatusTooltip', function(tFilter, getFullJobStatusFilter) {
      return function(status, actor, lastHistoryTimestamp) {
        const dateFormat = 'DD MMM YYYY HH:mm';
        const utcOffset = moment().utcOffset() / 60;
        const timeZone = 'GMT' + (utcOffset > 0 ? `+${utcOffset}` : utcOffset);
        const jobStatusKey = getFullJobStatusFilter(status, actor.domain);
        const humanActor = _.includes(['client', 'agent'], actor.domain) ? actor.id : null;
        return tFilter('SEARCH.ITEM.STATUS_TOOLTIP', {
          STATUS: jobStatusKey,
          BY_USER_EMAIL: humanActor ? `by ${humanActor}` : '',
          TIME_PREP: status === 'reviewable' || status === 'new' ? 'since' : 'at',
          TIME: moment(lastHistoryTimestamp).format(dateFormat),
          TIMEZONE: timeZone
        }, 'messageformat');
      };
    });
})();

