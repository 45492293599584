(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .filter('getSearchStatus', getSearchStatus);

  /**
   * ad.current.meta.status is one of [reviewable, approved, refused, 'no decision']
   * By when searching by ads, the accepted values are ['MANUAL', 'APPROVE', 'REFUSE', 'NO DECISION']
   * So conversion is needed
   */

  function getSearchStatus() {
    return function(status) {
      const mapping = {
        'reviewable': 'MANUAL',
        'approved': 'APPROVE',
        'refused': 'REFUSE',
        'no decision': '"NO DECISION"',
        'deferred': 'DEFER',
        'new': 'new'};
      return mapping[status] || status;
    };
  }
})();

