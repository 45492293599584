
(function() {
  'use strict';

  function Controller($timeout, $scope, getDeepSearchResult) {
    // Only show the search input box, if there are more than 10 items on the first and second level in total,
    const MAX_RESULTS_TO_HIDE_SEARCH = 10;
    const DEFAULT_PAGE_SIZE = 50;

    const vm = this;
    let component;
    vm.$onInit = () => {
      vm.componentId = `advanced-select-${$scope.$id}`;      
      $scope.$watch('$ctrl.data', function(newVal) {
        if (Array.isArray(newVal)) {
          $timeout(initializeSelect);
        }
      });
    };

    vm.$onDestroy = () => {
      if (component) {
        component.select2('destroy');
      }
    };

    function initializeSelect() {
      component = angular.element('#' + vm.componentId);

      $scope.$watch('$ctrl.isDisabled', function() {
        component.select2('enable', !vm.isDisabled);
      });

      if (vm.initialValue && vm.initialValue.text) {
        component.val(
          vm.initialValue.text
        );
      }

      if (vm.disableDirectories) {
        vm.data.forEach((item) => {
          if (item.children) {
            item.disabled = true;
          }
        });
      }

      const minimumResultsForSearch = shouldShowSearchInput() ? 0 : 9999;

      component.select2({
        theme: 'classic',
        data: vm.data,
        placeholder: vm.placeholder || 'select',
        initSelection: function(element, callback) {
          callback(vm.initialValue);
        },
        multiple: !!vm.multiple,
        formatLoadMore: 'Loading more...',
        minimumResultsForSearch: minimumResultsForSearch,
        containerCssClass: 'advanced-select-container',
        dropdownCssClass: `advanced-select-dropdown ${vm.customDropdownClass}`,
        query: function(q) {
          const pageSize = vm.pageSize || DEFAULT_PAGE_SIZE;
          const results = getDeepSearchResult(vm.data, q.term);
          q.callback({
            results: results.slice((q.page - 1) * pageSize, q.page * pageSize),
            more: results.length >= q.page * pageSize
          });
        }
      });

      component.on('select2-open', function() {
        component.select2('positionDropdown', !!vm.dropUp);
      });

      component.on('change', function(event) {
        // when single select, value is a string (the selected id)
        // when multiple select, value is an array of strings (selected ids)
        $scope.$apply(() => {
          vm.selectionChanged({value: event.val});
        });
      });
    }

    function shouldShowSearchInput() {
      let numberOfItemsInTwoLevels = 0;
      numberOfItemsInTwoLevels += vm.data.length;
      vm.data.forEach((item) => {
        if (Array.isArray(item.children)) {
          numberOfItemsInTwoLevels += item.children.length;
        }
      });
      return numberOfItemsInTwoLevels > MAX_RESULTS_TO_HIDE_SEARCH;
    }
  }

  angular
    .module('eva2-angular')
    .component('evaAdvancedSelect', {
      bindings: {
        data: '<',
        initialValue: '<',
        name: '@',
        placeholder: '@',
        disableDirectories: '<', // should be directories (nodes with children) be unselectable?
        pageSize: '<',
        selectionChanged: '&',
        isDisabled: '<',
        multiple: '<',
        dropUp: '<',
        customDropdownClass: '@'
      },
      template: require('./advancedSelect.html'),
      controller: Controller
    });
})();

