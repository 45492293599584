import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').service('getModerationNotesQueryFields', function() {
    return function(ad) {
      return {
        'documentId': ad.id,
        'remoteId': _.get(ad, 'remoteId.id'),
        'userId': _.get(ad, 'current.user.id'),
        'ipAddress': _.get(ad, 'current.user.ipAddress'),
        'userEmails[]': _.get(ad, 'current.user.emailAddresses'),
        'userPhoneNumbers[]': _.get(ad, 'current.user.phoneNumbers')
      };
    };
  });
})();
