(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .factory('applicationError', hasApplicationError);

  hasApplicationError.$inject = ['$rootScope'];

  function hasApplicationError($rootScope) {
    const _callbacks = [];
    let _hasError = false;

    function notify() {
      _callbacks.forEach(function(callback) {
        callback(_hasError);
      });
    }
    function registerListener(cb) {
      _callbacks.push(cb);
    }
    function trigger() {
      _hasError = true;
      notify();
    }
    function reset() {
      _hasError = false;
      notify();
    }
    function hasError() {
      return _hasError;
    }

    $rootScope.$on('$stateChangeStart', reset);

    return {
      onChange: registerListener,
      trigger: trigger,
      reset: reset,
      hasError: hasError
    };
  }
})();

