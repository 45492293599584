(function() {
  'use strict';

  function Controller() {
  }

  angular
    .module('eva2-angular')
    .component('evaTextBoxDisplay', {
      bindings: {
        label: '<',
        value: '<',
        name:'@',
      },
      template: require('./textBoxDisplay.html'),
      controller: Controller
    });
})();
