(function() {
  'use strict';

  angular.module('eva2-angular').controller('InviteTeamNoticeModal', InviteTeamNoticeModal);

  function InviteTeamNoticeModal($scope, $modalInstance, teamname) {
    $scope.content =
    {
      title: 'You are now part of the ' + teamname + ' team',
      text1: 'Teams let multiple people collaborate on the same project.',
      text2: 'You can change your team at any time'
    };
    $scope.close = function() {
      $modalInstance.dismiss('close');
    };
  }

  InviteTeamNoticeModal.$inject = ['$scope', '$modalInstance', 'teamname'];
})();
