import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').service('findBoundingBoxInImages', function() {
    function getDetectionResult(showBoundingBox, images) {
      let result = false;
      const detection = _.get(showBoundingBox, 'detection');
      _.forOwn(detection, (value, key)=> {
        if ((value === true) && _.some(images, (image)=> image.imageAugResult.detection[key].length > 0)) {
          result = true;
          return false;
        }
      });
      return result;
    }

    function getClassificationResult(showBoundingBox, images) {
      let result = false;
      const classification =  _.get(showBoundingBox, 'classification');
      _.forOwn(classification, (modelValue, modelId)=> {
        const ids = _.keys(modelValue);
        _.forEach(ids, (id)=> {
          if ((modelValue[id] === true) && _.some(images, (image) =>
            _.get(image, `imageAugResult.classification.${modelId}.value`) === id)) {
            result = true;
            return false;
          }
        });
        if (result) {
          return false;
        }
      });
      return result;
    }
    return function(showBoundingBox, images) {
      return getDetectionResult(showBoundingBox, images) || getClassificationResult(showBoundingBox, images);
    };
  });
})();

