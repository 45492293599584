"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentQueueNameAndId = void 0;
var getCurrentQueueNameAndId = function (mostRecentHistory) {
    var _a, _b, _c;
    var filteredProcessingResults = (_a = mostRecentHistory === null || mostRecentHistory === void 0 ? void 0 : mostRecentHistory.processingResults) === null || _a === void 0 ? void 0 : _a.filter(function (r) { return r.result !== 'NOTHING'; });
    // Be careful when rewriting the service because queueId and queueName can be undefined
    var queueId = (mostRecentHistory === null || mostRecentHistory === void 0 ? void 0 : mostRecentHistory.routingQueue) || ((_b = filteredProcessingResults === null || filteredProcessingResults === void 0 ? void 0 : filteredProcessingResults[0]) === null || _b === void 0 ? void 0 : _b.routingQueue);
    var queueName = (mostRecentHistory === null || mostRecentHistory === void 0 ? void 0 : mostRecentHistory.routingQueueName) || ((_c = filteredProcessingResults === null || filteredProcessingResults === void 0 ? void 0 : filteredProcessingResults[0]) === null || _c === void 0 ? void 0 : _c.routingQueueName);
    // To do retest when working on item details screen and queue screen
    // const currentQueueName = queues && queueId ? queues?.[Number(queueId)] : queueName;
    return [queueName, queueId];
};
exports.getCurrentQueueNameAndId = getCurrentQueueNameAndId;
