
(function() {
  'use strict';

  function Controller($state, UserPermissionManager, $stateParams,
    DeleteAllContentJobsManager, tFilter) {
    const vm = this;
    vm.$onInit = () => {

      vm.userHasAccessToContentDeletion = UserPermissionManager
        .hasPermissionToEnterRoute('app.assignment.settings.deleteAllContent', $stateParams.domain);

      vm.job = DeleteAllContentJobsManager.getJobByDomain($stateParams.domain);

      vm.textForAdmin = tFilter('SETTING.DELETE_ALL_CONTENT.NOTICE.TEXT_FOR_ADMIN', {
        REMAINING_TIME: vm.job.remainingTimeInText
      }, 'messageformat');

      vm.textForNonAdmin = tFilter('SETTING.DELETE_ALL_CONTENT.NOTICE.TEXT_FOR_NON_ADMIN', {
        REMAINING_TIME: vm.job.remainingTimeInText
      }, 'messageformat');
    };
    vm.goToCancelDeletingContent = () => {
      $state.go('app.assignment.settings.react', {component:'delete-all-content'});
    };


  }

  angular
    .module('eva2-angular')
    .component('evaDeleteAllContentNotice', {
      bindings: {
      },
      controllerAs: 'vm',
      template: require('./deleteAllContentNotice.html'),
      controller: Controller
    });
})();

