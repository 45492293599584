import _ from 'lodash';

(function() {
  'use strict';

  function Controller($element, $scope, $rootScope, $timeout) {
    'ngInject';
    const vm = this;

    $rootScope.$on('escKeyPressed', function() {
      $timeout(()=> {
        vm.searchText = '';
      });
    });

    function mouseMoveListener() {
      $element.find('.eva-dropdown-container li').removeClass('focused');
      angular.element(this).addClass('focused');
    }

    this.$onInit = () => {
      vm.items = angular.copy(vm.data);
      vm.filtered = vm.items;
      vm.selectedItems = [];

      $timeout(()=> {
        $element.find('.eva-dropdown-container li').on('mousemove', mouseMoveListener);
      });
    };

    this.$onDestroy = () => {
      $element.find('.eva-dropdown-container li').off('mousemove', mouseMoveListener);
    };

    this.openMenu = ($event) => {
      if ($($event.target).closest('.selected-item').length !== 0) {
        // if clicking on a selected item, do nothing
        return;
      }
      // if current feedback component is closed,
      // first close other decision dropdowns
      if ($('.eva-keyboard-focusable.open')) {
        $('.eva-keyboard-focusable.open').removeClass('open');
      }
      // then open feedback's dropdown
      if ($('.eva-multi-select-with-search')) {
        $element.find('.eva-multi-select-with-search').addClass('open');
      }
      if ($element.find('.eva-keyboard-focusable')?.setFocus) {
        $element.find('.eva-keyboard-focusable').setFocus();
      }
      if ($element.find('input.search-input')) {
        $element.find('input.search-input').focus();
      }
      if ($element.find('.eva-dropdown-container li')) {
        $element.find('.eva-dropdown-container li').removeClass('focused');
      }
    };

    this.closeMenu = ($event)=> {
      if ($($event.target).closest('.selected-item').length !== 0) {
        // if clicking on a selected item, do nothing
        return;
      }
      $element.find('.eva-multi-select-with-search').removeClass('open');
    };

    this.removeSelected = (item, $event)=> {
      $event.stopPropagation();
      const index = _.findIndex(vm.items, (it) => it.feedbackId === item.feedbackId);
      vm.items[index] = angular.extend(item, {selected: false});
      updateSelected();
      $element.find('.eva-multi-select-with-search').addClass('open');
    };

    this.toggleSelected = ()=> {
      updateSelected();
    };

    $scope.$watch('$ctrl.searchText', function(newVal) {
      if (newVal) {
        vm.filtered = vm.items.filter((item)=> {
          return item.name.toUpperCase().indexOf(newVal.toUpperCase()) !== -1;
        });
      } else {
        vm.filtered = vm.items;
      }
    });

    $scope.$watch('$ctrl.selectedIds', ()=> {
      vm.items.forEach((item)=> {
        item.selected = vm.selectedIds.indexOf(item.feedbackId) !== -1;
      });
      vm.selectedItems = vm.items.filter((item) => item.selected);
    });

    function updateSelected() {
      vm.selectedItems = vm.items.filter((item) => item.selected);
      vm.selectionChanged({value: vm.selectedItems});
    }
  }

  angular
    .module('eva2-angular')
    .component('evaMultiSelectWithSearch', {
      bindings: {
        dropDownTitle: '@',
        selectionChanged: '&',
        data: '<',
        placeholder: '@',
        isDisabled: '<',
        selectedIds: '<', // example: ['123', '456']
        enableHover: '<'
      },
      template: require('./multiSelectWithSearch.html'),
      controller: Controller
    });
})();
