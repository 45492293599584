(function() {
  'use strict';
  angular.module('eva2-angular').controller('ConfirmCtrl', function($scope, $modalInstance, resources) {
    /**
     * generic confirm modal
     * @param  resources={title:'title',content:'content', btnOk:'CONFIRM', btnCancel:'CANCEL}
     */

    const vm = this;
    vm.resources = resources;
    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
    vm.ok = function() {
      $modalInstance.close();
    };
  });
})();

