"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.highlightService = void 0;
var highlightService = function (data, EvaLogger, context) {
    return new Promise(function (resolve, reject) {
        if (!data || !data.text) {
            resolve([{ text: '', type: 'raw' }]);
        }
        else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            var worker_1 = new Worker(new URL('./worker.js', import.meta.url));
            var isDone_1 = false;
            worker_1.postMessage(data); // Send data to our worker.
            setTimeout(function () {
                worker_1.terminate();
                if (!isDone_1) {
                    EvaLogger.captureMessage("ERROR HighlightService TIMEOUT for item ".concat(context.itemId, " on ").concat(context.domain, " for ").concat(context.type, " rules ").concat(data.rules.map(function (rule) { return rule.rule.concat(','); })));
                    // When the worker take more than 1s to process the data, we return the data without highlight
                    // It let us avoid the infinite loading
                    resolve([{ text: data.text, type: 'raw' }]);
                }
            }, 15000);
            worker_1.addEventListener('message', function (e) {
                isDone_1 = true;
                worker_1.terminate();
                resolve(e.data.highlightList);
            }, false);
            worker_1.addEventListener('error', function (e) {
                EvaLogger.captureMessage("ERROR highlightService ERROR for item ".concat(context.itemId, " on ").concat(context.domain, " for ").concat(context.type, " with error ").concat(e.message));
                isDone_1 = true;
                resolve([{ text: data.text, type: 'raw' }]);
                worker_1.terminate();
                reject(e);
            });
        }
    });
};
exports.highlightService = highlightService;
