(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);

  appConfig.$inject = ['$stateProvider'];

  function appConfig($stateProvider) {
    $stateProvider.state('app.assignment.ad', {
      url: '/item/:id/',
      data: {
        title: 'PAGE_TITLE.ITEM'
      },
      views: {
        'content@app': {
          template: require('@partial/job/job.html'),
          controller: 'EvaJobCtrl',
          controllerAs: 'vm'
        }
      }
    }).state('app.assignment.adtest', {
      url: '/item/:id/test',
      data: {
        title: 'PAGE_TITLE.ITEM'
      },
      views: {
        'content@app': {
          template: require('@partial/job/job.html'),
          controller: 'EvaJobCtrl',
          controllerAs: 'vm'
        }
      }
    })
      .state('app.assignment.adOld', {
        url: '/ad/:id/',
        views: {
          'content@app': {
            template: '',
            controller: function($state, $stateParams) {
              $state.go('app.assignment.ad', {id: $stateParams.id});
            }
          }
        }
      });
  }
})();
