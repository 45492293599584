(function() {
  'use strict';
  angular.module('eva2-angular').service('ScrollUtils', function($document) {
    'ngInject';

    const bottomBarHeight = 50;
    const topNavigationHeight = 100;
    const jobMarginTop = 40;
    const previousJobShadowHeight = 3;

    function isFullyDisplayedInQueueView(elementTop, elementBottom) {
      const queueViewTop = $document.scrollTop() + topNavigationHeight;
      const queueViewBottom = $document.scrollTop() + window.innerHeight - bottomBarHeight;

      return (queueViewTop < elementTop) && (queueViewBottom > elementBottom);
    }

    return {
      bottomBarHeight,
      topNavigationHeight,
      jobMarginTop,
      previousJobShadowHeight,
      isFullyDisplayedInQueueView: isFullyDisplayedInQueueView
    };
  });
})();
