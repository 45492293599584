import {staging, production} from '../../../../envConstants';
(function() {
  'use strict';

  function Controller(BASE_URL) {
    const vm = this;
    if (window.location.href.indexOf('implio.com') === -1) {
      vm.inTestMode = true;
      if (BASE_URL === staging.BASE_URL) {
        vm.baseUrl = 'staging';
      }
      if (BASE_URL === production.BASE_URL) {
        vm.baseUrl = 'production';
      }
    }
  }

  angular
    .module('eva2-angular')
    .component('evaTestModeWarning', {
      bindings: {},
      template: `<p data-ng-if="$ctrl.inTestMode" class="test-mode-warning test-mode-warning-{{$ctrl.baseUrl}}">
        <span data-ng-show="$ctrl.baseUrl === 'production'">Be careful. You are testing on <b>Production API</b></span>
        <span data-ng-show="$ctrl.baseUrl === 'staging'">You are testing on <b>Staging API</b></span>
        </p>`,
      controller: Controller
    });
})();

