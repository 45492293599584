"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.itemLogViewDefaultStartDate = void 0;
var date_1 = require("@utils/date");
var date_fns_1 = require("date-fns");
exports.itemLogViewDefaultStartDate = (0, date_fns_1.set)((0, date_fns_1.sub)(new Date(), { days: 6 }), __assign({ hours: 0, minutes: 0 }, date_1.ZERO_SECONDS));
