/*
* This is a drop down list, which allows user to either select a option, or creates a custom one.
*
* The first option of the drop down is a a special option. When it's clicked, the dropdown list will be hidden and input field will be shown,
* so that the user can input a custom value.
*
* Users can close the input field and go back to the drop down list.
*
* pre-value: A pre-selected item or pre-defined custom value.
* Example:
* {label: 'bar', disabled: true} or {isCustomValue: true, label: 'foo'}
*
* */
(function() {
  'use strict';

  function Controller($scope, $timeout, $element) {
    'ngInject';
    const vm = this;

    this.$onInit = () => {
      if (vm.preValue) {
        vm.isCustomMode = vm.preValue.isCustomValue;
        if (vm.preValue.isCustomValue) {
          vm.customValue = vm.preValue.label;
        } else {
          vm.preselectedItem = vm.preValue;
        }
      }

      vm.deleteCustomValue = () => {
        vm.isCustomMode = false;
        vm.customValue = undefined;
      };

      vm.normalItemClicked = (item) => {
        vm.isCustomMode = false;
        item.isCustomValue = vm.isCustomMode;
        vm.valueChanged({value: item});
      };

      vm.separatedItemClicked = () => {
        vm.isCustomMode = true;
        if (vm.customValue === undefined) {
          vm.customValue = vm.inputDefaultValue;
        }
        $timeout(()=> {
          $element.find('input').select();
        }, 0);
      };

      $scope.$watch('$ctrl.customValue', (newValue, oldValue)=> {
        if (newValue !== oldValue) {
          updateCustomValue();
        }
      });
    };

    function updateCustomValue() {
      if (vm.customValue === undefined) {
        vm.valueChanged({value: undefined});
      } else {
        vm.valueChanged({value: {label: vm.customValue, isCustomValue: vm.isCustomMode}});
      }
    }
  }

  angular
    .module('eva2-angular')
    .component('evaSelectOrInput', {
      template: require('./selectOrInput.html'),
      controller: Controller,
      bindings: {
        valueChanged: '&',
        placeholder: '@',
        inputDefaultValue: '@',
        separatedItemName: '@',
        items: '<',
        preValue: '<'
      }
    });
})();
