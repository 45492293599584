"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var extractDomainFromUrl = function (url) {
    if (typeof url !== 'string') {
        return null;
    }
    // eslint-disable-next-line no-useless-escape
    var matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    // extract hostname (will be null if no match is found)
    return matches && matches[1] && matches[1].replace('https://', '').replace('http://', '');
};
exports.default = extractDomainFromUrl;
