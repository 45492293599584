(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .filter('hyphenize', hyphenize);

  function hyphenize() {
    return function(str) {
      return str.replace(' ', '-');
    };
  }
})();


