(function() {
  'use strict';

  angular.module('eva2-angular').service('analyzeRule', function() {
    const ITEM_TYPE = {
      SINGLE_RULE: 1,
      NESTED_RULE: 2,
      OPERATOR: 3,
      SINGLE_RULE_WITH_OR: 4
    };

    function itemsContainsArr(arr) {
      let isArr = false;
      arr.forEach(function(item) {
        if (Array.isArray(item)) isArr = true;
      });

      return isArr;
    }

    //get the item type depends on the format of item
    function getItemType(item) {
      if (!Array.isArray(item)) return ITEM_TYPE.OPERATOR;

      const item2 = item[2] || {};
      if (item && item2.type === 'list' && Array.isArray(item2.value)) {
        return ITEM_TYPE.SINGLE_RULE_WITH_OR; // item: [operator, $variable, [values]]. Example: ["CONTAINS", "$text", ["foo, bar"]]
      }

      if (!itemsContainsArr(item)) return ITEM_TYPE.SINGLE_RULE;

      if (!Array.isArray(item[2])) return ITEM_TYPE.NESTED_RULE;

      if (Array.isArray(item[1])) return ITEM_TYPE.NESTED_RULE; // item: [operator, [rule1], [rule2]]. Example: [“AND”, [“>”, “$price”, “1”],[“>”, “$price”, “2”]

      return ITEM_TYPE.SINGLE_RULE_WITH_OR; // item: [operator, $variable, [values]]. Example: ["CONTAINS", "$text", ["foo, bar"]]
    }

    function getRule(item) {
      let value;
      if (item.length === 3) {
        value = item[2];
      } else if ((item[0] === 'BETWEEN' || item[0] === 'NOT BETWEEN') && item.length === 4) {
        value = [item[2], item[3]];
      } else if (item[0] === 'EXISTS' || item[0] === 'NOT EXISTS') {
        value = '';
      } else {
        return;
      }
      return {
        sourceData: {
          field: item[1],
          operator: item[0],
          value: value
        }
      };
    }

    return function analyzeRule(item, parseResult = { rules: [], containsOR: false, containsLENGTH: false }) {
      const itemType = getItemType(item);

      switch (itemType) {
      case ITEM_TYPE.SINGLE_RULE: {
        if (item[0] === 'LENGTH') {
          parseResult.containsLENGTH = true;
        }
        const data = getRule(item);
        if (data) parseResult.rules.push(data);
        break;
      }
      case ITEM_TYPE.NESTED_RULE:
        item.forEach((itemData) => analyzeRule(itemData, parseResult));
        break;
      case ITEM_TYPE.OPERATOR:
        if (item === 'OR') parseResult.containsOR = true;
        if (item === 'LENGTH') parseResult.containsLENGTH = true;
        break;
      case ITEM_TYPE.SINGLE_RULE_WITH_OR:
        parseResult.containsOR = true;
        break;
      default:
        break;
      }
      return parseResult;
    };
  });
})();

