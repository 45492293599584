import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').service('findJobsWithFields', function(getModerationNotesQueryFields) {
    return function(fields, jobs) {
      return _.filter(jobs, ({ad})=> {
        const adFields = getModerationNotesQueryFields(ad);
        const hasMatch = _.some(_.keys(fields), (fieldKey)=> {
          const fieldValue = fields[fieldKey];
          if (Array.isArray(fieldValue)) {
            return _.intersection(fieldValue, adFields[fieldKey]).length > 0;
          } else {
            return fieldValue && (fieldValue === adFields[fieldKey]);
          }
        });
        return hasMatch;
      });
    };
  });
})();
