'use strict';

import { convertRouteForPermission } from '@common/permissions/permissionManager';

angular
  .module('eva2-angular')
  .service('TransitRestriction', function TransitRestriction($state, CurrentUser, goToDefault, ajax, UserPermissionManager) {
    'ngInject';

    const DEFAULT_TRANSITION_POLICY = {
      allowLoggedIn: true,
      requireAuth: true
    };

    function getTransitionPolicy(state) {
      if (!state.data || !state.data.transitionPolicy) {
        return DEFAULT_TRANSITION_POLICY;
      } else {
        return angular.extend(
          {},
          DEFAULT_TRANSITION_POLICY,
          state.data.transitionPolicy
        );
      }
    }

    this.$stateChangeStartListener = (event, to, toParams) => {
      const transitionPolicy = getTransitionPolicy(to);
      const loggedIn = CurrentUser.loggedIn;

      if (transitionPolicy.requireAuth && !loggedIn) {
        event.preventDefault();

        $state.go('app.login', {
          returnState: to.name,
          returnParams: toParams
        });

        return;
      }

      if (!transitionPolicy.allowLoggedIn && loggedIn) {
        event.preventDefault();
        goToDefault();
        return;
      }

      if (to.name.indexOf('app.assignment.') !== -1 && toParams.domain) {
        if (!UserPermissionManager.hasDomain(toParams.domain)) {
          event.preventDefault();
          $state.go('app.forbidden');
          return;
        }

        let route = convertRouteForPermission(to.name, toParams);
        if (!UserPermissionManager.hasPermissionToEnterRoute(route, toParams.domain)) {
          event.preventDefault();
          $state.go('app.assignment.forbidden', {domain: toParams.domain});
        }
      }
    };
  });
