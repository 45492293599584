(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('evaImageOnLoad', evaImageOnLoad);

  function evaImageOnLoad() {
    'ngInject';
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        element.on('load', function() {
          scope.$apply(attrs.evaImageOnLoad);
        });
      }
    };
  }
})();
