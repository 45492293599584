angular.module('eva2-angular').service('Intercom', function Intercom($window, $log, INTERCOM_ID) {
  'use strict';

  this.initialize = function initialize() {
    if (!$window.Intercom) {
      $log.error('Intercom', 'SDK is not setup (window.Intercom is missing)');
      return;
    }
    $log.debug('Booting intercom');
    $window.Intercom('boot', {
      app_id: INTERCOM_ID,
      hide_default_launcher: true
    });
  };

  this.login = function login(userId, name, username, email, intercomHmac) {
    // Gracefully handle old sessions where the userId hasn't been added yet from Apollo. (requires a login/logout).
    if (!userId) {
      return;
    }
    $log.debug('Identifying user:' + userId + ', name:' + name, ' hmac:' + intercomHmac);
    $window.Intercom('update', {
      user_id: userId,
      user_hash: intercomHmac,
      name,
      username,
      email
    });
  };

  this.logout = function reset() {
    $log.debug('Resetting logged in user');
    $window.Intercom('shutdown');
  };

  this.group = function group(groupId, name) {
    $log.debug('Registering group:' + groupId + ', name:' + name);
    $window.Intercom('update', {
      company: {
        id: groupId,
        name
      }
    });
  };

  this.pageview = function pageview() {
    $log.debug('Intercom pageview');
    $window.Intercom('update');
  };

  this.track = function track(key, metadata) {
    $log.debug('Tracking event:' + key + ', value:', metadata);
    $window.Intercom('trackEvent', key, metadata);
  };

  this.show = function() {
    $window.Intercom('show');
  };
});
