(function() {
  'use strict';

  function Controller($scope, htmlSanitizer) {
    'ngInject';
    const vm = this;

    $scope.$watch('$ctrl.highlight', function() {
      update();
    });

    $scope.$watch('$ctrl.value', function() {
      update();
    });

    function update() {
      vm.content = htmlSanitizer.sanitize(getContent(vm.highlight));
      vm.lengthText = getLengthText(vm.value, vm.highlight);
      vm.stripedValueFromHighlight = stripValueFromHighlight(vm.content);
    }
    function getContent(highlight) {
      if (!vm.value || !Array.isArray(vm.value) || vm.value.length === 0) {
        return '';
      }
      if (!vm.highlight || !Array.isArray(vm.highlight) || vm.highlight.length === 0) {
        return vm.value[0];
      }
      return highlight[0];
    }

    function stripValueFromHighlight(highlight) {
      if (!highlight) {
        return;
      }
      return highlight.replace('<span class="highlight-filter-match">', '')
        .replace('</span>', '');
    }

    function getLengthText(value, highlight) {
      const text = value && value.length > 1 ? `+${value.length - 1}` : '';
      if (!text) {
        return text;
      }
      if (Array.isArray(highlight) && highlight.length > 1) {
        return `<span class="search-array-result__more-value-block highlight-filter-match">${text}</span>`;
      }
      return `<span class="search-array-result__more-value-block">${text}</span>`;
    }
  }

  angular
    .module('eva2-angular')
    .component('evaSearchArrayResult', {
      bindings: {
        highlight: '<',
        value: '<',
        tooltipText: '@',
        searchText: '@',
        dictionaries: '<',
        domain: '@',
        name: '@'
      },
      template: require('./searchArrayResult.html'),
      controller: Controller
    });
})();
