
(function() {
  'use strict';

  function Controller() {
    const vm = this;
    vm.tooltips = {
      confirm: 'Confirm changes',
      revert: 'Discard changes'
    };
  }

  angular
    .module('eva2-angular')
    .component('evaConfirmRevertButtons', {
      bindings: {
        onConfirmClicked: '&',
        onRevertClicked: '&',
        isValid: '<',
        name:'@'
      },
      template: require('./confirmRevertButtons.html'),
      controller: Controller
    });
})();

