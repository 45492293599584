'use strict';

angular.module('eva2-angular')
  .factory('ServerSideValidationHTTPInterceptor', function($q, $rootScope, $log, $location, $injector, Notifier, tFilter) {
    return {
      responseError: function(response) {
        if (!response || !response.config) {
          return $q.reject(response);
        }

        if (response.config.method === 'POST' && response.data && response.data.context) {
          const eventName = ['ServerSideValidationFailed', response.data.context].join(':');
          $rootScope.$broadcast(eventName, response.data);
        }

        const $state = $injector.get('$state');
        const CurrentUser = $injector.get('CurrentUser');
        if (response.status === 401 && $state.current.name !== 'app.login') {
          CurrentUser.logOut('You need to login to access this page');
          Notifier.display(tFilter('LOG_IN.ERROR.SESSION_TOKEN_EXPIRED'), {type: 'error', ttl: 8000});
          $state.go('app.login');
        }
        window.sentryService.captureException(new Error(`API Error - Status: ${response.status}, Method: ${response.config?.method}, Url: ${response.config?.url}  Data: ${JSON.stringify(response.data)}`));
        return $q.reject(response);
      }
    };
  });
