(function() {
  'use strict';

  angular.module('eva2-angular').service('Enum', ()=>({
    //template enum in manual moderation
    TEMPLATE: {
      CLASSIFIED: 1,
      IMAGE: 2,
      CLASSIFIED2: 3
    },

    //error for csv importer mapping
    CSV_MAP_ERROR: {
      NO_ERROR: 0,
      COLUMN_ATTRIBUTE_MISS: 1,
      MAP_ATTRIBUTE_MISS: 2,
      EMPTY: 3,
      DUPLICATE: 4,
      WRONG_FORMAT: 5,
      REQUIRED: 6
    },

    TREE_OPEN_MODE: {
      NO_DATA: 0,
      TREE: 1,
      ONE_LEVEL_DROPDOWN: 2,
      TWO_LEVEL_DROPDOWN: 3
    },

    JOB_LIST_VIEW_TYPE: {
      GRID: 0,
      LIST: 1
    }
  }));
})();
