(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .factory('ruleModeManager', service);

  function service(parseBlangExpression, $q) {
    function canShowRuleInSimpleMode(domain, ruleString, domainBlangVariables) {
      if (ruleString === undefined || ruleString === null) {
        return $q.resolve(false);
      }
      if (ruleString === '') {
        return $q.resolve(true);
      }
      return parseBlangExpression(domain, ruleString).then((data)=> {
        const { hasComment, analyzedRule } = data;
        return !hasComment
          && !analyzedRule.containsOR
          && !analyzedRule.containsLENGTH
          && !hasCustomerSpecificDetails(analyzedRule.rules)
          && !hasVariableInValue(analyzedRule.rules)
          && !hasUnavailableField(analyzedRule.rules, domainBlangVariables);
      });
    }

    function hasCustomerSpecificDetails(rules) {
      return rules.some((rule) => !!rule.sourceData &&
          rule.sourceData.field && rule.sourceData.field.type === 'customerSpecific');
    }

    function hasVariableInValue(rules) {
      return rules.some((rule) => rule.sourceData && rule.sourceData.value && (rule.sourceData.value.type === 'variable'));
    }
    function hasUnavailableField(rules, availableFields) {
      const fieldsUsedInRules = rules.map((rule) => rule.sourceData && rule.sourceData.field && rule.sourceData.field.value);
      const availableFieldNames = availableFields.map((field) => field.id);
      return fieldsUsedInRules.some((field) => availableFieldNames.indexOf(field) === -1);
    }

    return {
      canShowRuleInSimpleMode
    };
  }
})();
