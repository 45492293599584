(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .filter('t', function(translateFilter) {
      return translateFilter;
    });
})();

