(function() {
  'use strict';
  angular.module('eva2-angular').controller('DeleteItemCtrl', function($modalInstance, config) {
    const vm = this;
    vm.config = config;

    vm.confirm = function() {
      config.onConfirm && config.onConfirm();
      $modalInstance.dismiss();
    };

    vm.cancel = ()=> {
      $modalInstance.dismiss('cancel');
    };
  });
})();


