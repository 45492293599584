(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);

  appConfig.$inject = ['$stateProvider'];

  function appConfig($stateProvider) {
    $stateProvider.state('app.inviteUserTokenVerify', {
      url: '/invite?token',
      data: {
        title: 'PAGE_TITLE.INVITE_USER',
        transitionPolicy: {
          requireAuth: false,
          allowLoggedIn: true
        }
      },
      views: {
        'main@': {
          template: require('@partial/start/start.html')
        },
        'content@app.inviteUserTokenVerify': {
          template: require('./inviteUserTokenVerify.html'),
          controller: 'InviteUserTokenVerifyCtrl',
          controllerAs: 'vm'
        }
      }
    });
  }
})();
