"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useClickOutside = void 0;
/**
 * Hook that alerts clicks outside of the passed ref
 */
var react_1 = require("react");
var useClickOutside = function (node, onClickOutside, dependency) {
    (0, react_1.useEffect)(function () {
        var handleClickOutside = function (event) {
            if (node.current && !node.current.contains(event.target)) {
                onClickOutside();
            }
        };
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return function () {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, dependency || []);
};
exports.useClickOutside = useClickOutside;
