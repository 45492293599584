'use strict';
import _ from 'lodash';
(function() {
  angular.module('eva2-angular').directive('blangEditor', function(ApiService) {
    const debouncedOnChange = _.debounce(function(domain, newValue, oldValue, scope, ngModelCtrl) {
      function applyState(stageValueForUpstreamNgModelValidation, errorMsg) {
        if (errorMsg) {
          scope.vm.blang.error = true;
          scope.vm.blang.message = errorMsg;
        } else {
          scope.vm.blang.error = false;
          scope.vm.blang.message = null;
        }

        scope.$applyAsync(function() {
          ngModelCtrl.$setViewValue(stageValueForUpstreamNgModelValidation);
        });
      }

      const scenarioUntouchedFieldWithoutValue = function() {
        return !newValue && !oldValue;
      };

      // Provided that the editor is used in a context where no initial value was provided by parent ngModel
      // then avoid triggering validation upstream causing potential messages to be shown to user by exiting fn
      if (scenarioUntouchedFieldWithoutValue()) {
        return;
      }

      const scenarioUserErasedText = function() {
        return !newValue;
      };

      if (scenarioUserErasedText()) {
        applyState('', null);
        return;
      }

    

      ApiService.validateBlangRule(domain, newValue).then((result)=> {
        if (result.result) {
          applyState(newValue, null);
          scope.vm.updateRuleVariables({value: result.parsedRule});
        } else {
          let msg = result.message;
          if (result.location && result.location.start) {
            msg = 'Line ' + result.location.start.line + ', column ' + result.location.start.column + ': ' + msg;
          }
          applyState(null, msg);
        }
      });
    }, 250, {trailing: true});

    return {
      restrict: 'E',
      replace: true,
      bindToController: true,
      require: '?ngModel',
      controllerAs: 'vm',
      controller: Controller,
      scope: {
        ngModel: '=',
        placeholder: '@',
        readonly: '@',
        domain: '@',
        ruleVariables: '<',
        updateRuleVariables:'&'
      },
      template: require('./blangEditor.html'),
      link: function(scope, element, attrs, ngModelCtrl) {
        if (!ngModelCtrl) {
          return;
        }

        scope.error = false;

        const onChange = function() {
          const candidate = scope.vm.blang.value;
          debouncedOnChange(scope.vm.domain, candidate, scope.vm.previousValue, scope, ngModelCtrl);
          scope.vm.previousValue = candidate;
        };
        scope.$watch('vm.blang.value', onChange);

        ngModelCtrl.$render = function() {
          if (scope) {
            scope.vm.blang.value = ngModelCtrl.$modelValue;
          }
        };

        const textarea = element.find('textarea[ng-model]');
        if (textarea.length > 0) {
          scope.$watch(function() {
            return element.hasClass('ng-invalid') || textarea.hasClass('ng-invalid');
          }, function() {
            // textarea.toggleClass('has-error', isInvalid);
          });
        }
      }
    };
  });

  function Controller(defineBlangMode) {
    const vm = this;
    vm.blang = {};
    vm.previousValue = null;

    defineBlangMode();

    vm.editorOptions = {
      readOnly: vm.readonly,
      placeholder: vm.placeholder,
      mode: 'blang',
      matchBrackets: true,
      lineNumbers: true,
      lineWrapping: true
    };
  }
})();
