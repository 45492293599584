(function() {
  'use strict';
  angular.module('eva2-angular').service('getFeedback', function(ApiAd, showJobError, EvaLogger, $q) {
    function parseFeedbackData(data) {
      return data.map((item) => ({name: item.feedbackName, id: item.id, feedbackId: item.feedbackId}));
    }

    function getFeedback(domain) {
      return ApiAd.getFeedback(domain)
        .then((data) => parseFeedbackData(data),
          (error) => {
            showJobError('loadFeedback', error);
            return $q.reject(error);
          });
    }

    return getFeedback;
  });
})();
