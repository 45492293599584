'use strict';

angular.module('eva2-angular').service('Invite', (ajax) => {
  const Invite = {

    verifyToken: function verifyPasswordToken(data) {
      return ajax.get('/invite/verify/' + data.token);
    },

    joinInvite: function joinInvite(data) {
      return ajax.post('/invite/join/' + data.token);
    },
    signup: function(data) {
      return ajax.post('/signup', {
        token: data.token,
        name: data.name,
        password: data.password,
        captchaToken: data.captchaToken,
        region: data.region
      });
    },
    login: function(data) {
      return ajax.post('/login', {
        token: data.token,
        username: data.username,
        password: data.password
      });
    }

  };

  return Invite;
});
