(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('focus', focusCtrl);

  focusCtrl.$inject = ['$timeout'];

  function focusCtrl($timeout) {
    return {
      scope: {
        focus: '@'
      },
      link: function(scope, element) {
        scope.$watch('focus', function(value) {
          if (value) {
            $timeout(function() {
              element[0].focus();
            });
          }
        });
      }
    };
  }
})();
