
(function() {
  'use strict';

  function Controller($scope) {
    const vm = this;

    vm.$onInit = () => {
      $scope.$watch('$ctrl.box', function() {
        if (!vm.box) {
          return;
        }
        vm.style = {
          width: vm.box.width,
          height: vm.box.height,
          left: vm.box.x,
          top: vm.box.y
        };
      });
    };
  }

  angular
    .module('eva2-angular')
    .component('evaBoundingBox', {
      bindings: {
        text: '@',
        box: '<',
        textArray: '<',
        noLabel: '<'
      },
      template: require('./boundingBox.html'),
      controller: Controller
    });
})();

