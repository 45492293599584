"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.percentage = exports.thousandSeparator = void 0;
// [TODO] - remove the ignore parameter, input should be always defined
var thousandSeparator = function (input, ignore) {
    if (ignore) {
        return input;
    }
    if (!input && input !== 0) {
        return '';
    }
    if (Number.isNaN(input))
        return input; // if the input is not a number return the input
    var inputString = input.toString();
    inputString += '';
    var x = inputString.split('.');
    var integerPart = x[0];
    var fractionalPart = x.length > 1 ? ".".concat(x[1]) : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(integerPart)) {
        // eslint-disable-next-line no-useless-concat
        integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
    }
    return integerPart + fractionalPart;
};
exports.thousandSeparator = thousandSeparator;
var percentage = function (input, decimals) {
    if (!input && input !== 0) {
        return '';
    }
    if (Number.isNaN(input) || !Number.isFinite(input)) {
        return '0%';
    }
    var num = input * 100;
    if (num % 1) {
        return "".concat((input * 100).toFixed(decimals), "%");
    }
    return "".concat((input * 100).toFixed(0), "%");
};
exports.percentage = percentage;
