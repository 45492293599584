(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);


  function appConfig($stateProvider) {
    $stateProvider.state('app.assignment.automation.dictionaryEdit', {
      url: '/dictionaries/:name/edit',
      data: {
        title: 'PAGE_TITLE.EDIT_LIST'
      },
      views: {
        content: {
          template: require('./dictionaryEdit.html'),
          controller: 'AdminDictionaryEditCtrl',
          controllerAs: 'vm'
        }
      }
    });
  }
})();
