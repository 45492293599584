(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('FilterBaseCtrl', FilterBaseCtrl);


  function FilterBaseCtrl($scope, $stateParams, Notifier, FilterStatusRecoverer, tFilter) {
    $scope.domain = $stateParams.domain;
    $scope.query = {};
    $scope.filterId = $stateParams.filterId;

    function error(filter, err) {
      Notifier.display(tFilter('AUTOMATION.DECISION_RULES.STATUS.ERROR_TEXT', {filterName: filter.name}, 'messageformat'), {
        type: 'error',
        ttl: 3000
      }, err);
    }

    function success(filter) {
      Notifier.display(tFilter('AUTOMATION.DECISION_RULES.STATUS.SUCCESS_TEXT', {filterName: filter.name}, 'messageformat'), {type: 'success', ttl: 1400});
    }

    function rollback(filter) {
      filter.enabled = !filter.enabled;
    }

    function recover(filter, recoveredFilter) {
      filter.enabled = recoveredFilter.enabled;
    }

    const recoverer = FilterStatusRecoverer.create(
      success,
      error,
      rollback,
      recover
    );

    $scope.updateStatus = function(event, filter) {
      if (event.target.nodeName !== 'INPUT') return;

      const reqOptions = {
        domain: $stateParams.domain,
        id: $stateParams.filterId
      };

      const filterCopy = {...filter};
      delete filterCopy.result;
      recoverer.apply(reqOptions, filterCopy);
    };

    $scope.updateFilter = function(event, filter) {
      if (event.target.nodeName !== 'INPUT') return;

      const reqOptions = {
        domain: $stateParams.domain,
        id: $stateParams.filterId
      };

      recoverer.apply(reqOptions, filter);
    };
  }
})();
