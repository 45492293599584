
(function() {
  'use strict';
  function Controller(Notifier, ApiService, tFilter, $element) {
    'ngInject';
    const vm = this;

    vm.toggleDropdown = (newValue)=> {
      if (newValue) {
        // if new value is true, i.e, opening a dropdown,
        // then close all other drop-downs
        const openedQuickAddToListComponents = angular
          .element('.quick-add-to-list--open .eva-single-select-with-search');
        for (let i = 0; i< openedQuickAddToListComponents.length; i++) {
          openedQuickAddToListComponents.eq(i).controller('evaQuickAddToList').showDropdown = false;
        }
        if ($.fn.closeDropDown) {
          $('.eva-keyboard-focused').closeDropDown();
        }
      }
      vm.showDropdown = newValue;
    };

    vm.closeDropdown = ()=> {
      vm.showDropdown = false;
    };

    vm.$onInit = () => {
      vm.itemSelected = (item)=> {
        if (!item) {
          return;
        }
        vm.showDropdown = false;
        $element.closest('.js-eva-job-hover-to-see-icons-container.has-mouse').removeClass('has-mouse');
  
        const listName = item.id;
        ApiService.addWordToList(vm.domain, listName, vm.word).then(()=> {
          Notifier.display(
            tFilter('ITEM.QUICK_ADD_TO_LIST.SUCCESS_MESSAGE', {
              NAME: vm.name,
              VALUE: vm.word,
              LIST_NAME: listName
            }, 'messageformat'), {type: 'success', ttl: 1800});
        }, (error)=> {
          const msg = error.status === 409 ?
            tFilter('ITEM.QUICK_ADD_TO_LIST.DUPLICATE_ERROR_MESSAGE', {
              NAME: vm.name,
              VALUE: vm.word,
              LIST_NAME: listName
            }, 'messageformat') :
            tFilter('ITEM.QUICK_ADD_TO_LIST.DUPLICATE_ERROR_MESSAGE', {
              LIST_NAME: listName
            }, 'messageformat');
          Notifier.display(msg, {type: 'failure', ttl: 6000});
        });
      };
    };
  }

  angular
    .module('eva2-angular')
    .component('evaQuickAddToList', {
      bindings: {
        data: '<',
        itemSelected: '&',
        domain: '@',
        word: '@',
        name: '@'
      },

      template: require('./quickAddToList.html'),
      controller: Controller,
      controllerAs: 'vm'
    });
})();

