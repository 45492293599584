'use strict';

angular.module('eva2-angular')
  .filter('slice', function() {
    return function(arr, start, end) {
      if (!arr) {
        return arr;
      }
      return arr.slice(start, end);
    };
  });
