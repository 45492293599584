import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').service('getDeepSearchResult', function() {
    function getDeepSearchResult(data, userInput) {
      if (!Array.isArray(data)) {
        return [];
      }
      if (!userInput) {
        return data;
      }
      const results = [];
      data.forEach((item) => {
        if (matched(item)) {
          results.push(item);
        } else {
          if (item.children) {
            const matchedChildren = getDeepSearchResult(item.children, userInput);
            if (matchedChildren.length > 0) {
              const itemWithMatchedChildrenOnly = _.cloneDeep(item);
              itemWithMatchedChildrenOnly.children = matchedChildren;
              results.push(itemWithMatchedChildrenOnly);
            }
          }
        }
      });
      return results;

      function matched(item) {
        return item.text && (item.text.toUpperCase().indexOf(userInput.toUpperCase()) >= 0);
      }
    }

    return getDeepSearchResult;
  });
})();
