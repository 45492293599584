import brokenImage from '../../assets/broken_Image.svg';
(function() {
  'use strict';

  angular
    .module('eva2-angular')

    .directive('httpSrc', httpSrc);

  function httpSrc($http) {
    'ngInject';
    return {
      link: link,
      scope: {
        requestUrl: '<',
        onFailedToLoadImage: '<'
      },
      restrict: 'A'
    };

    function link(scope, element) {
      /*
      *     Images are crawled by image service and saved in our S3.
      *     Authentication is needed to access these images.
      *     In this case,
      *     We add the auth token into the header of the GET request, get the image data,
      *     and set "src" to the "object url".
      *     For "object url", see https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
      * */
      let imageObjectUrl = null;

      function revokeObjectUrlIfExist(imageObjectUrl) {
        if (imageObjectUrl) {
          URL.revokeObjectURL(imageObjectUrl);
        }
      }
      scope.$on('$destroy', ()=> {
        revokeObjectUrlIfExist(imageObjectUrl);
      });

      scope.$watch('requestUrl', function(newVal, oldVal) {
        if (newVal) {
          downloadImage();
        } else if (oldVal) {
          element.prop( 'src', false );
        }
      });

      function handleImageLoadFailure() {
        element.prop( 'src', false );
        if (typeof scope.onFailedToLoadImage === 'function') {
          scope.onFailedToLoadImage();
        } else {
          element.attr('src', brokenImage);
        }
      }

      function downloadImage() {
        revokeObjectUrlIfExist(imageObjectUrl);
        const requestConfig = angular.extend({
          method: 'Get',
          url: scope.requestUrl,
          responseType: 'arraybuffer',
          cache: 'true'
        });
        $http(requestConfig)
          .then(function(response) {
            const blob = new Blob(
              [response.data], {type: response.headers('Content-Type')}
            );
              // revoke the old image object url, and creates a new one.
            imageObjectUrl = URL.createObjectURL(blob);
            element.attr('src', imageObjectUrl);
          }, handleImageLoadFailure);
      }
    }
  }
})();
