import { dataStoringApi } from '@common/services/dataStoring';
import { maxFromDate, minDateFromPeriod } from '@features/ItemLog/util/fromDateUtil';

(function() {
  'use strict';
  angular.module('eva2-angular').controller('DeleteQueueCtrl', function($modalInstance, ApiService, currentQueueId, $ngRedux, domain,
    currentQueueName, filters, numberOfItemsInQueue) {
    const vm = this;
    vm.domain = domain;
    vm.numberOfItemsInQueue = numberOfItemsInQueue;

    vm.currentQueueName = currentQueueName;

    vm.hasFilters = false;

    if (vm.domain) {
      $ngRedux.dispatch(
        dataStoringApi.endpoints.getDataStoringOption.initiate(vm.domain)
      ).then((response) => {
        vm.minDateFromPeriod = minDateFromPeriod(response.data.period).getTime();
        vm.filters = filters.map(item => ({
          ...item,
          from: maxFromDate(item.lastModifiedTime, vm.minDate)
        }));
        vm.hasFilters = vm.filters && vm.filters.length > 0;
      });
    }

    vm.confirm = function() {
      const promise = ApiService.deleteModerationQueue(vm.domain, currentQueueId);
      promise.then(() => {
        $modalInstance.close({ success: true });
      }).catch((error) => {
        $modalInstance.close({ success: false, error });
      });
    };

    vm.cancel = ()=> {
      $modalInstance.dismiss('cancel');
    };
  });
})();

