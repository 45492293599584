"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteAllContentJob = void 0;
var duration_1 = require("@utils/duration");
// @todo remove this class when we will port the deleteContent notice to React
var DeleteAllContentJob = /** @class */ (function () {
    function DeleteAllContentJob(jobData) {
        var _this = this;
        this.jobId = jobData.jobId;
        this.scheduledAt = new Date(jobData.startDateTimestamp);
        this.remainingTime = this.scheduledAt.getTime() - new Date().getTime();
        this.requestedByUser = { name: jobData.username, email: jobData.userId };
        this.requestedTime = new Date(jobData.registerTimestamp);
        this.remainingTimeInText = this.remainingTime > 0 ? (0, duration_1.duration)(this.remainingTime, true) : null;
        this.intervalId = setInterval(function () {
            _this.remainingTime = +_this.scheduledAt - +new Date();
            _this.remainingTimeInText = _this.remainingTime > 0 ? (0, duration_1.duration)(_this.remainingTime, true) : null;
            if (_this.remainingTime <= 0) {
                clearInterval(_this.intervalId);
            }
        }, 60000);
    }
    return DeleteAllContentJob;
}());
exports.DeleteAllContentJob = DeleteAllContentJob;
