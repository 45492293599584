angular.module('eva2-angular').directive('evaInputFilled', function() {
  'use strict';

  const _class = 'input--filled';

  return {
    restrict: 'A',
    link: function(scope, el) {
      function onInputFocus(ev) {
        angular.element(ev.target.parentNode).addClass(_class);
      }

      function onInputBlur(ev) {
        if (ev.target.value.trim() === '') {
          angular.element(ev.target.parentNode).removeClass(_class);
        }
      }

      // Listen to input change to catch autofill
      function onInputChange(ev) {
        if (ev.target.value.trim() !== '') {
          angular.element(ev.target.parentNode).addClass(_class);
        }
      }

      function onDestroy() {
        el.unbind('focus', onInputFocus);
        el.unbind('blur', onInputBlur);
        el.unbind('change', onInputChange);
      }

      el.on('blur', onInputBlur);
      el.on('focus', onInputFocus);
      el.on('change', onInputChange);

      scope.$on('$destroy', onDestroy);
    }
  };
});
