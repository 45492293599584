(function() {
  'use strict';
  angular.module('eva2-angular').service('getAdminEmailList', function(ApiService) {
    function getAdminEmailList(domain) {
      return ApiService.getMembers(domain).then((members) => {
        const admins = members.filter((member) => member.hierarchy === 2);
        return admins.map((admin) => admin.email);
      });
    }

    return getAdminEmailList;
  });
})();
