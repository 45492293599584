(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('evaPageTitle', pageTitle);

  pageTitle.$inject = [];

  function pageTitle() {
    return {
      restrict: 'A',
      controller: Controller
    };
  }

  Controller.$inject = ['$scope', '$rootScope'];

  function Controller($scope, $rootScope) {
    function setTitle(event, title) {
      $scope.title = title;
    }

    const removeListener = $rootScope.$on('$titleChanged', setTitle);
    $scope.$on('$destroy', removeListener);
  }
})();
