'use strict';

angular
  .module('eva2-angular')
  .directive('evaHasError', hasError);

hasError.$inject = ['applicationError'];

function hasError(applicationError) {
  return {
    restrict: 'A',
    link: function(el) {
      const $el = angular.element(el);

      applicationError.onChange(function(hasError) {
        const operation = hasError ? 'addClass' : 'removeClass';
        $el[operation]('hide');
      });
    }
  };
}
