angular.module('eva2-angular').service('DateFormat', function() {
  'use strict';

  const DateFormat = {
    display: 'yyyy-MM-dd HH:mm',
    details: 'yyyy-MM-dd HH:mm:ss',
    posted: 'dd MMMM @ HH:mm',
    entered: 'dd/MM/yy HH:mm:ss',
    short: 'dd/MM/yy HH:mm',
    hourMinute: 'HH:mm',
    moment: {
      display: 'DD MMM YYYY HH:mm',
      hours: 'YYYY-MM-DD HH:[00]',
      days: 'YYYY-MM-DD',
      months: 'YYYY-MM'
    }
  };

  return DateFormat;
});
