(function() {
  'use strict';

  angular.module('eva2-angular').service('IMAGE_AUG_NOTICES', function(tFilter, createNotice) {
    return [
      createNotice.classification(
        'drugs',
        'drug',
        tFilter('ITEM.BOUNDING_BOX.DRUG')
      ),
      createNotice.detection(
        'face_detection',
        (number) => tFilter('ITEM.NOTICE.FACE', {COUNT: number}, 'messageformat'),
        tFilter('ITEM.BOUNDING_BOX.FACE')
      ),
      createNotice.classification(
        'tobacco_alcohol',
        'tobacco',
        tFilter('ITEM.BOUNDING_BOX.TOBACCO')
      ),
      createNotice.classification(
        'tobacco_alcohol',
        'alcohol',
        tFilter('ITEM.BOUNDING_BOX.ALCOHOL')
      ),
      createNotice.detection(
        'text_detection',
        (number) => tFilter('ITEM.NOTICE.TEXT', {COUNT: number}, 'messageformat'),
        tFilter('ITEM.BOUNDING_BOX.TEXT')
      ),
      createNotice.classification(
        'nsfw',
        'suggestive',
        tFilter('ITEM.BOUNDING_BOX.NSFW', {VALUE: 'suggestive'}, 'messageformat')
      ),
      createNotice.classification(
        'nsfw',
        'nudity',
        tFilter('ITEM.BOUNDING_BOX.NSFW', {VALUE: 'nudity'}, 'messageformat')
      ),
      createNotice.classification(
        'nsfw',
        'gore',
        tFilter('ITEM.BOUNDING_BOX.NSFW', {VALUE: 'gore'}, 'messageformat')
      ),
      createNotice.classification(
        'rotation',
        'nok',
        tFilter('ITEM.BOUNDING_BOX.MISORIENTED')
      ),
      createNotice.classification(
        'weapon',
        'nok',
        tFilter('ITEM.BOUNDING_BOX.WEAPON')
      ),
      createNotice.classification(
        'quality_image',
        'low',
        tFilter('ITEM.BOUNDING_BOX.LOW_QUALITY')
      ),
      createNotice.classification(
        'watermark',
        'watermark',
        tFilter('ITEM.BOUNDING_BOX.WATERMARK')
      ),
      createNotice.chainedModel(
        'face_detection',
        'face_gender',
        'male',
        (number) => tFilter('ITEM.NOTICE.MALE', {COUNT: number}, 'messageformat'),
        tFilter('ITEM.BOUNDING_BOX.GENDER', {VALUE: 'male'}, 'messageformat')
      ),
      createNotice.chainedModel(
        'face_detection',
        'face_gender',
        'female',
        (number) => tFilter('ITEM.NOTICE.FEMALE', {COUNT: number}, 'messageformat'),
        tFilter('ITEM.BOUNDING_BOX.GENDER', {VALUE: 'female'}, 'messageformat')
      ),
      createNotice.chainedModel(
        'face_detection',
        'underage',
        'minor',
        (number) => tFilter('ITEM.NOTICE.MINOR', {COUNT: number}, 'messageformat'),
        tFilter('ITEM.BOUNDING_BOX.MINOR')
      ),
    ];
  });
})();
