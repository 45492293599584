import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').controller('EditImageModalCtrl', function($modalInstance, image, $timeout,
    imageCroppingAllowed, imageRotationAllowed) {
    const Cropper = require('cropperjs');
    const vm = this;
    const imageId = 'image-to-edit';
    vm.image = image;
    vm.containerSize = _.min([500, _.max([vm.image.originalSize.width, vm.image.originalSize.height])]);
    vm.containerStyle = {
      width: vm.containerSize,
      height: vm.containerSize
    };

    vm.imageCroppingAllowed = imageCroppingAllowed;
    vm.imageRotationAllowed = imageRotationAllowed;
    vm.imageEditor = null;
    let $imageToEdit;

    vm.initialize = (()=> {
      $imageToEdit = document.getElementById(imageId);
      vm.fileName = vm.image.src.split('/').pop();
      vm.originalTransformations = angular.copy(vm.image.transformations);
      const initializeData = getInitializeData(_.get(image, 'transformations.cropping'), _.get(image, 'transformations.rotation'));
      const options = initializeData ? {data: initializeData} : {autoCrop: false};
      vm.imageEditor = createImageEditor(options);
    });

    vm.cropButtonClicked = ()=> {
      if (!vm.imageEditor) {
        vm.imageEditor = createImageEditor();
      }
      if (vm.imageEditor.cropped) {
        vm.imageEditor.clear();
      } else {
        vm.imageEditor.crop();
      }
    };

    vm.rotate = (degree)=> {
      if (!vm.imageEditor) {
        vm.imageEditor = createImageEditor({autoCrop: false});
      }
      const {width, height} = vm.image.originalSize;
      vm.imageEditor.setData(getDataAfterRotation(degree, vm.imageEditor.getData(), {width, height}));
    };

    vm.revertToOriginal = ()=> {
      if (vm.imageEditor) {
        vm.imageEditor.rotate(-vm.rotation);
        vm.imageEditor.clear();
      }
    };

    vm.cancelChanges = ()=> {
      $modalInstance.close({transformations: vm.originalTransformations});
    };

    vm.saveChanges = () => {
      let data = {};
      const refinedCropping = refineCroppingAreaToImage(vm.cropping, vm.rotation);
      data.cropping = isCroppingAreaValid(refinedCropping) ? refinedCropping : null;
      data.rotation = vm.rotation === 0 ? null : vm.rotation;
      if (data.cropping === null && data.rotation === null) {
        data = null;
      }
      $modalInstance.close({transformations: data});
    };

    function createImageEditor(overrideOptions) {
      if ($imageToEdit && $imageToEdit.length === 0) {
        return;
      }
      const options = {
        zoomable: false,
        checkCrossOrigin: false,
        minCropBoxWidth: 32,
        minCropBoxHeight: 32,
        viewMode: 2,
        crop(event) { // both crop and rotate will trigger this event
          $timeout(()=> {
            const x1 = Math.round(event.detail.x);
            const y1 = Math.round(event.detail.y);
            const x2 = x1 + Math.round(event.detail.width);
            const y2 = y1 + Math.round(event.detail.height);
            vm.cropping = {x1, y1, x2, y2};
            vm.rotation = event.detail.rotate;
          });
        },
        ready() {
          if (vm.imageRotationAllowed && !vm.imageCroppingAllowed) {
            angular.element('.cropper-container').css('pointer-events', 'none');
          }
          angular.element('.cropper-js-container').css('visibility', 'visible');
        }
      };
      angular.extend(options, overrideOptions);
      return new Cropper($imageToEdit, options);
    }

    function getInitializeData(cropping, rotation) {
      if (!cropping && !rotation) {
        return null;
      }
      const data = {};
      if (cropping) {
        data.x = cropping.x1;
        data.y = cropping.y1;
        data.width = cropping.x2 - cropping.x1;
        data.height = cropping.y2 - cropping.y1;
      }
      if (rotation) {
        data.rotate = rotation;
      }
      return data;
    }

    function isCroppingAreaValid(cropping) {
      return cropping && !Object.keys(cropping).every((key) => cropping[key] === 0);
    }

    function refineCroppingAreaToImage(cropping, rotation) {
      if (!cropping) {
        return cropping;
      }
      const sizeAfterRotation = getSizeAfterRotation(rotation);
      return {
        x1: _.max([0, cropping.x1]),
        y1: _.max([0, cropping.y1]),
        x2: _.max([0, _.min([cropping.x2, sizeAfterRotation.width])]),
        y2: _.max([0, _.min([cropping.y2, sizeAfterRotation.height])])
      };
    }

    const getSizeAfterRotation = (rotation) => {
      const {width, height} = vm.image.originalSize;
      if (Math.abs(rotation) === 90 || Math.abs(rotation) === 270 ) {
        return {width: height, height: width};
      } else {
        return {width: width, height: height};
      }
    };
    function getDataAfterRotation(rotateBy, currentData, imageSize) {
      let x = currentData.x; let y = currentData.y;

      let {width: imageWidth, height: imageHeight} = imageSize;
      if (_.includes([90, -90, 270, -270], currentData.rotate)) {
        imageWidth = imageSize.height;
        imageHeight = imageSize.width;
      }

      if (rotateBy === 90) {
        x = imageHeight - currentData.y - currentData.height;
        y = currentData.x;
      }
      if (rotateBy === -90) {
        x = currentData.y;
        y = imageWidth - currentData.x - currentData.width;
      }
      return {x, y, width: currentData.height, height: currentData.width,
        rotate: getRotateWithinRange(rotateBy + currentData.rotate)};
    }

    function getRotateWithinRange(angle) {
      if (angle >= 360) {
        return angle - 360;
      }
      if (angle <= -360) {
        return angle + 360;
      }
      return angle;
    }
  });
})();

