(function() {
  'use strict';
  angular
    .module('eva2-angular')
    .config(appConfig);

  appConfig.$inject = ['$stateProvider'];

  function appConfig($stateProvider) {
    $stateProvider.state('app.assignment.csvImporter', {
      abstract: true,
      url: '/csv-importer',
      data: {
        title: 'PAGE_TITLE.CSV_IMPORTER'
      },
      views: {
        'content@app': {
          controller: 'CSVImporterController',
          controllerAs: 'vm',
          template: require('./csvImporter.html')
        },
        'navigationContainer@app.assignment.csvImporter': {
          template: '<csv-importer-navigation ' +
          'is-enabled-next-button-clicked="vm.isEnabledNextButtonClicked()" import-csv="vm.importCsv()" mapping="vm.startMapping()">' +
          '</csv-importer-navigation>'
        }
      }
    })
      .state('app.assignment.csvImporter.step0', {
        url: '',
        params: {
          resetFile: false,
          file: null,
          data: null
        },
        views: {
          'stepContainer@app.assignment.csvImporter': {
            template: '<upload data="vm.data" file="vm.file" reset="vm.reset()"></upload>'
          }
        }
      })
      .state('app.assignment.csvImporter.step1', {
        views: {
          'stepContainer@app.assignment.csvImporter': {
            template: require('@partial/csvImporter/stepMapColumn/stepMapColumn.html')
          }
        }
      })
      .state('app.assignment.csvImporter.step2', {
        views: {
          'stepContainer@app.assignment.csvImporter': {
            template: '<csv-preview data="vm.previewData" length ="vm.dataLength"><csv-preview>'
          }
        }
      });
  }
})();
