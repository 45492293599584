(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('matchedRules', matchedRules);

  matchedRules.$inject = [];

  function matchedRules() {
    const directive = {
      bindToController: {
        rules: '<',
        jobTitle: '<',
        domain: '<'
      },
      replace: 'true',
      controller: Controller,
      controllerAs: 'vm',
      restrict: 'E',
      template: require('./matchedRules.html'),
      scope: {
      }
    };
    return directive;
  }

  function Controller() {
    'ngInject';
  }
})();
