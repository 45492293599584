angular.module('eva2-angular').filter('groupBy', function() {
  'use strict';

  return function(input, arg) {
    if (Array.isArray(input) === false) {
      return null;
    }
    const grouped = input.reduce(function(result, item) {
      const key = item[arg];
      if (result[key]) {
        result[key].push(item);
      } else {
        result[key] = [item];
      }
      return result;
    }, {});

    return Object.keys(grouped).map(function(key) {
      return {
        key: key,
        values: grouped[key]
      };
    });
  };
});
