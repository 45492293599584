(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('filterViewCtrl', FilterViewCtrl);

  function FilterViewCtrl($scope, $stateParams, $controller, ApiFilters, UserPermissionManager, tFilter, $timeout) {
    'ngInject';
    $controller('FilterBaseCtrl', {$scope: $scope});

    const vm = this;

    vm.domain = $stateParams.domain;
    vm.filterId = $stateParams.filterId;

    vm.$onInit = () => {
      vm.canUserEditFilter = UserPermissionManager.getPermissionForOperation('automation:rule', vm.domain).canWrite;
      vm.getFilter();
    };

    // Needed for setting url parameters correctly in JobsSearchList.
    vm.extraRequestOptions = {
      filterId: vm.filterId
    };

    vm.domain = $stateParams.domain;

    vm.updateFilter = function(event) {
      $timeout(()=>{$scope.updateFilter(event, vm.filter);});
    };

    vm.getFilter = function() {
      const filterRequestOptions = {
        id: vm.filterId,
        domain: vm.domain
      };

      function whenError(error) {
        vm.errorLoadingFilter = true;
        vm.errorText = error && error.status === 404 ?
          tFilter('AUTOMATION.DECISION_RULES.ERROR.NO_RULE_MATCHED', {filterId: vm.filterId}, 'messageformat')
          : tFilter('AUTOMATION.DECISION_RULES.ERROR.LOADING_ERROR');
      }

      return ApiFilters
        .show(filterRequestOptions)
        .then(function(data) {
          vm.errorLoadingFilter = false;
          vm.filter = data;
          processFilterProps(vm.filter);
        }).catch(whenError);
    };


    vm.reloadFilter = function() {
      vm.getFilter();
    };

    const props = ['type', 'notice', 'result', 'enabled'];

    function processFilterProps() {
      vm.options = [];

      for (const prop in vm.filter) {
        if (props.indexOf(prop) !== -1) {
          if (prop === 'enabled') {
            vm.filterEnabled = vm.filter[prop];
            vm.filterEnabledText = vm.filter[prop] ? 'On' : 'Off';
            continue;
          }
          if (prop === 'notice') {
            vm.options.push(getNotice(vm.filter[prop]));
            continue;
          }  
          if (prop === 'result') {
            vm.actionLabel= tFilter('AUTOMATION.DECISION_RULES.RULE_VIEW.ACTION_LABEL');
            if(vm.filter[prop] === 'REFUSE'){
              vm.actionValue=`Refuse (${vm.filter.decisionName})`;
              continue;
            }
            vm.actionValue=tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.ACTIONS.RESULT'
              , {VALUE: vm.filter[prop]}, 'messageformat');
            continue;
          }

          vm.options.push({
            title: prop,
            value: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.ACTIONS.RESULT'
              , {VALUE: vm.filter[prop]}, 'messageformat')
          });

        }
      }
    }

    function getNotice(obj) {
      let str = 'Disabled';

      if (obj.enabled) {
        str = obj.text + ' (' + obj.severity + ')';
      }

      return {
        title: tFilter('AUTOMATION.DECISION_RULES.RULE_VIEW.NOTICE_LABEL'),
        value: str
      };
    }
  }
})();
