/*!
 * ruleConditions
 * Description: rule conditions component, add and remove rule condition dynamically
 */

(function() {
  'use strict';
  /**
   * This function adds "blangExpression" to the target rule in rules array, and returns
   * the concatenated rule expression.
   * @param rule{string}
   * @param index{number}
   * @param rules{array}
   * @returns {string|undefined} concatenated rule expression
   */
  function getConcatenatedRule(rule, index, rules) {
    if (index !== undefined) {
      rules[index].blangExpression = rule;
    }

    const isEveryRuleValid = rules.every((rule) => !!rule.blangExpression);
    return isEveryRuleValid ? rules.map((rule)=> rule.blangExpression).join(' AND ') : undefined;
  }

  function Controller(ApiDictionaries, parseBlangExpression, tFilter) {
    'ngInject';
    const vm = this;
    const resources = {
      addBtnText: tFilter('AUTOMATION.DECISION_RULES.BTN.ADD_CONDITION')
    };
    vm.$onInit = function() {
      vm.resources = resources;
      getDictionaries();
      if (vm.expression) {
        parseBlangExpression(vm.domain, vm.expression).then((parseResult)=> {
          vm.rules = parseResult.analyzedRule.rules;
        });
      } else {
        vm.rules = [];
      }
    };
    //public

    vm.deleteRule = function(index) {
      vm.rules.splice(index, 1);
      vm.updateExpression();
    };

    // callback when one single rule has updated.
    vm.updateExpression = function(rule, index) {
      vm.onUpdate({rule: getConcatenatedRule(rule, index, vm.rules)});
    };

    vm.addEmptyRule = function() {
      vm.rules.push({
        sourceData: {
          field: '',
          operator: '',
          value: ''
        },
        blangExpression: ''
      });
    };


    function getDictionaries() {
      ApiDictionaries
        .get(vm.domain)
        .then(function(results) {
          vm.dictionaries = results.map((item) => {
            item.text = item.name;
            item.id = item.name;
            return item;
          });
        });
    }
  }

  angular
    .module('eva2-angular')
    .component('ruleConditions', {
      bindings: {
        expression: '<', // string
        domain: '<',
        submitted: '<',
        onUpdate: '&',
        domainBlangVariables: '<'
      },
      template: require('./ruleConditions.html'),
      controller: Controller
    });
})();
