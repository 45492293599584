import { DeleteAllContentJob } from '@features/Settings/components/DeleteContent/DeleteAllContent/DeleteAllContentJobType';

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .factory('createDeleteAllContentJob', function(EvaLogger) {
      'ngInject';

      function createDeleteAllContentJob(jobData) {
        const scheduledAtObj = moment(jobData.startDateTimestamp);
        if (!scheduledAtObj.isValid()) {
          EvaLogger.captureMessage(`Deletion job ${jobData.jobId} has an invalid starting time: ${jobData.scheduledAt}`);
          return null;
        }
        if (scheduledAtObj.isBefore(moment.now())) {
          return null;
        }
        return new DeleteAllContentJob(jobData);
      }

      return createDeleteAllContentJob;
    });
})();
