
(function() {
  'use strict';

  function Controller() {
  }

  angular
    .module('eva2-angular')
    .component('evaVideoThumbnail', {
      bindings: {
        key: '<',
        containerSize: '<',
        selected: '<',
        video: '<',
        isYoutubeVideo: '<',
        deleted: '<',
        shouldDeferLoading: '<',
        onSelect: '&'
      },
      template: require('./videoThumbnail.html'),
      controller: Controller,
      controllerAs: 'vm'
    });
})();
