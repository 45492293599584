import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').service('findNodeByCategoryId', function() {
    return function(id, list) {
      return findNode(id, list);
    };

    function findNode(id, list) {
      if (!id || !list || list.length === 0) {
        return;
      }
      const result = _.find(list, (item)=> item.categoryId === id);
      if (result) {
        return result;
      }
      return findNode(id, _.filter(_.flatMap(list, (item) => item.subCategories), (o)=> !!o));
    }
  });
})();
