(function() {
  'use strict';
  angular
    .module('eva2-angular')
    .component('evaJobSearchableItem', {
      bindings: {
        name: '@',
        label: '@',
        value: '<',
        link: '@',
        searchText: '@',
        tooltipName: '@'
      },

      template: require('./jobSearchableItem.html')
    });
})();
