import _ from 'lodash';
(function() {
  'use strict';

  function Controller($timeout, KeyboardCode, moveHighlightInDropdown, $element) {
    const vm = this;
    const highlightClass = 'eva-single-select-with-search__results-item--highlighted';
    const itemHeight = 32;
    let list;

    const filterItemsByText = (items, text='') => {
      return _.filter(items, (item)=>
        item.text && item.text.toLowerCase().indexOf(text.toLowerCase()) !== -1);
    };

    const keydownListener = (event) => {
      if (!$element.is(':visible')) {
        // if element is not visible, then it should not respond to keys
        return;
      }
      switch (event.keyCode) {
      case KeyboardCode.ARROW_UP:
      case KeyboardCode.ARROW_DOWN: {
        event.stopPropagation();
        event.preventDefault();
        moveHighlightInDropdown(event.keyCode === KeyboardCode.ARROW_UP ? -1 : 1,
          $element.find('.' + highlightClass),
          highlightClass,
          $element.find('.eva-single-select-with-search__results-item'),
          $element.find('.eva-single-select-with-search__results')[0],
          itemHeight
        );
        return;
      }
      case KeyboardCode.ENTER: {
        const currentHighlightedItem = $element.find('.' + highlightClass);
        const currentHighlightedIndex = $element.find('.eva-single-select-with-search__results-item')
          .index(currentHighlightedItem);
        $timeout(()=> {
          vm.itemSelected({item: vm.filteredItems[currentHighlightedIndex]});
        });
        return;
      }
      case KeyboardCode.TAB:
        $timeout(()=> {
          vm.close();
        });
        return;
      case KeyboardCode.ESC:
        $timeout(()=> {
          vm.close();
        });
        return;
      }
    };

    const mouseMoveListener = ()=> {
      const items = $element.find('.eva-single-select-with-search__results-item');
      const hoveredElement = $element.find('.eva-single-select-with-search__results-item:hover');
      const indexInFilteredList = items.index(hoveredElement);
      // remove current highlight
      $element.find('.' + highlightClass).removeClass(highlightClass);
      // add new highlight
      items.eq(indexInFilteredList)
        .addClass(highlightClass);
    };

    vm.$onInit = ()=> {
      vm.filteredItems = filterItemsByText(vm.items, vm.inputValue);
      $timeout(()=> {
        // auto focus on input
        $element.find('.eva-single-select-with-search__input').focus();

        // add key down listener
        document.addEventListener('keydown', (event)=> keydownListener(event));

        // and mouse move listener to the list
        list = $element.find('.eva-single-select-with-search__results')[0];
        list.addEventListener('mousemove', (event) => mouseMoveListener(event));
      });
    };

    vm.$onDestroy = ()=> {
      if (list) {
        list.removeEventListener('mousemove', mouseMoveListener);
      }
      document.removeEventListener('keydown', keydownListener);
    };

    vm.inputValueChanged = ()=> {
      vm.filteredItems = filterItemsByText(vm.items, vm.inputValue);
      $element.find('.' + highlightClass).removeClass(highlightClass);
    };
  }

  angular
    .module('eva2-angular')
    .component('evaSingleSelectWithSearch', {
      bindings: {
        items: '<',
        itemSelected: '&',
        close: '&'
      },
      template: require('./singleSelectWithSearch.html'),
      controller: Controller,
      controllerAs: 'vm'
    });
})();

