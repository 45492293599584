(function() {
  'use strict';

  function Controller() {
  }

  angular
    .module('eva2-angular')
    .component('evaJobStatusWithTooltip', {
      bindings: {
        actor: '<',
        classNames: '@',
        status: '@',
        lastHistoryTimestamp: '@',
      },
      template: require('./jobStatusWithTooltip.html'),
      controller: Controller,
      controllerAs: 'vm'
    });
})();
