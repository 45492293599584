
(function() {
  'use strict';

  function Controller(Notifier, tFilter, $timeout) {
    const vm = this;
    vm.clicked = ()=> {
      $timeout(() => {
        if (navigator.clipboard) {
          try {
            navigator.clipboard.writeText(vm.value).then(() => {
              Notifier.display(tFilter('ITEM.COPY_MESSAGE', { VALUE: vm.value }, 'messageformat'), {
                type: 'success',
                ttl: 1500
              });
            });
          } catch(err){
            Notifier.display(tFilter('ITEM.COPY_MESSAGE_FAILURE'), {type: 'failure', ttl: 1500});
          }

        }
      });
    };
  }

  angular
    .module('eva2-angular')
    .component('evaCopyIcon', {
      bindings: {
        value: '@'
      },
      controllerAs: 'vm',
      template: require('./copyIcon.html'),
      controller: Controller
    });
})();


