(function() {
  'use strict';
  angular.module('eva2-angular').service('getNodeFamily', function(findNodeByCategoryId) {
    return function(node, list) {
      const parents = [];
      addParent(node, list, parents);
      return [node, ...parents].reverse();
    };

    function addParent(node, list, parents) {
      if (!node || !node.parentCategoryId || !list) {
        return;
      }
      const parent = findNodeByCategoryId(node.parentCategoryId, list);
      if (parent) {
        parents.push(parent);
        addParent(parent, list, parents);
      }
    }
  });
})();
