"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.saveUserSetting = exports.getUserSetting = void 0;
var getUserSettingName = function (CurrentUser) {
    return "".concat(CurrentUser.username, "_setting");
};
var getUserSettingObj = function (CurrentUser) {
    var userSetting = localStorage.getItem(getUserSettingName(CurrentUser));
    return userSetting ? JSON.parse(userSetting) : {};
};
var getUserSetting = function (attr, CurrentUser) {
    var userSettingObj = getUserSettingObj(CurrentUser);
    return userSettingObj[attr];
};
exports.getUserSetting = getUserSetting;
var saveUserSetting = function (attr, value, CurrentUser) {
    var userSettingObj = getUserSettingObj(CurrentUser);
    userSettingObj[attr] = value;
    localStorage.setItem(getUserSettingName(CurrentUser), JSON.stringify(userSettingObj));
};
exports.saveUserSetting = saveUserSetting;
