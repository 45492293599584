import _ from 'lodash';
(function() {
  'use strict';

  function Controller($scope) {
    'ngInject';
    const vm = this;
    $scope.$watch('vm.data', ()=> {
      if (vm.data) {
        vm.isNewUser = _.every(vm.data, (item)=> item.count === 0);
      }
    });
  }

  angular
    .module('eva2-angular')
    .component('evaUserPreviousItemsCount', {
      bindings: {
        ready: '<',
        data: '<',
        isOpenInNewTab: '<'
      },
      template: require('./userPreviousItemsCount.html'),
      controller: Controller,
      controllerAs: 'vm'
    });
})();
