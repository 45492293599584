"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.routeMapping = void 0;
exports.routeMapping = {
    'change-password': 'changePassword',
    'team-general': 'teamGeneral',
    'team-members': 'teamMembers',
    'manual-moderation-settings': 'manualModerationSettings',
    'field-editing': 'fieldEditing',
    orchestration: 'orchestration',
    'api-integration': 'apiIntegration',
    'right-to-be-forgotten': 'deleteUserData',
    'data-storing-options': 'dataStoringOptions',
    'delete-all-content': 'deleteAllContent',
    security: 'security',
};
