/*!
 * Required Param:job, categories, types, duplicates, feedbackList, onSelectDecision,
 */

import _ from 'lodash';
import { highlightService } from '@utils/highlightService';

(function () {
  'use strict';

  function Controller($timeout, $element, $stateParams, $scope,
    getModerationNotesQueryFields, CurrentUser, EvaLogger, ApiService) {
    'ngInject';
    const GALLERY_SIZE_MEDIUM = { width: 270, height: 178 };
    const GALLERY_SIZE_LARGE = { width: 540, height: 360 };
    const vm = this;

    const fetchTrustedDomains = async () => {
      try {
        const data = await ApiService.getTrustedDomains(vm.domain);
        if (data) {
          vm.trustedDomains = data.map((item) => item.trustedDomain);
        } else{
          vm.trustedDomains = [];
        }
      } catch (error) {
        console.error('Error fetching trusted domains', error); 
        vm.trustedDomains = [];
      }
    };


    vm.$onInit = () => {
      const images = _.get(vm, 'job.ad.current.content.images');
      const videos = _.get(vm, 'job.ad.videosInfo.content');
      vm.domain = $stateParams.domain;
      fetchTrustedDomains();
      vm.isQueuePage = $stateParams.queueId ? true : false;
      vm.currentUserEmail = CurrentUser.email;
      vm.isUsingImageGalleryMedium = vm.template && vm.template.image_gallery_id === 1;
      vm.isUsingImageGalleryLarge = vm.template && vm.template.image_gallery_id === 2;

      const containsMedia = () => {
        return (images && images.length > 0) || (videos && videos.length > 0);
      };
      vm.containsMedia = containsMedia();
    };


    vm.focusSelf = function () {
      if (vm.focusJob) vm.focusJob();
    };


    $timeout(() => {
      // XXX: cannot achieve this by pure css ".media-gallery:empty", because there is empty space inside ".media-gallery"
      if ($element.find('.media-gallery > *').length === 0 && $element.find('eva-job-content-item > *').length === 0) {
        $element.find('.eva-job-content-image-container + hr').hide();
      }
    });

    /**
   *
   * @param selectedIds {Array} an array of objects [{id: 1}, {id: 2}]
   */
    vm.updateFeedback = (selectedFeedback) => vm.job.selectedFeedback = selectedFeedback.map(({ id }) => ({ id }));

    vm.updateSelectedImageIndex = (index) => {
      vm.updateSelectedJobImageIndex({ index: index });
    };

    vm.noticeTriggered = (id, value) => {
      const attr = `showBoundingBox.${id}`;
      if (value === true || value === false) {
        _.set(vm.job, attr, value);
      }
    };

    $scope.$watch('$ctrl.job.moderationNotes.notes', (newValue) => {
      $timeout(() => {
        const moderationNotesContainerElement = $element.find('#moderationNoteContainer');
        vm.isNotesScrollbarVisible = moderationNotesContainerElement.get(0).scrollHeight >
          moderationNotesContainerElement.get(0).clientHeight;
      });
      if (Array.isArray(newValue) && newValue.length > 0) {
        vm.setModerationNotesOpenStatus(true);
      }
    }, true);

    $scope.$watch('$ctrl.job.ad.current.content.title', (newValue) => {
      if (newValue) {
        highlightService({
          text: newValue,
          rules: vm.job?.ad?.current.processingResults || [],
          adFieldsToHighlight: '$title,$text'
        },
        EvaLogger, {
          domain: vm.domain,
          itemId: vm?.job?.ad?.remoteId.id,
          type: 'title'
        }).then((titlehighlightParts) => {
          vm.job.ad.current.content.titlehighlightParts = titlehighlightParts;
          $scope.$apply();
        });
      }
    }, true);

    $scope.$watch('$ctrl.job.ad.current.content.body', (newValue) => {
      if(newValue){
        highlightService({
          text: newValue,
          rules: vm.job?.ad?.current.processingResults || [],
          adFieldsToHighlight: '$body,$text'
        },
        EvaLogger, { domain: vm.domain, itemId: vm?.job?.ad?.remoteId.id, type: 'body' }).then((bodyhighlightParts) => {
          vm.job.ad.current.content.bodyhighlightParts = bodyhighlightParts;
          $scope.$apply();
        });
      }
    }, true);


    vm.setModerationNotesOpenStatus = (value) => {
      vm.setIsNotesExpanded({ value });
      if (value) {
        vm.mainImageContainerSize = vm.isUsingImageGalleryMedium ? GALLERY_SIZE_MEDIUM : GALLERY_SIZE_LARGE;
      } else {
        vm.mainImageContainerSize = null;
        vm.thumbnailContainerSize = null;
      }
    };

    vm.onNewNoteCreated = () => {
      vm.onNewModerationNoteCreated({ query: getModerationNotesQueryFields(vm.job.ad) });
    };

  }



  angular
    .module('eva2-angular')
    .component('evaJob', {
      bindings: {
        job: '<',
        categories: '<',
        availableQueues: '<',
        types: '<',
        feedbackList: '<',
        hideReviewBar: '<',
        disableReviewBar: '<',
        onSelectDecision: '&',
        focusJob: '&',
        template: '<',
        editPermissions: '<',
        preSelectedImageIndex: '<',
        updateSelectedJobImageIndex: '&',
        dictionaries: '<',
        showModerationNotesIndicator: '<',
        onNewModerationNoteCreated: '&',
        failedToLoadModerationNotes: '&',
        setIsNotesExpanded: '&',
        isNotesExpanded: '<',
        isTheNewestVersion: '<',
        index: '<'
      },

      template: require('./job.html'),
      controller: Controller
    });
})();
