'use strict';

angular.module('eva2-angular')
  .factory('LoadingTracker', function(promiseTracker) {
    return promiseTracker({
      activationDelay: 500,
      minDuration: 1000,
      maxDuration: null
    });
  });
