(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('emptyNgTable', emptyNgTable);

  emptyNgTable.$inject = [];

  function emptyNgTable() {
    return {
      restrict: 'E',
      transclude: {
        noData: 'noData',
        noResults: 'noResults',
        hasData: 'hasData',
        hasError: 'hasError',
        isLoading: '?isLoading'
      },
      replace: true,
      template: require('./emptyNgTable.html'),
      controller: Controller,
      controllerAs: 'vm',
      bindToController: {},
      scope: {
        tableParams: '=',
        reset: '=',
        hasQuery: '<'
      }
    };
  }

  Controller.$inject = [
    '$scope',
    'ngTableEventsChannel'
  ];

  function Controller($scope, ngTableEventsChannel) {
    const vm = this;

    vm.hasNoData = false;
    vm.hasNoResults = false;
    vm.hasError = false;

    vm.reload = function() {
      $scope.tableParams.reload();
    };

    const afterReloadDataEvent = function(params) {
      const first = !params.data;
      const total = params.settings().total;
      const page = params.page();

      const hasNoData = total === 0 && page === 1;
      const errorFetchingData = params.errorLoadingStats;

      vm.hasNoData = !errorFetchingData && !first && hasNoData && !vm.hasQuery;
      vm.hasNoResults = !first && hasNoData && vm.hasQuery;
      vm.hasError = errorFetchingData;
      vm.isLoading = params.isLoading;
    };

    const isMyPublisher = function(publisher) {
      return $scope.tableParams === publisher;
    };

    ngTableEventsChannel.onAfterReloadData(afterReloadDataEvent, $scope, isMyPublisher);
  }
})();
