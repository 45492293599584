(function() {
  'use strict';
  angular.module('eva2-angular').service('userSetting', function(CurrentUser, storage) {
    function getUserSettingName() {
      return CurrentUser.username + '_setting';
    }

    function getUserSettingObj() {
      const userSetting = storage.getItem(getUserSettingName());
      return (userSetting) ? JSON.parse(userSetting) : {};
    }

    function getUserSetting(attr) {
      const userSettingObj = getUserSettingObj();
      return userSettingObj[attr];
    }

    function saveUserSetting(attr, value) {
      const userSettingObj = getUserSettingObj();
      userSettingObj[attr] = value;
      storage.setItem(getUserSettingName(), JSON.stringify(userSettingObj));
    }

    return {getUserSetting, saveUserSetting};
  });
})();
