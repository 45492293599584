(function() {
  'use strict';

  angular.module('eva2-angular').constant('csvImporter', {
    MAPPER_TYPE: {
      MANDATORY: 0,
      OPTIONAL: 1
    },
    optionalAttributes: [
      //content
      {id: 'content:url', label: 'content:url'},
      {id: 'content:price:amount', label: 'content:price:amount'},
      {id: 'content:price:currency', label: 'content:price:currency'},
      {id: 'content:type:id', label: 'content:type:id'},
      {id: 'content:type:name', label: 'content:type:name'},
      {id: 'content:category:id', label: 'content:category:id'},
      {id: 'content:category:name', label: 'content:category:name'},
      {id: 'content:images', label: 'content:images'},
      {id: 'content:status', label: 'content:status'},
      {id: 'content:createdAt', label: 'content:createdAt'},
      {id: 'content:updatedAt', label: 'content:updatedAt'},
      {id: 'content:publishedAt', label: 'content:publishedAt'},
      {id: 'content:adminUrl', label: 'content:adminUrl'},
      {id: 'content:languageExpected', label: 'content:languageExpected'},
      //user
      {id: 'user:id', label: 'user:id'},
      {id: 'user:name', label: 'user:name'},
      {id: 'user:phoneNumbers', label: 'user:phoneNumbers'},
      {id: 'user:emailAddresses', label: 'user:emailAddresses'},
      {id: 'user:adminUrl', label: 'user:adminUrl'},
      //location
      {id: 'location:city', label: 'location:city'},
      {id: 'location:postalCode', label: 'location:postalCode'},
      {id: 'location:region', label: 'location:region'},
      {id: 'location:countryCode', label: 'location:countryCode'},
      {id: 'location:ipAddress', label: 'location:ipAddress'}
    ],
    mandatoryAttributes: [
      {id: 'id', label: 'id'},
      {id: 'content:title', label: 'content:title'},
      {id: 'content:body', label: 'content:body'}
    ],
    NUMBER_OF_COLUMN_DATA_TO_DISPLAY: 5
  });
})();

