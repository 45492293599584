// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../assets/youtube_icon.svg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../assets/cannot_play.svg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../assets/play_button.svg");
var ___HTML_LOADER_IMPORT_3___ = require("../../../assets/pause_button.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<div class=\"eva-video-item-wrapper\" data-ng-style=\"{'width':vm.containerSize.width, 'height': vm.containerSize.height}\" data-ng-class=\"{'eva-video-item-wrapper--selected': vm.selected,\n'eva-video-item-wrapper--deleted': vm.deleted,\n'eva-video-item-wrapper--waiting': vm.shouldDeferLoading}\" data-ng-click=\"vm.onSelect()\"> <img class=\"eva-video-item__youtube-image\" data-ng-show=\"vm.video.provider === 'youtube'\" data-ng-class=\"{'eva-video-item__youtube-image--small': vm.containerSize.width < 80}\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> <video width=\"100%\" height=\"100%\" class=\"video-sized-player\" data-key=\"{{vm.key}}\" video-src request-url=\"vm.shouldDeferLoading ? null: (vm.video.canonicalUrl + '#t=0.1')\"> </video> <div class=\"eva-video-lazy-loading-animation\"></div> <div class=\"eva-video-item__play-button\" data-ng-show=\"vm.video\"> <img class=\"eva-video-item__play-image\" data-ng-show=\"vm.video.status !== 'success'\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> <img class=\"eva-video-item__play-image\" data-ng-show=\"vm.video.status === 'success' && !vm.video.playing\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"> <img class=\"eva-video-item__pause-image\" data-ng-show=\"vm.video.status === 'success' && vm.video.playing\" src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\"> </div> </div> ";
// Exports
module.exports = code;