(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .factory('HttpErrorManager', service);

  service.$inject = [];

  function service() {
    /* When status = -1, it means a request is aborted
       (it could happen when route changes or when there's no internet connection)
       When status = 401, app should log out and only show a general message in log in view
    */
    const shouldDisplayErrorMessage = (status) => status > 0 && status !== 401;
    return {
      shouldDisplayErrorMessage: shouldDisplayErrorMessage
    };
  }
})();
