(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('MediaGalleryModalCtrl', MediaGalleryModal);

  function MediaGalleryModal($scope, $modalInstance, images, videos, selectedKey, isDisabled, videoCurrentTime,
    videoCurrentTimeYoutube, $timeout, showBoundingBox, KeyboardCode, $controller) {
    const MAX_SLIDES_TO_SHOW = 10;
    const vm = this;
    vm.images = images;
    vm.videos = videos;
    vm.videoCurrentTime = videoCurrentTime;
    vm.videoCurrentTimeYoutube = videoCurrentTimeYoutube;  
    $timeout(()=> {
      $controller('GalleryWithVideoCtrl', {$scope, vm, 
        mainVideoPlayer: $('.media-gallery-modal .main-video')[0],
        videoThumbnails: $('.media-gallery-modal .video-thumbnail-player')});
      vm.getPrev = () => {
        const prev = parseInt(vm.selectedKey) - 1;
        if (prev >= 0) {
          vm.selectGalleryItem(`${prev}`);
        }
    
        if (vm.showThumbnailsInCarousel) {
          vm.$slider.slick('slickGoTo', getSliderStateIndex(parseInt(vm.selectedKey)));
        }
      };
    
      vm.getNext = () => {
        const next = parseInt(vm.selectedKey) + 1;
        if (next <= vm.count - 1 ) {
          vm.selectGalleryItem(`${next}`);
        }
    
        if (vm.showThumbnailsInCarousel) {
          vm.$slider.slick('slickGoTo', getSliderStateIndex(parseInt(vm.selectedKey)));
        }
      };
      angular.element(document).on('keydown', arrowKeydownListener);
      setSizeToMainImage();
      $timeout(()=> {
        initializeSlick();
      });
    });
    
    vm.selectedKey = selectedKey;
    vm.isDisabled = isDisabled;
    vm.showBoundingBox = showBoundingBox;
    vm.count = (vm.images ? vm.images.length: 0) +  (vm.videos? vm.videos.length : 0);
    vm.numberOfSlideStates = vm.count - MAX_SLIDES_TO_SHOW + 1;
    vm.thumbnailContainerSize = {
      width: 84,
      height: 84
    };
    vm.thumbNailBoundingBoxStyle = {noLabel: true};

    vm.toggle = () => {
      vm.imageMap[vm.selectedKey].deleted = !vm.imageMap[vm.selectedKey].deleted;
    };

    vm.closeModal = () => {
      $modalInstance.close({selectedKey: vm.selectedKey,mainVideoPlayer: vm.mainVideoPlayer, 
        ytPlayer: vm.ytPlayer  });
    };

    $scope.$on('$destroy', function() {
      if (vm.showThumbnailsInCarousel) {
        vm.$slider.slick('unslick');
      }
      angular.element(document).off('keydown', arrowKeydownListener);
    });

    /**
     * @param index{Number}: the index of the slide that should be displayed in carousel
     * @returns {Number}: the index of the state to go to
     *
     * Example: If you have 15 slides, and you only show 10 slides at a time.
     * The carousel will have 6 states. State0: [0,1,..9]. State1: [1,2,...10], State6: [6,7..15].
     * If you want to show slide 0 (or 1,2 ...9), go to State0.
     * If you want to show slide 10, go to State1.
     * If you want to show slide 11, go to State2.
     * If you want to show slide 15, go to State6.
     */
    function getSliderStateIndex(index) {
      if (index < MAX_SLIDES_TO_SHOW) {
        return 0;
      }
      return index - MAX_SLIDES_TO_SHOW + 1;
    }

    function setSizeToMainImage() {
      const imageGalleryHeight = 150;
      const $mainImage = angular.element('.media-gallery-modal .media-item-container');
      const windowWidth = angular.element(window).width();
      const windowHeight = angular.element(window).height();
      vm.mainImageContainerSize = {
        height: windowHeight - imageGalleryHeight,
        width: windowWidth
      };
      $mainImage.css('height', vm.mainImageContainerSize.height + 'px');
      $mainImage.css('width', vm.mainImageContainerSize.width + 'px');
    }

    function initializeSlick() {
      vm.showThumbnailsInCarousel = vm.count > MAX_SLIDES_TO_SHOW;
      if (vm.showThumbnailsInCarousel) {
        vm.$slider = angular.element('#media-gallery-slider');
        vm.$slider.slick({
          infinite: false,
          speed: 300,
          slidesToShow: MAX_SLIDES_TO_SHOW,
          arrows: true,
          draggable: false,
          appendArrows: '.media-gallery-modal-container .media-gallery',
          prevArrow: '<i class="implioicons-chevron-left" id="btn-prev"></i>',
          nextArrow: '<i class="implioicons-chevron-right" id="btn-next"></i>'
        });
        vm.$slider.slick('slickGoTo', getSliderStateIndex(parseInt(vm.selectedKey)));
      }
    }

    function arrowKeydownListener(e) {
      switch (e.which) {
      case KeyboardCode.ARROW_LEFT: // left
        $timeout(function() {
          vm.getPrev();
        });
        break;

      case KeyboardCode.ARROW_RIGHT: // right
        $timeout(function() {
          vm.getNext();
        });
        break;
      case KeyboardCode.ESC: {
        $timeout(function() {
          vm.closeModal();
        });
        break;
      }
      case KeyboardCode.ENTER: {
        $timeout(function() {
          vm.onVideoThumbnailClicked(vm.selectedKey);
        });



        break;
      }
      case KeyboardCode.SPACE: {
        $timeout(function() {
          vm.onVideoThumbnailClicked(vm.selectedKey);
        });
        break;
      }

      default: return; // exit this handler for other keys
      }
      e.preventDefault(); // prevent the default action (scroll / move caret)
    }
  }
})();
