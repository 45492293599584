(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('evaApplicationError', hasApplicationError);

  hasApplicationError.$inject = [];

  function hasApplicationError() {
    return {
      restrict: 'E',
      replace: true,
      template: require('./applicationError.html'),
      controller: Controller,
      controllerAs: 'vm',
      bindToController: {}
    };
  }

  Controller.$inject = ['$state', '$stateParams', 'applicationError'];

  function Controller($state, $stateParams, applicationError) {
    const vm = this;

    vm.hasError = false;
    applicationError.onChange(function(hasError) {
      vm.hasError = hasError;
    });

    vm.reload = reload;

    function reload() {
      $state.transitionTo($state.current, $stateParams, {
        reload: true,
        inherit: false,
        notify: true
      });
    }
  }
})();
