(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('SearchCtrl', SearchCtrl);

  function SearchCtrl() {
    'ngInject';
  }
})();
