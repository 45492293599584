(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .factory('roundNumber', roundNumber);

  function roundNumber() {
    return function(number, decimalPlace) {
      const m = Math.pow(10, decimalPlace);
      const roundedResult = Math.round((number + Number.EPSILON)* m) / m;
      return roundNumber === 0 ? 0 : roundedResult;
    };
  }
})();
