'use strict';

import _ from 'lodash';
angular.module('eva2-angular').factory('Mapper', function(csvImporter, Enum, MapperManager) {
  'ngInject';
  const itemsRequired = [
    ['content:price:currency', 'content:price:amount'],
    ['content:category:id', 'content:category:name'],
    ['content:type:id', 'content:type:name']
  ];

  function reFormatCustomerSpecific(str) {
    const arr = str.split(':');
    if (arr.length === 1) return ('customerSpecific:' + str);
    else if (arr.length === 2) return (arr[0] + ':' + 'customerSpecific:' + arr[1]);
    else return '';
  }

  function getAttributeWrongStatus(attribute) {
    if (!validAttribute(attribute)) return;

    let isWrongType = false;
    if (attribute.isCustomValue) {
      const arr = attribute.label.split(':');
      if (arr.length > 2) isWrongType = true;
      else if (arr.length === 2 && (['user', 'content', 'location'].indexOf(arr[0]) === -1)) {
        isWrongType = true;
      } else {
        arr.forEach(function(item) {
          const re = /^[a-zA-Z]+[a-zA-Z0-9]*$/g;
          if (!item.match(re)) isWrongType = true;
        });
      }
    }

    return isWrongType;
  }

  function isCustomAttrEmpty(attribute) {
    if (!validAttribute(attribute)) return;

    if (attribute.label === '') return true;
    else return attribute.label.replace(/\s/g, '') === '';
  }

  function validAttribute(attribute) {
    if (attribute === undefined || attribute.label === undefined) return false;
    else return true;
  }

  function getAttributeDuplicateStatus(attribute, optionalMappers, id) {
    if (!validAttribute(attribute) || !attribute.isCustomValue) return;

    return optionalMappers
      .some((mapper) => mapper.id !== id && mapper.decidedAttribute && mapper.decidedAttribute.isCustomValue
        && mapper.decidedAttribute.label !== undefined
        && mapper.decidedAttribute.label.toLowerCase() === attribute.label.toLowerCase());
  }

  function getOtherAttributeRequired(attribute, optionalMappers) {
    if (!validAttribute(attribute) || attribute.isCustomValue) return;

    const id = attribute.id;
    const defaultMapper = optionalMappers
      .filter((mapper) => (!!mapper.decidedAttribute && !mapper.decidedAttribute.isCustomValue));

    let missAttr;

    itemsRequired.forEach(function(itemRequired) {
      const tempMissAttr = checkMissedAttr(id, defaultMapper, itemRequired);
      if (!!tempMissAttr && tempMissAttr.length > 0) missAttr = tempMissAttr;
    });
    return missAttr;
  }

  function checkMissedAttr(id, defaultMapper, itemRequired) {
    const missAttr = [];
    if (itemRequired.indexOf(id) > -1) {
      itemRequired.forEach((item) => {
        if (!isIdExistInMappers(item, defaultMapper)) missAttr.push(item);
      });
    }
    return missAttr;
  }

  function isIdExistInMappers(id, mappers) {
    return mappers.some((mapper) => mapper.decidedAttribute.id === id);
  }

  class Mapper {
    constructor(type, decidedAttribute, selectedColumn, attributeInputDefaultValue) {
      if (type === csvImporter.MAPPER_TYPE.OPTIONAL) {
        this.canChangeAttribute = true;
        this.canBeRemoved = true;
        this.attributeInputDefaultValue = attributeInputDefaultValue;
      }
      this.id = _.uniqueId('mapper_');
      this.type = type;
      this.decidedAttribute = decidedAttribute;
      this.selectedColumn = selectedColumn;
      if (this.decidedAttribute) {
        this.decidedAttribute.takenBy = this.id;
      }
      if (this.selectedColumn) {
        this.selectedColumn.takenBy = this.id;
      }
    }

    resetError() {
      this.error = {};
    }

    setError() {
      //set all the error for a mapper, including attr and column
      this.resetError();
      const attribute = this.decidedAttribute;
      const optionalMappers = MapperManager.optionalMappers;
      if (this.selectedColumn === undefined && this.decidedAttribute !== undefined) {
        this.error.column = Enum.CSV_MAP_ERROR.COLUMN_ATTRIBUTE_MISS;
      }
      if (this.selectedColumn !== undefined && this.decidedAttribute === undefined) {
        this.error.attr = Enum.CSV_MAP_ERROR.MAP_ATTRIBUTE_MISS;
        return;
      }
      if (isCustomAttrEmpty(attribute)) {
        this.error.attr = Enum.CSV_MAP_ERROR.EMPTY;
        return;
      }
      if (getAttributeWrongStatus(attribute)) {
        this.error.attr = Enum.CSV_MAP_ERROR.WRONG_FORMAT;
        return;
      }
      if (getAttributeDuplicateStatus(attribute, optionalMappers, this.id)) {
        this.error.attr = Enum.CSV_MAP_ERROR.DUPLICATE;
        return;
      }
      const missAttr = getOtherAttributeRequired(attribute, optionalMappers);
      if (!!missAttr && missAttr.length > 0) {
        this.error.attr = Enum.CSV_MAP_ERROR.REQUIRED;
        this.error.missingAttr = missAttr;
        return;
      }
    }

    updateAttributeByCustomValue(decidedAttribute) {
      decidedAttribute.id = reFormatCustomerSpecific(decidedAttribute.label);
      this.decidedAttribute = decidedAttribute;
    }

    updateAttributeBySelectedItem(decidedAttribute, optionalAttributes) {
      if (decidedAttribute.id === undefined) {
        this.decidedAttribute = undefined;
        return;
      }
      const selectedAttribute = _.find(optionalAttributes, (attribute) => attribute.id === decidedAttribute.id);
      selectedAttribute.takenBy = this.id;
      this.decidedAttribute = selectedAttribute;
    }

    updateColumn(columnId, allColumns) {
      const selectedColumn = _.find(allColumns, (column) => column.id === columnId);
      selectedColumn.takenBy = this.id;
      this.selectedColumn = selectedColumn;
    }

    releasePreviouslySelectedColumn() {
      // Remove the old association
      if (this.selectedColumn !== undefined) {
        this.selectedColumn.takenBy = undefined;
      }
    }

    releasePreviouslySelectedAttribute() {
      // Remove the old association
      if (this.decidedAttribute !== undefined) {
        this.decidedAttribute.takenBy = undefined;
      }
    }

    get isValid() {
      if (this.type === csvImporter.MAPPER_TYPE.MANDATORY) return this.selectedColumn !== undefined;
      else {
        if (!!this.error && (this.error.attr || this.error.column)) {
          return false;
        }
        return true;
      }
    }

    get isEmpty() {
      return this.selectedColumn === undefined && this.decidedAttribute === undefined;
    }
  }

  return Mapper;
})
;
