"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.refineCroppingAreaToImage = exports.getDataAfterRotation = exports.isCropped = exports.getCropperStyle = void 0;
var getCropperStyle = function (originalSize) {
    var containerSize = Math.min(500, Math.max(originalSize.width, originalSize.height));
    return { width: containerSize, height: containerSize };
};
exports.getCropperStyle = getCropperStyle;
var isCropped = function (cropping) {
    if (cropping !== null) {
        var x1 = cropping.x1, y1 = cropping.y1, x2 = cropping.x2, y2 = cropping.y2;
        return ![x1, y1, x2, y2].every(function (el) { return el === 0; });
    }
    false;
};
exports.isCropped = isCropped;
var getRotateWithinRange = function (angle) {
    if (angle >= 360) {
        return angle - 360;
    }
    if (angle <= -360) {
        return angle + 360;
    }
    return angle;
};
var getDataAfterRotation = function (rotateBy, currentData, imageSize) {
    var x = currentData.x;
    var y = currentData.y;
    var imageWidth = imageSize.width, imageHeight = imageSize.height;
    if ([90, -90, 270, -270].includes(currentData.rotate)) {
        imageWidth = imageSize.height;
        imageHeight = imageSize.width;
    }
    if (rotateBy === 90) {
        x = imageHeight - currentData.y - currentData.height;
        y = currentData.x;
    }
    if (rotateBy === -90) {
        x = currentData.y;
        y = imageWidth - currentData.x - currentData.width;
    }
    var rotation = rotateBy + currentData.rotate;
    var normalizedRotation = getRotateWithinRange(rotation);
    return {
        x: x,
        y: y,
        width: currentData.height,
        height: currentData.width,
        rotate: normalizedRotation,
    };
};
exports.getDataAfterRotation = getDataAfterRotation;
var getSizeAfterRotation = function (rotation, imageSize) {
    var width = imageSize.width, height = imageSize.height;
    if (Math.abs(rotation) === 90 || Math.abs(rotation) === 270) {
        return { width: height, height: width };
    }
    return { width: width, height: height };
};
var refineCroppingAreaToImage = function (cropping, rotation, imageSize) {
    var sizeAfterRotation = getSizeAfterRotation(rotation, imageSize);
    return {
        x1: Math.max(0, (cropping === null || cropping === void 0 ? void 0 : cropping.x1) || 0),
        y1: Math.max(0, (cropping === null || cropping === void 0 ? void 0 : cropping.y1) || 0),
        x2: Math.max(0, Math.min((cropping === null || cropping === void 0 ? void 0 : cropping.x2) || 0, sizeAfterRotation.width)),
        y2: Math.max(0, Math.min((cropping === null || cropping === void 0 ? void 0 : cropping.y2) || 0, sizeAfterRotation.height)),
    };
};
exports.refineCroppingAreaToImage = refineCroppingAreaToImage;
