
import _ from 'lodash';
(function() {
  'use strict';

  function Controller(getRoles, $stateParams) {
    'ngInject';
    const vm = this;
    vm.$onInit = () => {
      vm.maxInviteBox = 3;
      vm.inviteBoxes = [{}];
      vm.domain = $stateParams.domain;
  
      getRoles().then((roles)=> {
        vm.roles = roles;
        vm.isReadyToCreateNewInvite = true;
        vm.defaultRole = _.find(vm.roles, (role) => role.role === 'Admin');
      });
    };

    vm.addInviteBox = () => {
      if (vm.inviteBoxes.length < vm.maxInviteBox) {
        vm.inviteBoxes.push({});
      }
    };
  }

  angular
    .module('eva2-angular')
    .component('evaInviteUsers', {
      bindings: {
      },
      controllerAs: 'vm',
      template: require('./inviteUsers.html'),
      controller: Controller
    });
})();

