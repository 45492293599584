import _ from 'lodash';
import { dataStoringApi } from '@common/services/dataStoring';
import { maxFromDate, minDateFromPeriod } from '@features/ItemLog/util/fromDateUtil';

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('AdminFiltersCtrl', AdminFiltersCtrl);


  function AdminFiltersCtrl($scope, $stateParams, $controller, $ngRedux, 
    ApiFilters, Notifier, tFilter, $modal,$timeout, $filter, userSetting) {
    $controller('FilterBaseCtrl', {$scope: $scope});

    const vm = this;

    vm.domain = $stateParams.domain;
    vm.query = {};
    vm.searchFields = ['name', 'description'];
    vm.entity = 'decisionRules';
    const decisionRulesSortingLocalStorage = userSetting.getUserSetting(`${vm.entity}Sorting`);
    const currentSorting = decisionRulesSortingLocalStorage?.[vm.domain];
    // currentSorting?.enabled is the old local storage value (we were using enabled to sort not enabledSort)
    vm.defaultSorting = (currentSorting && !currentSorting?.enabled)? currentSorting : {enabledSort: 'asc'};

    let removeLocal;

    vm.$onInit = () => {
      loadFilters();
    };

    if (vm.domain) {
      $ngRedux.dispatch(
        dataStoringApi.endpoints.getDataStoringOption.initiate(vm.domain)
      ).then((response) => {
        vm.minDate = minDateFromPeriod(response.data.period).getTime();
      });
    }

    vm.updateStatus = function(event, filter) {
      $timeout(() => {
        $scope.updateStatus(event, filter);
      });
    };

    vm.updateFilter = function(event, filter) {
      $timeout(() => {
        $scope.updateFilter(event, filter);
      });
    };

    vm.reloadFilters = vm.$reloadViewController = () => {
      loadFilters();
    };

    vm.getRuleActionColumnText = function(filter) {
      const action =tFilter(`AUTOMATION.DECISION_RULES.RULE_ACTION.${filter.result}`);
      if(filter.result != 'REFUSE') {
        return action;
      }
      return `${action} (${filter.decisionName})`;

    };


    /*
     * Add attr to sort, which is not case sensitive
     */
    function dataParse(item) {
      item.nameSort = (!!item && !!item.name) ? item.name.toLowerCase() : '';
      item.lastModifiedBySort = (!!item && !!item.lastModifiedBy) ? item.lastModifiedBy.toLowerCase() : '';
      // default sort order is asc we want to have enabled on top
      item.enabledSort = item.enabled ? 1 : 2;
      // default sort order is asc we want to have recent date on the top top
      item.createdTimeSort= - new Date(item.createdTime).getTime();
      // default sort order is asc we want to have recent date on the top top
      item.lastModifiedTimeSort= - new Date(item.lastModifiedTime).getTime(); 
      return item;
    }

    function loadFilters() {
      const reqOptions = {
        domain: vm.domain
      };
      // localSearchList is accepting the Promise vm.filters
      vm.filters = ApiFilters
        .index(reqOptions)
        .then(function(results) {
          const rawData = results;
          const data = (!!rawData && Array.isArray(rawData)) ? rawData.map(dataParse) : [];
          const finalData=data.map(item=>({
            ...item,
            from: maxFromDate(item.lastModifiedTime, vm.minDate)
          }));
          removeLocal = function(filter) {
            _.remove(finalData, filter);
          };
          return finalData;
        });
    }

    vm.removeFilter = function(filter) {
      if (!removeLocal) {
        return;
      }

      const reqOptions = {
        domain: vm.domain,
        id: filter.id
      };

      ApiFilters.destroy(reqOptions, filter).then(function() {
        removeLocal(filter);
        vm.tableParams.reload();
        Notifier.display(tFilter('AUTOMATION.DECISION_RULES.DELETE_POPOVER.SUCCESS_TEXT', {filterName: filter.name}, 'messageformat')
          , {type: 'success', ttl: 1400});
      }).catch(function(result) {
        const msg = tFilter('AUTOMATION.DECISION_RULES.DELETE_POPOVER.FAIL_TEXT');
        Notifier.display(msg, {type: 'error', ttl: 12000}, result);
      });
    };

    vm.openDeleteModal = (filter) => {
      $modal.open({
        template: require('@partial/modals/deleteItem/deleteItem.html'),
        controller: 'DeleteItemCtrl',
        controllerAs: 'vm',
        windowClass: 'no-fade',
        backdrop: 'static',
        resolve: {
          config: () => ({
            title: tFilter('AUTOMATION.DECISION_RULES.DELETE_POPOVER.TITLE'),
            text: tFilter('AUTOMATION.DECISION_RULES.DELETE_POPOVER.TEXT', {filterName: filter.name}, 'messageformat'),
            onConfirm: ()=> vm.removeFilter(filter)
          })
        }
      });
    };
  }
})();
