import _ from 'lodash';
(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('AdminDictionariesCtrl', AdminDictionariesCtrl);


  function AdminDictionariesCtrl(ApiDictionaries, $stateParams, Notifier, tFilter, $modal, userSetting) {
    const vm = this;

    vm.domain = $stateParams.domain;
    vm.query = {};
    vm.searchFields = ['name', 'description'];

    vm.entity = 'lists';
    const listsSortingLocalStorage = userSetting.getUserSetting(`${vm.entity}Sorting`);
    vm.defaultSorting = listsSortingLocalStorage?.[vm.domain] || {nameSort: 'asc'};

    const reqOptions = {
      domain: vm.domain
    };

    let removeLocal;

    vm.reloadDictionaries = vm.$reloadViewController = () => {
      getDictionaries();
    };

    vm.openDeleteModal = (list) => {
      $modal.open({
        template: require('@partial/modals/deleteItem/deleteItem.html'),
        controller: 'DeleteItemCtrl',
        controllerAs: 'vm',
        windowClass: 'no-fade',
        backdrop: 'static',
        resolve: {
          config: () => ({
            title: tFilter('AUTOMATION.LISTS.DELETE_POPOVER.TITLE'),
            text: tFilter('AUTOMATION.LISTS.DELETE_POPOVER.TEXT', {listName: list.name}, 'messageformat'),
            onConfirm: ()=> vm.removeDictionary(list)
          })
        }
      });
    };

    /*
     * Add attr to sort, which is not case sensitive
     */
    function dataParse(item) {
      item.nameSort = (!!item && !!item.name) ? item.name.toLowerCase() : '';
      item.lastModifiedBySort = (!!item && !!item.lastModifiedBy) ? item.lastModifiedBy.toLowerCase() : '';
      // default sort order is asc we want to have recent date on the top top
      item.createdTimeSort= - new Date(item.createdTime).getTime();
      // default sort order is asc we want to have recent date on the top top
      item.lastModifiedTimeSort= - new Date(item.lastModifiedTime).getTime(); 
      return item;
    }

    function getDictionaries() {
      vm.dictionaries = ApiDictionaries
        .get(vm.domain)
        .then(function(results) {
          const rawData = results;
          const data = (!!rawData && Array.isArray(rawData)) ? rawData.map(dataParse) : [];

          removeLocal = function(filter) {
            _.remove(data, filter);
          };
          return data;
        });
    }

    // Load dictionaries
    getDictionaries();

    vm.removeDictionary = function(dictionary) {
      if (!removeLocal) {
        return;
      }

      ApiDictionaries.delete(reqOptions, dictionary).then(function() {
        removeLocal(dictionary);
        vm.tableParams.reload();
        Notifier.display(tFilter('AUTOMATION.LISTS.DELETE_POPOVER.SUCCESS_TEXT', {dictionaryName: dictionary.name}, 'messageformat'), {type: 'success', ttl: 1400});
      }).catch(function(result) {
        if (result.status === 401) {
          return;
        }
        let msg = tFilter('AUTOMATION.LISTS.DELETE_POPOVER.NOT_FOUND_TEXT');
        if (result) {
          let filterNamesCommaSeparated = '';
          const data = result.data;
          if (data && data.length > 0) {
            const filterNames = data.map(function(filter) {
              return '\'' + filter.name + '\'';
            });
            filterNamesCommaSeparated = filterNames.join(', ');
          }
          msg = tFilter('AUTOMATION.LISTS.DELETE_POPOVER.FAIL_TEXT', {dictionaryName: dictionary.name, filterNamesCommaSeparated: filterNamesCommaSeparated}, 'messageformat');
        }
        Notifier.display(msg, {
          type: 'error',
          ttl: 60000
        }, result);
      });
    };
  }
})();
