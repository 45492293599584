"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmModal = void 0;
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var _store_1 = require("@store");
var deleteContentSlice_1 = require("@features/Settings/state/deleteContentSlice");
var react_redux_1 = require("react-redux");
var Button_1 = __importStar(require("@components/Button"));
function ConfirmModal(_a) {
    var domain = _a.domain, userId = _a.userId, setIsModalOpen = _a.setIsModalOpen, submitForm = _a.submitForm;
    var t = (0, react_i18next_1.useTranslation)(['settings']).t;
    var dispatch = (0, _store_1.useAppDispatch)();
    var documentCount = (0, react_redux_1.useSelector)(deleteContentSlice_1.deleteUserContentSelector).documentCount;
    (0, react_1.useEffect)(function () {
        dispatch((0, deleteContentSlice_1.countUserDataToDelete)(domain, userId));
    }, [userId]);
    return (react_1.default.createElement("div", { className: "delete-user-modal" },
        react_1.default.createElement("h4", { className: "title" }, "".concat(t('settings:RIGHT_TO_BE_FORGOTTEN.DELETE_USER_DATA_MODAL.TITLE'), " ").concat(userId)),
        documentCount > 0 && (react_1.default.createElement("div", { className: "has-data-to-delete" },
            react_1.default.createElement("p", { dangerouslySetInnerHTML: {
                    __html: t('settings:RIGHT_TO_BE_FORGOTTEN.DELETE_USER_DATA_MODAL.TEXT1', {
                        count: documentCount,
                        USER_ID: userId,
                    }) || '',
                } }),
            react_1.default.createElement("div", { className: "gdpr__button-group" },
                react_1.default.createElement(Button_1.SecondaryButton, { text: t('settings:RIGHT_TO_BE_FORGOTTEN.DELETE_USER_DATA_MODAL.CANCEL_BUTTON_TEXT'), onClick: function () { return setIsModalOpen(false); } }),
                react_1.default.createElement(Button_1.default, { text: t('settings:RIGHT_TO_BE_FORGOTTEN.DELETE_USER_DATA_MODAL.CONFIRM_BUTTON_TEXT'), onClick: submitForm })))),
        documentCount <= 0 && (react_1.default.createElement("div", { className: "no-data-to-delete" },
            react_1.default.createElement("p", null, t('settings:RIGHT_TO_BE_FORGOTTEN.DELETE_USER_DATA_MODAL.USER_HAS_NO_DATA_TEXT', { USER_ID: userId })),
            react_1.default.createElement("div", { className: "gdpr__button-group" },
                react_1.default.createElement(Button_1.default, { text: t('settings:RIGHT_TO_BE_FORGOTTEN.DELETE_USER_DATA_MODAL.OK_BUTTON_TEXT'), onClick: function () { return setIsModalOpen(false); } }))))));
}
exports.ConfirmModal = ConfirmModal;
