import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').service('getFittedImageScale', function() {
    return function(containerSize, imageSize) {
      if (!containerSize || !imageSize) {
        return;
      }
      return _.min([1, containerSize.width / imageSize.width, containerSize.height / imageSize.height]);
    };
  });
})();
