(function() {
  'use strict';

  function Controller($element, $scope, $rootScope, $timeout) {
    'ngInject';
    const vm = this;


    $rootScope.$on('escKeyPressed', function() {
      $timeout(()=> {
        vm.searchText = '';
      });
    });

    function mouseMoveListener() {
      $element.find('.eva-dropdown-container li').removeClass('focused');
      angular.element(this).addClass('focused');
    }

    vm.$onInit = () => {
      vm.dropdownCssClass = 'drop' + (vm.dropDirection || 'up');
      vm.filtered = vm.data;

      $timeout(()=> {
        $element.find('.eva-dropdown-container li').on('mousemove', mouseMoveListener);
      });
    };

    vm.$onDestroy = () => {
      $element.find('.eva-dropdown-container li').off('mousemove', mouseMoveListener);
    };

    $scope.$watch('$ctrl.data', ()=> {
      vm.filtered = vm.data;
    }, true);

    $scope.$watch('$ctrl.searchText', function(newVal) {
      if (newVal) {
        vm.filtered = vm.data.filter((item)=> {
          return item.name.toUpperCase().indexOf(newVal.toUpperCase()) !== -1;
        });
      } else {
        vm.filtered = vm.data;
      }
    });

    vm.selectItem = (item)=> {
      $element.find('.eva-drop-down-with-search').removeClass('open');
      vm.select({item});
    };

    $scope.$watch('$ctrl.isDisabled', () => {
      vm.disabledClass = vm.isDisabled ? 'is-disabled' : '';
    });
  }

  angular
    .module('eva2-angular')
    .component('evaDropDownWithSearch', {
      bindings: {
        datacyName: '@',
        dropDownTitle: '@',
        componentClass: '<',
        statusClass: '<',
        select: '&',
        data: '<',
        buttonText: '@',
        iconText: '<',
        isDisabled: '<',
        dropDirection: '@'
      },
      template: require('./dropDownWithSearch.html'),
      controller: Controller
    });
})();
