(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .filter('hideBesedoEmails', hideBesedoEmails);

  /**
   * This function takes an array and filters out emails that ends with @besedo.com and @iosquare.com
   * @returns {Function}
   */

  function hideBesedoEmails() {
    'ngInject';
    return function(teamMembersList, currentUserEmail) {
      if (!Array.isArray(teamMembersList) || !currentUserEmail) {
        return teamMembersList;
      }

      if (isBesedoAccount(currentUserEmail)) {
        // for Besedo users, show all the team members as it is
        return teamMembersList;
      }

      const filtered = teamMembersList.filter((account)=> {
        // for non-Besedo users, only show team members that are non-Besedo
        if (!account.email) {
          return true;
        }
        return !isBesedoAccount(account.email);
      });

      return filtered;
    };

    function isBesedoAccount(email) {
      return (email.indexOf('@besedo.com') !== -1) || (email.indexOf('@iosquare.com') !== -1);
    }
  }
})();

