(function() {
  'use strict';
  angular.module('eva2-angular').service('groupDecisionData', function() {

    return function (report) {
      const decisionKeys = ['accepted', 'rejected', 'noDecision'];
      const ALL = 'all';
      const result = {};
      [...decisionKeys, ALL].forEach((key)=> {
        result[key]= {
          auto: 0,
          manual: 0
        };
      });
      report.forEach((dayReport)=> {
        decisionKeys.forEach((key)=> {
          const auto = dayReport[key+'Auto'];
          const manual = dayReport[key+'Manual'];
          result[key].auto += auto;
          result[key].manual += manual;
          result[ALL].auto += auto;
          result[ALL].manual += manual;
        });
      });
      return result;
    };

  });
})();
