"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useImageStyles = void 0;
var react_1 = require("react");
var isImageMetadataSuccess_1 = require("../utils/isImageMetadataSuccess");
var getImageStyles_1 = require("../utils/getImageStyles");
var useImageStyles = function (containerSize, selectedImageId, imageMetadata) {
    var _a = __read((0, react_1.useState)({
        transform: '',
        clipPath: '',
    }), 2), imageTransformationsStyle = _a[0], setImageTransformationsStyle = _a[1];
    var _b = __read((0, react_1.useState)(), 2), boundingBoxContainerStyle = _b[0], setContainerStyle = _b[1];
    var setStyles = function () {
        if (imageMetadata && (0, isImageMetadataSuccess_1.isImageMetadataSuccess)(imageMetadata)) {
            var transformations = imageMetadata.transformations, originalSize = imageMetadata.originalSize;
            var imageTransformationsStyle_1 = (0, getImageStyles_1.getImageTransformationsStyle)(transformations, containerSize, originalSize);
            var boundingBoxContainerStyle_1 = (0, getImageStyles_1.getBoundingBoxContainerStyle)(transformations, containerSize, originalSize);
            setImageTransformationsStyle(imageTransformationsStyle_1);
            setContainerStyle(boundingBoxContainerStyle_1);
        }
    };
    (0, react_1.useEffect)(function () {
        setStyles();
    }, [selectedImageId, imageMetadata, containerSize]);
    (0, react_1.useEffect)(function () {
        setStyles();
    }, []);
    return [imageTransformationsStyle, boundingBoxContainerStyle || null];
};
exports.useImageStyles = useImageStyles;
