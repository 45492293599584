import _ from 'lodash';
import { createRoot } from 'react-dom/client';
import { default as React } from 'react';

import { Provider } from 'react-redux';
import store from '@store';
import NotePanel from '@features/ItemDetail/components/ModerationNote/index';

(function() {
  'use strict';

  function Controller($element, $scope, $timeout, ApiService, Notifier, tFilter, $stateParams, CurrentUser, $state) {
    let vm = this;
    let root;

    vm.$onInit = () => {
      renderNoteList();
    };

    $scope.$watch('vm.data.notes', () => {
      renderNoteList();
    });


    const renderNoteList = () => {
      $timeout(() => {
        try {
          if (!root) {
            root = createRoot(document.querySelector(`#moderation-note-list-${vm.documentId}`));
          }
          root.render(
            <Provider store={store}>
              <NotePanel
                data={vm.data}
                documentId={vm.documentId}
                domain={$stateParams.domain}
                onNewNoteCreated={vm.onNewNoteCreated}
                removeNoteFromList={(noteId) => {
                  _.remove(vm.data.notes, { id: noteId });
                  renderNoteList();
                }}
                reloadNotes={vm.reloadNotes}
                angularJsDependencies={{
                  CurrentUser,
                  href: $state.href
                }}
              />
            </Provider>
          );
        } catch (ex) {// During the go back process with have a dom render issue
          console.log(ex);
        }

      });
    };

    vm.reloadNotes = () => {
      vm.failedToLoadModerationNotes();
    };
  }

  angular
    .module('eva2-angular')
    .component('evaModerationNotes', {
      bindings: {
        show: '<',
        domain: '<',
        documentId: '@',
        onNewNoteCreated: '&',
        failedToLoadModerationNotes: '&',
        data: '<',
        currentUserEmail: '@'
      },

      template: require('./moderationNotes.html'),
      controller: Controller,
      controllerAs: 'vm'
    });
})();

