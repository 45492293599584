import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').service('getModerationNotesForJob', function(ApiService, getModerationNotesQueryFields) {
    return function(domain, job) {
      job.moderationNotes = {...job.moderationNotes,
        failedToLoad: false,
        loading: true
      };
      const query = getModerationNotesQueryFields(job.ad);
      return ApiService.getModerationNotes(domain, query)
        .then((data)=> {
          const notes = _.map(data.notes, (note)=> {
            const ad = job.ad;
            note.matchingFields = {
              id: note.remoteId === _.get(ad, 'remoteId.id'),
              user: note.userId === _.get(ad, 'current.user.id'),
              ip: note.ipAddress === _.get(ad, 'current.user.ipAddress'),
              email: _.intersection(note.userEmails, _.get(ad, 'current.user.emailAddresses')).length > 0,
              phone: _.intersection(note.userPhoneNumbers, _.get(ad, 'current.user.phoneNumbers')).length > 0
            };
            return note;
          });
          job.moderationNotes = {...job.moderationNotes, notes, failedToLoad: false};
        }, ()=> {
          job.moderationNotes = {...job.moderationNotes, notes: null, failedToLoad: true};
        }).finally(()=> {
          job.moderationNotes.loading = false;
        }); 
    };
  });
})();
