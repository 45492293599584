/*evaTextarea: evaTextarea with both view mode and edit mode*/
import SecurityWarningModal from '@/react-app/common/components/SecurityWarningModal';
import { openLinkInNewTab, shouldOpenSecurityWarningModal } from '@/react-app/common/utils/linkUtils';
import store from '@store';
import { default as React } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

(function() {
  'use strict';

  function Controller($element, $timeout, tFilter, $scope) {
    const autosize = require('autosize');
    'ngInject';
    const vm = this;

    this.$onInit = function() {
      //default value
      this.placeholderText = this.placeholderText || tFilter('ITEM.BODY.PLACEHOLDER');
      const mode = 'VIEW';
      this.mode = this.mode || mode;
    };

    $timeout(()=> {
      $element.find('.linkified').on('click', function(e) {
        // clicking on the links shouldn't trigger edit mode
        e.stopPropagation();
      });
    });

    //links
    this.onClick = function() {
      const height = $element.find('.plain-text').height();
      if (!this.disabledMe && this.mode === 'VIEW') {
        this.mode = 'EDIT';
        this.confirmedValue = this.value;
        this.confirmedHighlightList = this.highlightList;

        $timeout(()=> {
          $element.find('textarea').height(height);
          autosize($element.find('textarea'));
          $element.find('textarea').focus();
        });

      }
    };

    this.onKeyDown = function(event){
      if (event.keyCode === 13 && event.shiftKey) {
        event.preventDefault();
        this.onBlur();
      }
    };

    this.onTextAreaGotFocus = ()=> {
      this.textAreaHasFocus = true;
    };

    this.onBlur = function() {
      this.textAreaHasFocus = false;
      this.mode = 'VIEW';
    };

    this.resetToOriginalValue = () => {
      this.value = this.confirmedValue;
      this.highlightList = this.confirmedHighlightList;
      this.onBlur();
    };

    this.$onDestroy = () => {
      $element.find('.linkified').off('click');
    };

    this.handleAnchorLinkClick =(link)=>{
      const shouldOpenModal= shouldOpenSecurityWarningModal(link, vm.trustedDomains);
      if(shouldOpenModal){
        vm.link=link;
        setIsModalOpen(true);
      } else{
        openLinkInNewTab(link);
      }
    };

    const setIsModalOpen = (isOpen) => {
      vm.isModalOpen = isOpen;
    };

    $scope.$watchGroup(['$ctrl.isModalOpen', '$ctrl.link'], ()=> {
      renderModal();
    });

    const renderModal = () => {
      $timeout(() => {
        const root = createRoot(document.querySelector('#modal-body'));

        root.render(<Provider store={store}>
          <SecurityWarningModal
            isOpen={vm.isModalOpen}
            setIsOpen={setIsModalOpen}
            link={vm.link}
          />
        </Provider>        
        );
      });
    };

  }

  angular
    .module('eva2-angular')
    .component('evaJobBody', {
      bindings: {
        mode: '@', //the value should be "VIEW" or "EDIT"
        highlightList: '<',
        placeholderText: '@',
        value: '=',
        disabledMe: '<',
        rules: '<',
        trustedDomains: '<'
      },
      template: require('./jobBody.html'),
      controller: Controller
    });
})();
