(function() {
  'use strict';

  function Controller($state, MapperManager, tFilter) {
    'ngInject';
    const NUMBER_OF_STEPS = 3;
    const IMPORT_FILE_INDEX = 2;
    const MAPPING_INDEX = 1;
    const vm = this;
    vm.$state = $state;
    vm.getCurrentStepIndex = () => {
      // The last character of the state name (eg.app.csvImporter.step0) is the step index
      const currentStateName = vm.$state.current.name;
      const index = parseInt(currentStateName.substr(currentStateName.length - 1));
      return index;
    };
    vm.isStepFinished = (index) => index < vm.getCurrentStepIndex();
    vm.isCurrentStep = (index) => index === vm.getCurrentStepIndex();
    vm.goPrev = () => {
      const prevStepIndex = vm.getCurrentStepIndex() - 1 >= 0 ? vm.getCurrentStepIndex() - 1 : 0;
      $state.go(`app.assignment.csvImporter.step${prevStepIndex}`);
      angular.element('body').scrollTop(0);
    };

    vm.isInColumnMappingStep = ()=> vm.getCurrentStepIndex() === 1;

    vm.isGoNextButtonEnabled = () => !vm.isInColumnMappingStep()
      || (vm.isInColumnMappingStep()
        && MapperManager.isMandatoryMappingValid());

    vm.isNextStepEnabled = () => !vm.isInColumnMappingStep()
      || (vm.isInColumnMappingStep()
        && MapperManager.isAllMappingValid());

    vm.goNext = () => {
      if (!vm.isGoNextButtonEnabled()) {
        return;
      }
      vm.isEnabledNextButtonClicked();
      if (!vm.isNextStepEnabled()) {
        return;
      }
      const lastIndex = NUMBER_OF_STEPS - 1;
      const currentIndex = vm.getCurrentStepIndex();

      switch (currentIndex) {
      case IMPORT_FILE_INDEX:
        vm.importCsv();
        break;
      case MAPPING_INDEX:
        vm.mapping();
        break;
      default:
        if (currentIndex < lastIndex) $state.go(`app.assignment.csvImporter.step${currentIndex + 1}`);
      }

      angular.element('body').scrollTop(0);
    };

    vm.res = {
      title: tFilter('SETTING.CSV_IMPORTER.TITLE'),
      steps: [
        {
          label: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.HEADER'),
        },
        {
          label: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.HEADER'),
          buttonText: {
            prev: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.BACK_BTN'),
            next: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.NEXT_BTN')
          }
        },
        {
          label: tFilter('SETTING.CSV_IMPORTER.PREVIEW_DATA.HEADER'),
          buttonText: {
            prev: tFilter('SETTING.CSV_IMPORTER.PREVIEW_DATA.BACK_BTN'),
            next: tFilter('SETTING.CSV_IMPORTER.PREVIEW_DATA.NEXT_BTN')
          }
        },
        {
          label: tFilter('SETTING.CSV_IMPORTER.IMPORT_DATA.HEADER'),
          buttonText: {
            prev: tFilter('SETTING.CSV_IMPORTER.PREVIEW_DATA.BACK_BTN'),
            next: tFilter('SETTING.CSV_IMPORTER.PREVIEW_DATA.NEXT_BTN')
          }
        }
      ]
    };
  }

  angular
    .module('eva2-angular')
    .component('csvImporterNavigation', {
      template: require('./navigation.html'),
      controller: Controller,
      bindings: {
        importCsv: '&',
        mapping: '&',
        isEnabledNextButtonClicked: '&'
      }
    });
})();
