(function() {
  'use strict';

  function Controller(processedBy, tFilter) {
    'ngInject';
    const vm = this;
    vm.$onInit = () => {
      vm.processedBy = processedBy;
      vm.res = {
        linkText: tFilter('MODERATION.QUEUE_VIEW.JOB_VERSION.BACK_LINK')
      };
    };
  }

  angular
    .module('eva2-angular')
    .component('jobVersionInfo', {
      template: require('./jobVersionInfo.html'),
      controller: Controller,
      bindings: {
        historyItem: '<',
        hideLink: '<',
        onLinkClicked: '&'
      }
    });
})();
