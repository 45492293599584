(function() {
  'use strict';

  angular.module('eva2-angular').service('csvMap', function() {
    /**
     * Process data so that they will be accepted by external API
     * @param mapKey {string}
     * @param value {*}
     * @returns {*}
     */
    function parseValueForExternalApi(mapKey, value) {
      if (mapKey === 'content:price:amount') {
        return parseFloat(value);
      }

      if (mapKey === 'content:images') {
        if (typeof value !== 'string') {
          return [];
        }
        return value.split(',').map((item)=> ({src: item.trim()}));
      }

      if (mapKey === 'user:phoneNumbers' || mapKey === 'user:emailAddresses') {
        if (typeof value !== 'string') {
          return [];
        }
        return value.split(',').map((item) => item.trim());
      }
      return value;
    }

    /**
     *
     * @param item {object} example: {"user_defined_csv_column_name": "Mint condition macbook pro 2009"}
     * @param map {object} example: {content:type:name: "user_defined_csv_column_name"}
     * @returns {object}
     */
    function assignItem(item, map) {
      const tempObj = {};
      for (const implioKey in map) { 
        const userDefinedKey = map[implioKey];
        const value = parseValueForExternalApi(implioKey, item[userDefinedKey]);

        const mapArr = implioKey.split(':');
        if (mapArr) {
          const mapLength = mapArr.length;
          if (mapLength > 0) {
            let key0;
            let key1;
            let key2;

            switch (mapLength) {
            case 1:
              key0 = mapArr[0];
              tempObj[key0] = value;
              break;
            case 2:
              key0 = mapArr[0];
              key1 = mapArr[1];
              if (tempObj[key0] === undefined) tempObj[key0] = {};
              tempObj[key0][key1] = value;
              break;
            case 3:
              key0 = mapArr[0];
              key1 = mapArr[1];
              key2 = mapArr[2];
              if (tempObj[key0] === undefined) tempObj[key0] = {};
              if (tempObj[key0][key1] === undefined) tempObj[key0][key1] = {};
              tempObj[key0][key1][key2] = value;
              break;
            }
          }
        }
      }
      return tempObj;
    }

    function reformateData(map, data) {
      return data.map(function(item) {
        return assignItem(item, map);
      });
    }

    /**
     * This function returns an array of objects [{implioAttributeKey, value}] based on mapping.
     * @param map {object} example: {content:type:name: "user_defined_csv_column_name"}
     * @param data {Array} example: [{"user_defined_csv_column_name": "Mint condition macbook pro 2009"}]
     * @returns {Array} example: [{"content:type:name": "Mint condition macbook pro 2009"}]
     */
    function previewData(map, data) {
      return data.slice(0, 10).map(function(item) {
        const newItem = {};
        for (const implioAttrKey in map) { 
          const userDefinedAttrKey = map[implioAttrKey];
          newItem[implioAttrKey] = parseValuePreview(implioAttrKey, item[userDefinedAttrKey]);
        }
        return newItem;
      });

      function parseValuePreview(mapKey, value) {
        switch (mapKey) {
        case 'content:price:amount':
          return parseFloat(value);
        default:
          return value;
        }
      }
    }

    function hasPropertyError(arr, map) {
      const hasArr = [];
      const missArr = [];
      arr.forEach(function(item) {
        if (map[item]) hasArr.push(item);
        else missArr.push(item);
      });
      if (!!hasArr && hasArr.length > 0 && !!missArr && missArr.length > 0) return {has: hasArr, miss: missArr};
      else return false;
    }

    function validate(map) {
      const error = [];

      const priceError = hasPropertyError(['content:price:currency', 'content:price:amount'], map);
      if (priceError) error.push(priceError);

      const categoryError = hasPropertyError(['content:category:id', 'content:category:id'], map);
      if (categoryError) error.push(categoryError);

      const typeError = hasPropertyError(['content:type:id', 'content:type:name'], map);
      if (typeError) error.push(typeError);

      if (!!error && error.length > 0) return {valid: false, error: error};
      else return {valid: true};
    }

    return {
      validate: validate,
      reformateData: reformateData,
      previewData: previewData
    };
  });
})();
