"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEndDate = exports.getStartDate = exports.fillMissingStatistics = void 0;
var date_fns_1 = require("date-fns");
var HOUR_IN_MS = 3600000;
var DAY_IN_MS = 86400000;
var MONTH_IN_MS = 2592000000;
function fillMissingStatistics(rawReport, start, end, aggregationInterval) {
    var emptyReport = initializeEmptyReport(start, end, aggregationInterval);
    var reportFromBackend = {};
    rawReport.forEach(function (item) {
        reportFromBackend[(0, date_fns_1.getTime)(new Date(item.date || 0))] = item;
    });
    var finalReport = combineReport(reportFromBackend, emptyReport, aggregationInterval);
    return Object.keys(finalReport)
        .sort(function (a, b) { return parseInt(a) - parseInt(b); })
        .map(function (key) { return finalReport[parseInt(key)]; });
}
exports.fillMissingStatistics = fillMissingStatistics;
function combineReport(reportFromBackend, emptyReport, aggregationInterval) {
    // eslint-disable-next-line no-nested-ternary
    var INTERVAL = aggregationInterval === 'hour' ? HOUR_IN_MS : aggregationInterval === 'day' ? DAY_IN_MS : MONTH_IN_MS;
    var CLOSE_ENOUGH_PERCENTAGE = 1 / 24; // when interval is one day, we allow 1 hour difference
    var finalReport = __assign({}, emptyReport);
    var datesFromBackend = Object.keys(reportFromBackend);
    var expectedDates = Object.keys(emptyReport);
    datesFromBackend.forEach(function (date) {
        var timestamp = parseInt(date);
        if (expectedDates.indexOf(date) !== -1) {
            finalReport[timestamp] = reportFromBackend[timestamp];
        }
        else {
            // check if there is a close enough date
            var closeDate = expectedDates.find(function (d) { return Math.abs(parseInt(d) - timestamp) <= INTERVAL * CLOSE_ENOUGH_PERCENTAGE; });
            if (closeDate) {
                finalReport[parseInt(closeDate)] = __assign(__assign({}, reportFromBackend[timestamp]), { date: parseInt(closeDate) });
            }
            else {
                console.error("Cannot find a close date for: ".concat(timestamp));
            }
        }
    });
    return finalReport;
}
var getStartOrEndFct = function (startOrEnd, aggregationInterval) {
    switch (aggregationInterval) {
        case 'day':
            return startOrEnd === 'start' ? date_fns_1.startOfDay : date_fns_1.endOfDay;
        case 'hour':
            return startOrEnd === 'start' ? date_fns_1.startOfHour : date_fns_1.endOfHour;
        case 'month':
            return startOrEnd === 'start' ? date_fns_1.startOfMonth : date_fns_1.endOfMonth;
        default:
            return function (date) { return date; };
    }
};
var getUtc = function (date) {
    return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
};
function getStartDate(startDate, aggregationInterval) {
    var sd = getStartOrEndFct('start', aggregationInterval)(new Date(startDate));
    var utcDate = getUtc(sd);
    return new Date(utcDate);
}
exports.getStartDate = getStartDate;
function getEndDate(endDate, aggregationInterval) {
    var sd = getStartOrEndFct('end', aggregationInterval)(new Date(endDate));
    var utcDate = getUtc(sd);
    return new Date(utcDate);
}
exports.getEndDate = getEndDate;
function initializeEmptyReport(start, end, aggregationInterval) {
    var out = {};
    var startDate = getStartDate(start, aggregationInterval);
    var endDate = getEndDate(end, aggregationInterval);
    while ((0, date_fns_1.isBefore)(startDate, endDate)) {
        out[startDate.getTime()] = {
            date: startDate.getTime(),
            acceptedAuto: 0,
            rejectedAuto: 0,
            deferredAuto: 0,
            noDecisionAuto: 0,
            noDecisionManual: 0,
            acceptedManual: 0,
            rejectedManual: 0,
            deferredManual: 0,
            received: 0,
        };
        startDate = (0, date_fns_1.add)(startDate, {
            months: aggregationInterval === 'month' ? 1 : 0,
            days: aggregationInterval === 'day' ? 1 : 0,
            hours: aggregationInterval === 'hour' ? 1 : 0,
        });
    }
    return out;
}
