(function() {
  'use strict';

  function Controller(tFilter, $stateParams, getFeedback, ApiDecisions) {
    const vm = this;

    vm.$onInit = () => {
      vm.domain = $stateParams.domain;
      vm.selectedFeedbackIds = vm.rule.feedbackReasons ? vm.rule.feedbackReasons.map((feedback) => feedback.id) : [];
      getFeedback(vm.domain).then((data) => {
        vm.feedbackList = data;
      });
      ApiDecisions.getByDomain(vm.domain).then((result) =>{
        vm.availableDecisions = result.results.map(decision => ({...decision, label: decision.name}));
        vm.selectedDecision = vm.availableDecisions.find(decision => decision.id === parseInt(vm.rule.decisionId));
      });
    };

    vm.updateFeedback = (feedbackReasons) => {
      vm.rule.feedbackReasons = feedbackReasons.map((item) => ({id: item.feedbackId, name: item.name}));
    };

    vm.selectedAvailableQueuesChanged = (item) => {
      vm.rule.routingQueueObj = item;
    };

    vm.selectedAvailableDecisionsChanged = (decision) => {
      vm.selectedDecision = decision;
      vm.rule.decisionId = String(decision.id);
    };

    vm.text = {
      title: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.ACTIONS.HEADER'),
      radioButtons: {
        refuse: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.ACTIONS.REFUSE_LABEL'),
        manual: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.ACTIONS.SEND_QUEUE_LABEL'),
        noAction: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.ACTIONS.NO_ACTION_LABEL'),
        approve: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.ACTIONS.APPROVE_LABEL'),
      },
      refusalReason: {
        validation: {
          required: tFilter('AUTOMATION.DECISION_RULES.ERROR.REASON_REQUIRED')
        }
      },
      checkboxes: {
        highlightKeywords: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.ACTIONS.HIGHLIGHT_LABEL'),
        displayMessage: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.ACTIONS.DISPLAY_MSG_LABEL'),
      },
      message: {
        placeholder: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.ACTIONS.DISPLAY_MSG_TEXTAREA_PLACEHOLDER'),
        category: {
          good: 'Good',
          info: 'Information',
          problem: 'Problem',
          critical: 'Critical'
        },
        validation: {
          required: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.ACTIONS.REQUIRED_MSG')
        }
      },
      status: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.STATUS_LABEL'),
      buttonCreate: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.BTN.CREATE'),
      buttonCancel: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.BTN.CANCEL_PASCAL')
    };
  }

  angular
    .module('eva2-angular')
    .component('selectRuleDecision', {
      template: require('./selectRuleDecision.html'),
      controller: Controller,
      bindings: {
        rule: '=',
        submitted: '<',
        availableQueues: '<'
      }
    });
})();
