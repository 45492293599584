"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryBuilder = exports.getStatusQuery = exports.getUrlParametersFromOptions = exports.getQueryFromUrlParams = exports.STATUSES = void 0;
exports.STATUSES = [
    {
        id: 'APPROVE',
        label: 'DASHBOARD.ITEM_LOG.TABLE.TABS.APPROVED',
        query: 'APPROVE',
    },
    {
        id: 'REFUSE',
        label: 'DASHBOARD.ITEM_LOG.TABLE.TABS.REFUSED',
        query: 'REFUSE',
    },
    {
        id: 'NO DECISION',
        label: 'DASHBOARD.ITEM_LOG.TABLE.TABS.NO_DECISION',
        query: '"NO DECISION"',
    },
    {
        id: 'MANUAL',
        label: 'DASHBOARD.ITEM_LOG.TABLE.TABS.REVIEWABLE',
        query: function (prefix) { return "(".concat(prefix, ":\"MANUAL\" OR ").concat(prefix, ":\"DEFER\")"); },
    },
    {
        id: 'new',
        label: 'DASHBOARD.ITEM_LOG.TABLE.TABS.PROCESSING',
        query: '"new"',
    },
];
/**
 * Convert URL parameters to variables that are set in the controller.
 * On init, all variables are parsed from the URL query parameters. They are stored locally in
 * the controller as vm.variables.
 * When the search is reset, they will be parsed from an empty object (i.e. no pre-defined options),
 * and in this case they are reset to the default variables for the customisable variables.
 * @param defaults default local variablesP
 * @param queryParameters the current query parameters in the URL (or undefined for reset search criteria).
 * @param STATUSES
 * @returns local variables used in the controller
 */
var getQueryFromUrlParams = function (defaults, queryParameters, STATUSES) {
    var id = queryParameters.get('id');
    var perPage = queryParameters.get('perPage');
    var cursor = queryParameters.get('cursor');
    var status = queryParameters.get('status');
    var order = queryParameters.get('order');
    var sort = queryParameters.get('sort');
    var from = queryParameters.get('from');
    var to = queryParameters.get('to');
    var type = queryParameters.get('type');
    var text = queryParameters.get('text');
    // perPage is undefined in itemLog page
    var perPageValue = (perPage && [10, 20, 30, 40, 50].includes(Number.parseInt(perPage)) ? Number.parseInt(perPage) : defaults.perPage);
    return {
        id: id || defaults.id,
        filterId: defaults.filterId || undefined,
        perPage: perPageValue,
        cursor: cursor || undefined,
        status: status && STATUSES ? STATUSES.find(function (x) { return x.id === status; }) || defaults.status : defaults.status || undefined,
        order: order === 'asc' || order === 'desc' ? order : defaults.order,
        sort: sort === 'date' || sort === 'score' ? sort : defaults.sort,
        from: from ? new Date(Number.parseInt(from)) : defaults.from,
        to: to ? new Date(Number.parseInt(to)) : defaults.to,
        type: type || undefined,
        domain: defaults.domain,
        text: text || undefined,
    };
};
exports.getQueryFromUrlParams = getQueryFromUrlParams;
/**
 * Convert request options to URL query parameters in the url.
 * This converts the request options used in the API call to query parameters that are set in the URL.
 * These query parameters in the URL are then parsed in getVariablesFromObject if the page is re-initialised.
 * @param options
 * @param defaults
 * @returns {{domain, filterId: (*|string), perPage: undefined, cursor: undefined, id: *, status: undefined, order: undefined, sort: undefined, from: undefined, to: undefined}}
 */
var getUrlParametersFromOptions = function (options, defaults) {
    var id = options.id, cursor = options.cursor, perPage = options.perPage, status = options.status, sort = options.sort, order = options.order, from = options.from, to = options.to, type = options.type, text = options.text;
    return {
        domain: defaults.domain,
        filterId: defaults.filterId,
        perPage: perPage !== defaults.perPage ? perPage : undefined,
        cursor: cursor,
        id: id,
        status: status !== defaults.status ? status === null || status === void 0 ? void 0 : status.id : undefined,
        order: order !== defaults.order ? order : defaults.order,
        sort: sort !== defaults.sort ? sort : defaults.sort,
        from: from ? new Date(from).getTime() : undefined,
        to: to ? new Date(to).getTime() : undefined,
        type: type,
        text: text,
    };
};
exports.getUrlParametersFromOptions = getUrlParametersFromOptions;
var getStatusQuery = function (decisionResultTypes, variables, status) {
    var decisionResultType = decisionResultTypes.find(function (type) { return type.id === variables.type; });
    var prefix = decisionResultType ? decisionResultType.prefix : 'metadata.result';
    var id = decisionResultType ? decisionResultType.id : '';
    if (!status && id === 'auto') {
        return "".concat(prefix, ":(APPROVE || REFUSE || NO DECISION) AND (!metadata.manualResults.result:*)");
    }
    if (!status) {
        return "(".concat(prefix, ": *)");
    }
    if (status.id === 'REFUSE' && id === 'auto') {
        return "".concat(prefix, ":(REFUSE) AND (!metadata.manualResults.result:*)");
    }
    if (status.id === 'MANUAL' && typeof status.query === 'function') {
        return status.query(prefix);
    }
    return "(".concat(prefix, ": ").concat(status.query, ")");
};
exports.getStatusQuery = getStatusQuery;
var queryBuilder = function (searchParam, minDate, searchTimestamp, decisionResultTypes) {
    var id = searchParam.id, filterId = searchParam.filterId, from = searchParam.from, to = searchParam.to, status = searchParam.status, text = searchParam.text;
    var fromOrMin = from || minDate;
    var filterIdQuery = filterId ? "(metadata.automaticResults.filters.id: ".concat(filterId, ")") : null;
    var idQuery = id ? "(document.id: ".concat(id, ")") : null;
    var f = !fromOrMin ? '*' : fromOrMin.valueOf();
    var t = !to ? '*' : to.valueOf();
    var dateRangeQuery = f || t ? "(metadata.created: [".concat(f, " TO ").concat(t, "])") : null;
    var statusQuery = decisionResultTypes && (0, exports.getStatusQuery)(decisionResultTypes, searchParam, status);
    var timestamp = "(metadata.created:[* TO ".concat(searchTimestamp, "])");
    var searchText = text ? "".concat(encodeURIComponent(text)) : null;
    // when id is queried, ignore date
    if (id) {
        return [searchText, idQuery, filterIdQuery, statusQuery, timestamp].filter(function (s) { return !!s; }).join(' AND ');
    }
    return [searchText, filterIdQuery, dateRangeQuery, statusQuery, timestamp].filter(function (s) { return !!s; }).join(' AND ');
};
exports.queryBuilder = queryBuilder;
