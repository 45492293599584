(function() {
  'use strict';
  angular.module('eva2-angular').controller('CsvCompleteCtrl', function($scope, $modalInstance, $state, errorNum, totalNum, domain, errorMsg, tFilter) {
    const vm = this;
    vm.errorNum = errorNum;
    vm.errorMsg = errorMsg;
    vm.resources = {
      title: {
        success: tFilter('SETTING.CSV_IMPORTER.IMPORT_DATA.SUCCESS_MODAL.SUCCESS_TEXT'),
        failure: tFilter('SETTING.CSV_IMPORTER.IMPORT_DATA.SUCCESS_MODAL.FAIL_TEXT')
      },
      p1: tFilter('SETTING.CSV_IMPORTER.IMPORT_DATA.SUCCESS_MODAL.TEXT1'),
      p2: tFilter('SETTING.CSV_IMPORTER.IMPORT_DATA.SUCCESS_MODAL.TEXT2'),
      p3: tFilter('SETTING.CSV_IMPORTER.IMPORT_DATA.SUCCESS_MODAL.TEXT3'),
      apiDocLink: tFilter('SETTING.CSV_IMPORTER.IMPORT_DATA.SUCCESS_MODAL.API_DOC_LINK'),
      button: tFilter('SETTING.CSV_IMPORTER.IMPORT_DATA.SUCCESS_MODAL.GOT_IT_BTN')
    };

    vm.successNum = totalNum - errorNum;
    vm.isImportSuccessful = vm.successNum > 0;
    vm.successMessage = tFilter('SETTING.CSV_IMPORTER.IMPORT_DATA.SUCCESS_MODAL.ITEM_SUCCESS_TEXT', {successNum: vm.successNum}, 'messageformat');

    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
      redirectUser();
    };

    vm.error = '';
    if (vm.errorNum) {
      vm.error = tFilter('SETTING.CSV_IMPORTER.IMPORT_DATA.SUCCESS_MODAL.ERROR_TEXT', {errorNum: vm.errorNum}, 'messageformat');
    }

    function redirectUser() {
      const disallowHistoryBack = {location: 'replace'};
      if (vm.isImportSuccessful) {
        $state.go('app.assignment.overview.statistics', {domain}, disallowHistoryBack);
      } else {
        $state.go('app.assignment.csvImporter.step0', {domain, resetFile: true}, disallowHistoryBack);
      }
    }
  });
})();
