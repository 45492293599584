(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('checkboxSwitch', checkboxSwitch);

  checkboxSwitch.$inject = [];

  function checkboxSwitch() {
    const directive = {
      restrict: 'E',
      replace: true,
      template: require('./checkboxSwitch.html'),
      require: 'ngModel',
      scope: {
        checked: '=ngModel'
      },
      link: function(scope, elem) {
        // Make use of a watch to assign a CSS class providing more smooth animated transition upon future changes post the
        // initial value assigned when the checkbox-switcher is loaded the first time in the view (where no such animation is used).
        const removeWatch = scope.$watch('checked', function(val) {
          // Certain context will assign value undefined to the checkbox-switcher directive initially prior to assigning the initial load-time bool value
          // in such a case we don't want to count it as a relevant initial assignment..
          if (val === undefined) {
            return;
          }

          // Assign CSS class providing smooth transitions upon any change post the very change triggering this watch invocation.
          // A timeout is deployed as it won't work to enact on any following invocation of a watch as it won't be fast enough to actually
          // result in a visual transition for that very next watch invocation (that would only affect the following change and watch invocation).
          // So as a workaround:
          setTimeout(() => {
            const nativeElem = elem[0];
            $(nativeElem.querySelector('.checkbox-switch-inner')).addClass('smooth-transition');
            $(nativeElem.querySelector('.checkbox-switch-switch')).addClass('smooth-transition');
          }, 40);

          removeWatch();
        });
      }
    };
    return directive;
  }
})();
