(function() {
  'use strict';

  function Controller($timeout, $element, $state, $scope, userSetting, $sanitize) {
    'ngInject';

    const vm = this;
    const MAX_RECENT_HISTORY_NUM = 100;

    function searchText(value='') {
      saveSearchText(value);
      $state.go('app.assignment.search', {
        text: value,
        cursor: undefined // Also reset the pagination cursor when searching
      });
    }

    function saveSearchText(value) {
      //save the value to localStorage,
      // if there is duplicates, the old one will be removed
      // the maximum number of history item is MAX_RECENT_HISTORY_NUM
      const sanitizedValue = $sanitize(value);
      if (sanitizedValue && typeof sanitizedValue === 'string') {
        const temp = vm.recentSearch
          .filter((item) => !!item && item.text !== sanitizedValue)
          .slice(0, MAX_RECENT_HISTORY_NUM - 1);
        vm.recentSearch = [{text: sanitizedValue.trim()}, ...temp];

        userSetting.saveUserSetting('recentSearch', vm.recentSearch);
      }
    }

    this.$onInit = function() {
      vm.pause = 400;
      const recentSearch = userSetting.getUserSetting('recentSearch') || [];
      // sanitize recent search to prevent xss attack 
      vm.recentSearch = recentSearch.map((item) => ({text: $sanitize(item.text).trim()}));
      $timeout(() => {
        const searchInbox = $element.find('#navSearchBox_value');

        //when the text change from outside, e.g, trigger the search from list, set the text in the input manually
        $scope.$watch('vm.text', () => {
          searchInbox.val(vm.text);
          saveSearchText(vm.text);
        });

        vm.onFocus = (isFocus) => {
          //select all text when focus
          vm.isInputFocused = isFocus;
          if (isFocus) {
            searchInbox.select();
            searchInbox.scope().currentIndex = -1;
          } else {//when onblur manual change the pause value is a work around to avoid the the dropdown showing after search
            vm.pause = 0;
            $timeout(()=> vm.pause = 400);
          }
        };

        vm.inputChanged = ($item) => {
          vm.inputValue = $item;
        };

        //handler for search when press enter
        searchInbox.on('keydown', (e) => {
          if (e.keyCode === 13) {
            searchText(e.target.value);
            searchInbox.blur();
          }
        });

        //handler for search when select the result by click
        const dropdown = $element.find('.angucomplete-dropdown');
        dropdown.on('click', () => {
          if(vm?.selectedItem?.title){
            searchText(vm.selectedItem.title.trim());
          }
        });
      });
    };
  }

  angular
    .module('eva2-angular')
    .component('searchbox', {
      bindings: {
        text: '@'
      },
      template: require('./searchbox.html'),
      controller: Controller,
      controllerAs: 'vm'
    });
})();
