(function() {
  'use strict';
  angular.module('eva2-angular').directive('evaDatePicker', datePicker);

  function datePicker(DatePickerConfig, DateFormat, MomentUtil) {
    return {
      restrict: 'E',
      replace: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        value: '<',
        minValue: '<',
        maxValue: '<',
        placeholder: '@',
        onUpdate: '&',
        isStartPicker: '<',
        isEndPicker: '<'
      },
      template: require('./datePicker.html'),
      link: function(scope, elm) {
        scope.placeholder = scope.placeholder || 'Select a date';
        const configuration = new DatePickerConfig();
        elm.datetimepicker(configuration);
        setupWatcher('maxValue', 'maxDate');
        setupWatcher('minValue', 'minDate');

        scope.$watch('value', function(newValue) {
          elm.data('DateTimePicker').date(!newValue ? null : getJqDateString(newValue));
        });

        // Register event listener for changes of selected date + time within JQuery date time plugin
        elm.on('dp.change', function(e) {
          const event = angular.copy(e);
          if (event.date) {
            if (scope.isStartPicker) {
              event.date.seconds(0).milliseconds(0);
            }
            if (scope.isEndPicker) {
              event.date.seconds(59).milliseconds(999);
            }
          }
          scope.onUpdate({value: event.date});
        });

        elm.on('dp.show', function() {
          const currentDate = elm.data('DateTimePicker').date();
          if (!currentDate) {
            if (scope.isEndPicker) {
              elm.data('DateTimePicker').date(moment().hours(23).minutes(59));
            }

            if (scope.isStartPicker) {
              elm.data('DateTimePicker').date(moment().hours(0).minutes(0));
            }
          }
        });

        function setupWatcher(attributeName, methodName) {
          scope.$watch(attributeName, function(newValue) {
            // set date to boolean:false means not setting a value
            elm.data('DateTimePicker')[methodName](!newValue ? false : getJqDateString(newValue));
          });
        }

        function getJqDateString(date) {
          const momentObj = MomentUtil.getMoment(date);
          if (!momentObj) {
            return null;
          }
          return momentObj.format(configuration.format || DateFormat.moment.display);
        }
      }
    };
  }

  function Controller() {
  }
})();
