(function() {
  'use strict';

  function Controller($timeout, $element) {
    const vm = this;
    const bodyLineHeight = 20;
    const numberOfLinesToShow = vm.numberOfLinesToShow || 3;

    vm.$onInit = () => {
      $timeout(()=> {
        const hiddenBody = $element.find('.eva-extendable-text__hidden-content')[0];
        vm.inExtendableMode = hiddenBody.offsetHeight > numberOfLinesToShow * bodyLineHeight;
        hiddenBody.remove();
      });
    };

    vm.toggleExtendMode = () => {
      vm.isBodyExtended = !vm.isBodyExtended;
    };


  }

  angular
    .module('eva2-angular')
    .component('evaExtendableText', {
      bindings: {
        highlight: '<',
        content: '<',
        numberOfLinesToShow: '<'
      },
      template: `<div class="eva-extendable-text" 
                      data-ng-class="{'eva-extendable-text--has-show-more-link': $ctrl.inExtendableMode}"
                      data-ng-click="$ctrl.inExtendableMode && $ctrl.toggleExtendMode()">
      <div class="eva-extendable-text__content"
         data-ng-class="{'is-body-extended': $ctrl.isBodyExtended}"
         sanitized-html-highlight="$ctrl.highlight"
         sanitized-html="$ctrl.content">
      </div>
      <div class="eva-extendable-text__hidden-content"
         sanitized-html-highlight="$ctrl.highlight"
         sanitized-html="$ctrl.content"></div>
      <div class="eva-extendable-text__control-icon-container">
          <span data-ng-show="!$ctrl.isBodyExtended" 
          class="eva-extendable-text__read-more-icon implioicons-arrow-2-down">
          </span>
          <span data-ng-show="$ctrl.isBodyExtended"
          class="eva-extendable-text__read-less-icon implioicons-arrow-2-up"></span>
      </div>
    </div>`,
      controller: Controller
    });
})();
