(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);


  function appConfig($stateProvider) {
    $stateProvider.state('app.assignment.automation.filterEdit', {
      url: '/filters/:filterId/edit',
      data: {
        title: 'PAGE_TITLE.EDIT_RULE'
      },
      views: {
        content: {
          template: require('./filterEdit.html'),
          controller: 'AdminFilterEditCtrl',
          controllerAs: 'vm'
        }
      }
    });
  }
})();
