'use strict';

angular.module('eva2-angular').factory('AugBox', function() {
  'ngInject';
  // TO DO we replaced boundingBox with rectangle so we need to test it in the other part of the app
  class AugBox {
    constructor(noticeId, rectangle, tagText) {
      this.noticeId = noticeId;
      this.rectangle = rectangle;
      this.tagText = tagText;
    }
  }

  return AugBox;
})
;
