'use strict';

angular
  .module('eva2-angular')
  .directive('sanitizedHtml', sanitizedHtml);

function sanitizedHtml($parse, $compile) {
  'ngInject';
  return {
    restrict: 'A',
    compile: function ngBindHtmlCompile(tElement, tAttrs) {
      const htmlGetter = $parse(tAttrs.sanitizedHtml);
      const highlightGetter = $parse(tAttrs.sanitizedHtmlHighlight);
      $compile.$$addBindingClass(tElement);
      return function(scope, element) {
        const htmlHighlightWrapped = highlightGetter(scope);
        const htmlPlain = htmlGetter(scope);
        if(Array.isArray(htmlHighlightWrapped) && htmlHighlightWrapped.length === 1 ){
          element.html(htmlHighlightWrapped[0]);
        } else {
          element.text(htmlPlain);
        }
      };
    }
  };
}
