import _ from 'lodash';

(function() {
  'use strict';
  angular.module('eva2-angular').service('getDecisionData', function(EvaLogger) {
    function parseSelectedFeedback(job) {
      if (Array.isArray(job.selectedFeedback) && job.selectedFeedback.length > 0) {
        return job.selectedFeedback.map((item) => ({id: item.id, name: item.label}));
      }
      return [];
    }

    function getDecisionData(job) {
      if (!_.get(job, 'selectedDecision.type')) {
        EvaLogger.captureMessage(`item doesn't have a decision type. item: ${JSON.stringify(job)}`);
        return;
      }

      const decisionType = job.selectedDecision.type.toUpperCase();
      const ad = {current: {content: _.cloneDeep(_.get(job, 'ad.current.content'))}};
      if (_.get(ad, 'current.content.images')) {
        ad.current.content.images = _.map(ad.current.content.images, (image)=> {
          return _.omit(image, ['imageServiceUrl', 'augBoxes', 'originalSize', 'phash']);
        });
      }
      ad.current.user = job.ad.current.user;
      ad.customerSpecific = job.ad.customerSpecific;
      const videos = _.get(ad, 'current.content.videos');
      if (videos) {
        ad.current.content.videos = _.map(ad.current.content.videos, (video)=> {
          return _.omit(video, ['playing', 'error']);
        });
        const enrichVideos = (videos, videosInfoContent) => {
          return videos
            .filter(video => video.src !== null)
            .map((video,index) => {
              const deleted = videosInfoContent[index].deleted;
              return deleted !== undefined ? {...video, deleted: deleted} : video;
            });
        };    
        const videosInfoContent = _.get(job, 'ad.videosInfo.content');
        if(videosInfoContent){
          ad.current.content.videos = enrichVideos(videos,videosInfoContent);
        }
      }

      const feedbackData = parseSelectedFeedback(job);
      const refusalReasons = job.selectedDecision.reason ? [job.selectedDecision.reason] : null;

      const decisionData = {
        id: job.ad.id,
        decision: decisionType,
        ad,
        feedback: feedbackData,
        decisions: refusalReasons,
      };

      if (decisionType === 'DEFER' && job.routingQueueObj) {
        decisionData.routingQueue = job.routingQueueObj.id;
        decisionData.routingQueueName = job.routingQueueObj.label;
      }

      return decisionData;
    }

    return getDecisionData;
  });
})();
