(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .service('FilterStatusRecoverer', ['ApiFilters', FilterStatusRecoverer]);

  function FilterStatusRecoverer(ApiFilters) {
    return {
      create: function(success, error, rollback, recover) {
        return {
          apply: (reqOptions, filter) => {
            /* Updating a filter is usually patching it with regard to whether is should be enabled or not. When user initiates action
             by clicking checkbox "switcher" the UI component will switch prior to an actual acknowledgement from server about successful "switch".
             As such a failure will instead be handled in such a way that a subsequent fetch of the filter (in its current state in the back-end) is made
             in order to assign the correct value to the checkbox. If that very request would fail too then the value (checked as in enabled, or not)
             will roll-back to it's previous as it is highly likely that the patch operation that initially failed wasn't successful in the first place.
             */
            function rollbackFilterEnabledStatus() {
              // Default to rolling back to the value that was changed when user initiated action to change status of filter whether enabled or not (prior to server acknowledgement)
              rollback(filter);
            }

            const errorPatching = (err) => {
              error(angular.copy(filter), err);

              const afterSubsequentFetchOfFilter = (result) => {
                const filterRefetchedFromServer = result;
                if (!filterRefetchedFromServer) {
                  rollbackFilterEnabledStatus();
                } else {
                  // Assign current scope filter to the actual status as freshly given by back-end in order to avoid discreptancy between the switched value
                  // in UI that initiated this update of filter in back-end that seemed to have failed.
                  // filter.enabled = filterRefetchedFromServer.enabled;
                  recover(filter, filterRefetchedFromServer);
                }
              };

              const errorFetchingFilter = () => {
                rollbackFilterEnabledStatus();
              };

              // Attempt fetching current filter in its latest state from server to see
              // whether filter is regarded as enabled / disabled server-side after the failed operation to update it
              ApiFilters
                .show(reqOptions)
                .then(afterSubsequentFetchOfFilter)
                .catch(errorFetchingFilter);
            };

            ApiFilters
              .updateStatus(reqOptions, {id: filter.id, enabled: filter.enabled})
              .then(() => {
                success(filter);
              })
              .catch(errorPatching);
          }
        };
      }
    };
  }
})();
