(function() {
  'use strict';
  angular.module('eva2-angular').service('localMouseWheel', function() {
    return function(event, element) {
      const elementReachedTop = element.scrollTop === 0;
      const elementTotallyScrolled = element.scrollTop === element.scrollHeight - element.clientHeight;
      if ((event.deltaY > 0 && elementTotallyScrolled) || (event.deltaY < 0 && elementReachedTop)) {
        event.preventDefault();
      }
    };
  });
})();
