/*evaNotice: evaNotice show messages*/
import _ from 'lodash';
(function() {
  'use strict';

  const severities = ['critical', 'problem', 'info', 'good'];

  function Controller() {
    const vm = this;
    vm.$onInit = () => {
      vm.sortedNotices = _.orderBy(vm.notices, (o) => severities.indexOf(o.severity), ['desc']);
    };

    vm.clicked = (notice)=> {
      if (notice.lockInActive) {
        notice.lockInActive = false;
        notice.active = false;
      } else if (notice.active) {
        notice.lockInActive = true;
      }

      vm.noticeTriggered({id: notice.id, value: notice.active});
    };

    vm.mouseEntered = (notice)=> {
      if (notice.lockInActive) {
        return;
      }
      notice.active = true;
      vm.noticeTriggered({id: notice.id, value: notice.active});
    };

    vm.mouseLeft = (notice)=> {
      if (notice.lockInActive) {
        return;
      }
      notice.active = false;
      vm.noticeTriggered({id: notice.id, value: notice.active});
    };
  }

  angular
    .module('eva2-angular')
    .component('evaNotices', {
      bindings: {
        notices: '<',
        noticeTriggered: '&',
        generatedNotices: '<',
        hideMainIcon: '<'
      },
      template: require('./notice.html'),
      controller: Controller
    });
})();

