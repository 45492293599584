import _ from 'lodash';

(function() {
  'use strict';


  angular
    .module('eva2-angular')
    .controller('manualQueuesCtrl', manualQueuesCtrl);

  
  function manualQueuesCtrl($stateParams, $modal, Notifier, $q, UserPermissionManager, ApiService,
    $scope, $timeout, tFilter, userSetting) {
    'ngInject';

    const vm = this;

    vm.domain = $stateParams.domain;
    vm.searchFields = ['name'];
    vm.entity = 'manualModerationQueues';
    vm.numbersOfQueuesPerPage = 20;
    const INTERVAL_RELOAD_QUEUES = 3000;
    const manualModerationQueuesSortingLocalStorage = userSetting.getUserSetting(`${vm.entity}Sorting`);
    vm.defaultSorting = manualModerationQueuesSortingLocalStorage?.[vm.domain] || {nameSort: 'asc'};

    vm.query = {};
    if (!vm.domain) {
      return;
    }
    /* make humanize in moment.js module, when more than 0 second show actual seconds*/
    moment.relativeTimeThreshold('ss', 0);

    let timeoutPromise;

    vm.loadQueues = ()=> {
      vm.getQueuePromise = ApiService.getQueueStats(vm.domain).then((data)=> {
        data.forEach((item)=> {
          item.name = item.label;
          item.nameSort = item.label.toLowerCase();
          item.oldestFormatted = moment(item.oldest).format('YYYY-MM-DD HH:mm:ss');
          item.oldestHumanized = item.oldest ? moment(item.oldest).fromNow() : '-';
          item.countSort = -item.count;
        });

        vm.queues = data;

        return data;
      }, (error)=> {
        vm.errorCode = error.status;
        return $q.reject();
      }).finally(()=> {
        timeoutPromise = $timeout(()=> {
          vm.loadQueues();
        }, INTERVAL_RELOAD_QUEUES);
      });
    };

    $scope.$on('$destroy', ()=> {
      $timeout.cancel(timeoutPromise);
    });

    vm.loadQueues();

    vm.userCanEditOrDeleteQueue = UserPermissionManager.getPermissionForOperation('manual:settings', vm.domain).canWrite;

    vm.addQueue = ()=> {
      const modalInstance = $modal.open({
        template: require('@partial/modals/addQueue/addQueue.html'),
        controller: 'AddQueueCtrl',
        controllerAs: 'vm',
        windowClass: 'queue-modal',
        backdrop: 'static',
        resolve: {
          domain: ()=> vm.domain,
          existingQueueNames: () => vm.queues.map((q)=> q.name),
          currentQueueId: ()=> undefined,
          currentQueueName: () => undefined
        }
      });

      modalInstance.result.then(function(result) {
        if (result.success) {
          vm.loadQueues();
          Notifier.display(tFilter('MANUAL.ADD_QUEUE_POPOVER.SUCCESS_TEXT'), {
            type: 'success', ttl: 1800
          });
        } else {
          Notifier.display(tFilter('MANUAL.ADD_QUEUE_POPOVER.FAIL_TEXT'), {
            type: 'failure', ttl: 6000
          }, result.error);
        }
      });
    };

    vm.editQueue = (currentQueueId, currentQueueName)=> {
      const modalInstance = $modal.open({
        template: require('@partial/modals/addQueue/addQueue.html'),
        controller: 'AddQueueCtrl',
        controllerAs: 'vm',
        windowClass: 'queue-modal',
        backdrop: 'static',
        resolve: {
          domain: ()=> vm.domain,
          existingQueueNames: () => vm.queues.map((q)=> q.name),
          currentQueueId: ()=> currentQueueId,
          currentQueueName: () => currentQueueName
        }
      });

      modalInstance.result.then(function(result) {
        if (result.success) {
          vm.loadQueues();
          Notifier.display(tFilter('MANUAL.EDIT_QUEUE_POPOVER.SUCCESS_TEXT'), {
            type: 'success', ttl: 1800
          });
        } else {
          Notifier.display(tFilter('MANUAL.EDIT_QUEUE_POPOVER.FAIL_TEXT'), {
            type: 'failure', ttl: 6000
          }, result.error);
        }
      });
    };

    vm.isDefaultQueue = (queueId) => ['default', 'escalated'].indexOf(queueId) !== -1;


    vm.deleteQueue = (currentQueueId, currentQueueName)=> {
      const promises = [
        ApiService.getFiltersUsedByQueue(vm.domain, currentQueueId),
        ApiService.getQueueStats(vm.domain)
      ];

      $q.all(promises).then((data)=> {
        const filters = data[0];
        const numberOfItemsInQueue = _.get(_.find(data[1], (item) => item.id === currentQueueId), 'count');
        const modalInstance = $modal.open({
          template: require('@partial/modals/deleteQueue/deleteQueue.html'),
          controller: 'DeleteQueueCtrl',
          controllerAs: 'vm',
          windowClass: 'queue-modal',
          backdrop: 'static',
          resolve: {
            domain: ()=> vm.domain,
            currentQueueId: ()=> currentQueueId,
            currentQueueName: () => currentQueueName,
            filters: () => filters.map((filter) => ({
              id: filter.id,
              name: filter.name,
              lastModifiedTime: filter.lastModifiedTime
            })),
            numberOfItemsInQueue: ()=> numberOfItemsInQueue
          }
        });

        modalInstance.result.then(function(result) {
          if (result.success) {
            Notifier.display(tFilter('MANUAL.DELETE_QUEUE_POPOVER.SUCCESS_TEXT'), {
              type: 'success', ttl: 1800
            });
          } else {
            Notifier.display(tFilter('MANUAL.DELETE_QUEUE_POPOVER.FAIL_TEXT'), {
              type: 'failure', ttl: 6000
            }, result.error);
          }
          vm.loadQueues();
        });
      })
        .catch((error)=> {
          Notifier.display(tFilter('MANUAL.DELETE_QUEUE_POPOVER.FAIL_TEXT'), {
            type: 'failure', ttl: 6000
          }, error);
        });
    };
  }
})();
