(function() {
  'use strict';
  angular.module('eva2-angular').service('getScrollTopForItemList', function() {
    return function(itemTop, itemHeight, containerScrollTop, containerHeight, extraScrollFromBottom = 0) {
      if (itemTop < containerScrollTop) {
        // when item is hidden from the top of the drop down menu
        return itemTop;
      }
      if (itemTop + itemHeight > containerScrollTop + containerHeight) {
        // when item is hidden in the bottom of the drop down menu
        return itemTop + itemHeight - containerHeight + extraScrollFromBottom;
      }
      return containerScrollTop;
    };
  });
})();
