const { shouldBeRenderedAsLink } = require('@/react-app/common/utils/linkUtils');


(function() {
  'use strict';

  /**
   * Directive used for providing filter match highlighting on arbitrary variable to be bound and outputted to element's content.
   *
   * The directive will:
   * <ul>
   *   <li>1) bind the contents of a referenced model variable expected to contain a text string,
   *   <li>2) remove any HTML mark-up from the value (without affecting source model - one way binding),
   *   <li>3) make use of provided processing results for the ad (data provided with another required attribute "processing-results" set on element where directive is invoked) in addition
   *   to provided system names of fields of interest in the ad at hand (data provided by another required element attribute 'ad-fields-to-highlight') to
   *    process the string for literals/regexps provided in results for processed filters indicating what to highlight,
   *   <li>4) convert any matches into raw text range objects defining literal, start position etc, 5) reduce any overlapping or adjacent matched text ranges,
   *   <li>6) highlight the resulting reduced text ranges with HTML markup providing tooltip information about what filters that matched (the whole or part of the highlighted range)
   * </ul>
   * Example invokation in HTML: <div ad-processing-results="vm.ad.processingResults" ad-fields-to-highlight="$body,$text" highlight-filter-matched="vm.ad.body" ></div>
   * Above would result in the processed highlighted text to be inserted into the div element as content.
   */
  angular
    .module('eva2-angular')
    .directive('bindHighlightFilterMatched', highlightFilterMatched);

  highlightFilterMatched.$inject = ['$parse', '$compile', 'tFilter'];





  function highlightFilterMatched( $parse, $compile) {
    require('linkifyjs/dist/linkify.min');
    require('linkifyjs/dist/linkify-jquery.min');
    const directive = {
      restrict: 'A',
      compile: function ngBindHtmlCompile(tElement, tAttrs) {
        const handleAnchorLinkClickGetter=$parse(tAttrs.handleAnchorLinkClick);
        // const bindHighlightFilterMatchedGetter = $parse(tAttrs.bindHighlightFilterMatched);
        // const adProcessingResultsGetter = $parse(tAttrs.adProcessingResults);
        const adFieldsToHighlight = (tAttrs.adFieldsToHighlight || '').split(',');

        const highlightListGetter = $parse(tAttrs.highlightList);



        const bindHighlightFilterMatchedWatch = $parse(tAttrs.bindHighlightFilterMatched, function getStringValue(value) {
          return (value || '').toString();
        });

        const adProcessingResultsWatch = $parse(tAttrs.adProcessingResults, function getStringValue(value) {
          return (value || '').toString();
        });

        $compile.$$addBindingClass(tElement);

        return function bindHighlightFilterMatchedLink(scope, element, attr) {
          $compile.$$addBindingInfo(element, attr.highlightList);

          function update(scope, element) {
            // Get hold of the string literal describing the names of the ad fields about to be highlighted, required to acquire correct data from processing results to use as part of highlighting
            if (!adFieldsToHighlight) {
              throw new Error('No HTML attribute "ad-fields-to-highlight" set on HTML element where directive "highlight-filter-matched" was invoked.' +
                ' Please set it to a string literal indicating the system names of the ad fields relevant for highlighting (comma separated if more than one).');
            }
            const list  =  highlightListGetter(scope);
            // const text = bindHighlightFilterMatchedGetter(scope);
            const handleAnchorLinkClick = handleAnchorLinkClickGetter(scope);
            // console.log('text', text);
            const div = angular.element('<div></div>');

            for(const el of list){
              const {text, type} = el;
              if(type === 'raw'){
                const span = angular.element('<span></span>');
                div.append(span.text(text));
              }
              if(type === 'html'){
                div.append(text);
              }
            }
            const linkFn = $compile(div);
            const content = linkFn(scope);
            element.empty();
            element.append(content);
            element.linkify(
              {
                className: 'titleAndBodyVisitedLinkColor externalLinkHover',
                validate: {
                  // linkify library  is not considering domain with url params as a link that's why
                  // the string www.learn.coding101.com?lesson=variables will not be received in shouldBeRenderedAsLink
                  // we should find an alternative library to use instead of linkify if we want to support this
                  url: (value) => shouldBeRenderedAsLink(value),
                },
                events: {
                  click: (e) => {
                    e.preventDefault();
                    // linkify is adding http:// and / at the end for hostname only links
                    const linkifyLink=event.target.href;
                    handleAnchorLinkClick(linkifyLink);
                    
                  },
                },
              }
            );
          }

          scope.$watchGroup([adProcessingResultsWatch, bindHighlightFilterMatchedWatch], function() {
            update(scope, element, attr);
          });
        };
      }
    };
    return directive;
  }

})();
