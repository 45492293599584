(function() {
  'use strict';

  angular.module('eva2-angular').controller('UserIntroductionModalCtrl', UserIntroductionModalCtrl);

  function UserIntroductionModalCtrl($scope, $modalInstance, $timeout, $state, showFullIntroduction, tFilter) {
    'ngInject';
    $scope.vm = {};
    $scope.vm.showFullIntroduction = showFullIntroduction;
    const optionalContents = [
      {
        title: tFilter('INTRODUCTION.OPTIONAL.1_TITLE'),
        text: tFilter('INTRODUCTION.OPTIONAL.1_TEXT'),
      },
      {
        title: tFilter('INTRODUCTION.OPTIONAL.2_TITLE'),
        text: tFilter('INTRODUCTION.OPTIONAL.2_TEXT')
      }
    ];

    const defaultContents =
      [
        {
          title: tFilter('INTRODUCTION.DEFAULT.1_TITLE'),
          text: tFilter('INTRODUCTION.DEFAULT.1_TEXT')
        },
        {
          title: tFilter('INTRODUCTION.DEFAULT.2_TITLE'),
          text: tFilter('INTRODUCTION.DEFAULT.2_TEXT')
        },
        {
          title: tFilter('INTRODUCTION.DEFAULT.3_TITLE'),
          text: tFilter('INTRODUCTION.DEFAULT.3_TEXT')
        },
        {
          title: tFilter('INTRODUCTION.DEFAULT.4_TITLE'),
          text: tFilter('INTRODUCTION.DEFAULT.4_TEXT')
        },
        {
          title: tFilter('INTRODUCTION.DEFAULT.5_TITLE'),
          text: tFilter('INTRODUCTION.DEFAULT.5_TEXT')
        },
        {
          title: tFilter('INTRODUCTION.DEFAULT.6_TITLE'),
          text: tFilter('INTRODUCTION.DEFAULT.6_TEXT')
        },
        {
          title: tFilter('INTRODUCTION.DEFAULT.7_TITLE'),
          text: tFilter('INTRODUCTION.DEFAULT.7_TEXT')
        }
      ];


    $scope.contents = $scope.vm.showFullIntroduction ? defaultContents.concat(optionalContents) : defaultContents;

    $scope.close = function() {
      $modalInstance.dismiss('close');
      if ($scope.vm.data) {
        $state.go('app.assignment.csvImporter.step0',
          {data: $scope.vm.data, file: $scope.vm.file});
      }
    };

    $timeout(() => {
      //wait for the dom ready to set the event handler
      //TODO: investigate is it the best way to wait for dom ready

      $scope.initialed = true;
      $scope.currentSlideIndex = 0;
      $scope.isFirstSlide = true;
      $scope.isLastSlide = false;
      angular.element('#user-intro-carousel').on('slide.bs.carousel', function(e) {
        $timeout(function() {
          $scope.currentSlideIndex = angular.element(e.relatedTarget).index();
          $scope.isFirstSlide = $scope.currentSlideIndex === 0;
          $scope.isLastSlide = $scope.currentSlideIndex === $scope.contents.length - 1;
        });
      });
    });

    $scope.$on('$destroy', ()=> {
      angular.element('#user-intro-carousel').off('slide.bs.carousel');
    });
  }
})();
