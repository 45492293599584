const { shouldBeRenderedAsLink } = require('@/react-app/common/utils/linkUtils');

angular.module('eva2-angular').service('validator', function() {
  'use strict';

  const validator = {
    /**
     * Validate a URL
     * @param  {string} string - URL
     * @return {boolean}
     */
    isUrl: function(url) {
      return (typeof url === 'string') && shouldBeRenderedAsLink(url);
    }
  };

  return validator;
});
