(function() {
  'use strict';

  function Controller($element, $scope, $timeout, strArr, supportedBrowser, str, CurrentUser, tFilter) {
    'ngInject';
    const Papa = require('papaparse');

    const vm = this;

    const csvMimeType = [
      'application/excel',
      'application/vnd.ms-excel',
      'application/vnd.msexcel',
      'text/anytext',
      'text/comma-separated-values',
      'text/csv'
    ];

    const peopleAllowedToUploadMoreAds = ['alessio.coco@besedo.com', 'kevin.martinez@besedo.com',
      'ada.ge@besedo.com', 'jean.rincon@besedo.com', 'giuliano.barducci@besedo.com', 'angie.catalina@besedo.com',
      'olivier.vencencius@besedo.com'];
    vm.LIMIT_DOC_LENGTH = peopleAllowedToUploadMoreAds.indexOf(CurrentUser.email) !== -1 ? 20000 : 1000;

    vm.resources = {
      error: {
        moreThanOneFile: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.WARNING1'),
        wrongType: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.WARNING2'),
        csvWrong: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.WARNING3'),
        emptyCsv: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.WARNING4'),
        columnError: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.WARNING5'),
        duplicateColumn: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.WARNING6')
      },
      dragDropText: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.DRAG_DROP_TEXT'),
      selectFileText: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.SELECT_FILE_BTN'),
      tryAgain: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.TRY_AGAIN_BTN'),
      cancel: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.DELETE_BTN'),
      bigFileText: tFilter('SETTING.CSV_IMPORTER.UPLOAD_FILE.BIG_FILE_TEXT', {limitDocLength: vm.LIMIT_DOC_LENGTH}, 'messageformat')
    };
    vm.FILE_UPLOAD_STATES = {
      EMPTY: 0,
      HAS_FILE: 1,
      ERROR: 2,
      READY: 3
    };

    vm.$onInit = function() {
      if (!!vm.data && !!vm.file && !!vm.file.name) {
        vm.fileUploadState = vm.FILE_UPLOAD_STATES.READY;
      } else {
        reset();
      }
      $timeout(() => {
        const form = $element.find('form');
        form.on('drag dragstart dragend dragover dragenter dragleave drop', dragDropHandler)
          .on('dragover dragenter', angular.bind(null, dragOverHandler, form))
          .on('dragleave dragend drop', angular.bind(null, dragLeaveHandler, form))
          .on('drop', fileDropHandler);

        const input = $element.find('input');

        //angular ng-change does support input file
        input.on('change', fileChangeHandler);
      });
    };

    if (vm.resetFile) {
      reset();
    }

    function readFile() {
      function completeCallBack(result) {
        const columns = strArr.removeEmptyStrInArr(result.meta.fields);
        const hasColumnDeplicate = strArr.hasDuplicateStrInArr(columns);

        $timeout(()=> {
          if (!columns) {
            //empty file
            vm.fileError = vm.resources.error.emptyCsv;
            vm.fileUploadState = vm.FILE_UPLOAD_STATES.ERROR;
          } else if (hasColumnDeplicate) {
            //deplicate column
            vm.fileError = vm.resources.error.duplicateColumn;
            vm.fileUploadState = vm.FILE_UPLOAD_STATES.ERROR;
          } else if (columns.length === 0) {
            //less than 3 column
            vm.fileError = vm.resources.error.csvWrong;
            vm.fileUploadState = vm.FILE_UPLOAD_STATES.ERROR;
          } else if (columns.length < 3) {
            //less than 3 column
            vm.fileError = vm.resources.error.columnError;
            vm.fileUploadState = vm.FILE_UPLOAD_STATES.ERROR;
          } else {
            if (!!result.data && result.data.length > 0) { //check empty file
              if (result.data.length > vm.LIMIT_DOC_LENGTH) {
                result.data.pop();
                vm.file.bigFile = true;
              }
              vm.data = result.data;
              vm.fileUploadState = vm.FILE_UPLOAD_STATES.READY;
            } else {
              //empty file
              vm.fileError = vm.resources.error.emptyCsv;
              vm.fileUploadState = vm.FILE_UPLOAD_STATES.ERROR;
            }
          }
        });
      }

      function errorCallBack() {
        vm.fileError = vm.resources.error.csvWrong;
        vm.fileUploadState = vm.FILE_UPLOAD_STATES.ERROR;
      }

      Papa.parse(vm.droppedFiles, {
        header: true,
        complete: completeCallBack,
        error: errorCallBack,
        preview: (vm.LIMIT_DOC_LENGTH + 1),
        skipEmptyLines: true
      });
    }

    function isFileTypeWrong(file) {
      const browserInfo = supportedBrowser.getBrowserInfo();
      if (browserInfo && browserInfo.browser === 'IE') {
        return !file || !file.name || !str.isEndWith(file.name, '.csv');
      }
      return !file || csvMimeType.indexOf(file.type) === -1;
    }

    function fileChanged(files) {
      reset();

      if (!!files && files.length > 0) {
        $timeout(()=> {
          if (files.length === 1) {
            vm.droppedFiles = files[0];
            if (isFileTypeWrong(vm.droppedFiles)) {
              vm.fileError = vm.resources.error.wrongType;

              vm.fileUploadState = vm.FILE_UPLOAD_STATES.ERROR;
            } else {
              vm.file.name = vm.droppedFiles.name;
              vm.fileUploadState = vm.FILE_UPLOAD_STATES.HAS_FILE;
              readFile();
            }
          } else if (files.length > 1) {
            vm.fileError = vm.resources.error.moreThanOneFile;
            vm.fileUploadState = vm.FILE_UPLOAD_STATES.ERROR;
          }
        });
      }
    }

    function dragDropHandler(e) {
      e.preventDefault();
      e.stopPropagation();
    }

    function dragOverHandler(form) {
      form.addClass('is-dragover');
    }

    function dragLeaveHandler(form) {
      form.removeClass('is-dragover');
    }

    function fileDropHandler(e) {
      fileChanged(e.originalEvent.dataTransfer.files);
    }

    function fileChangeHandler(e) {
      fileChanged(e.target.files);
    }

    function reset() {
      vm.fileUploadState = vm.FILE_UPLOAD_STATES.EMPTY;
      vm.file = {};
      if (vm.droppedFiles) delete vm.droppedFiles;
      if (vm.data) delete vm.data;
      vm.fileError = '';

      vm.reset();
    }


    vm.onCancel = function() {
      const form = $element.find('form');
      if (form[0]) {
        form[0].reset();
      }
      reset();
    };

    vm.$onDestroy = function() {
      const form = $element.find('form');
      form.off('drag dragstart dragend dragover dragenter dragleave drop', dragDropHandler)
        .off('dragover dragenter', angular.bind(null, dragOverHandler, form))
        .off('dragleave dragend drop', angular.bind(null, dragLeaveHandler, form))
        .off('drop', fileDropHandler);

      const input = $element.find('input');
      //angular ng-change does support input file
      input.off('change', fileChangeHandler);
    };
  }

  angular
    .module('eva2-angular')
    .component('csvFileUpload', {
      bindings: {
        data: '=',
        file: '=',
        reset: '&',
        fileUploadState: '=',
        resetFile: '<'
      },
      template: require('./fileUpload.html'),
      controller: Controller,
      controllerAs: 'vm'
    });
})();

