angular.module('eva2-angular').service('PageTitle', function PageTitle($rootScope, tFilter) {
  'use strict';

  const DEFAULT_TITLE = tFilter('PAGE_TITLE.IMPLIO');
  let title = DEFAULT_TITLE;

  Object.defineProperty(this, 'current', {
    get: () => title
  });

  this.setTitle = function setTitle(newTitle) {
    if (!newTitle) {
      newTitle = DEFAULT_TITLE;
    }

    if (title !== newTitle) {
      title = newTitle;
      $rootScope.$emit('$titleChanged', `${newTitle} - ${DEFAULT_TITLE}`);
    }
  };
});
