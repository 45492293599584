import _ from 'lodash';
/*!
 * ruleCondition
 * Description: single rule condition
 */

(function() {
  'use strict';
  function Controller($element, $timeout, ruleConditionHelper, $scope, tFilter) {
    const autosize = require('autosize');
    'ngInject';
    const vm = this;
    const resources = {
      placeholder: {
        field: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.BASIC_MODE.CRITERIA_PLACEHOLDER'),
        operator: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.BASIC_MODE.OPERATOR_PLACEHOLDER'),
        booleanValue: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.BASIC_MODE.VALUE_PLACEHOLDER'),
        textValue: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.BASIC_MODE.ENTER_TEXT_PLACEHOLDER'),
        numValue: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.BASIC_MODE.CRITERIA_PLACEHOLDER'),
        selectList: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.BASIC_MODE.SELECT_LIST_PLACEHOLDER'),
        emptySelectList: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.BASIC_MODE.NO_LIST_PLACEHOLDER')
      },
      tooltip: {
        delete: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.BASIC_MODE.DELETE_ICON_TOOLTIP')
      }
    };

    //private
    const availableOperator = ruleConditionHelper.allOperators;

    function getPlaceHolder(dictionaries) {
      return (!!dictionaries && dictionaries.length > 0)
        ? resources.placeholder.selectList
        : resources.placeholder.emptySelectList;
    }

    //public
    this.$onInit = function() {
      this.resources = resources;

      // when input type is "text", autosize the text area.
      $scope.$watch('$ctrl.inputType', () => {
        if ($scope.$ctrl.inputType === 'TEXT') {
          $timeout(()=> {
            autosize($element.find('textarea'));
          }, 0);
        }
      });

      $scope.$watch('$ctrl.dictionaries', () => {
        vm.selectListPlaceholder = getPlaceHolder(vm.dictionaries);
      });

      const result = ruleConditionHelper.translateBlangToUI(this.value, this.availableField);
      this.availableBooleanValue = ruleConditionHelper.availableBooleanValue;

      this.selectedField = result.field;
      if (this.selectedField) {
        this.selectedField.text = this.selectedField.label;
      }

      this.selectedOperator = result.operator;

      setAvailableOperator(this.selectedField);
      setInputType(this.selectedField, this.selectedOperator);

      this.inputValue = result.value || {};
      adaptDataForAdvancedSelectComponent(this.inputValue);

      this.updateResult();
    };

    function setAvailableOperator(selectedField) {
      vm.availableOperator = selectedField
        ? ruleConditionHelper.getAvailableOperator(selectedField.type)
        : availableOperator;
    }

    function setInputType(selectedField, selectedOperator) {
      const inputType = ruleConditionHelper.getTheInputType(selectedField, selectedOperator);
      if (vm.inputType !== inputType) {
        vm.inputType = inputType;
        vm.inputValue = {};
      }
    }

    this.selectedFieldChanged = function(fieldId) {
      $timeout(()=> {
        this.selectedField = _.find(this.availableField, (item) => item.id === fieldId);
        setAvailableOperator(this.selectedField);
        if (vm.availableOperator.indexOf(this.selectedOperator) === -1) {
          this.selectedOperator = null;
        }
        setInputType(this.selectedField, this.selectedOperator);
        this.updateResult();
      });
    };

    this.selectedOperatorChanged = function(operator) {
      this.selectedOperator = operator;
      setInputType(this.selectedField, this.selectedOperator);
      this.updateResult();
    };

    this.selectedListChanged = function(list) {
      $timeout(()=> {
        this.inputValue.selectedList = list;
        this.updateResult();
      });
    };

    this.selectedBooleanChanged = function(flag) {
      this.inputValue.selectedBoolean = flag;
      this.updateResult();
    };

    this.updateResult = function() {
      const translateUItoBlangResult = ruleConditionHelper
        .translateUItoBlang(this.selectedField, this.selectedOperator, this.inputValue, this.inputType);
      this.translateUItoBlangErrors = translateUItoBlangResult.errors;
      this.onUpdate({value: translateUItoBlangResult.expression});
    };

    function adaptDataForAdvancedSelectComponent(inputValue) {
      if (inputValue && inputValue.selectedList) {
        vm.initialSelectedList = {
          text: inputValue.selectedList[0]
        };
      }
    }
  }

  angular
    .module('eva2-angular')
    .component('ruleCondition', {
      bindings: {
        value: '<', // type: object
        dictionaries: '<',
        domain: '@',
        onUpdate: '&',
        deleteMe: '&',
        submitted: '<',
        availableField: '<'
      },
      template: require('./ruleCondition.html'),
      controller: Controller
    });
})();
