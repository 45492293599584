'use strict';

(function() {
  angular.module('eva2-angular').directive('decisionPicker', function() {
    return {
      bindToController: true,
      require: 'ngModel',
      controller: Controller,
      controllerAs: 'vm',
      restrict: 'E',
      template: require('./decisionPicker.html'),
      scope: {
        ngModel: '='
      },
      link: function(scope, element, attrs, ngModelCtrl) {
        if (!ngModelCtrl) {
          return;
        }

        scope.$watch('vm.selectedId', function() {
          scope.$evalAsync(function() {
            ngModelCtrl.$setViewValue(scope.vm.selectedId);
          });
        });

        ngModelCtrl.$render = function() {
          scope.vm.selectedId = ngModelCtrl.$modelValue;
        };
      }
    };
  });

  function Controller(ApiDecisions, $stateParams) {
    const vm = this;

    // Decisions available to pick from under current site (a.k.a 'domain').
    vm.availableDecisions = [];

    activate();

    function whenErrorLoadingDecisions() {
      vm.errorLoadingData = true;
    }

    vm.reload = () => {
      activate();
    };

    function activate() {
      ApiDecisions.getByDomain($stateParams.domain).then(function(result) {
        vm.availableDecisions = result.results;
        vm.errorLoadingData = false;
      }).catch(whenErrorLoadingDecisions);
    }
  }
})();
