"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBoundingBox = void 0;
var createBoundingBox = function (box) {
    var xmin = box.xmin, xmax = box.xmax, ymin = box.ymin, ymax = box.ymax;
    return {
        x: xmin,
        y: Math.min(ymax, ymin),
        width: xmax - xmin,
        height: Math.abs(ymin - ymax),
    };
};
exports.createBoundingBox = createBoundingBox;
