import _ from 'lodash';
(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .filter('getFullJobStatus', function() {
      return function(status, actorDomain) {
        if (status === 'no decision') {
          return 'no-decision';
        }
        if (_.includes(['approved', 'refused'], status)) {
          const mapping = {client: 'remotely', agent: 'manual', processor: 'auto'};
          return mapping[actorDomain] + '-' + status;
        }
        return status;
      };
    });
})();
