import _ from 'lodash';
(function() {
  'use strict';

  function Controller($scope, $timeout, ruleModeManager, ApiService, ApiAd, tFilter) {
    const autosize = require('autosize');
    'ngInject';
    const vm = this;

    const text = {
      label: {
        optional: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.OPTIONAL_LABEL')
      },
      ruleName: {
        label: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.RULE_NAME_LABEL'),
        placeholder: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.RULE_NAME_PLACEHOLDER'),
        validation: {
          minLength: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.MIN_LENGTH_TEXT')
        }
      },
      ruleDescription: {
        label: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.RULE_DESCRIPTION_LABEL'),
        placeholder: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.RULE_DESCRIPTION_PLACEHOLDER')
      },
      status: {
        tooltip: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.STATUS_TOOLTIP')
      },
      button: {
        cancel: tFilter('AUTOMATION.DECISION_RULES.BTN.CANCEL_PASCAL')
      },
      blangEditor: {
        placeholder: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.ADVANCED_MODE.EDITOR_PLACEHOLDER'),
      },
      mode: {
        header: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.EDITOR_HEADER'),
        link: {
          advancedMode: {
            labelSwitchTo: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.ADVANCED_MODE.BASIC_MODE_LINK'),
            tooltip: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.ADVANCED_MODE.BASIC_MODE_LINK_TOOLTIP')
          },
          simpleMode: {
            labelSwitchTo: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.BASIC_MODE.ADVANCED_MODE_LINK'),
            tooltip: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.BASIC_MODE.ADVANCED_MODE_LINK_TOOLTIP')
          }
        },
        errorMessage: {
          invalid: tFilter('AUTOMATION.DECISION_RULES.ERROR.REQUIRED_VALID_RULE_TEXT')
        },
        tooltip: {
          text: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.EDITOR_ICON_TOOLTIP'),
          linkLabel: tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.EDITOR_ICON_TOOLTIP_LINK')
        }
      }
    };


    vm.submit = () => {
      const isRuleFormValid = () => {
        if($scope.ruleCreateForm.$invalid || !vm.rule.rule || (vm.rule.result === 'REFUSE' && !vm.rule.decisionId)){
          return false; 
        }
        return true;
      };
      
      if(isRuleFormValid()){
        vm.onSave();
      }
    };

    vm.updateRuleExpression = (ruleExpression) => {
      vm.rule.rule = ruleExpression;
    };
    vm.switchMode = (toMode) => {
      vm.isInSimpleMode = toMode === 'SIMPLE' && vm.canShowRuleInSimpleMode;
    };
    vm.updateRuleVariables = function(newRule){
      vm.ruleVariables = newRule;
    };

    vm.$onInit = () => {
      vm.text = text;
      vm.rule.variables = [];
      setDefaultRuleValues();
      ApiService.getBlangVariables(vm.domain)
        .then((data) => {
          vm.domainBlangVariables = data.map((item) => {
            item.text = item.label;
            return item;
          });
          // when initializing the view, try show rules in basic mode. If not possible, show advanced mode.
          // manually call evaluateRuleMode, in case watch function to $ctrl.rule.rule is not fired yet
          evaluateRuleMode(vm.rule, vm.domainBlangVariables).then(()=> {
            vm.switchMode('SIMPLE');
            vm.isReadyToShow = true;
          });
          $timeout(function() {
            autosize(angular.element('textarea#rule-description'));
          });
          $scope.$watch('$ctrl.rule.rule', function() {
            evaluateRuleMode(vm.rule, vm.domainBlangVariables);
          });
        });
      ApiAd.getAvailableQueues(vm.domain).then(function(result) {
        vm.availableQueues = result;
        // if the rule has a routing queue set to it then select that one otherwise choose default
        vm.rule.routingQueueObj = _.find(result, (it) => it.id === (vm.rule.routingQueue || 'default'));
      });
    };

    function evaluateRuleMode(rule, domainBlangVariables) {
      return ruleModeManager.canShowRuleInSimpleMode(vm.domain, rule.rule, domainBlangVariables)
        .then((flag)=> {
          vm.canShowRuleInSimpleMode = flag;
          vm.canShowRuleInAdvancedMode = rule.rule !== undefined && rule.rule !== null;
        });
    }

    function setDefaultRuleValues() {
      if (vm.rule) {
        if(!vm.rule.notice){
          vm.rule.notice =  {enabled: false, severity: 'GOOD'};
        }
        if(!vm.rule.wordHighlighting){
          vm.rule.wordHighlighting = {enabled: false};
        }
      }
    }
  }

  angular
    .module('eva2-angular')
    .component('ruleEditor', {
      template: require('./ruleEditor.html'),
      controller: Controller,
      bindings: {
        headerText: '@',
        buttonText: '@',
        domain: '<',
        rule: '=',
        onSave: '&',
        onCancel: '&'
      }
    });
})();
