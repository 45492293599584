angular
  .module('eva2-angular')
  .constant('MatchedRulesAndHistoryModalTabIndex', {
    MATCHED_RULES: 1,
    HISTORY: 2
  });
angular
  .module('eva2-angular')
  .controller('RulesAndHistoryModalCtrl', function($scope, $modalInstance, history, rules, jobTitle, domain,
    MatchedRulesAndHistoryModalTabIndex, initialTabIndex) {
    'use strict';
    $scope.TAB = MatchedRulesAndHistoryModalTabIndex;
    $scope.history = history;
    $scope.rules = rules;
    $scope.jobTitle = jobTitle;
    $scope.domain = domain;
    $scope.dismissModal = () => {
      $modalInstance.dismiss();
    };
    $scope.currentTab = initialTabIndex || $scope.TAB.HISTORY;
    $scope.selectTab = (tabIndex) => {
      $scope.currentTab = tabIndex;
    };
  });
