import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').service('addInfoToImages', function(BASE_URL, getAugBoxesToOneImage) {
    function createImageServiceURL(domain, adId, urlInternalId) {
      return `${BASE_URL}/ads/${domain}/${adId}/image/${urlInternalId}`;
    }

    function isImageInfoValid(imageInfo) {
      return imageInfo
      && (imageInfo.crawlingStatus === 'success')
      && imageInfo.urlInternalId
      && imageInfo.width > 0
      && imageInfo.height > 0;
    }
    return function(images, imagesInfoContent, domain, adId, imageAug) {
      // add info to images
      // Remember to remove them in getDecisionData, so they won't be sent to clients.
      _.forEach(images, (item)=> {
        const matchedImageInfo = _.find(imagesInfoContent, (info) => info.url === item.src);
        if (!isImageInfoValid(matchedImageInfo)) {
          return;
        }
        item.imageServiceUrl = createImageServiceURL(domain, adId, matchedImageInfo.urlInternalId);
        item.originalSize = {width: matchedImageInfo.width, height: matchedImageInfo.height};
        item.phash = _.get(matchedImageInfo, 'phash.value');

        const matchedImageAug = _.find(imageAug, (imageAugItem) =>
          imageAugItem.urlInternalId === matchedImageInfo.urlInternalId);
        if (matchedImageAug) {
          item.augBoxes = getAugBoxesToOneImage(matchedImageAug);
        }      
      });
    };
  });
})();
