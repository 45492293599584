angular.module('eva2-angular').directive('evaHoverDropdown', function() {
  'use strict';

  return {
    restrict: 'CA',
    link: function(scope, element) {
      element.on('mouseenter', function() {
        $('.eva-keyboard-focusable.open').removeClass('open');
        element.addClass('open');
        element.find('input').focus();
        if (element && element.hasClass('eva-keyboard-focusable') && typeof element.setFocus === 'function') {
          element.setFocus();
        }
      });

      element.on('mouseleave', function() {
        element.removeClass('open');
      });
      scope.$on('$destroy', function() {
        element.off('mouseenter');
        element.off('mouseleave');
        element.remove();
      });
    }
  };
});
