"use strict";
// this the rewrite getTableCurrentDisplayedInfo angular service
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatPercentage = exports.formatTotal = exports.getRange = void 0;
var formatter_1 = require("./formatter");
var getRange = function (offset, limit) {
    if (Number.isNaN(offset) || Number.isNaN(limit)) {
        return '0';
    }
    return "".concat((0, formatter_1.thousandSeparator)(offset + 1), " - ").concat((0, formatter_1.thousandSeparator)(offset + limit));
};
exports.getRange = getRange;
var formatTotal = function (total) {
    return Number.isNaN(total) ? '0' : (0, formatter_1.thousandSeparator)(total);
};
exports.formatTotal = formatTotal;
var formatPercentage = function (value) {
    if (value === 0) {
        return '0%';
    }
    if (value < 0.1) {
        return '<0.1%';
    }
    return "".concat(value.toFixed(1), "%");
};
exports.formatPercentage = formatPercentage;
