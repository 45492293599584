import { unsafeCharacters } from '@common/constants';
import _ from 'lodash';
(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('SignupCtrl', SignupCtrl);

  function SignupCtrl($scope, ApiSignup, $state, CurrentUser, Notifier, $stateParams, Invite, goToDefault,
    TestRegex, RECAPTCHA_KEY, ApiService, tFilter, $timeout, storage, checkTeamCreationStatus) {
    'ngInject';
    const vm = this;

    const regions = [{
      id: 'europe',
      label: tFilter('SIGN_UP.REGIONS_LABEL.EUROPE')
    }, {
      id: 'south-america',
      label: tFilter('SIGN_UP.REGIONS_LABEL.LATIN_AMERICA')
    }, {
      id: 'north-america',
      label: tFilter('SIGN_UP.REGIONS_LABEL.NORTH_AMERICA')
    }, {
      id: 'asia-pacific',
      label: tFilter('SIGN_UP.REGIONS_LABEL.ASIA')
    }, {
      id: 'africa',
      label: tFilter('SIGN_UP.REGIONS_LABEL.AFRICA')
    }, {
      id: 'middle-east',
      label: tFilter('SIGN_UP.REGIONS_LABEL.MIDDLE_EAST')
    }, {
      id: 'oceania',
      label: tFilter('SIGN_UP.REGIONS_LABEL.OCEANIA')
    }];

    const resources = {
      error: {
        reCaptcha: tFilter('SIGN_UP.ERROR.VERIFY_NOT_ROBOT')
      },
      region: {
        label: tFilter('SIGN_UP.SERVICE_REGION_LABEL'),
        tooltip: tFilter('SIGN_UP.SERVICE_REGION_TOOLTIP')
      }
    };

    const teamname = $stateParams.inviteParams.teamname;
    const inviteToken = $stateParams.inviteParams.token;
    const inviteEmail = $stateParams.inviteParams.email;
    const inviteDomain = $stateParams.inviteParams.domain;
    const disallowHistoryBack = {location: 'replace'};
    const title =
    {
      default: tFilter('SIGN_UP.TITLE'),
      formInvite: tFilter('SIGN_UP.FROM_INVITE', {TEAM_NAME: teamname}, 'messageformat')
    };

    let isAccountCreatedPromise;

    vm.regions = regions;
    vm.resources = resources;
    vm.MAX_NAME_LENGTH = 30;
    vm.MAX_COMPANY_LENGTH = vm.MAX_NAME_LENGTH;

    vm.signup = {};
    vm.signup.selectedRegion = vm.regions[0];

    vm.emailRegex = TestRegex.email;
    vm.RECAPTCHA_KEY = RECAPTCHA_KEY;
    vm.loading = false;
    $scope.signupButtonMessage = tFilter('SIGN_UP.SIGN_UP_BTN' );
    $scope.unsafeCharacters=unsafeCharacters;
    
    ApiService.getUserLocation().then((data) => {
      const inferredRegionId = data && data.continentId;
      const inferredRegion = _.find(vm.regions, (region) => region.id === inferredRegionId);
      if (inferredRegion) {
        vm.signup.selectedRegion = inferredRegion;
      }
    });

    const onTeamCreationSuccess = (data) =>{

      vm.loading = false;
      // send event to Google Tag Manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmission'
      });
      // Auto-login user using auth data in response from API from signup POST request
      CurrentUser.logIn(data);

      if (inviteDomain) {
        $state.go('app.assignment.overview.statistics', {
          domain: inviteDomain,
          inviteParams: {inviteTeamName: teamname}
        }, disallowHistoryBack);
      } else {
        goToDefault({showIntroduction: true});
      }
    };

    const onTeamCreationFailure = (err) => {
      $scope.teamIsBeingCreated = false;
      if(err){
        Notifier.display(tFilter('SIGN_UP.ERROR.TEAM_CREATION_ERROR'), {type: 'error', ttl: 8000}, err);
      } else {
        Notifier.display(tFilter('SIGN_UP.ERROR.TEAM_CREATION_ERROR'), {type: 'error', ttl: 8000});
      }
      $state.go('app.login');
    };

    function onSignUpSuccess(createdTeamData) {
      const domain = createdTeamData.authenticated.domains[0].domain;
      checkTeamCreationStatus(domain,20, onTeamCreationSuccess, onTeamCreationFailure, createdTeamData);
    }



    function onSignUpFailure(err) {
      $scope.teamIsBeingCreated = false;
      grecaptcha.reset();
      $scope.error = true;

      if (!err) {
        return;
      }

      const nonAllowedUserDetails = err.status && (err.status === 409 || err.status === 400);
      if (nonAllowedUserDetails) {
        if (err.data.context === 'region') {
          vm.regionError = err.data.message;
        }
        return;
      }

      let msg = tFilter('SIGN_UP.ERROR.SERVER_ERROR');

      if (err.status === -1) {
        msg = tFilter('SIGN_UP.ERROR.CONNECT_ERROR');
      }

      Notifier.display(msg, {type: 'error', ttl: 8000});
    }

    if (inviteToken) {
      $scope.inviteEmail = inviteEmail;
      $scope.title = title.formInvite;
      $scope.fromInvite = true;
    } else {
      $scope.title = title.default;
      $scope.fromInvite = false;
    }

    const setLoadingToFalse = function() {
      vm.loading = false;
    };

    $scope.submit = function() {
      vm.loading = true;
      $scope.teamIsBeingCreated = true;
      if (inviteToken) {
        Invite
          .signup({name: vm.signup.name, password: vm.signup.password,
            captchaToken: vm.signup.myRecaptchaResponse, token: inviteToken, region: vm.signup.selectedRegion.id})
          .then(onSignUpSuccess)
          .catch(onSignUpFailure)
          .finally(setLoadingToFalse);
      } else {
        ApiSignup
          .signup(angular.copy(vm.signup))
          .then(onSignUpSuccess)
          .catch(onSignUpFailure)
          .finally(setLoadingToFalse);
      }
    };

    vm.updateRegion = (item) => {
      if (item !== vm.signup.selectedRegion) {
        vm.signup.selectedRegion = item;
        vm.regionError = null;
      }
    };

    vm.filterUnsafeCharactersOnBlur = (e)=> {
      let safe = e.target.value.replace(new RegExp(`[${unsafeCharacters}]`, 'g'), '');
      vm.signup[e.target.name]=safe;
    };

    $scope.$watch('teamIsBeingCreated', function(newVal, oldVal) {
      if (oldVal !== newVal && newVal) {
        $scope.signupButtonMessage = tFilter('SIGN_UP.TEAM_CREATION_TEXT');
      }
      if (oldVal !== newVal && newVal === false) {
        $scope.signupButtonMessage = tFilter('SIGN_UP.SIGN_UP_BTN');
      }
    });

    $scope.$on('$destroy', function(){
      if (isAccountCreatedPromise) {
        $timeout.cancel(isAccountCreatedPromise);
      }
    });
  }
})();
