/**
 * This directive compares two ng-model-controller's view value
 *
 * One use case is to compare "confirm password" and "new password".
 * Since new password has validators, when it has a invalid format, the model value will be undefined.
 * In that case, we need to compare confirm password's view value to new password's view value.
 */
(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('compareTo', compareTo);

  compareTo.$inject = [];

  function compareTo() {
    const directive = {
      require: 'ngModel',
      scope: {
        otherModelController: '<compareTo'
      },
      link: function(scope, element, attributes, ngModelCtrl) {
        ngModelCtrl.$validators.compareTo = function(modelValue, viewValue) {
          if (scope.otherModelController) {
            return viewValue === scope.otherModelController.$viewValue;
          }
          return false;
        };

        scope.$watch('otherModelController.$viewValue', function() {
          ngModelCtrl.$validate();
        });
      }
    };
    return directive;
  }
})();
