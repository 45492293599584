angular.module('eva2-angular').factory('RequestTrackerInterceptor', function RequestTrackerInterceptor($location, $q, RequestTracker) {
  'use strict';

  // FIXME once we have unified error output format, this crap will be deleted
  function guessErrorMessage(obj) {
    if (typeof obj === 'string' || obj instanceof Error) {
      return obj + '';
    } else if (obj.data && typeof obj.data === 'object') {
      if (obj.data.message) {
        return obj.data.message;
      } else if (obj.data.error && obj.data.error.message) {
        return obj.data.error.message;
      }
    }

    if (obj.statusText) {
      return obj.statusText;
    }

    return 'Unknown error';
  }

  function registerError(rejection) {
    return $q.reject(rejection);
  }

  return {
    guessErrorMessage: guessErrorMessage,
    request: function addTimeoutPromise(config) {
      if (!config.url || config.url.slice(-5) === '.html') {
        return config;
      }

      if (RequestTracker.isActive() && !config.timeout) {
        config.timeout = RequestTracker.newTimeout();
      }

      return config;
    },
    requestError: registerError,
    responseError: registerError
  };
});
