"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SIDEBAR = void 0;
exports.SIDEBAR = [
    {
        label: 'settings:SIDE_BAR.USER_SETTINGS_LABEL',
        states: [
            {
                label: 'settings:SIDE_BAR.CHANGE_PASSWORD_LINK',
                component: 'change-password',
            },
        ],
    },
    {
        label: 'settings:SIDE_BAR.TEAM_SETTINGS_LABEL',
        states: [
            {
                label: 'settings:SIDE_BAR.GENERAL_LINK',
                component: 'team-general',
            },
            {
                label: 'settings:SIDE_BAR.TEAM_MEMBERS_LINK',
                component: 'team-members',
            },
            {
                label: 'settings:SIDE_BAR.MODERATION_INTERFACE_LINK',
                component: 'manual-moderation-settings',
            },
            {
                label: 'settings:SIDE_BAR.FIELD_EDITING_LINK',
                component: 'field-editing',
            },
            {
                label: 'settings:SIDE_BAR.ORCHESTRATION_LINK',
                component: 'orchestration',
            },
            {
                label: 'settings:SIDE_BAR.SECURITY_LINK',
                component: 'security',
            },
            {
                label: 'settings:SIDE_BAR.API_INTEGRATION_LINK',
                component: 'api-integration',
            },
        ],
    },
    {
        label: 'settings:SIDE_BAR.DATA_AND_PRIVACY_LABEL',
        states: [
            {
                label: 'settings:SIDE_BAR.CSV_IMPORTER_LINK',
                component: 'app.assignment.csvImporter.step0',
            },
            {
                label: 'settings:SIDE_BAR.RIGHT_TO_BE_FORGOTTEN_LINK',
                component: 'right-to-be-forgotten',
            },
            {
                label: 'settings:SIDE_BAR.DATA_RETENTION_POLICY_LINK',
                component: 'data-storing-options',
            },
            {
                label: 'settings:SIDE_BAR.DELETE_ALL_CONTENT_LINK',
                component: 'delete-all-content',
            },
        ],
    },
];
