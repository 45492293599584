(function() {
  'use strict';
  angular.module('eva2-angular').service('submitDecisionForOneJob', function(ajax, getDecisionData) {
    /**
     * To submit decision for a job
     * @param job {object}
     * @param domain {string}
     * @returns {promise}
     */

    function submitDecisionForOneJob(job, domain, queueName, queueId) {
      const decisionData = getDecisionData(job);
      const decision = {...decisionData, queueName, queueId};
      return ajax.put(`/ads/${domain}/${decisionData.id}/decision`, decision).then((data) => ({ad: data}));
    }

    return submitDecisionForOneJob;
  });
})();
