module.exports = {
  local: {
    BASE_URL: '/api',
    APOLLO_URL: process.env.MINIKUBEIP ?
      `http://${process.env.MINIKUBEIP}:30000` :
      'http://MINIKUBE_IP:30000',
    DEPLOY_INFO: {env: 'local'},
    DEBUG_ENABLED: !!process.env.UI_DEBUG || false,
    Intercom: process.env.INTERCOM_ID || 'p73hv2gu',
    Sentry: 'https://8acb187fac564963828adaf1a19aca46@sentry.io/60174', // sentinel-dev
    reCaptcha: '6LebgBoUAAAAAJboRy9OEnZZo5UK-rRqvO11Ry7K',
    EXTERNAL_API_URL: process.env.MINIKUBEIP ?
      `http://${process.env.MINIKUBEIP}:30707/v1/ads` :
      'http://MINIKUBE_IP:30707/v1/ads'
  },
  // Devenv config
  dev: {
    BASE_URL: process.env.MINIKUBEIP ?
      `http://${process.env.MINIKUBEIP}:30000` :
      'http://MINIKUBE_IP:30000', // NOT USELESS: To be overriden by the deploy script for the devenv
    DEPLOY_INFO: {env: 'local'},
    DEBUG_ENABLED: !!process.env.UI_DEBUG || false,
    Intercom: process.env.INTERCOM_ID || 'p73hv2gu',
    Sentry: 'https://8acb187fac564963828adaf1a19aca46@sentry.io/60174', // sentinel-dev
    reCaptcha: '6LebgBoUAAAAAJboRy9OEnZZo5UK-rRqvO11Ry7K',
    EXTERNAL_API_URL: process.env.MINIKUBEIP ?
      `http://${process.env.MINIKUBEIP}:30707/v1/ads` :
      'http://MINIKUBE_IP:30707/v1/ads'
  },
  // TLS Devenv config
  devTls: {
    BASE_URL: process.env.MINIKUBEIP ?
      `https://${process.env.MINIKUBEIP}:32443/apollo` :
      'https://MINIKUBE_IP:32443/apollo', // NOT USELESS: To be overriden by the deploy script for the devenv
    DEPLOY_INFO: {env: 'local'},
    DEBUG_ENABLED: !!process.env.UI_DEBUG || false,
    Intercom: process.env.INTERCOM_ID || 'p73hv2gu',
    Sentry: 'https://8acb187fac564963828adaf1a19aca46@sentry.io/60174', // sentinel-dev
    reCaptcha: '6LebgBoUAAAAAJboRy9OEnZZo5UK-rRqvO11Ry7K',
    EXTERNAL_API_URL: process.env.MINIKUBEIP ?
      `https://${process.env.MINIKUBEIP}:32443/api/v1/ads` :
      'https://MINIKUBE_IP:32443/api/v1/ads'
  },
  staging: {
    BASE_URL: 'https://web-services.staging-implio.com',
    DEPLOY_INFO: {env: 'staging'},
    DEBUG_ENABLED: false,
    Intercom: 'p73hv2gu',
    Sentry: 'https://53b68f6af1e344abbff08320429a5d2f@sentry.io/141632', // eva-staging
    reCaptcha: '6LejgBoUAAAAAImRXeuJ8aI55fB35pUhG4k-ZG1m',
    EXTERNAL_API_URL: 'https://api.staging-implio.com/v1/ads'
  },
  production: {
    BASE_URL: 'https://web-services.implio.com',
    DEPLOY_INFO: {env: 'production'},
    DEBUG_ENABLED: false,
    Intercom: 'n1ohesnw',
    Sentry: 'https://293f9595cb33406180d45dbc4fa4bb7c@sentry.io/141646', // eva-production
    reCaptcha: '6LcyehoUAAAAAOiscgllT3SIr_wIsZMgjpFD-K5j',
    EXTERNAL_API_URL: 'https://api.implio.com/v1/ads',
  }
};
