"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addAxis = exports.transitionGrouped = exports.transitionStacked = exports.getXAxiScaleLabel = exports.getXScaleArr = exports.getYMax = exports.getY = exports.getColorScheme = exports.getTotalDecision = exports.getSeriesNum = exports.getDataToShowKeys = exports.DATE_FORMATS = exports.legendKeys = void 0;
var date_fns_1 = require("date-fns");
var d3_axis_1 = require("d3-axis");
var d3_array_1 = require("d3-array");
var number_1 = require("@utils/number");
var Graph_1 = require("@features/Statistic/components/Graph");
exports.legendKeys = [
    'totalDecisions',
    'acceptedAuto',
    'acceptedManual',
    'rejectedAuto',
    'rejectedManual',
    'noDecisionAuto',
    'noDecisionManual',
];
exports.DATE_FORMATS = {
    hour: 'MMM dd HH:mm',
    day: 'MMM d',
    month: 'yyyy MMM',
};
var getDataToShowKeys = function (dataToShow) {
    return Object.keys(dataToShow);
};
exports.getDataToShowKeys = getDataToShowKeys;
var getSeriesNum = function (dataToShow) {
    var keys = (0, exports.getDataToShowKeys)(dataToShow);
    return keys.reduce(function (currentValue, key) { return (dataToShow[key] ? 1 : 0) + currentValue; }, 0);
};
exports.getSeriesNum = getSeriesNum;
var getTotalDecision = function (inputData) {
    return inputData.map(function (item) { return ({
        acceptedAuto: item.acceptedAuto,
        rejectedAuto: item.rejectedAuto,
        acceptedManual: item.acceptedManual,
        rejectedManual: item.rejectedManual,
        noDecisionAuto: item.noDecisionAuto,
        noDecisionManual: item.noDecisionManual,
        totalDecisions: item.acceptedAuto +
            item.rejectedAuto +
            item.acceptedManual +
            item.rejectedManual +
            item.noDecisionAuto +
            item.noDecisionManual,
    }); });
};
exports.getTotalDecision = getTotalDecision;
var getColorScheme = function (graphColorScheme, dataToShow) {
    var colorScheme = [];
    var keys = (0, exports.getDataToShowKeys)(graphColorScheme);
    keys.forEach(function (key) {
        if (dataToShow[key])
            colorScheme.push(graphColorScheme[key]);
    });
    return colorScheme;
};
exports.getColorScheme = getColorScheme;
var getY = function (dataToShow, graphData) {
    var temp = {
        acceptedManual: [],
        acceptedAuto: [],
        totalDecisions: [],
        rejectedAuto: [],
        rejectedManual: [],
        noDecisionAuto: [],
        noDecisionManual: [],
    };
    exports.legendKeys.forEach(function (key) {
        temp[key] = [];
    });
    var getSeriesArr = function (item, temp) {
        exports.legendKeys.forEach(function (key) {
            if (dataToShow[key]) {
                temp[key].push(item[key]);
            }
        });
    };
    var getArrForY = function (data) {
        var arr = [];
        exports.legendKeys.forEach(function (key) {
            if (dataToShow[key]) {
                arr.push(data[key]);
            }
        });
        return arr;
    };
    graphData &&
        graphData.forEach(function (item) {
            getSeriesArr(item, temp);
        });
    return getArrForY(temp);
};
exports.getY = getY;
var getYStack = function (dataToShow, y, d) {
    if (dataToShow.totalDecisions && d[1] - d[0] === Math.max.apply(Math, __spreadArray([], __read(d.data), false))) {
        // if it is receive
        return y(d[1] - d[0]);
    }
    return y(d[1]);
};
// max y aixs differ when totalDecisions exists, because of the tricky totalDecisions status in stacked
var getYMax = function (dataToShow, yz, y01z) {
    // max y aixs differ when totalDecisions exists, because of the tricky totalDecisions status in stacked
    var yMax;
    if (dataToShow.totalDecisions) {
        yMax = Number((0, d3_array_1.max)(yz, function (y) {
            return (0, d3_array_1.max)(y);
        }));
    }
    else {
        yMax = (0, d3_array_1.max)(y01z, function (y) {
            return (0, d3_array_1.max)(y, function (d) {
                return d[1];
            });
        });
    }
    return yMax;
};
exports.getYMax = getYMax;
var getXScaleArr = function (n) {
    var arr = [];
    var i = 0;
    while (i < n) {
        arr.push(i);
        i++;
    }
    return arr;
};
exports.getXScaleArr = getXScaleArr;
var getXAxiScaleLabel = function (data, staples, aggregationInterval, i, dataLength) {
    var numberOfStaples = staples;
    var interval = dataLength > numberOfStaples ? Math.ceil(dataLength / numberOfStaples) : 1;
    return i % interval === 0 && data[i].date ? (0, date_fns_1.format)(new Date(data[i].date || 0), exports.DATE_FORMATS[aggregationInterval]) : '';
};
exports.getXAxiScaleLabel = getXAxiScaleLabel;
var transitionStacked = function (dataToShow, d3c, duration) {
    if (duration === void 0) { duration = 0; }
    d3c.y.domain([0, d3c.yMax]);
    d3c.rect
        .transition()
        .duration(duration)
        .delay(function (_, i) {
        return i * 10;
    })
        .attr('y', function (d) {
        return getYStack(dataToShow, d3c.y, d);
    })
        .attr('height', function (d) {
        return d3c.y(d[0]) - d3c.y(d[1]);
    })
        .transition()
        .attr('x', function (_, i) {
        return d3c.x(i) || 0;
    })
        .attr('width', d3c.x.bandwidth());
};
exports.transitionStacked = transitionStacked;
var transitionGrouped = function (d3c, seriesLength, duration) {
    if (duration === void 0) { duration = 0; }
    d3c.y.domain([0, d3c.yMax]);
    d3c.rect
        .transition()
        .duration(duration)
        .delay(function (_, i) {
        return i * 10;
    })
        .attr('x', function (_, i) {
        var _a, _b;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore ParentNode type don't contains "___data___"
        var seriesNumber = ((_b = (_a = this === null || this === void 0 ? void 0 : this.parentNode) === null || _a === void 0 ? void 0 : _a.__data__) === null || _b === void 0 ? void 0 : _b.key) || 0;
        return (d3c.x(i) || 0) + (d3c.x.bandwidth() / seriesLength) * seriesNumber;
    })
        .attr('width', d3c.x.bandwidth() / seriesLength)
        .transition()
        .attr('y', function (d) {
        return d3c.y(d[1] - d[0]);
    })
        .attr('height', function (d) {
        return d3c.y(0) - d3c.y(d[1] - d[0]);
    });
};
exports.transitionGrouped = transitionGrouped;
var addAxis = function (d3, data, staples, aggregationInterval, dataLength) {
    // gridlines in y axis function
    var makeYGridlines = function () {
        return (0, d3_axis_1.axisLeft)(d3.y).ticks(5);
    };
    // add the Y gridlines
    d3.svg
        .append('g')
        .attr('class', 'grid')
        .attr('transform', "translate(".concat(Graph_1.margin.left, ",").concat(Graph_1.margin.top, ")"))
        .call(makeYGridlines()
        .tickSize(-d3.width)
        .tickFormat(function () { return ''; }));
    // add x axis
    d3.g
        .append('g')
        .attr('class', 'axis axis--x')
        .attr('transform', "translate(0,".concat(d3.height, ")"))
        .call((0, d3_axis_1.axisBottom)(d3.x)
        .tickSize(0)
        .tickFormat(function (d) {
        return (0, exports.getXAxiScaleLabel)(data, staples, aggregationInterval, Number(d), dataLength);
    })
        .tickPadding(6));
    // add y axis
    d3.g
        .append('g')
        .attr('class', 'axis axis--y')
        .call((0, d3_axis_1.axisLeft)(d3.y)
        .tickSize(5)
        .ticks(5)
        .tickFormat(function (d) {
        return (0, number_1.bigNumberFormatter)(Number(d)).toString();
    }));
};
exports.addAxis = addAxis;
