angular.module('eva2-angular').service('Analytics', function Analytics(Intercom) {
  'use strict';

  this.initialize = function initialize() {
    Intercom.initialize();
  };

  this.event = function event(category, action, label) {
    Intercom.track(`${action} ${category}`, {
      name: label
    });
  };

  this.pageview = function pageview(url, title, params) {
    Intercom.pageview(url, title, params);
  };
});
