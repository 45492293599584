(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('VerifyTokenCtrl', VerifyTokenCtrl);

  VerifyTokenCtrl.$inject = ['$scope', '$state', '$stateParams', '$timeout', 'ApiPassword'];

  function VerifyTokenCtrl($scope, $state, $stateParams, $timeout, ApiPassword) {
    const vm = this;

    vm.token = $stateParams.sptoken;

    vm.verifyToken = () => {
      if (!vm.token) {
        vm.failure = true;
        $scope.loading = false;
        return;
      }

      ApiPassword.verifyPasswordToken({token: vm.token})
        .then(() => {
          $state.go('app.newPassword', {token: vm.token});
        })
        .catch(() => {
          vm.failure = true;
        });
    };

    vm.verifyToken();
  }
})();
