'use strict';

angular
  .module('eva2-angular.user')
  .service('APP_USER', APP_USER);

function APP_USER(getUserBySessionToken) {
  'ngInject';

  const service = {populate, reset};
  reset();

  function populate() {
    return getUserBySessionToken()
      .then(function(data) {
        service.loggedIn = true;
        service.authData = data;
      });
  }

  function reset() {
    service.loggedIn = false;
  }

  return service;
}
