angular.module('eva2-angular').filter('filterBy', function() {
  'use strict';

  return function(array, which, query) {
    if (Array.isArray(array) === false || typeof which !== 'string' || typeof query !== 'string') {
      return array;
    }
    if (which.length <= 0 || query.length <= 0) {
      return array;
    }
    query = new RegExp('^' + query.replace(/([^\d])/g, '\\$1'));
    return array.filter(function(value) {
      const item = value[which] || '';
      return item.match(query) !== null;
    });
  };
});
