"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// TODO (@maciek.glowacki): this validator could be removed after it is no longer used inside angularJs
function validatePasswordFormat(password) {
    return (typeof password === 'string' &&
        password.length >= 8 &&
        /\d/.test(password) &&
        /[A-Za-z]/.test(password) &&
        /[^\dA-Za-z]/.test(password));
}
exports.default = validatePasswordFormat;
