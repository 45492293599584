angular.module('eva2-angular')
  .directive('startLogo', function() {
    'use strict';

    return {
      restrict: 'E',
      replace: false,
      template: require('./startLogo.html'),
      link: function() {
      }
    };
  });
