(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('evaPagination', evaPagination);

  evaPagination.$inject = [];

  function evaPagination() {
    return {
      restrict: 'E',
      replace: true,
      controller: Controller,
      controllerAs: 'vm',
      bindToController: {},
      template: require('./pagination.html'),
      scope: {
        data: '<',
        paginate: '&'
      }
    };
  }

  Controller.$inject = [
    '$scope'
  ];

  function Controller($scope) {
    const vm = this;

    function getState(data) {
      const {loading: isLoading, next, prev, total} = data;

      return {
        isLoading,
        next,
        prev,
        total
      };
    }

    function update(data) {
      angular.extend(vm, getState(data || {}));
    }

    $scope.$watch('data', function(newData, oldData) {
      if (newData === oldData) {
        return;
      }
      update(newData);
    });
  }
})();
