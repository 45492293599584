"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateRangePicker = void 0;
var react_1 = __importStar(require("react"));
var date_fns_1 = require("date-fns");
var react_i18next_1 = require("react-i18next");
var date_1 = require("@utils/date");
var DatePicker_1 = require("./DatePicker");
var Timezone_1 = require("./Timezone");
function DateRangePicker(props) {
    var _a, _b;
    var shouldReload = props.shouldReload, defaultFromValue = props.defaultFromValue, defaultToValue = props.defaultToValue, defaultMinValue = props.defaultMinValue, defaultMaxValue = props.defaultMaxValue, dateFormat = props.dateFormat, allowNull = props.allowNull, onChangeCb = props.onChangeCb;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _c = __read(react_1.default.useState(defaultFromValue), 2), fromDate = _c[0], setFromDate = _c[1];
    var _d = __read(react_1.default.useState(defaultToValue), 2), toDate = _d[0], setToDate = _d[1];
    (0, react_1.useEffect)(function () {
        defaultToValue && setToDate(defaultToValue);
        defaultFromValue && setFromDate(defaultFromValue);
    }, [defaultFromValue, defaultToValue, shouldReload]);
    (0, react_1.useEffect)(function () {
        var defaultNow = allowNull ? undefined : new Date();
        var from = fromDate || (!allowNull && defaultFromValue) || defaultNow;
        var to = toDate || (!allowNull && defaultToValue) || defaultNow;
        if ((to && from && (0, date_fns_1.isAfter)(to, from)) || allowNull) {
            onChangeCb && onChangeCb(from, to && (0, date_fns_1.set)(to, { seconds: 59, milliseconds: 999 }));
        }
    }, [fromDate, toDate]);
    var maxFromDate = (0, react_1.useMemo)(function () {
        var now = new Date();
        if (toDate) {
            return (0, date_fns_1.isAfter)(toDate, now) ? now : toDate;
        }
        return defaultToValue;
    }, [toDate]);
    var minFromDate = (0, react_1.useMemo)(function () {
        if (toDate && defaultMinValue && !allowNull) {
            var toMinus2Years = (0, date_fns_1.sub)(toDate, { years: 2 });
            return (0, date_fns_1.isBefore)(toMinus2Years, defaultMinValue) ? defaultMinValue : toMinus2Years;
        }
        return defaultMinValue;
    }, [toDate]);
    var maxToDate = (0, react_1.useMemo)(function () {
        if (fromDate && defaultMaxValue) {
            var fromPlus2Years = (0, date_fns_1.add)(fromDate, { years: 2 });
            return (0, date_fns_1.isAfter)(fromPlus2Years, defaultMaxValue) ? defaultMaxValue : fromPlus2Years;
        }
    }, [fromDate]);
    return (react_1.default.createElement("div", { className: "date-range-picker" },
        react_1.default.createElement(DatePicker_1.DatePicker, { name: "from", defaultValue: defaultFromValue, dateFormat: dateFormat, minValue: minFromDate, maxValue: allowNull ? toDate || defaultMaxValue : maxFromDate, onChangeCb: setFromDate, allowNull: false, placeholder: (_a = t('itemLog:SELECT_START_DAY_PLACEHOLDER')) !== null && _a !== void 0 ? _a : '', defaultTime: __assign({ hours: 0, minutes: 0 }, date_1.ZERO_SECONDS), shouldReload: shouldReload || undefined }),
        react_1.default.createElement("div", { className: "date-picker-line" }, "to"),
        react_1.default.createElement(DatePicker_1.DatePicker, { name: "to", defaultValue: defaultToValue, dateFormat: dateFormat, minValue: fromDate || defaultMinValue, maxValue: allowNull ? defaultMaxValue : maxToDate, onChangeCb: setToDate, allowNull: allowNull, placeholder: (_b = t('itemLog:SELECT_END_DAY_PLACEHOLDER')) !== null && _b !== void 0 ? _b : '', defaultTime: __assign({ hours: 23, minutes: 59 }, date_1.FIFTY_NINE_SECONDS), shouldReload: shouldReload || undefined }),
        react_1.default.createElement("div", { className: "date-picker-line timezone" },
            react_1.default.createElement(Timezone_1.Timezone, null))));
}
exports.DateRangePicker = DateRangePicker;
