angular.module('eva2-angular').service('RequestTracker', function RequestTracker($q) {
  'use strict';
  let active = false;
  let timeouts = [];

  this.activate = function activate() {
    active = true;
  };

  this.clearAll = function clearAll() {
    timeouts.forEach((timeout) => timeout.resolve('State change'));
    timeouts = [];
  };

  this.isActive = function isActive() {
    return active;
  };

  this.newTimeout = function newTimeout() {
    const deferred = $q.defer();
    timeouts = timeouts.concat(deferred);
    return deferred.promise;
  };
});
