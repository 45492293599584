(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);

  appConfig.$inject = ['$stateProvider'];

  function appConfig($stateProvider) {
    $stateProvider.state('app.assignment.manual', {
      url: '/manual',
      abstract: true,
      views: {
        'content@app': {
          template: `<div class="manual container" ui-view>
          </div>`,
          controllerAs: 'vm'
        }
      }
    })
      .state('app.assignment.manual.queues', {
        url: '/',
        controllerAs: 'vm',
        controller: 'manualQueuesCtrl',
        template: require('./manualQueues.html'),
      })
      .state('app.assignment.manual.queue', {
        url: '/:queueId/',
        template: require('@partial/jobs/jobs.html'),
        controller: 'EvaJobsCtrl',
        controllerAs: 'vm'
      });
  }
})();

