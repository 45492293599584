(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('PasswordResetCtrl', PasswordResetCtrl);

  PasswordResetCtrl.$inject = ['$stateParams', '$scope', 'ApiPassword'];

  function PasswordResetCtrl($stateParams, $scope, ApiPassword) {
    const vm = this;
    vm.email = $stateParams.email || '';

    vm.requestPasswordResetEmail = () => {
      $scope.loading = true;

      ApiPassword.requestPasswordResetEmail({email: vm.email})
        .then(() => {
          vm.success = true;
        })
        .catch(() => {
          vm.failure = true;
        })
        .finally(() => {
          $scope.loading = false;
        });
    };
  }
})();
