(function() {
  'use strict';
  angular.module('eva2-angular').service('isFeatureEnabled', function(FEATURE_TOGGLE_CONFIG, DEPLOY_INFO) {
    return function(featureName) {
      if (DEPLOY_INFO.env === 'staging' || DEPLOY_INFO.env === 'production') {
        return FEATURE_TOGGLE_CONFIG[featureName][DEPLOY_INFO.env];
      }
      return true;
    };
  });
})();
