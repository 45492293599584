(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('dictionaryItemEditor', filterList);

  function filterList() {
    const directive = {
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      restrict: 'E',
      template: require('./dictionaryItemEditor.html'),
      scope: {
        dictionary: '='
      }
    };
    return directive;
  }
  function Controller($modal, regexValidator, Notifier, $timeout, tFilter) {
    const vm = this;
    vm.addItem = addItem;
    vm.newItem = '';
    vm.addToItems = addToItems;
    vm.deleteItem = deleteItem;
    vm.hasFocus = false;
    vm.importItems = importItems;
    vm.regexValidator = regexValidator.isRegExp;

    function getValidationError(item) {
      if (!item) {
        return tFilter('AUTOMATION.LISTS.ERROR.CANNOT_EMPTY');
      }
      const exists = vm.dictionary.items.indexOf(item) !== -1;
      const isRegExp = regexValidator.isRegExp(item);
      if (!isRegExp && exists) {
        return tFilter('AUTOMATION.LISTS.ERROR.ALREADY_EXIST');
      }
      const lowerCaseItem = item.toLowerCase();
      const existsInsensitive = vm.dictionary.items.some((element) => element.toLowerCase() === lowerCaseItem);

      if (!isRegExp && existsInsensitive) {
        return tFilter('AUTOMATION.LISTS.ERROR.EXIST_CASE_SENSITIVE');
      }
      if (isRegExp && exists) {
        return tFilter('AUTOMATION.LISTS.ERROR.EXPR_EXIST');
      }
      if (isRegExp && !regexValidator.isValidRegExp(item)) {
        return tFilter('AUTOMATION.LISTS.ERROR.EXPR_INVALID');
      }
      return 0;
    }

    function addToItems(item) {
      return vm.dictionary.items.push(item);
    }

    function addItem() {
      const item = vm.newItem.trim();
      vm.hasFocus = false;

      const error = getValidationError(item);
      if (!error) {
        addToItems(item);
        vm.newItem = '';
      } else {
        vm.newItemError = error;
      }

      $timeout(() => {
        vm.hasFocus = true;
      });
    }

    function importItems() {
      $modal.open({
        template: require('@partial/modals/importDictionaryItem/importDictionaryItem.html'),
        controller: 'importDictionaryItemCtrl',
        size: 'md',
        backdrop: 'static'
      }).result.then((items) => {
        let addedItems = 0;
        items.forEach((item) => !getValidationError(item) && addToItems(item) && addedItems++);
        if (addedItems > 0) {
          Notifier.display(
            tFilter('AUTOMATION.LISTS.LIST_BUILDER.ITEM_IMPORTED_TEXT', {addedItems: addedItems}, 'messageformat'),
            {type: 'success', ttl: 2000}
          );
        }
      });
    }

    function deleteItem(item) {
      const indexOfItem = vm.dictionary.items.indexOf(item);
      vm.dictionary.items.splice(indexOfItem, 1);
    }
  }
})();
