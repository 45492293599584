(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('AdminDictionaryCreateCtrl', AdminDictionaryCreateCtrl);

  function AdminDictionaryCreateCtrl($stateParams, $state, Notifier, ApiDictionaries, Analytics, tFilter) {
    const vm = this;
    vm.dictionary = {items: []};
    vm.create = create;
    vm.afterCreate = afterCreate;

    activate();

    function create() {
      ApiDictionaries
        .create({domain: $stateParams.domain}, vm.dictionary)
        .then(afterCreate)
        .catch(whenErrorSaving);
    }

    function whenErrorSaving(err) {
      // todo: check for duplicate name before we save
      // since we don't have support for checking if a list name already exists,
      // we only catch the error after we have tried to save the list.
      if (err.status === 401) {
        return;
      }
      if (err.status === 409) {
        Notifier.display(tFilter('AUTOMATION.LISTS.ERROR.LIST_NAME_EXISTS'), {type: 'error', ttl: 1800});
      } else {
        Notifier.display(tFilter('AUTOMATION.LISTS.ERROR.SAVE_ERROR'), {type: 'failure', ttl: 6000});
      }
    }

    function afterCreate() {
      Analytics.event('list', 'create', vm.dictionary.name);

      Notifier.display(tFilter('AUTOMATION.LISTS.LIST_BUILDER.SUCCEED_CREATE'), {type: 'success', ttl: 1400});
      $state.go('app.assignment.automation.dictionaries', {domain: $stateParams.domain});
    }

    function activate() {
    }
  }
})();
