"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
function Modal(_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, children = _a.children;
    var handleKeyDown = (0, react_1.useCallback)(function (event) {
        if (event.key === 'Escape') {
            setIsOpen(false);
        }
    }, []);
    (0, react_1.useEffect)(function () {
        document.addEventListener('keydown', handleKeyDown);
        return function () {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null, isOpen && (react_1.default.createElement("div", { className: "custom-modal" },
        react_1.default.createElement("div", { className: "custom-modal-content" },
            react_1.default.createElement("i", { className: "implioicons-close rules-and-history-modal__close-btn", onClick: function () { return setIsOpen(false); } }),
            children)))));
}
exports.default = Modal;
