import Search from '@features/Search';
import store from '@store';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { Provider } from 'react-redux';

(function() {
  'use strict';

  function Controller($state, $stateParams, UserPermissionManager,
    $timeout, CurrentUser, EvaLogger) {
    'ngInject';
    const vm = this;
    const root = createRoot(document.querySelector('#search'));


    vm.$onInit = function() {
      renderSearch();
    };

    vm.$onDestroy = function() {
      root.unmount();
    };

    const renderSearch = () => {
      $timeout(() => {
        root.render(
          <Provider store={store}>
            <Search  domain={$stateParams.domain}
              angularJsDependencies={
                {
                  href: $state.href,
                  userPermissionManager: UserPermissionManager,
                  go: $state.go,
                  EvaLogger,
                }
              }
              currentUser={CurrentUser}
            />
          </Provider>
        );
      });
    };
  }

  angular
    .module('eva2-angular')
    .component('evaSearch', {
      template: require('./search.html'),
      controller: Controller,
      controllerAs: 'vm'
    });
})();
