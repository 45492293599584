// TO DO verify if this component is still used
(function() {
  'use strict';

  function Controller(tFilter, $scope, $timeout) {
    'ngInject';
    const vm = this;

    vm.$onInit = () => {
      vm.approved = [];
      vm.refused = [];
      vm.reviewable = [];
      vm.other = [];
      vm.categories = [
        {name: tFilter('ITEM.DUPLICATE_MODAL.CATEGORY.APPROVED'), value: vm.approved},
        {name: tFilter('ITEM.DUPLICATE_MODAL.CATEGORY.REFUSED'), value: vm.refused},
        {name: tFilter('ITEM.DUPLICATE_MODAL.CATEGORY.REVIEWABLE'), value: vm.reviewable},
        {name: tFilter('ITEM.DUPLICATE_MODAL.CATEGORY.OTHER'), value: vm.other}
      ];
    };

    function groupJobsByStatus(jobs) {
      jobs.forEach((job) => {
        if (job.ad.current.meta.status === 'approved') {
          vm.approved.push(job);
          return;
        }
        if (job.ad.current.meta.status === 'refused') {
          vm.refused.push(job);
          return;
        }
        if (job.ad.current.meta.status === 'reviewable') {
          vm.reviewable.push(job);
          return;
        }
        vm.other.push(job);
      });
    }
    $scope.$watch('vm.duplicateJobs', ()=> {
      if (vm.duplicateJobs) {
        $timeout(() => groupJobsByStatus(vm.duplicateJobs));
      }
    });
  }

  angular
    .module('eva2-angular')
    .component('newDuplicateJobs', {
      template: require('./duplicateJobs.html'),
      controller: Controller,
      bindings: {
        duplicateJobs: '<'
      }
    });
})();
