(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);

  appConfig.$inject = ['$stateProvider'];

  function appConfig($stateProvider) {
    $stateProvider.state('app.passwordReset', {
      url: '/password_reset',
      data: {
        title: 'PAGE_TITLE.RESET_PASSWORD',
        transitionPolicy: {
          requireAuth: false,
          allowLoggedIn: true
        }
      },
      params: {
        email: null
      },
      views: {
        'main@': {
          template: require('@partial/start/start.html')
        },
        'content@app.passwordReset': {
          template: require('./passwordReset.html'),
          controller: 'PasswordResetCtrl',
          controllerAs: 'vm'
        }
      }
    })
      .state('app.verifyToken', {
        url: '/password_reset/verify?sptoken',
        data: {
          title: 'PAGE_TITLE.RESET_PASSWORD',
          transitionPolicy: {
            requireAuth: false,
            allowLoggedIn: true
          }
        },
        views: {
          'main@': {
            template: require('@partial/start/start.html')
          },
          'content@app.verifyToken': {
            template: require('./verifyToken.html'),
            controller: 'VerifyTokenCtrl',
            controllerAs: 'vm'
          }
        }
      })
      .state('app.newPassword', {
        url: '/password_reset/new_password',
        data: {
          title: 'PAGE_TITLE.SET_NEW_PASSWORD',
          transitionPolicy: {
            requireAuth: false,
            allowLoggedIn: true
          }
        },
        params: {
          token: null
        },
        views: {
          'main@': {
            template: require('@partial/start/start.html')
          },
          'content@app.newPassword': {
            template: require('./newPassword.html'),
            controller: 'NewPasswordCtrl',
            controllerAs: 'vm'
          }
        }
      });
  }
})();
