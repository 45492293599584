import _ from 'lodash';

function Controller(defineBlangMode, $scope) {
  'use strict';
  const vm = this;
  const sortOrder = ['APPROVE', 'REFUSE', 'MANUAL', 'NOTHING'];
  defineBlangMode();

  vm.expressionCount = {
    readOnly: true,
    mode: 'blang',
    matchBrackets: true,
    lineNumbers: true,
    lineWrapping: true,
    viewportMargin: Infinity
  };

  vm.toggleRuleView = function(rule) {
    rule.opened = !rule.opened;
  };
  $scope.$watch('$ctrl.rules', ()=> {
    vm.sortedRules = _.sortBy(_.cloneDeep(vm.rules), (item)=> {
      return sortOrder.indexOf(item.result);
    });
  });
}
angular
  .module('eva2-angular')
  .component('rules',
    {
      template: require('./rules.html'),
      controller: Controller,
      bindings: {
        rules: '<',
        domain: '<'
      },
    });