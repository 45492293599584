"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.baseApi = void 0;
var react_1 = require("@reduxjs/toolkit/query/react");
var apiService_1 = require("./apiService");
exports.baseApi = (0, react_1.createApi)({
    reducerPath: 'api',
    baseQuery: (0, apiService_1.axiosBaseQuery)({
        baseUrl: BASE_URL,
        withCredentials: true,
    }),
    endpoints: function () { return ({}); },
});
