"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnterRouteRequirement = void 0;
exports.EnterRouteRequirement = {
    // filters (rules)
    'app.assignment.automation.filters': [{ operation: 'automation:rules', requireWriteAccess: true }],
    // filter (rule)
    'app.assignment.automation.filterCreate': [{ operation: 'automation:rule', requireWriteAccess: true }],
    'app.assignment.automation.filterEdit': [{ operation: 'automation:rule', requireWriteAccess: true }],
    'app.assignment.automation.filterView': [{ operation: 'automation:rule', requireWriteAccess: false }],
    // dictionary (list)
    'app.assignment.automation.dictionaryCreate': [{ operation: 'automation:lists', requireWriteAccess: true }],
    'app.assignment.automation.dictionaryEdit': [{ operation: 'automation:lists', requireWriteAccess: true }],
    // dictionaries (lists)
    'app.assignment.automation.dictionaries': [{ operation: 'automation:lists', requireWriteAccess: true }],
    // team settings
    'app.assignment.settings.teamMembers': [
        { operation: 'list:users', requireWriteAccess: false },
        { operation: 'invite:users', requireWriteAccess: true },
    ],
    'app.assignment.settings.apiIntegration': [{ operation: 'api:settings', requireWriteAccess: true }],
    'app.assignment.settings.security': [{ operation: 'manual:settings', requireWriteAccess: true }],
    'app.assignment.settings.manualModerationSettings': [{ operation: 'manual:settings', requireWriteAccess: true }],
    'app.assignment.settings.fieldEditing': [{ operation: 'manual:settings', requireWriteAccess: true }],
    'app.assignment.settings.orchestration': [{ operation: 'api:settings', requireWriteAccess: true }],
    'app.assignment.settings.teamGeneral': [{ operation: 'api:settings', requireWriteAccess: true }],
    // manual moderation
    'app.assignment.ad': [{ operation: 'moderate:documents', requireWriteAccess: true }],
    'app.assignment.manual.queues': [{ operation: 'moderate:documents', requireWriteAccess: true }],
    'app.assignment.manual.queue': [{ operation: 'moderate:documents', requireWriteAccess: true }],
    // CSV importer
    'app.assignment.csvImporter.step0': [{ operation: 'import:documents', requireWriteAccess: true }],
    'app.assignment.csvImporter.step1': [{ operation: 'import:documents', requireWriteAccess: true }],
    'app.assignment.csvImporter.step2': [{ operation: 'import:documents', requireWriteAccess: true }],
    'app.assignment.overview.statistics': [{ operation: 'dashboard', requireRoleHigherThanNoAccess: true }],
    'app.assignment.overview.recentItems': [{ operation: 'search:overview', requireWriteAccess: false }],
    // Content deletion
    'app.assignment.settings.deleteTeam': [{ operation: 'data:deletion', requireWriteAccess: true }],
    'app.assignment.settings.deleteAllContent': [{ operation: 'data:deletion', requireWriteAccess: true }],
    'app.assignment.settings.deleteUserData': [{ operation: 'data:deletion', requireWriteAccess: true }],
    'app.assignment.settings.dataStoringOptions': [{ operation: 'data:deletion', requireWriteAccess: true }],
};
