angular.module('eva2-angular').service('regexValidator', function() {
  'use strict';

  const RegexValidator = () => {};

  const looksLikeRegExp = /^\/(.+)\/([a-zA-Z]*)$/;
  const validRegExp = /^\/(.+)\/((i|m|g){0,3})$/;

  RegexValidator.prototype.isValidRegExp = (regExp) => {
    // check general syntax with modifiers
    if (regExp.match(validRegExp) === null) {
      return false;
    }

    // check that regex is actually valid
    try {
      const regExpMatches = regExp.match(validRegExp);
      const regExpMatchPart = regExpMatches[1];
      const regExpFlagPart = regExpMatches[2];

      const regexp = new RegExp(regExpMatchPart, regExpFlagPart);
      ''.match(regexp);
      return true;
    } catch (err) {
      return false;
    }
  };

  RegexValidator.prototype.isRegExp = (regExp) => regExp.match(looksLikeRegExp) !== null;

  return new RegexValidator();
});
