(function() {
  'use strict';
  
  angular
    .module('eva2-angular')
    .directive('videoSrc',
      function () {
        return {
          restrict: 'A',
          scope: {
            requestUrl: '<',
          },
          link: function (scope, elem) {
            scope.$watch('requestUrl', function (newVal) {
              if (typeof newVal !== 'string') {
                return;
              }
              elem.attr('src', newVal);
            });
          }
        };
      }
    );
})();