'use strict';

angular.module('eva2-angular').filter('extractFilterVote', function() {
  function lowerCaseNullSafe(str) {
    if (!str) {
      return str;
    }

    return str.toLowerCase();
  }

  /**
   * Takes an ad's processingResults as input and attempts to find entry in array with same id as another expected provided value additional.filterId.
   * Returns string detailing the result and vote made by the specific matching ad.
   */
  return function(input, additional) {
    if (!input) {
      return;
    }

    if (!input || !Array.isArray(input)) {
      return 'No history provided for item, unable to retrieve what rule voted';
    }

    const processingResults = input;
    const currentFilterId = additional.filterId;

    const resultForCurrentFilter = processingResults.find(function(candidate) {
      return candidate.id === currentFilterId;
    });

    if (!resultForCurrentFilter) {
      return 'No details on current rule\'s vote found in item\'s history';
    }
    if (resultForCurrentFilter.decisionName) {
      return lowerCaseNullSafe(resultForCurrentFilter.result) + ': ' + resultForCurrentFilter.decisionName;
    }
    return lowerCaseNullSafe(resultForCurrentFilter.result);
  };
});
