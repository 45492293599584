import _ from 'lodash';

(function() {
  'use strict';

  function Controller(tFilter, getFullJobStatusFilter, $scope, durationFilter, getCurrentQueueName) {
    'ngInject';

    const vm = this;

    const getDurationText = (timeArray) => {
      // convert time array to a string, e.g "*d *h *m *s"
      const unitArray = ['d', 'h', 'm', 's'];
      return timeArray.reduce((prevValue, currentValue, currentIndex) =>
        currentValue === 0 ? prevValue : prevValue + ' ' + currentValue + unitArray[currentIndex], '') || '0s';
    };
    $scope.$watch('$ctrl.history', ()=> {
      vm.sortedHistory = _.sortBy(vm.history, ['timestamp']);
      _.forEach(vm.sortedHistory, (item, index)=> {
        item.event = getHistoryStatus(item);
        item.timeToPreviousItem = index - 1 >= 0 ?
          `(+${durationFilter(
            moment(item.timestamp).milliseconds(0)
              .diff(moment(vm.sortedHistory[index-1].timestamp).milliseconds(0)), false, getDurationText)})`
          : '';
      });
    });

    function getHistoryStatus(item) {
      const queueName = getCurrentQueueName(item);
      const status = getFullJobStatusFilter(item.meta.status, item.actor.domain);
      if(status === 'image-fetched'){
        const getImagesCounts = (content) => {
          const [imageFetchSuccessCount, imageFetchFailureCount ] =
                        content.reduce(([imageFetchSuccessCount, imageFetchFailureCount], curr) => {
                          if(curr.crawlingStatus === 'success') {
                            return [imageFetchSuccessCount + 1, imageFetchFailureCount];
                          } else {
                            return [imageFetchSuccessCount, imageFetchFailureCount + 1];
                          }
                        },[0,0]);
          return [imageFetchSuccessCount, imageFetchFailureCount,imageFetchSuccessCount + imageFetchFailureCount];
        };

        const [imageFetchSuccessCount,imageFetchFailureCount, imagesCount] = getImagesCounts(item.content);

        if(imagesCount > 0) {
          const imageFetched =
                        tFilter('ITEM.HISTORY_MODAL.IMAGE_FETCHED',
                          {
                            IMAGE_FETCH_SUCCESS_COUNT: imageFetchSuccessCount,
                          }, 'messageformat');
          const imageFailed =
                        tFilter('ITEM.HISTORY_MODAL.IMAGE_FAILED',
                          {
                            IMAGE_FETCH_FAILURE_COUNT: imageFetchFailureCount,
                          }, 'messageformat');
          return [imageFetched, imageFailed];
        }
      } else {
        const event = tFilter('ITEM.HISTORY_MODAL.STATUS',
          {
            STATUS: status,
            QUEUE_NAME: queueName ? queueName + ' ' : '',
          }, 'messageformat');
        return [event];
      }
    }
  }
  angular
    .module('eva2-angular')
    .component('jobHistory', {
      template: require('./jobHistory.html'),
      controller: Controller,
      bindings: {
        history: '<',
        domain: '<'
      }
    });
})();