angular.module('eva2-angular').directive('evaHasMouse', function($timeout) {
  'use strict';

  return {
    restrict: 'CA',
    link: function(scope, element) {
      element.addClass('js-eva-job-hover-to-see-icons-container');
      element.on('mouseenter', function() {
        element.addClass('has-mouse');
        $timeout(()=> {
          element.find('.eva-single-select-with-search__input').focus();
        }, 500);
        if (element.find('.quick-add-to-list--open').length > 0 && $.fn.closeDropDown) {
          $('.eva-keyboard-focused').closeDropDown();
        }
      });

      element.on('mouseleave', function() {
        element.removeClass('has-mouse');
      });
      scope.$on('$destroy', function() {
        element.off('mouseenter');
        element.off('mouseleave');
        element.remove();
      });
    }
  };
});

