(function() {
  'use strict';
  angular.module('eva2-angular').service('moveHighlightInDropdown', function(getNewIndexAfterMove, getScrollTopForItemList) {
    return function(delta, currentHighlightedItem, highlightClass, items, dropdownContainer, itemHeight) {
      const currentHighlightedIndex = items.index(currentHighlightedItem);

      // remove current highlight
      currentHighlightedItem.removeClass(highlightClass);

      // calculate new index
      const newIndex = getNewIndexAfterMove(currentHighlightedIndex, delta, items.length);

      // add new highlight
      items.eq(newIndex).addClass(highlightClass);

      // scroll to new highlighted item
      scrollToItem(items.eq(newIndex)[0],
        dropdownContainer,
        itemHeight);
    };

    function scrollToItem(targetItem, dropdownContainer, itemHeight, extraScrollFromBottom = 0, animateDuration = 0) {
      if (targetItem) {
        $(dropdownContainer).animate({scrollTop: getScrollTopForItemList(targetItem.offsetTop, itemHeight,
          dropdownContainer.scrollTop, dropdownContainer.offsetHeight, extraScrollFromBottom)}, animateDuration);
      }
    }
  });
})();
