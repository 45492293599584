(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('evaAddTimeZoneTooltip', evaAddTimeZoneTooltip);

  function evaAddTimeZoneTooltip(tFilter) {
    const directive = {
      link: function(scope, element) {
        const timeZone = moment.tz.guess();
        element.attr('title', tFilter('DASHBOARD.STATISTICS.TIMEZONE_TOOLTIP', {timeZone}, 'messageformat'));
      }
    };
    return directive;
  }
})();
