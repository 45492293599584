(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('evaServerSideValidator', function($rootScope) {
      return {
        require: 'ngModel',
        scope: {
          evaServerSideValidator: '@'
        },
        link: function(scope, el, attr, ctrl) {
          const eventName = ['ServerSideValidationFailed', scope.evaServerSideValidator].join(':');
          const onServerSideValidationFailed = function(event, message) {
            ctrl.$setValidity('serverSideValidation', false);
            ctrl.serverSideValidation = message.message;
          };

          const serverSideValidationHandler = $rootScope.$on(eventName, onServerSideValidationFailed);

          const onDestroy = function() {
            serverSideValidationHandler();
          };

          ctrl.$validators.serverSideValidation = function() {
            return true;
          };

          scope.$on('$destroy', onDestroy);
        }
      };
    });
})();

