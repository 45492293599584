(function() {
  'use strict';
  const A = 65;
  const R = 82;
  const S = 83;
  const SHIFT = 16;

  angular.module('eva2-angular').constant('KeyboardCode', {
    ENTER: 13,
    ESC: 27,
    ARROW_UP: 38,
    ARROW_DOWN: 40,
    ARROW_LEFT: 37,
    ARROW_RIGHT: 39,
    APPROVE: A,
    REFUSE: R,
    SEND_TO_QUEUE: S,
    HIDE_MENU: SHIFT,
    TAB: 9,
    SPACE: 32
  });
})();
