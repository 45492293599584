import _ from 'lodash';
// import { dataStoringApi } from '@common/services/dataStoring';

(function () {
  'use strict';

  function Controller($scope, ApiService, $timeout,$ngRedux, $state, getScrollTopForItemList, getNewIndexAfterMove, userSetting) {
    'ngInject';

    const vm = this;
    let list;
    const MORE_THAN_10000 = '10000+';
    const routes = {
      listOfFilters: 'app.assignment.automation.filters',
      listOfDictionaries: 'app.assignment.automation.dictionaries',
      listOfQueues: 'app.assignment.manual.queues',
      statistics: 'app.assignment.overview.statistics',
    };
    vm.routeReplacementMap = {
      // filters
      'app.assignment.automation.filterEdit': routes.listOfFilters,
      'app.assignment.automation.filterView': routes.listOfFilters,
      // dictionaries
      'app.assignment.automation.dictionaryEdit': routes.listOfDictionaries,
      // manual
      'app.assignment.manual.queue': routes.listOfQueues,
      // single ad
      'app.assignment.ad': routes.statistics,
      'app.assignment.ad.test': routes.statistics,
      'app.assignment.adOld': routes.statistics,
      'app.assignment.forbidden': routes.statistics,
      'app.forbidden': routes.statistics,
    };
    const itemHeight = 34;
    vm.$state = $state;

    this.$onInit = () => {
      $timeout(() => {
        list = document.getElementsByClassName('team-picker-list')[0];
        list.addEventListener('mousemove', () => {
          const hoveredElement = document.querySelector('.team-picker-list-item:hover');
          const indexInFilteredList = [...list.children].indexOf(hoveredElement);
          $scope.$apply(() => {
            setFocus(vm.filteredTeams, indexInFilteredList);
          });
        });

        $scope.$watch('$ctrl.teams', () => {
          vm.teamList = _.map(vm.teams, (team) =>
            _.assign({}, team, {
              number: {
                loading: true,
                failed: false,
                value: undefined,
              },
            }),
          );
          vm.filteredTeams = vm.teamList;
        });

        $scope.$watch('$ctrl.display', (newVal, oldVal) => {
          if (newVal === oldVal) {
            return;
          }
          if (vm.display) {
            vm.searchText = '';
            document.addEventListener('keydown', keyDownListener);
            document.addEventListener('mousedown', mouseDownListener); 
            getData(vm.teamList);
            $timeout(() => {
              const selectedTeamIndex = _.findIndex(vm.teamList, (team) => team.selected);
              setFocus(vm.filteredTeams, selectedTeamIndex);
              scrollToTeam(selectedTeamIndex, list, itemHeight, 0.5 * list.offsetHeight - itemHeight, 200);
              const teamPickerInput = document.getElementById('team-picker-input');
              teamPickerInput && teamPickerInput.focus();
            }, 300);
          } else {
            document.removeEventListener('keydown', keyDownListener);
            document.removeEventListener('mousedown', mouseDownListener);
            clearFocus();
          }
        });

        $scope.$watch('$ctrl.searchText', (newVal, oldVal) => {
          if (oldVal !== newVal) {
            clearFocus();
            vm.filteredTeams = vm.searchText
              ? _.filter(vm.teamList, (t) => {
                return _.includes(t.name.toLowerCase(), vm.searchText.toLowerCase());
              })
              : vm.teamList;
          }
        });
      });
    };

    this.$onDestroy = () => {
      if (list) {
        list.addEventListener('mousemove');
      }
      document.removeEventListener('keydown', keyDownListener);
      document.removeEventListener('mousedown', mouseDownListener);
    };

    vm.linkClicked = (event, team) => {
      const domain=team?.domain;
      handleNavigationParams(domain, event);
      event.stopPropagation();
      /**
       * After a use clicks on a link, browser will add a ":focus" pseudo class on it.
       * We need to remove this ":focus" pseudo class,
       * otherwise,
       * if the user presses CTRL + CMD, the browser will automatically opens again the link in :focus state
       */
      $timeout(() => {
        document.activeElement.blur();
      });

      const openingANewTab = event.metaKey || event.ctrlKey; // when pressing CMD/CTRL
      if (!openingANewTab) {
        vm.onClose();
      } else {
        $timeout(() => {
          document.getElementById('team-picker-input').focus();
        });
      }
    };

    const navigate = (event, route, params) => {
      // when pressing CMD/CTRL/Mouse wheel click
      if (event.metaKey || event.ctrlKey || event.which === 2) {
        const url = $state.href(route, params, { notify: true, reload: true });
        window.open(url, '_blank');
        return;
      } 
      $state.go(route, params, { notify: true, reload: true });
      vm.onClose();
      
    };

    const handleNavigationParams =(domain, event) =>{
      if (!domain) {
        return;
      }
      const route = vm.routeReplacementMap[$state.current.name] || $state.current.name;
      let params = { domain };
      // if (($state.current.name === 'app.assignment.automation.filterView' || $state.current.name === 'app.assignment.overview.recentItems')) {
      //   $ngRedux.dispatch(
      //     dataStoringApi.endpoints.getDataStoringOption.initiate(domain)
      //   ).then((response) => {
      //     const minDate = response.data.minDate;
      //     params = { ...params, from: minDate };
      //     navigate(event, route, params);
      //   });
      //   return ;
      // }
      if (($state.current.name === 'app.assignment.search')) {
        // get Sort params from local storage
        const searchSortingLocalStorage = userSetting.getUserSetting('searchSorting');
        const defaultSearchUrlParamsSort= searchSortingLocalStorage?.[domain] || 'date';
        const defaultSearchUrlParamsOrder= searchSortingLocalStorage?.[domain] || 'desc';
        params = { ...params, sort: defaultSearchUrlParamsSort, order: defaultSearchUrlParamsOrder };
        navigate(event, route, params);
        return ;
      }
      navigate(event, route, params);
    };

    function clearFocus() {
      vm.currentFocusIndex = null;
      _.forEach(vm.teamList, (t) => {
        t.focused = false;
      });
    }

    function getData(teamList) {
      _.forEach(teamList, (team) => {
        ApiService.getQueueStats(team.domain)
          .then(
            (data) => {
              team.number.failed = false;
              team.number.value = data.reduce((acc, cur) => {
                if (cur.count === MORE_THAN_10000 || acc === MORE_THAN_10000) {
                  return MORE_THAN_10000;
                }
                return acc + cur.count;
              }, 0);
              team.number.isMoreThanZeo = team.number.value > 0 || team.number.value === MORE_THAN_10000;
            },
            () => {
              team.number.failed = true;
            },
          )
          .finally(() => {
            team.number.loading = false;
          });
      });
    }

    function scrollToTeam(index, container, itemHeight, extraScrollFromBottom = 0, animateDuration = 0) {
      const targetTeam = _.get(container, `children[${index}]`);
      if (targetTeam) {
        $(container).animate(
          {
            scrollTop: getScrollTopForItemList(
              targetTeam.offsetTop,
              itemHeight,
              container.scrollTop,
              container.offsetHeight,
              extraScrollFromBottom,
            ),
          },
          animateDuration,
        );
      }
    }

    function setFocus(teamList, currentFocusIndex) {
      if (!teamList || isNaN(currentFocusIndex)) {
        return;
      }
      vm.currentFocusIndex = currentFocusIndex;
      _.forEach(teamList, (t) => {
        t.focused = false;
      });
      _.set(teamList[currentFocusIndex], 'focused', true);
    }

    const mouseDownListener = (downEvent) => {
      downEvent.preventDefault();
      // mouse wheel click
      if (downEvent.which === 2) {
        document.onmouseup = (upEvent)=> {
          if(upEvent.target === downEvent.target){
            const domain = _.get(vm.filteredTeams, `${vm.currentFocusIndex}.domain`);
            $scope.$apply(() => {
              handleNavigationParams(domain, event);
            });
          }
        };
      }
    };

    function keyDownListener(event) {
      switch (event.key) {
      case 'ArrowUp': {
        event.preventDefault();
        $scope.$apply(() => {
          moveFocus(vm.currentFocusIndex, -1, vm.filteredTeams);
        });
        break;
      }
      case 'ArrowDown': {
        event.preventDefault();
        $scope.$apply(() => {
          moveFocus(vm.currentFocusIndex, 1, vm.filteredTeams);
        });
        break;
      }
      case 'Enter': {
        event.preventDefault();
        const domain = _.get(vm.filteredTeams, `${vm.currentFocusIndex}.domain`);
        $scope.$apply(() => {
          handleNavigationParams(domain, event);
        });
        break;
      }
      case 'Escape': {
        event.preventDefault();
        $scope.$apply(() => {
          vm.onClose();
        });
        break;
      }
      default:
      }
    }

    function moveFocus(currentFocusIndex, delta, renderedList) {
      const newIndex = getNewIndexAfterMove(currentFocusIndex, delta, renderedList.length);
      setFocus(renderedList, newIndex);
      scrollToTeam(newIndex, list, itemHeight);
    }
  }

  angular.module('eva2-angular').component('evaTeamPicker', {
    bindings: {
      display: '<', // boolean. true or false
      teams: '<',
      onClose: '&',
    },
    template: require('./teamPicker.html'),
    controller: Controller,
  });
})();
