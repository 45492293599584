(function() {
  'use strict';

  angular.module('eva2-angular').service('str', function() {
    return {
      isEndWith: function(item, chars) {
        if (item.match(chars + '$')) {
          return true;
        } else {
          return false;
        }
      },
      isStartWith: function(item, chars) {
        if (item.match('^' + chars)) {
          return true;
        } else {
          return false;
        }
      }
    };
  });
})();
