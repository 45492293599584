(function() {
  'use strict';

  function Controller(getDecisionSummary, $scope, $interval, tFilter, 
    $timeout, createIntersectionObserver, $pageVisibility) {
    'ngInject';
    const vm = this;

    const SECOND = 1000;
    const MINUTE = SECOND * 60;
    let decisionSummaryRef;
    const intervals = {};

    vm.$onInit = () => {
      vm.isTabIdle = false;
      vm.isIntersecting = false;
      decisionSummaryRef = document.querySelectorAll('.job-summary')[vm.index];
      if(decisionSummaryRef){
        vm.unobserveCallback = createIntersectionObserver([decisionSummaryRef], intersectionCallback, {rootMargin: '400px 0px 400px 0px'});      
      }
    };

    $pageVisibility.$on('pageFocused', () => {
      $timeout(()=>{
        vm.isTabIdle = false;
      });
    });

    $pageVisibility.$on('pageBlurred', () => {
      $timeout(()=>{
        vm.isTabIdle = true;
      });
    });


    const intersectionCallback = ({isIntersecting})=> {
      if (isIntersecting) {
        vm.isIntersecting = true;
        const intervalTime = getIntervalTime(vm?.data?.decisionFromNow || vm?.data?.timeWaiting || '');
        if(vm.intervalTime !== intervalTime){
          vm.intervalTime = intervalTime;
          resetIntervals();
        } else {
          startIntervals();

        }      
      } else {
        vm.isIntersecting = false;
        cancelIntervals();
      }
    };
    


    const startIntervals = () => {
      intervals.stopDecisionFromNow = $interval(() => {
        if(vm.data.decisionFromNow){
          const {timeNum, timeUnit} = vm.data.getDecisionFromNow(vm.data.timestamp);
          vm.data.decisionFromNow = tFilter('ITEM.DECISION_SUMMARY.DECISION_FROM_NOW', {TIME_NUM: timeNum,  TIME_UNIT: timeUnit}, 'messageformat');
        }
      }, vm.intervalTime);
      intervals.stopTimeWaiting = $interval(() => {
        if(vm.data.timeWaiting){
          const timeSinceEntered = vm.data.getTimeSinceEntered();
          vm.data.timeWaiting = tFilter('ITEM.DECISION_SUMMARY.TIME_WAITING', {STATUS: vm.data.status, TIME: timeSinceEntered}, 'messageformat');
        }                                                 
      }, vm.intervalTime);

    };


    const cancelIntervals = () => {
      if(intervals.stopDecisionFromNow){
        $interval.cancel(intervals.stopDecisionFromNow);
      }
      if(intervals.stopTimeWaiting){
        $interval.cancel(intervals.stopTimeWaiting);
      }
    };

    const resetIntervals = () => {
      cancelIntervals();
      startIntervals();
    };

    const getIntervalTime = (timeText) => {
      if(timeText.includes('s') || timeText.includes('m')){
        return SECOND;
      }
      return MINUTE;
    };


    $scope.$watch('$ctrl.job.ad.history', function() {
      $timeout(() => {
        vm.data = getDecisionSummary(vm.job);
        vm.intervalTime = getIntervalTime(vm.data.decisionFromNow || vm.data.timeWaiting);
        resetIntervals();
      });

    });

    $scope.$watch('$ctrl.isTabIdle', (newValue)=>{
      if(newValue && vm.isIntersecting){
        cancelIntervals();
      } 
      if(!newValue && vm.isIntersecting) {
        const intervalTime = getIntervalTime(vm.data.decisionFromNow || vm.data.timeWaiting);
        if(vm.intervalTime !== intervalTime){
          vm.intervalTime = intervalTime;
          resetIntervals();
        } else {
          startIntervals();
        }
      }
    });

 
    $scope.$on('$destroy', function() {
      if (vm.unobserveCallback) {
        vm.unobserveCallback();
      }
      cancelIntervals();
    });
  }
  angular
    .module('eva2-angular')
    .component('evaDecisionSummary', {
      bindings: {
        job: '<',
        hideStatus: '<',
        hideBar: '<',
        index: '<',
      },

      template: require('./decisionSummary.html'),
      controller: Controller
    });
})();
