(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('dictionaryEditor', filterList);

  function filterList() {
    const directive = {
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      restrict: 'E',
      template: require('./dictionaryEditor.html'),
      scope: {
        dictionary: '=',
        onSaveFn: '=',
        edit: '=?'
      }
    };
    return directive;
  }

  function Controller($state, $stateParams) {
    const vm = this;
    vm.cancel = cancel;

    function cancel() {
      $state.go('app.assignment.automation.dictionaries', {domain: $stateParams.domain});
    }
  }
})();
