(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .filter('arrayToString', arrayToString);

  function arrayToString() {
    return function(data, separator = ', ') {
      if (!Array.isArray(data)) {
        return '';
      }
      return data.join(separator);
    };
  }
})();

