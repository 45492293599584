
import * as Sentry from '@sentry/browser';
import { Angular as AngularIntegration } from '@sentry/integrations';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new AngularIntegration()],
  release: COMMIT_HASH,
  beforeSend: function(event) {
    if (DEPLOY_INFO.env === 'local') {
      console.log(event);
      return null;
    }
    return event;
  },
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i
  ] 
});