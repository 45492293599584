import _ from 'lodash';
/*!
 * eva-job-decision
 * Description: eva job decision component
 */

(function() {
  'use strict';

  function Controller($timeout, $element, $scope, tFilter) {
    'ngInject';
    const vm = this;

    //private
    let selectedRefuseDecisionKey;

    const DECISION_TYPE = {
      APPROVE: 'approve',
      REFUSE: 'refuse',
      DEFER: 'defer'
    };

    const jobStatusToDecision = {
      approved: DECISION_TYPE.APPROVE,
      refused: DECISION_TYPE.REFUSE
    };

    vm.DECISION_TYPE = DECISION_TYPE;

    function approveButtonMouseEnterListener() {
      $('.eva-keyboard-focusable.eva-drop-down-with-search.open').removeClass('open');
      if ($element && $element.find('.btn-approve')) {
        $element.find('.btn-approve').setFocus();
      }
    }

    this.$onInit = ()=> {
      const currentJobStatus = _.get(vm.job, 'ad.current.meta.status');
      if (currentJobStatus) {
        // if job already has a decision
        const currentDecisionType = _.get(jobStatusToDecision, currentJobStatus);
        if (currentDecisionType === DECISION_TYPE.APPROVE) {
          this.approve();
        } else if (currentDecisionType === DECISION_TYPE.REFUSE) {
          const categoryName = vm.job.decisionSet.length === 1? tFilter('ITEM.DEFAULT_REFUSE_BUTTON_LABEL') : _.get(vm.job, 'ad.current.meta.decisions[0].categoryName');
          this.refuse(categoryName, _.get(vm.job, 'ad.current.meta.decisions[0]'));
        }
      }
      updateDecisionText();

      vm.queues = this.availableQueues.map((item)=> {
        item.name = item.label;
        return item;
      });

      $timeout(()=> {
        $element.find('.btn-approve').on('mouseenter', approveButtonMouseEnterListener);
      });
    };

    this.$onDestroy = ()=> {
      $element.find('.btn-approve').off('mouseenter', approveButtonMouseEnterListener);
    };

    this.approve = function() {
      this.job.selectedDecision = {type: vm.DECISION_TYPE.APPROVE};
      this.onSelectDecision();
    };

    this.escalate = function(queue) {
      this.job.routingQueueObj = queue;
      this.job.selectedDecision = {type: vm.DECISION_TYPE.DEFER};
      this.onSelectDecision();
    };

    /**
     * decisionSet is like: [
     * { key: 'scam',
     *   values: [{id: 1, name: 'refusal reason 1'},{id: 2, name: 'refusal reason 2'}]
     * },
     * { key: 'illegal',
     *   values: [{id: 3, name: 'refusal reason 3'},{id: 4, name: 'refusal reason 4'}]
     * },
     * ]
     *
     * refuseDecisionKey is the button text of a refuse button (in this case, "scam" and "illegal")
     */
    this.refuse = function(refuseDecisionKey, reason) {
      selectedRefuseDecisionKey = refuseDecisionKey;
      this.job.selectedDecision = {type: vm.DECISION_TYPE.REFUSE, reason: reason};
      this.onSelectDecision();
    };

    this.isSelectedDecision = function(decisionType, refuseDecisionKey) {
      const selectedDecisionType = _.get(vm.job, 'selectedDecision.type');
      if (decisionType === selectedDecisionType) {
        if (decisionType === vm.DECISION_TYPE.REFUSE) {
          return selectedRefuseDecisionKey === refuseDecisionKey;
        }
        return true;
      }
    };

    vm.showEscalateButton = () => !vm.job.isPostReviewing;

    function updateDecisionText() {
      vm.selectedDecisionText = tFilter('ITEM.DECISION_TEXT', {
        DECISION_TYPE: _.get(vm.job.selectedDecision, 'type'),
        REFUSAL_REASON_NAME: _.get(vm.job.selectedDecision, 'reason.name'),
        QUEUE_NAME: _.get(vm.job.routingQueueObj, 'label')
      }, 'messageformat');
    }

    $scope.$watch('$ctrl.job.selectedDecision', ()=> {
      updateDecisionText();
    });

    $scope.$watch('$ctrl.availableQueues', ()=> {
      vm.queues = vm.availableQueues.map((item)=> {
        item.name = item.label;
        return item;
      });
    }, true);
  }

  angular
    .module('eva2-angular')
    .component('evaJobDecision', {
      bindings: {
        job: '<',
        onSelectDecision: '&',
        availableQueues: '<',
        isDisabled: '<'
      },

      template: require('./decision.html'),
      controller: Controller
    });
})();
