angular.module('eva2-angular').directive('evaAddAppStateNameToElementClass', function($rootScope) {
  'use strict';

  return {
    restrict: 'A',
    link: function(scope, el) {
      const $el = angular.element(el);
      const onStateChange = function(e, toState, toParams, fromState) {
        if (fromState && fromState.name) {
          $el.removeClass(fromState.name.replace(/\./g, '-'));
        }

        if (toState && toState.name) {
          $el.addClass(toState.name.replace(/\./g, '-'));
        }
      };

      // Since there is a chance, that state transition may stop and not reach
      // $stateChangeSuccess, it's better to listen on $stateChangeSuccess, when
      // "to" state is for sure to be set to current one.
      $rootScope.$on('$stateChangeSuccess', onStateChange);
    }
  };
});
