'use strict';

angular
  .module('eva2-angular')
  .service('EditValueInTreeModal', EditValueInTreeModal);

function EditValueInTreeModal($modal) {
  'ngInject';

  this.open = function open(valuesList, initialValue, title, expandedNodes) {
    const dialogSettings = {
      template: require('./editValueInTree.html'),
      controller: EditValueInTreeModalCtrl,
      controllerAs: 'vm',
      size: 'lg',
      backdrop: 'static',
      windowClass: 'tall-modal',
      resolve: {
        valuesList: () => valuesList,
        initialValue: () => initialValue,
        title: () => title,
        expandedNodes: ()=> expandedNodes
      }
    };

    return $modal.open(dialogSettings).result;
  };
}

function EditValueInTreeModalCtrl($modalInstance, valuesList, initialValue, title, expandedNodes, $timeout) {
  'ngInject';

  const vm = this;
  vm.valuesList = valuesList;
  vm.initialValue = initialValue;
  vm.selectedValue = initialValue;
  vm.expandedNodes = expandedNodes;

  vm.title = title;
  $timeout(()=> {
    const windowHeight = window.innerHeight;
    const selectedElement = angular.element('.tree-selected');
    if (selectedElement.length > 0) {
      angular.element('.modal').animate({scrollTop: selectedElement.offset().top - windowHeight * 0.5});
    }
  });

  vm.treeOptions = {
    nodeChildren: 'subCategories',
    dirSelectable: false,
    injectClasses: {
      ul: 'a1',
      li: 'a2',
      liSelected: 'a7 active',
      iExpanded: 'a3 edit-tree-icons implioicons-arrow-down',
      iCollapsed: 'a4 edit-tree-icons implioicons-arrow-right',
      iLeaf: 'a5 edit-tree-icons implioicons-dash',
      label: 'a6 label',
      labelSelected: 'a8 selected'
    }
  };

  vm.save = function save() {
    if (!vm.selectedValue) {
      return;
    }
    const value = vm.initialValue.id !== vm.selectedValue.id && vm.selectedValue || null;
    $modalInstance.close(value);
  };

  vm.select = (sel) => {
    vm.selectedValue = sel;
  };

  vm.cancel = function cancel() {
    $modalInstance.close(null);
  };
}
