import _ from 'lodash';
(function () {
  'use strict';

  function Controller(tFilter, $stateParams) {
    'ngInject';
    const vm = this;

    vm.domain = $stateParams.domain;

    const resources = {
      label: {
        status: tFilter('ITEM.STATUS_LABEL'),
        category: tFilter('ITEM.CATEGORY'),
        type: tFilter('ITEM.TYPE'),
        price: tFilter('ITEM.PRICE')
      }
    };

    const isValidTranslation = (str) => str && !str.includes('.');

    const getLanguagesText = (languageExpectedIsoCode = '', languageDetectedIsoCode = '') => {
      const languageExpected = languageExpectedIsoCode !== null ? tFilter(`LANGUAGES.${languageExpectedIsoCode.toUpperCase()}`) : '';
      const languageDetected = languageDetectedIsoCode !== null ? tFilter(`LANGUAGES.${languageDetectedIsoCode.toUpperCase()}`) : '';
      const languageExpectedText = isValidTranslation(languageExpected)
        ? tFilter('LANGUAGES.EXPECTED', { language: languageExpected }, 'messageformat')
        : '';
      const languageDetectedText = isValidTranslation(languageDetected) ? languageDetected : tFilter('LANGUAGES.UNKNOWN');
      return languageExpectedText ? `${languageDetectedText} (${languageExpectedText})` : languageDetectedText;
    };

    vm.$onInit = () => {
      vm.resources = resources;
      const languageExpectedIsoCode = _.get(vm.job.ad.current.content, 'languageExpected');
      const languageDetectedIsoCode = _.get(vm.job.ad.current.meta.language, 'best_match');
      vm.languagesText = getLanguagesText(languageExpectedIsoCode, languageDetectedIsoCode);
      vm.languagesLabel = tFilter('LANGUAGES.LABEL');
      vm.priceLabel = `${(vm?.resources?.label?.price || '')} (${vm?.job?.ad?.current?.content?.priceCurrency || ''})`;
    };

    vm.categoryUpdated = (value) => {
      vm.job.ad.current.content.category = value;
    };

    vm.typeUpdated = (value) => {
      vm.job.ad.current.content.type = value;
    };

    vm.updateCustomerSpecific = (key,value) => {
      vm.job.ad.customerSpecific.content[key] = value;
    };
  }

  angular
    .module('eva2-angular')
    .component('evaJobContentItem', {
      bindings: {
        job: '<',
        categories: '<',
        dictionaries: '<',
        types: '<',
        editPermissions: '<',
        trustedDomains: '<',
      },
      template: require('./jobContentItem.html'),
      controller: Controller
    });
})();
