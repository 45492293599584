(function() {
  'use strict';
  angular.module('eva2-angular').service('extractDomainFromUrl', function() {
    return function(url) {
      if (typeof url !== 'string') {
        return null;
      }
      // eslint-disable-next-line no-useless-escape
      const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
      // extract hostname (will be null if no match is found)
      return matches && matches[1] && matches[1].replace('https://', '').replace('http://', '');
    };
  });
})();
