import _ from 'lodash';
import { generateHash } from '@/react-app/features/moderationItem/mediaGallery/utils/generateHash';
(function () {
  'use strict';

  angular.module('eva2-angular').factory('JobModel', function ($filter, ApiService, addInfoToImages, getImageAugNotices, tFilter, extractDomainFromUrl) {
    'ngInject';

    const Job = function (jobData) {
      if (jobData) {
        this.setData(jobData);
      }
    };
    const groupBy = $filter('groupBy');
    const getNotice = function (processingResults) {
      const notices = [];

      _.forEach(processingResults, (result) => {
        const notice = result.notice;

        if (_.get(result, 'notice.enabled')) {
          notices.push(_.assign({}, {
            message: notice.text,
            rule: result.rule,
            severity: notice.severity ? notice.severity.toLowerCase() : ''
          }));
        }
      });
      return notices;
    };

    function replaceDecisionSetLabel(groupedDecisionSet) {
      if (!!groupedDecisionSet && groupedDecisionSet.length === 1) {
        groupedDecisionSet[0].key = tFilter('ITEM.DEFAULT_REFUSE_BUTTON_LABEL');
      }
      return groupedDecisionSet;
    }

    function isDecisionMade(status) {
      return ['refused', 'approved', 'no decision'].indexOf(status) !== -1;
    }

    function setCurrentDecision(status, isPreApprove, refusalReasons) {
      if (status === 'deferred' || status === 'reviewable') {
        return { type: isPreApprove ? 'approve' : null, reason: null };
      }
      if (status === 'refused') {
        return { type: 'refuse', reason: refusalReasons };
      }
      if (status === 'approved') {
        return { type: 'approve' };
      }
    }

    function isJobPostReviewed(history) {
      if (!Array.isArray(history)) {
        return false;
      }
      let numberOfDecisionMade = 0;
      history.forEach((historyItem) => {
        if (!historyItem || !historyItem.meta || !historyItem.meta.status) {
          return;
        }
        if (isDecisionMade(historyItem.meta.status)) {
          numberOfDecisionMade++;
        }
      });
      return numberOfDecisionMade > 1;
    }

    function getUserDefinedLocationText(address) {
      if (!address) {
        return;
      }
      const info = [address.city, address.postcode, address.countryCode];
      return (info.filter((item) => !!item).join(', ')) + (address.region ? ` (${address.region})` : '');
    }

    function getGeolocationText(geolocation) {
      const info = [_.get(geolocation, 'city.name'), _.get(geolocation, 'postal.code'),
        _.get(geolocation, 'country.iso_code')];
      return (info.filter((item) => !!item).join(', ')) || 'N/A';
    }

    function getOpenInDomainText(url) {
      return tFilter('SEARCH.ITEM.OPEN_IN_WEBSITE',
        { WEBSITE: extractDomainFromUrl(url) || url }, 'messageformat');
    }

    function addImagesInfoToHistory(history, imagesInfo) {
      const crawlingCompletionTime = _.get(imagesInfo, 'crawlingCompletionTime');
      const content = _.get(imagesInfo, 'content');
      if (content && crawlingCompletionTime && content.length) {
        const mergedHistory = [...history, { timestamp: crawlingCompletionTime, meta: { status: 'image-fetched' }, actor: { domain: 'processor' }, content }];
        return mergedHistory;
      } else {
        return history;
      }
    }

    const enrichVideosInfo = (videos, videosInfoContent) => {
      return videosInfoContent.map((video, index) => {
        const deleted = videos[index].deleted;
        return deleted !== undefined ? { ...video, deleted: deleted } : video;
      });
    };
    Job.prototype = {
      setDataWithJobAndDecision: function (data, decisionSet, isPreApprove) {
        const vm = this;
        vm.setData({ ad: data, decisionSet: decisionSet }, isPreApprove);
      },
      setData: function (data, isPreApprove) {
        if (data.decisionSet) this.decisionSet = replaceDecisionSetLabel(groupBy(data.decisionSet, 'categoryName'));

        this.ad = data.ad;

        this.focusMe = false;

        const processingResults = data.ad.current.processingResults;
        this.notices = getNotice(processingResults);
        this.selectedDecision = setCurrentDecision(_.get(this.ad, 'current.meta.status'), isPreApprove, _.get(this.ad, 'current.meta.decisions[0]'));
        this.selectedFeedbackIds = _.map(_.get(this.ad, 'current.meta.feedback'), (f) => f.id);
        this.isDecisionMade = isDecisionMade(_.get(this.ad, 'current.meta.status'));
        this.isPostReviewed = isJobPostReviewed(this.ad.history);

        const images = _.get(this.ad, 'current.content.images')|| [];
        const videos = _.get(this.ad, 'current.content.videos') || [];
        const imagesLength = images ? images.length : 0;
        const videosLength = videos ? videos.length : 0;
        const videosInfoContent = _.get(this.ad, 'videosInfo.content') || [];

        addInfoToImages(images, _.get(this.ad, 'imagesInfo.content') || [], this.ad.remoteId.domain,
          this.ad.id, _.get(this.ad, 'current.meta.imageAug'));
       
        this.ad.videosInfo={
          ...this.ad.videosInfo,
          content: enrichVideosInfo(videos, videosInfoContent),
        };

        if (this.ad?.current?.content?.images) {
          const imagesWithId = this.ad.current.content.images.map((item, index) => 
            ({ ...item, id: generateHash(`${item.src}${index}`) }));
          this.ad.current.content.images = imagesWithId;
  
        }
        if(this.ad?.videosInfo?.content){
          const videosWithId = this.ad.videosInfo.content.map((item, index) =>
            ({ ...item, id: generateHash(`${item.url}${index}`) }));
          this.ad.videosInfo.content = videosWithId;
        }
        this.generatedNotices = getImageAugNotices(images);
        this.userDefinedLocationText = getUserDefinedLocationText(_.get(this.ad, 'current.content.address'));
        this.geolocationText = getGeolocationText(_.get(this.ad, 'current.meta.geolocation'));
        this.openItemIdAdminUrlTooltip = getOpenInDomainText(_.get(this.ad, 'current.content.adminUrl'));
        this.openItemIdUrlTooltip = getOpenInDomainText(_.get(this.ad, 'current.content.url'));
        this.openUserIdTooltip = getOpenInDomainText(_.get(this.ad, 'current.user.adminUrl'));
        this.titleWithPlaceholder = _.get(this.ad, 'current.content.title') || `${tFilter('ITEM.ITEM_ID_PLACEHOLDER', { ITEM_ID: this.ad.remoteId.id }, 'messageformat')}`;
        this.hasTitleField = this.ad.current.content && Object.prototype.hasOwnProperty.call(this.ad.current.content, 'title');
        this.hasBodyField = this.ad.current.content && Object.prototype.hasOwnProperty.call(this.ad.current.content, 'body');
        this.mediaCount = imagesLength + videosLength;
        this.ad.history = addImagesInfoToHistory(this.ad.history, this.ad.imagesInfo);
      },
      load: function (domain, id) {
        return ApiService.getAd(domain, id).then((data) => this.setData(data));
      }
    };
    return Job;
  });
})();
