(function() {
  'use strict';
  angular.module('eva2-angular').controller('SearchInfoModalCtrl', function($modalInstance, tFilter) {
    const vm = this;
    vm.resources = {
      button: tFilter('SEARCH.HELP_MODAL.BUTTON.GOT_IT'),
      textSearchSection: {
        text: tFilter('SEARCH.HELP_MODAL.TEXT_SEARCH_SECTION.TEXT')
      },
      searchOnSpecificFieldsSection: {
        text: tFilter('SEARCH.HELP_MODAL.SEARCH_ON_SPECIFIC_FIELDS_SECTION.TEXT'),
        line1: tFilter('SEARCH.HELP_MODAL.SEARCH_ON_SPECIFIC_FIELDS_SECTION.LINE1'),
        line2: tFilter('SEARCH.HELP_MODAL.SEARCH_ON_SPECIFIC_FIELDS_SECTION.LINE2'),
        line3: tFilter('SEARCH.HELP_MODAL.SEARCH_ON_SPECIFIC_FIELDS_SECTION.LINE3')
      },
      rangeSearchSection: {
        text: tFilter('SEARCH.HELP_MODAL.RANGE_SEARCH_SECTION.TEXT'),
        line1: tFilter('SEARCH.HELP_MODAL.RANGE_SEARCH_SECTION.LINE1'),
        line2: tFilter('SEARCH.HELP_MODAL.RANGE_SEARCH_SECTION.LINE2')
      },
      logicalOperatorsSection: {
        text: tFilter('SEARCH.HELP_MODAL.LOGICAL_OPERATORS_SECTION.TEXT'),
        line1: tFilter('SEARCH.HELP_MODAL.LOGICAL_OPERATORS_SECTION.LINE1')
      },
      moreHelpSection: {
        text: tFilter('SEARCH.HELP_MODAL.MORE_HELP_SECTION.TEXT'),
      }
    };
    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
  });
})();

