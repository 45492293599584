(function() {
  'use strict';

  function Controller() {
    'ngInject';
    const vm = this;

    vm.$onInit = () => {
      vm.searchText = `metadata.dedupHash:"${vm.dedupHash}" AND NOT document.id:"${vm.jobRemoteId}"`;
    };
  }

  angular
    .module('eva2-angular')
    .component('evaDuplicatesPreview', {
      bindings: {
        duplicatesCount: '<',
        jobRemoteId: '<',
        hasError: '<',
        loading: '<',
        dedupHash: '<'
      },
      template: require('./duplicatesPreview.html'),
      controller: Controller,
      controllerAs: 'vm',
    });
})();
