(function() {
  'use strict';

  function Controller() {
  }

  angular
    .module('eva2-angular')
    .component('evaKeyboardLayout', {
      bindings: {
      },
      template: require('./keyboardLayout.html'),
      controller: Controller
    });
})();

