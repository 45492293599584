(function() {
  'use strict';

  function Controller($scope, Enum, tFilter) {
    'ngInject';
    const vm = this;

    vm.res = {
      inputOrSelect: {
        label: {
          default: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.ATTRIBUTE_LABEL'),
          customerSpecific: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.CUSTOMER_SPECIFIC_TEXT')
        },
        tooltip: {
          defaultText: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.ATTRIBUTE_ICON_TOOLTIP'),
          customerSpecificText: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.CUSTOMER_SPECIFIC_ICON_TOOLTIP'),
          textLink: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.ATTRIBUTE_ICON_TOOLTIP_LINK')
        }
      },
      selectAttribute: {
        placeholder: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.SELECT_ATTR_PLACEHOLDER'),
        separatedItemName: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.CREATE_SPECIFIC_ATTR_OPTION'),
        warning: {
          1: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.WARNING'),
          2: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.WARNING'),
          3: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.WARNING3'),
          4: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.WARNING4'),
          5: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.WARNING5'),
          6: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.WARNING6')
        }
      },
      selectColumn: {
        label: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.MAP_COLUMN_LABEL'),
        placeholder: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.SELECT_COLUMN_PLACEHOLDER')
      },
      deleteButton: {
        text: tFilter('SETTING.CSV_IMPORTER.MAP_COLUMNS.DELETE_BTN')
      }
    };

    this.$onInit = () => {
      vm.Enum = Enum;

      vm.columnClicked = (column) => {
        const columnId = column ? column.id : undefined;
        vm.onSelectedColumnChanged({columnId: columnId});
      };

      vm.decidedAttributeChanged = (decidedAttribute) => {
        vm.onDecidedAttributeChanged({decidedAttribute: decidedAttribute});
      };

      $scope.$watch('$ctrl.columns', () => {
        if (!vm.columns) {
          return;
        }
        vm.processedColumns = vm.columns.map((column)=> angular.extend({}, column, {
          disabled: !!column.takenBy && column.takenBy !== vm.mapper.id
        }));
      }, true);

      $scope.$watch('$ctrl.attributes', () => {
        if (!vm.attributes) {
          return;
        }
        vm.processedAttributes = vm.attributes.map((attribute)=> angular.extend({}, attribute, {
          disabled: !!attribute.takenBy && attribute.takenBy !== vm.mapper.id
        }));
      }, true);
    };
  }

  angular
    .module('eva2-angular')
    .component('csvColumnMapper', {
      template: require('./csvColumnMapper.html'),
      controller: Controller,
      bindings: {
        submitted: '=',
        mapper: '<',
        columns: '<',
        attributes: '<',
        onRemoveSelf: '&',
        onSelectedColumnChanged: '&',
        onDecidedAttributeChanged: '&',
      }
    });
})();
