
import SecurityWarningModal from '@/react-app/common/components/SecurityWarningModal';
import { openLinkInNewTab, shouldOpenSecurityWarningModal } from '@/react-app/common/utils/linkUtils';
import store from '@store';
import { default as React } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';


/*evaTextTitle: evaTextTitle high light text in view mode*/
(function() {
  'use strict';
  function Controller($scope, $element, $timeout) {
    const autosize = require('autosize');
    'ngInject';
    const vm = this;

    this.$onInit = function() {
      //default value
      const mode = 'VIEW';
      this.mode = this.mode || mode;
    };
    //links
    this.onClick = function() {
      if (!this.isDisabled) {
        this.mode = 'EDIT';
        const height = $element.find('.eva-text-view').height();
        $element.find('textarea').height(height);
        autosize($element.find('textarea'));
        $timeout(()=> {
          $element.find('textarea').focus();
          vm.confirmedHighlightList = this.highlightList;
          vm.confirmedValue = this.value;
        });
      }
    };
    this.onBlur = function() {
      this.mode = 'VIEW';
    };

    this.onKeyDown = function(event){
      if (event.keyCode === 13 && event.shiftKey) {
        event.preventDefault();
        this.onBlur();
      }
    };

    this.resetToOriginalValue = ()=> {
      vm.value = this.confirmedValue;
      vm.highlightList = this.confirmedHighlightList;
      this.mode = 'VIEW';
    };

    this.handleAnchorLinkClick =(link)=>{
      const shouldOpenModal= shouldOpenSecurityWarningModal(link, vm.trustedDomains);
      if(shouldOpenModal){
        vm.link=link;
        setIsModalOpen(true);
      } else{
        openLinkInNewTab(link);
      }
    };

    const setIsModalOpen = (isOpen) => {
      vm.isModalOpen = isOpen;
    };

    $scope.$watchGroup(['$ctrl.isModalOpen', '$ctrl.link'], ()=> {
      renderModal();
    });

    const renderModal = () => {
      $timeout(() => {
        const root = createRoot(document.querySelector('#modal-title'));
        root.render(<Provider store={store}>
          <SecurityWarningModal
            isOpen={vm.isModalOpen}
            setIsOpen={setIsModalOpen}
            link={vm.link}
          />
        </Provider>
        );
      });
    };

  }
  angular
    .module('eva2-angular')
    .component('evaJobTitle', {
      bindings: {
        mode: '@', //the value should be "VIEW" or "EDIT"
        placeholderText: '@',
        highlightList: '<',
        value: '=',
        isDisabled: '<',
        rules: '<',
        highlightMatched: '<',
        titleSize: '@',
        trustedDomains: '<'
      },
      template: require('./jobTitle.html'),
      controller: Controller
    });
})();
