"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GraphContainer = exports.resources = exports.margin = void 0;
var react_1 = __importStar(require("react"));
var d3_selection_1 = require("d3-selection");
var d3_array_1 = require("d3-array");
var d3_scale_1 = require("d3-scale");
var d3_shape_1 = require("d3-shape");
var lodash_1 = require("lodash");
var d3 = __importStar(require("d3"));
var react_redux_1 = require("react-redux");
var Tooltip_1 = require("./components/Tooltip");
var Legend_1 = require("./components/Legend");
var tooltipUtil_1 = require("./components/Tooltip/tooltipUtil");
var graphUtil_1 = require("./graphUtil");
var overviewSlice_1 = require("../../state/overviewSlice");
var THRESHOLD_STACK_CHARTS = 10;
var GRAPH_WIDTH = 950;
var GRAPH_HEIGHT = 300;
exports.margin = { top: 10, right: 10, bottom: 16, left: 55 };
exports.resources = {
    acceptedManual: 'DASHBOARD.STATISTICS.CHART.LEGEND.APPROVED_MANUAL',
    acceptedAuto: 'DASHBOARD.STATISTICS.CHART.LEGEND.APPROVED_AUTO',
    totalDecisions: 'DASHBOARD.STATISTICS.CHART.LEGEND.TOTAL_DECISIONS',
    rejectedAuto: 'DASHBOARD.STATISTICS.CHART.LEGEND.REFUSED_AUTO',
    rejectedManual: 'DASHBOARD.STATISTICS.CHART.LEGEND.REFUSED_MANUAL',
    noDecisionAuto: 'DASHBOARD.STATISTICS.CHART.LEGEND.NO_DECISION_AUTO',
    noDecisionManual: 'DASHBOARD.STATISTICS.CHART.LEGEND.NO_DECISION_MANUAL',
};
var defaultDataToShow = {
    acceptedManual: true,
    acceptedAuto: true,
    totalDecisions: true,
    rejectedAuto: true,
    rejectedManual: true,
    noDecisionAuto: true,
    noDecisionManual: true,
};
var graphColorScheme = {
    totalDecisions: '#5a0077',
    acceptedAuto: '#459d68',
    acceptedManual: '#7fe0a6',
    rejectedAuto: '#ff4d4c',
    rejectedManual: '#ffa5a4',
    noDecisionAuto: '#606060',
    noDecisionManual: '#999999',
};
function GraphContainer() {
    var data = (0, react_redux_1.useSelector)(overviewSlice_1.overviewReportSelector);
    var staples = (0, react_redux_1.useSelector)(overviewSlice_1.staplesOverviewSelector);
    var aggregationInterval = (0, react_redux_1.useSelector)(overviewSlice_1.aggregationIntervalOverviewSelector);
    /*
      Main state of the graph
    */
    // To move between stacked and grouped mode
    var _a = __read((0, react_1.useState)(false), 2), isStackedMode = _a[0], setStackedMode = _a[1];
    // We can disable the input mode with this props
    var _b = __read((0, react_1.useState)(false), 2), groupedInputDisabled = _b[0], setGroupedInputDisabled = _b[1];
    // The list of types of data to show (ie manualApproved only)
    var _c = __read((0, react_1.useState)(defaultDataToShow), 2), dataToShow = _c[0], setDataToShow = _c[1];
    /*
      Tooltip state
     */
    // Index of the lastHovered bar
    var _d = __read((0, react_1.useState)(-1), 2), lastHoveredBarIndex = _d[0], setLastHoveredBarIndex = _d[1];
    // To open and close the tooltip
    var _e = __read((0, react_1.useState)(false), 2), isTooltipOpen = _e[0], setIsTooltipOpen = _e[1];
    // To compute the tooltip position, we need the highest position of the current bar
    var _f = __read((0, react_1.useState)(0), 2), highestBinBarHeight = _f[0], setHighestBinBarHeight = _f[1];
    var _g = __read((0, react_1.useState)(0), 2), tooltipLeftPos = _g[0], setTooltipLeftPos = _g[1];
    var _h = __read((0, react_1.useState)(null), 2), tooltipContent = _h[0], setTooltipsContent = _h[1];
    var svgRef = (0, react_1.useRef)(null);
    var graphData = (0, react_1.useMemo)(function () {
        var dataWithTotal = (0, graphUtil_1.getTotalDecision)(data);
        if (dataWithTotal) {
            return dataWithTotal;
        }
        return [];
    }, [data]);
    (0, react_1.useEffect)(function () {
        (0, d3_selection_1.select)(svgRef.current).selectAll('*').remove();
        renderGraph();
    }, [graphData, isStackedMode, dataToShow]);
    var onDataToShowCheckboxChange = function (key) {
        var _a;
        setDataToShow(__assign(__assign({}, dataToShow), (_a = {}, _a[key] = !dataToShow[key], _a)));
    };
    var renderGraph = function () {
        var seriesLength = (0, graphUtil_1.getSeriesNum)(dataToShow); // The number of series.
        var dataLength = graphData ? graphData.length : 0; // The number of values per series.
        // The yz array has seriesLength elements, representing the y-values of each of the seriesLength series.
        // The y01z array has the same structure as yz, but with stacked [y₀, y₁] instead of y.
        var yz = (0, graphUtil_1.getY)(dataToShow, graphData);
        var y01zKeys = (0, d3_shape_1.stack)().keys((0, d3_array_1.range)(seriesLength));
        var y01z = y01zKeys.order(d3_shape_1.stackOrderReverse)((0, d3_array_1.transpose)(yz));
        var yMax = (0, graphUtil_1.getYMax)(dataToShow, yz, y01z);
        var svg = d3.select(svgRef.current);
        if (svg.empty()) {
            return;
        }
        var width = GRAPH_WIDTH - exports.margin.left - exports.margin.right;
        var height = GRAPH_HEIGHT - exports.margin.top - exports.margin.bottom;
        var g = svg.append('g').attr('transform', "translate(".concat(exports.margin.left, ",").concat(exports.margin.top, ")"));
        // calc x attr
        var x = (0, d3_scale_1.scaleBand)()
            .domain((0, graphUtil_1.getXScaleArr)(dataLength))
            .rangeRound([0, width])
            .padding(0.15);
        var widthPerStack = ((x(dataLength - 1) || 0) - (x(0) || 0)) / (dataLength - 1);
        // calc y attr
        var y = (0, d3_scale_1.scaleLinear)()
            .domain([0, yMax])
            .range([height, 0]);
        var color = (0, d3_scale_1.scaleOrdinal)()
            .domain((0, d3_array_1.range)(seriesLength))
            .range((0, graphUtil_1.getColorScheme)(graphColorScheme, dataToShow));
        var series = g
            .selectAll('.series')
            .data(y01z)
            .enter()
            .append('g')
            .attr('fill', function (_, i) {
            var computedColor = color(i.toString());
            return (0, lodash_1.isEmpty)(computedColor) ? '' : computedColor;
        });
        var rect = series
            .selectAll('rect')
            .data(function (d) {
            return d;
        })
            .enter()
            .append('rect')
            .attr('x', function (_, i) {
            return x(i) || 0;
        })
            .attr('y', height)
            .attr('width', x.bandwidth())
            .attr('height', 0)
            .on('mousemove', function (event) {
            (0, tooltipUtil_1.showTooltip)(this, event, x, series, widthPerStack, lastHoveredBarIndex, setLastHoveredBarIndex, setIsTooltipOpen, function (barIndex) {
                setHighestBinBarHeight((0, tooltipUtil_1.computeHighestBinBarHeight)(y, barIndex, graphData, dataToShow, isStackedMode));
                setTooltipLeftPos((x(0) || 0) + exports.margin.left + barIndex * widthPerStack + widthPerStack / 2);
                setTooltipsContent((0, tooltipUtil_1.computeTooltipContent)(barIndex, dataToShow, graphData, data, series, exports.resources, aggregationInterval));
            });
        })
            .on('mouseout', function () {
            setIsTooltipOpen(false);
            series.selectAll('rect').classed('highlighted', false);
            setLastHoveredBarIndex(-1);
        });
        var getD3Context = function () {
            return {
                y: y,
                x: x,
                rect: rect,
                yMax: yMax || 0,
                svg: svg,
                g: g,
                width: width,
                height: height,
            };
        };
        (0, graphUtil_1.addAxis)(getD3Context(), data, staples, aggregationInterval, dataLength);
        // if data is a too big array, automatically show stacked chart and disable grouped chart
        // otherwise, show the selected chart (if nothing selected, show grouped one).
        if (data.length > THRESHOLD_STACK_CHARTS) {
            setGroupedInputDisabled(true);
            (0, graphUtil_1.transitionStacked)(dataToShow, getD3Context(), 500);
            setStackedMode(true);
        }
        else {
            setGroupedInputDisabled(false);
            if (isStackedMode) {
                (0, graphUtil_1.transitionStacked)(dataToShow, getD3Context(), 500);
            }
            else {
                (0, graphUtil_1.transitionGrouped)(getD3Context(), seriesLength, 500);
            }
        }
    };
    return (react_1.default.createElement("div", null,
        react_1.default.createElement("div", { className: "stacked-chart-container flex-container flex-space-between" },
            react_1.default.createElement("div", { className: "overview-graph-container" },
                react_1.default.createElement("svg", { ref: svgRef, id: "overview-graph", width: "100%", height: "100%", viewBox: "0 0 950 300" })),
            react_1.default.createElement(Legend_1.Legend, { isStackedMode: isStackedMode, setStackedMode: setStackedMode, groupedInputDisabled: groupedInputDisabled, dataToShow: dataToShow, onDataToShowCheckboxChange: onDataToShowCheckboxChange }),
            react_1.default.createElement(Tooltip_1.Tooltip, { isVisible: isTooltipOpen, dataIndex: lastHoveredBarIndex, highestBinBarHeight: highestBinBarHeight, leftPos: tooltipLeftPos, tooltipContent: tooltipContent }))));
}
exports.GraphContainer = GraphContainer;
