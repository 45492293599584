(function() {
  'use strict';

  function Controller() {
    'ngInject';
  }

  angular
    .module('eva2-angular')
    .component('evaExternalLink', {
      bindings: {
        link: '<',
        tooltipText: '@'
      },
      template: require('./externalLink.html'),
      controller: Controller
    });
})();
