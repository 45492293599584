"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertRouteForPermission = exports.hasPermissionToEnterRouteUtil = exports.hasRoleHigherThanNoAccessUtil = exports.getPermissionForOperationUtil = exports.setPermissionForOperationUtil = exports.SETTINGS_LEGACY_ROUTE = exports.SETTINGS_ROUTE = void 0;
var enterRouteRequirement_1 = require("@common/permissions/enterRouteRequirement");
var types_1 = require("./types");
exports.SETTINGS_ROUTE = 'app.assignment.settings.react';
exports.SETTINGS_LEGACY_ROUTE = 'app.assignment.settings';
function setPermissionForOperationUtil(EvaLogger, rawAuthorizationData) {
    if (!Array.isArray(rawAuthorizationData)) {
        EvaLogger.captureMessage("raw authorization data is expected to be an array. Actually it is ".concat(rawAuthorizationData));
        return null;
    }
    var operationPermission = {};
    rawAuthorizationData.forEach(function (dataForDomain) {
        operationPermission[dataForDomain.domain] = {};
        dataForDomain.permissions.forEach(function (permission) {
            operationPermission[dataForDomain.domain][permission.operation] = { can_write: permission.can_write };
        });
    });
    return operationPermission;
}
exports.setPermissionForOperationUtil = setPermissionForOperationUtil;
function getPermissionForOperationUtil(operationPermission, operation, domain) {
    var _a;
    if (!operationPermission || !operationPermission[domain] || !operationPermission[domain][operation]) {
        return {};
    }
    var canWrite = ((_a = operationPermission[domain][operation]) === null || _a === void 0 ? void 0 : _a.can_write) || false;
    return {
        canWrite: canWrite === true,
        canReadOnly: canWrite === false,
    };
}
exports.getPermissionForOperationUtil = getPermissionForOperationUtil;
var hasRoleHigherThanNoAccessUtil = function (operationPermission, domain) {
    if (!operationPermission[domain]) {
        return false;
    }
    return !(Object.keys(operationPermission[domain]).length === 0);
};
exports.hasRoleHigherThanNoAccessUtil = hasRoleHigherThanNoAccessUtil;
function hasPermissionToEnterRouteUtil(operationPermission, domain, routeName) {
    var requirements = enterRouteRequirement_1.EnterRouteRequirement[routeName];
    if (!requirements) {
        return true;
    }
    return requirements.every(function (requirement) {
        var permission = getPermissionForOperationUtil(operationPermission, requirement.operation, domain);
        return ((requirement.requireWriteAccess && permission.canWrite) ||
            (requirement.requireWriteAccess === false && (permission.canWrite || permission.canReadOnly)) ||
            (requirement.requireRoleHigherThanNoAccess && (0, exports.hasRoleHigherThanNoAccessUtil)(operationPermission, domain)));
    });
}
exports.hasPermissionToEnterRouteUtil = hasPermissionToEnterRouteUtil;
/**
 * PermissionManager is based on the routeName. Since we refactor the Settings router in React
 * we need to convert the routeName to the old one.
 * Should be removed when we will move to a React router.
 * Convert app.assignment.settings.react({component:delete-all-content}} to app.assignment.settings.deleteAllContent
 */
function convertRouteForPermission(route, routeParams) {
    if (route !== exports.SETTINGS_ROUTE) {
        return route;
    }
    if (routeParams.component && types_1.routeMapping[routeParams.component]) {
        var routeComponentName = types_1.routeMapping[routeParams.component];
        return "".concat(exports.SETTINGS_LEGACY_ROUTE, ".").concat(routeComponentName);
    }
    return "".concat(exports.SETTINGS_LEGACY_ROUTE, ".teamGeneral");
}
exports.convertRouteForPermission = convertRouteForPermission;
