"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STATUS_LIST = exports.getFullJobStatusFilter = exports.getCurrentQueueName = void 0;
var getCurrentQueueName = function (mostRecentHistory, queues) {
    var _a, _b;
    if (!mostRecentHistory) {
        return;
    }
    var filteredProcessingResults = mostRecentHistory.processingResults && mostRecentHistory.processingResults.filter(function (p) { return p.result !== 'NOTHING'; });
    var queueId = mostRecentHistory.routingQueue ||
        (Array.isArray(filteredProcessingResults) && ((_a = filteredProcessingResults[0]) === null || _a === void 0 ? void 0 : _a.routingQueue)) ||
        'default';
    var queueName = mostRecentHistory.routingQueueName ||
        (Array.isArray(filteredProcessingResults) && ((_b = filteredProcessingResults[0]) === null || _b === void 0 ? void 0 : _b.routingQueueName)) ||
        'Default';
    var queue = Array.isArray(queues) && queues.find(function (q) { return q.id === queueId; });
    return queue ? queue.label : queueName;
};
exports.getCurrentQueueName = getCurrentQueueName;
var getFullJobStatusFilter = function (status, actorDomain) {
    if (status === 'no decision') {
        return 'no-decision';
    }
    // if (_.includes(['approved', 'refused'], status)) {
    if (status === 'approved' || status === 'refused') {
        var mapping = { client: 'remotely', agent: 'manual', processor: 'auto' };
        return "".concat(mapping[actorDomain], "-").concat(status);
    }
    return status;
};
exports.getFullJobStatusFilter = getFullJobStatusFilter;
exports.STATUS_LIST = {
    'remotely-approved': 'Remotely-approved',
    'remotely-refused': 'Remotely-refused',
    'manual-approved': 'Manually-approved',
    'auto-approved': 'Auto-approved',
    'manual-refused': 'Manually-refused',
    'auto-refused': 'Auto-refused',
    reviewable: 'Reviewable',
    deferred: 'Reviewable',
    new: 'Processing',
    processing: 'Processing',
    'no-decision': 'No decision',
    escalated: 'Deferred',
};
