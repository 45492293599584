import _ from 'lodash';
(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('evaMediaItem', MediaItem);

  function MediaItem() {
    'ngInject';
    return {
      replace: true,
      restrict: 'E',
      template: require('./mediaItem.html'),
      controller: Controller,
      transclude: {
        text: '?text'
      },
      scope: {},
      bindToController: {
        item: '<',
        selected: '<',
        isDisabled: '<',
        containerSize: '<',
        showText: '<',
        showBoundingBox: '<',
        boundingBoxStyle: '<',
        shouldDeferLoading: '<',
        shouldRequestBySize: '<',
        disableDownload: '<',
        disableLoadingAnimation: '<',
        showActionIcons: '<',
        imageCroppingAllowed: '<',
        imageRotationAllowed: '<',
        onImageClicked: '&',
        openZoomModal: '&'
      },
      controllerAs: 'vm'
    };
  }

  function Controller($scope, getImageTransformStyle, BoundingBox, tFilter, mergeAugBoxes, 
    getRequestImageSize, $timeout, $modal, $state) {
    'ngInject';
    const vm = this;

    vm.$onInit = () =>{ 
      vm.failedToFetchImageText = tFilter('ITEM.IMAGE_FAIL_TEXT', {link: vm.item.src}, 'messageformat');
    };


    vm.toggle = ($event) => {
      $event.stopPropagation();
      vm.item.deleted = !vm.item.deleted;
    };

    vm.onFailedToLoadImage = ()=> {
      vm.failedToLoadImage = true;
    };

    vm.editSelectedItem = ($event) => {
      $event.stopPropagation();
      const modalInstance = $modal.open({
        template: require('@partial/modals/editImageModal/editImageModal.html'),
        controller: 'EditImageModalCtrl',
        controllerAs: 'vm',
        bindToController: true,
        windowClass: 'eva-edit-image-modal-window',
        backdrop: 'static',
        resolve: {
          image: () => vm.item,
          imageCroppingAllowed: ()=> vm.imageCroppingAllowed,
          imageRotationAllowed: ()=> vm.imageRotationAllowed
        }
      });

      modalInstance.result.then(function(result = {}) {
        vm.item.transformations = result.transformations;
      });
    };

    vm.searchImage = ($event)=> {
      $event.stopPropagation();
      window.open($state.href('app.assignment.search', {
        text: `imagesInfo.content.phash.value: ${vm.item.phash}`
      }));
    };


    const unwatch = $scope.$watch('vm.selected', (newVal)=> {
      if (newVal) {
        vm.disableDownload = false;
        if (vm.disableLoadingAnimation) {
          $timeout(()=> {
            vm.disableLoadingAnimation = false;
          }, 2000);
        }
        unwatch();
      }
    });

    $scope.$watchGroup(['vm.item.transformations', 'vm.containerSize', 'vm.item.originalSize',
      'vm.shouldRequestBySize'], ()=> {
      updateStyle();
    });

    $scope.$watchGroup(
      ['vm.shouldRequestBySize', 'vm.requestImageSize', 'vm.shouldDeferLoading',
        'vm.item.imageServiceUrl', 'vm.disableDownload'],
      ()=> {
        vm.requestUrl = !vm.disableDownload && getRequestUrl({
          shouldRequestBySize: vm.shouldRequestBySize,
          requestImageSize: vm.requestImageSize,
          shouldDeferLoading: vm.shouldDeferLoading,
          imageServiceUrl: vm.item.imageServiceUrl
        });
        vm.failedToLoadImage = false;
      });

    $scope.$watch('vm.showBoundingBox', () => {
      updateBoundingBoxes();
    }, true);


    function getRequestUrl({shouldRequestBySize, requestImageSize, shouldDeferLoading, imageServiceUrl}) {
      if (shouldDeferLoading || !imageServiceUrl || (shouldRequestBySize && !requestImageSize)) {
        return;
      }
      const sizeQuery = shouldRequestBySize && requestImageSize ?
        `?width=${requestImageSize.width}&height=${requestImageSize.height}`
        : '';
      return imageServiceUrl + sizeQuery;
    }

    function updateStyle() {
      if (_.get(vm.item, 'transformations.cropping')) {
        vm.shouldRequestBySize = false;
      }
      if (vm.shouldRequestBySize) {
        vm.requestImageSize = getRequestImageSize(vm.item.originalSize, vm.containerSize);
      }
      vm.transformStyle = getImageTransformStyle
        .getImageStyle(vm.item.transformations, vm.containerSize, vm.item.originalSize);
      vm.canvasStyle = getImageTransformStyle
        .getCanvasStyle(vm.item.transformations, vm.containerSize, vm.item.originalSize);
      // update bounding boxes after transformation
      updateBoundingBoxes();
    }

    function updateBoundingBoxes() {
      if (!vm.canvasStyle) {
        return;
      }
      const {inset, rotation, scale, size} = vm.canvasStyle; // canvasStyle.size is after rotation & scale
      vm.boxesInsideImage = [];
      vm.boxesOnWholeImage = [];
      _.forEach(
        mergeAugBoxes(_.get(vm.item, 'augBoxes'), vm.showBoundingBox),
        (item) => {
          const result = {
            tagText: item.tagText,
            rectangle: getBoundingBoxAfterTransform({box: item.rectangle, scale, rotation, size, inset})
          };
          if (item.rectangle) {
            vm.boxesInsideImage.push(result);
          } else {
            vm.boxesOnWholeImage.push(result);
          }
        }
      );
    }

    function getBoundingBoxAfterTransform({box, scale, rotation, size, inset}) {
      if (!box) {
        if (inset) {
          const {left, right, top, bottom} = inset;
          return new BoundingBox(left, top, size.width - left - right, size.height - top -bottom);
        }
        return new BoundingBox(0, 0, size.width, size.height);
      }
      return BoundingBox.rotate(BoundingBox.scale(box, scale), rotation, size);
    }
  }
})();
