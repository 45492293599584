(function() {
  'use strict';

  function Controller() {
    'ngInject';
  }

  angular
    .module('eva2-angular')
    .component('modalCloseButton', {
      bindings: {
        onCloseButtonClicked: '&'
      },
      template: require('./modalCloseButton.html'),
      controller: Controller
    });
})();
