(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);

  appConfig.$inject = ['$stateProvider'];

  angular
    .module('eva2-angular')
    .controller('ForbiddenCtrl', ForbiddenCtrl);

  function ForbiddenCtrl(getAdminEmailList, $stateParams, tFilter) {
    'ngInject';
    const vm = this;

    vm.domain = $stateParams.domain;

    vm.line1 = tFilter('FORBIDDEN.RESTRICTED_TEXT');
    vm.line2 = tFilter('FORBIDDEN.CONTACT_OTHER');

    if (vm.domain) {
      getAdminEmailList(vm.domain).then((emails) => {
        vm.adminEmailList = emails;
      });
    }
  }

  function appConfig($stateProvider) {
    $stateProvider.state('app.assignment.forbidden', {
      url: '/forbidden',
      data: {
        title: 'PAGE_TITLE.FORBIDDEN'
      },
      views: {
        'content@app': {
          template: require('./forbidden.html'),
          controller: 'ForbiddenCtrl',
          controllerAs: 'vm'
        }
      }
    }).state('app.forbidden', {
      url: '/forbidden',
      data: {
        title: 'PAGE_TITLE.FORBIDDEN'
      },
      views: {
        'content@app': {
          template: require('./forbidden.html'),
          controller: 'ForbiddenCtrl',
          controllerAs: 'vm'
        }
      }
    });
  }
})();

