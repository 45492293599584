"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getJobDuration = exports.getYearToSecondsTimeArray = exports.getDaysToMillisecondsTimeArray = exports.duration = exports.ONE_YEAR = exports.ONE_MONTH = exports.ONE_DAY = exports.ONE_HOUR = exports.ONE_MINUTE = exports.ONE_SECOND = void 0;
var date_fns_1 = require("date-fns");
exports.ONE_SECOND = 1000;
exports.ONE_MINUTE = 60 * exports.ONE_SECOND;
exports.ONE_HOUR = exports.ONE_MINUTE * 60;
exports.ONE_DAY = exports.ONE_HOUR * 24;
exports.ONE_MONTH = exports.ONE_DAY * 30;
exports.ONE_YEAR = exports.ONE_DAY * 365;
// @param duration{Number}: a time duration in milliseconds
// @param isVerbose{Boolean}: if true, the output will be in verbose format, e.g "1 day 2 hours 3 minutes"
// @param customGetText{Function}: a custom function tp format the time array to a string
// @returns {String}: a string that represents the duration in days, hours, minutes, seconds and milliseconds
var duration = function (duration, isVerbose, customGetText) {
    if (Number.isNaN(duration)) {
        return '';
    }
    var getConciseText = function (timeArray) {
        // convert time array to a string, e.g "*d *h *m"
        timeArray.pop();
        var unitArray = ['d', 'h', 'm'];
        var isZero = function (value) { return value === 0; };
        if (timeArray.every(isZero)) {
            return '0m';
        }
        return timeArray.reduce(function (prevValue, currentValue, currentIndex) {
            return currentValue === 0 ? prevValue : "".concat(prevValue, " ").concat(currentValue).concat(unitArray[currentIndex]);
        }, '');
    };
    var getVerboseText = function (timeArray) {
        timeArray.pop();
        var unitArray = [' day', ' hour', ' minute', ' second'];
        var isZero = function (value) { return value === 0; };
        if (timeArray.every(isZero)) {
            return '0 minutes';
        }
        return timeArray.reduce(function (prevValue, currentValue, currentIndex) {
            if (currentValue === 0) {
                return prevValue;
            }
            if (currentValue === 1) {
                return "".concat(prevValue, " ").concat(currentValue).concat(unitArray[currentIndex]);
            }
            return "".concat(prevValue, " ").concat(currentValue).concat(unitArray[currentIndex], "s");
        }, '');
    };
    //   const getText = customGetText || (isVerbose ? getVerboseText : getConciseText);
    var getText = function (timeArray) {
        if (customGetText) {
            return customGetText(timeArray);
        }
        if (isVerbose) {
            return getVerboseText(timeArray);
        }
        return getConciseText(timeArray);
    };
    return getText((0, exports.getDaysToMillisecondsTimeArray)(duration)).trim(); // remove space in the beginning of the string
};
exports.duration = duration;
var getDaysToMillisecondsTimeArray = function (duration) {
    // convert duration from milliseconds to an array [days, hours, minutes, seconds, milliseconds]
    var days = Math.floor(duration / exports.ONE_DAY);
    var dayRemainder = duration % exports.ONE_DAY;
    var hours = Math.floor(dayRemainder / exports.ONE_HOUR);
    var hourRemainder = dayRemainder % exports.ONE_HOUR;
    var minutes = Math.floor(hourRemainder / exports.ONE_MINUTE);
    var minutRemainder = hourRemainder % exports.ONE_MINUTE;
    var seconds = Math.floor(minutRemainder / exports.ONE_SECOND);
    var secondRemainder = minutRemainder % exports.ONE_SECOND;
    var milliseconds = Math.floor(secondRemainder);
    return [days, hours, minutes, seconds, milliseconds];
};
exports.getDaysToMillisecondsTimeArray = getDaysToMillisecondsTimeArray;
var getYearToSecondsTimeArray = function (duration) {
    // convert duration from seconds to an array [years, months, days, hours, minutes, seconds]
    var years = Math.floor(duration / exports.ONE_YEAR);
    var yearRemainder = duration % exports.ONE_YEAR;
    var months = Math.floor(yearRemainder / exports.ONE_MONTH);
    var monthRemainder = yearRemainder % exports.ONE_MONTH;
    var days = Math.floor(monthRemainder / exports.ONE_DAY);
    var dayRemainder = yearRemainder % exports.ONE_DAY;
    var hours = Math.floor(dayRemainder / exports.ONE_HOUR);
    var hourRemainder = dayRemainder % exports.ONE_HOUR;
    var minutes = Math.floor(hourRemainder / exports.ONE_MINUTE);
    var minutesRemainder = hourRemainder % exports.ONE_MINUTE;
    var seconds = Math.floor(minutesRemainder / exports.ONE_SECOND);
    return [years, months, days, hours, minutes, seconds];
};
exports.getYearToSecondsTimeArray = getYearToSecondsTimeArray;
var getJobDuration = function (lastHistoryTimestamp) {
    var yearToSecondsTimeArray = (0, exports.getYearToSecondsTimeArray)((0, date_fns_1.differenceInMilliseconds)(new Date(), new Date(lastHistoryTimestamp)));
    var yearToSecondsUnitArray = ['year', 'month', 'day', 'hour', 'minute', 'second'];
    // first non zero index is the first index where time is > 0
    var firstNonZeroIndex = yearToSecondsTimeArray.findIndex(function (time) { return time > 0; });
    var timeValue = yearToSecondsTimeArray[firstNonZeroIndex];
    var timeUnit = yearToSecondsUnitArray[firstNonZeroIndex];
    return { timeValue: timeValue, timeUnit: timeUnit };
};
exports.getJobDuration = getJobDuration;
