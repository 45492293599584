import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').service('getImageTransformStyle', function(getFittedImageScale) {
    function getTranslateAndScale(cropping, containerSize, imageSize, rotation) {
      let {width, height} = imageSize;
      if ([90, -90, 270, -270].indexOf(rotation) !== -1) {
        width = imageSize.height;
        height = imageSize.width;
      }

      if (!cropping) {
        cropping = {
          x1: 0,
          y1: 0,
          x2: width,
          y2: height
        };
      }
      // get scale
      const oldScale = getFittedImageScale(containerSize, imageSize);
      const newScale = getFittedImageScale(containerSize, {
        width: cropping.x2 - cropping.x1,
        height: cropping.y2 - cropping.y1
      });
      const scale = newScale/oldScale;

      // get translate

      const translateX = ((cropping.x1 + cropping.x2 - width) / 2 * scale + imageSize.width / 2) / imageSize.width;
      const translateY = ((cropping.y1 + cropping.y2 - height) / 2 * scale + imageSize.height / 2) / imageSize.height;

      return {
        scale, translateX, translateY
      };
    }

    function getInsetPercentage(x1, x2, y1, y2, width, height) {
      return {
        top: y1 / height,
        right: (width - x2) / width,
        bottom: (height - y2) / height,
        left: x1 / width
      };
    }

    function rotateData(inset, rotation) {
      const data = [inset.top, inset.right, inset.bottom, inset.left];
      const rotateNTimes = rotation > 0 ? rotation / 90 : (rotation + 360) / 90;
      _.times(rotateNTimes, ()=> {
        data.push(data.shift());
      });
      return {top: data[0], right: data[1], bottom: data[2], left: data[3]};
    }

    function getInset(cropping, imageSize, rotation) {
      if (!cropping || !imageSize) {
        return null;
      }
      const {x1, x2, y1, y2} = cropping;
      let {width, height} = imageSize;

      if ([90, -90, 270, -270].indexOf(rotation) !== -1) {
        width = imageSize.height;
        height = imageSize.width;
      }
      return rotateData(getInsetPercentage(x1, x2, y1, y2, width, height), rotation);
    }


    function getImageStyle(transforms, containerSize, imageSize) {
      // if containerSize is set, it means it's place where might display transformations,
      // in this case, max-width and max-height will be none, and width/height will be manually calculated.
      if (!containerSize || !imageSize || !_.get(imageSize, 'width') || !_.get(imageSize, 'height')) {
        return;
      }
      const style = {transform: ''};
      const rotation = _.get(transforms, 'rotation');
      const cropping = _.get(transforms, 'cropping');

      // add rotation
      if (rotation) {
        style['transform'] = `rotate(${rotation}deg)`;
      }

      // add inset
      const inset = getInset(cropping, imageSize, rotation);
      if (inset) {
        style['clip-path'] = `inset(${inset.top * 100}% ${inset.right * 100}% ${inset.bottom * 100}% ${inset.left * 100}%)`;
      }

      const {scale, translateX, translateY} = getTranslateAndScale(cropping, containerSize, imageSize, rotation);
      // add scale
      style['transform'] = `scale(${scale}) ` + style['transform'];

      // add translate (to center image after cropping and rotation)
      style['transform'] = `translate(${-translateX * 100}%, ${-translateY * 100}%) ` + style['transform'];

      return style;
    }

    function getCanvasStyle(transforms, containerSize, imageOriginalSize) {
      // if containerSize is set, it means it's place where might display transformations,
      // in this case, max-width and max-height will be none, and width/height will be manually calculated.
      if (!containerSize || !imageOriginalSize || !_.get(imageOriginalSize, 'width') || !_.get(imageOriginalSize, 'height')) {
        return;
      }

      const croppingInOriginalSize = _.get(transforms, 'cropping');
      const rotation = _.get(transforms, 'rotation');
      const getTranslateAndScale = (cropping, containerSize, naturalImageSize, rotation) => {
        // cropping has coordinates in the new coordinates system after rotation
        const rotatedNaturalImageSize = _.includes([90, -90, 270, -270], rotation) ?
          {width: naturalImageSize.height, height: naturalImageSize.width} : naturalImageSize;

        cropping = cropping ||{x1: 0, y1: 0, x2: rotatedNaturalImageSize.width, y2: rotatedNaturalImageSize.height};
        const cropWidth = cropping.x2 - cropping.x1;
        const cropHeight = cropping.y2 - cropping.y1;

        const scale = getFittedImageScale(containerSize, {width: cropWidth, height: cropHeight});

        // get translate
        const translateX = - 0.5 * rotatedNaturalImageSize.width * scale
          + (0.5 * rotatedNaturalImageSize.width - (cropping.x1 + 0.5 * cropWidth)) * scale;
        const translateY = - 0.5 * rotatedNaturalImageSize.height * scale
          + (0.5 * rotatedNaturalImageSize.height - (cropping.y1 + 0.5 * cropHeight)) * scale;

        return {
          scale: scale, translateX, translateY,
          width: naturalImageSize.width * scale, height: naturalImageSize.height * scale
        };
      };
      const {scale, translateX, translateY, width, height} = getTranslateAndScale(croppingInOriginalSize, containerSize,
        imageOriginalSize, rotation);

      const style = {transform: ''};

      const sizeAfterScaleAndRotation = _.includes([90, -90, 270, -270], rotation) ?
        {width: height, height: width}: {width, height};

      const getInset = (cropping, sizeAfterScaleAndRotation, scale) =>{
        if (!cropping) {
          return null;
        }

        return {
          top: cropping.y1 * scale, // minus the tag height
          right: sizeAfterScaleAndRotation.width - cropping.x2 * scale,
          bottom: sizeAfterScaleAndRotation.height - cropping.y2 * scale,
          left: cropping.x1 * scale
        };
      };
      const inset = getInset(croppingInOriginalSize, sizeAfterScaleAndRotation, scale);
      const insetStyle = inset ? {
        'width': sizeAfterScaleAndRotation.width + 'px',
        'height': sizeAfterScaleAndRotation.height + 'px',
        'clip-path': `inset(${inset.top}px ${inset.right}px ${inset.bottom}px ${inset.left}px)`
      }: null;

      // add translate (to center image after cropping and rotation)
      style['transform'] = `translate(${translateX}px, ${translateY}px) ` + style['transform'];

      style['width'] = sizeAfterScaleAndRotation.width + 'px';
      style['height'] = sizeAfterScaleAndRotation.height + 'px';

      return {
        inset,
        style,
        scale,
        insetStyle,
        rotation,
        size: {
          width: sizeAfterScaleAndRotation.width,
          height: sizeAfterScaleAndRotation.height
        }
      };
    }

    return {
      getImageStyle,
      getCanvasStyle
    };
  });
})();
