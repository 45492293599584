(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);

  appConfig.$inject = ['$stateProvider'];

  function appConfig($stateProvider) {
    $stateProvider.state('app.assignment.automation', {
      url: '/automation',
      abstract: true,
      data: {
        nav: {
          secondary: [{
            name: 'AUTOMATION.DECISION_RULES.NAV',
            state: 'app.assignment.automation.filters',
            allStatesUnderNavTab: [
              'app.assignment.automation.filters',
              'app.assignment.automation.filterCreate',
              'app.assignment.automation.filterEdit',
              'app.assignment.automation.filterView'
            ]
          }, {
            name: 'AUTOMATION.LISTS.NAV',
            state: 'app.assignment.automation.dictionaries',
            allStatesUnderNavTab: [
              'app.assignment.automation.dictionaries',
              'app.assignment.automation.dictionaryCreate',
              'app.assignment.automation.dictionaryEdit'
            ]
          }]
        }
      },
      views: {
        'content@app': {
          template: require('@partial/layouts/layouts2.html')
        }
      }
    });
  }
})();
