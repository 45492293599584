import _ from 'lodash';
import {getVariablesToTest} from '@utils/blangEditor/blangEditorUtil';
import {VARIABLES_REF} from '@utils/blangEditor/variableRef';

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('filterTester', filterTester);

  function filterTester() {
    const directive = {
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      restrict: 'E',
      template: require('./filterTester.html'),
      scope: {
        filter: '=',
        validRule: '=',
        ruleVariables: '<'
      }
    };
    return directive;
  }


  function Controller($scope, ApiFilters, ApiAd, $stateParams, Notifier, HttpErrorManager, $timeout, tFilter) {
    const booleanDefault = false;

    const vm = this;
    getLastAdAsBlangContent().then(() => {
      testFilter();
    });
    vm.testFilter = testFilter;
    vm.testFilterLoading = false;
    vm.variables = VARIABLES_REF;

    function testFilter() {
      vm.testResult = null;
      vm.testFilterLoading = true;
      if (vm.testFilterTimeout) {
        $timeout.cancel(vm.testFilterTimeout);
      }

      vm.testFilterTimeout = $timeout(testFilterApiCall, 400);
    }

    function testFilterApiCall() {
      const reqOptions = {
        domain: $stateParams.domain
      };

      function whenError(error) {
        if (HttpErrorManager.shouldDisplayErrorMessage(error.status)) {
          Notifier.display(tFilter('AUTOMATION.DECISION_RULES.ERROR.ERROR_RUN_RULE'), {type: 'failure', ttl: 6000}, error);
        }
      }

      function resetTestFilterLoading() {
        vm.testFilterLoading = false;
      }

      if (!vm.filter || !vm.content) {
        return;
      }
      // To prevents sending "" to /filters/test api, because "" is considered existing variable EXIST("") -> Expression matches!
      const filterTestContentPayload = {
        ...vm.content,
        ..._.mapValues(vm.content, (value) => {
          if (value === '') {
            return null;
          }
          return value;
        })
      };
      ApiFilters.test(reqOptions, {filter: vm.filter, content: [filterTestContentPayload]})
        .then(function(results) {
          vm.testResult = results[0];
        })
        .catch(whenError)
        .finally(resetTestFilterLoading);
    }

    function getLastAd() {
      return ApiAd.getLatest($stateParams.domain).then(function(result) {
        const ad = result.ad;
        if (ad && ad[0]) {
          return ad[0].current;
        } else {
          return {};
        }
      });
    }

    function getLastAdAsBlangContent() {
      return getLastAd().then(function(ad) {
        vm.content = {};
        _.forIn(vm.variables, function(item, key) {
          if (typeof (item.adPath) === 'string') {
            vm.content[key] = _.get(ad, item.adPath);
            // Fixed: IMP-1599 Incorrect result when testing $professional field in filter-tester
            if (vm.content[key] === undefined && item.fieldType === 'boolean') {
              vm.content[key] = booleanDefault;
            }
          } else {
            vm.content[key] = item.adPath(ad);
          }
        });
      });
    }



    function onRuleChange() {
      if (!vm.validRule) {
        return;
      }
      if(vm.ruleVariables){
        // /validate api returns an array or a single object
        vm.testFields = getVariablesToTest(vm.ruleVariables, vm.variables);
      }
      testFilter();
    }

    function onContentChange() {
      if (!vm.validRule) {
        return;
      }

      if (!vm.content) {
        return;
      }
      const text = [vm.content.title, vm.content.body].filter(x => x).join(' ');
      vm.content.text = text;
      testFilter();
    }

    $scope.$watch('vm.filter.rule', function() {
      onRuleChange();
    });
    $scope.$watch('vm.ruleVariables', function() {
      onRuleChange();
    });

    $scope.$watch('vm.content', function() {
      onContentChange();
    }, true);
  }
})();
