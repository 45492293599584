import _ from 'lodash';
(function() {
  'use strict';

  angular.module('eva2-angular').service('createNotice', function(AugBox, createBoundingBox) {
    function createDetectionNotice(modelName, getNoticeText, tagText) {
      const noticeId = modelName;
      return {
        id: noticeId,
        getNoticeText,
        tagText,
        getAugBoxes: function(imageAugResult) {
          return _.map(_.get(imageAugResult, `result.${modelName}.value`,), (item)=>
            new AugBox(noticeId, createBoundingBox(item.bounding_box), tagText));
        }
      };
    }

    function createClassificationNotice(modelName, classificationValue, tagText) {
      const noticeId = `${modelName}:${classificationValue}`;
      return {
        id: noticeId,
        getNoticeText: ()=> tagText,
        tagText,
        getAugBoxes: (imageAugResult)=>
          _.get(imageAugResult, `result.${modelName}.value.best_match`) === classificationValue ?
            [new AugBox(
              noticeId,
              null,
              tagText
            )]: []
      };
    }

    function createChainedModelNotice(parentModelName, chainedModelName,
      chainedModelClassificationValue, getNoticeText, tagText) {
      const noticeId = `${parentModelName}:${chainedModelName}:${chainedModelClassificationValue}`;
      return {
        id: noticeId,
        getNoticeText,
        tagText,
        getAugBoxes: (imageAugResult)=> {
          const results = [];
          _.forEach(_.get(imageAugResult, `result.${parentModelName}.value`), (item)=> {
            if (_.get(item, `${chainedModelName}.value.best_match`) === chainedModelClassificationValue) {
              results.push(new AugBox(
                noticeId,
                createBoundingBox(item.bounding_box),
                tagText
              ));
            }
          });
          return results;
        }
      };
    }


    return {
      detection: createDetectionNotice,
      classification: createClassificationNotice,
      chainedModel: createChainedModelNotice
    };
  });
})();
