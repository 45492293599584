import _ from 'lodash';
(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .factory('JobPosition', JobPosition);

  function JobPosition() {
    'ngInject';

    class JobPosition {
      constructor(index, start, height) {
        this.index = index;
        this.start = start;
        this.height = height;
        this.end = start + height;
      }
      updateShowingHeight(slidingWindowStart, slidingWindowEnd) {
        this.showingHeight = _.min([this.end, slidingWindowEnd]) - _.max([this.start, slidingWindowStart]);
      }
    }

    return JobPosition;
  }
})();
