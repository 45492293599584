import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').service('getUserPreviousItemsCount', function(ApiService, $q, roundNumber, tFilter) {
    return function(domain, job) {
      const userId = _.get(job, 'ad.current.user.id');
      if (!userId || !domain) {
        return;
      }
      const userPreviousItemsCount = [
        {status: 'APPROVE', tooltip: tFilter('ITEM.STATUS', {STATUS: 'approved'}, 'messageformat')},
        {status: 'REFUSE', tooltip: tFilter('ITEM.STATUS', {STATUS: 'refused'}, 'messageformat')}
      ];

      const promises = userPreviousItemsCount
        .map((item)=> ApiService.getUserPreviousItemsCount(domain, userId, item.status));

      return $q.all(promises).then((data)=> {
        const sum = data.reduce((acc, cur)=> acc + cur.count, 0);
        userPreviousItemsCount.forEach((item, index)=> {
          item.count = data[index].count;
          const percentage = item.count / sum * 100;
          item.countInPercentage = roundNumber(percentage, 1) + '%';
          item.searchQuery = item.count > 0 ? `document.user.id:"${userId}" AND metadata.result:"${item.status}"`: '';
        });
        job.userPreviousItemsCount = userPreviousItemsCount;
        job.userCountFetched = true;
      });
    };
  });
})();
