/*!
 * eva-gallery-for-job-image
 * Description: eva gallery component in job image template
 */
import MediaGalleryLarge from '../../../../react-app/features/moderationItem/mediaGallery/components/MediaGalleryLarge';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../../../../redux/store';
import { Provider } from 'react-redux';


(function () {
  'use strict';

  function Controller($scope, $state, $timeout) {
    'ngInject';

    //private
    const vm = this;
    let reactRoot = null;
    vm.mediaGalleryLargeId = null;
    //public
    vm.$onInit = () => {
      vm.total = (vm.images ? vm.images.length : 0) + (vm.videos ? vm.videos.length : 0);
      vm.mediaGalleryLargeId = `gallery-for-job-image-${vm.index}`;

      vm.getPrev = () => {
        const prev = parseInt(vm.selectedKey) - 1;
        if (prev >= 0) {
          vm.selectGalleryItem(`${prev}`);
        }
      };
      vm.getNext = () => {
        const next = parseInt(vm.selectedKey) + 1;
        if (next <= vm.total - 1) {
          vm.selectGalleryItem(`${next}`);
        }
      };

      const disableVideo = (id) => {
        $timeout(() => {
          const isDeleted = !!vm.videos.find((image) => image.id === id).deleted;
          vm.videos = vm.videos.map((video) => {
            return video.id === id ? { ...video, deleted: !isDeleted } : video;
          });
        });
      };

      const handleImageDeletion = (id) => {
        $timeout(() => {
          const isDeleted = !!vm.images.find((image) => image.id === id).deleted;
          vm.images = vm.images.map((image) => {
            return image.id === id ? { ...image, deleted: !isDeleted } : image;
          });
        });
      };

      const handleImageTransformations = (id, transformations) => {
        $timeout(() => {
          vm.images = vm.images.map((image) => {
            return image.id === id ? { ...image, transformations: transformations } : image;
          });
        });
      };

      const renderMediaGalleryLarge = () => {
        if (vm.mediaGalleryLargeId) {
          $timeout(() => {
            if (!reactRoot) {
              reactRoot = createRoot(document.querySelector(`#${vm.mediaGalleryLargeId}`));
            }
            reactRoot.render(<Provider store={store}>
              {vm.total > 0 &&
                <MediaGalleryLarge
                  angularJsDependencies={{ $state, handleImageDeletion, handleImageTransformations }}
                  showBoundingBox={vm.showBoundingBox}
                  actionContainerStyle={vm.actionContainerStyle}
                  isEditModeEnabled={!vm.isDisabled}
                  videosMetadata={vm.videos || []}
                  disableVideo={disableVideo}
                  imagesMetadata={vm.images || []}
                  imageCroppingAllowed={vm.imageCroppingAllowed}
                  imageRotationAllowed={vm.imageRotationAllowed}
                  shouldDeferLoadingImages={vm.shouldDeferLoadingImages || false}
                  modalParentElementSelector={`#${vm.mediaGalleryLargeId}`}
                  adId={vm.adId}
                />}
            </Provider>);
          });
        }
      };

      $scope.$watch('vm.isDisabled', () => {
        renderMediaGalleryLarge();
      }, true);

      $scope.$watch('vm.videos', () => {
        renderMediaGalleryLarge();
      }, true);


      $scope.$watch('vm.showBoundingBox', () => {
        renderMediaGalleryLarge();
      }, true);


      $scope.$watch('vm.images', () => {
        renderMediaGalleryLarge();
      }, true);


    };
  }

  angular
    .module('eva2-angular')
    .component('evaGalleryForJobImage', {
      bindings: {
        images: '=',
        videos: '=',
        isDisabled: '<',
        imageCroppingAllowed: '<',
        imageRotationAllowed: '<',
        preselectedIndex: '<',
        updateSelectedIndex: '&',
        showBoundingBox: '<',
        shouldDeferLoadingImages: '<',
        index: '<',
        adId: '<'
      },
      template: require('./galleryForJobImage.html'),
      controller: Controller,
      controllerAs: 'vm'
    });
})();
