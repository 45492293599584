(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .service('DeleteAllContentJobsManager', function($interval) {
      'ngInject';

      this.jobs = {};

      this.update = (domain, job)=> {
        if (this.jobs[domain]) {
          $interval.cancel(this.jobs[domain].promise);
        }
        this.jobs[domain] = job;
      };

      this.getJobByDomain = (domain) => {
        return this.jobs[domain];
      };
    });
})();
