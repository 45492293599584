(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);

  function appConfig($stateProvider) {
    $stateProvider.state('app.assignment.automation.dictionaries', {
      url: '/lists',
      data: {
        title: 'PAGE_TITLE.LIST'
      },
      views: {
        content: {
          template: require('./dictionaries.html'),
          controller: 'AdminDictionariesCtrl',
          controllerAs: 'vm'
        }
      }
    });
  }
})();
