angular.module('eva2-angular').filter('status', function() {
  'use strict';

  return function(fullStr) {
    fullStr = fullStr || '';
    fullStr = fullStr + '';
    switch (fullStr) {
    case 'new':
      return 'processing';
    case 'deferred':
      return 'escalated';
    default:
      return fullStr;
    }
  };
});
