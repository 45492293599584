(function() {
  'use strict';
  angular.module('eva2-angular').controller('InfoModalCtrl', function($scope, $modalInstance, data, tFilter) {
    const vm = this;
    vm.resources = {
      button: tFilter('SETTING.CSV_IMPORTER.IMPORT_DATA.SUCCESS_MODAL.GOT_IT_BTN')
    };
    vm.text = data.text;
    vm.title = data.title;
    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
  });
})();

