import SecurityWarningModal from '@/react-app/common/components/SecurityWarningModal';
import { openLinkInNewTab, shouldOpenSecurityWarningModal } from '@/react-app/common/utils/linkUtils';
import store from '@store';
import { default as React } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

(function() {
  'use strict';

  function Controller($stateParams, $scope, validator,$element, $timeout) {
    const autosize = require('autosize');

    const vm = this;
    vm.domain = $stateParams.domain;
    vm.$onInit = () => {
      vm.booleanTypes = [{value:true, label:'true'}, {value:false, label:'false'}];
      vm.mode = vm.mode || 'VIEW';
      vm.readOnlyField = false;
      vm.isValueUrl = validator.isUrl(vm.value);
      vm.inputType = 'text';
      if(typeof vm.value === 'object' || vm.value === 'null' || vm.value ===  null|| vm.value === 'undefined' || Array.isArray(vm.value)){
        vm.readOnlyField = true;
      }else if(vm.isValueUrl){
        vm.inputType = 'text';
      }else if(typeof vm.value === 'string') {
        vm.inputType = 'text';
      }else if(vm.value === 'true' || vm.value === 'false' || vm.value === true || vm.value === false){
        vm.inputType = 'boolean';
      } else if(!isNaN(vm.value)) {
        vm.inputType = 'number';
      }
      vm.isValid = vm.checkIfValid();
      vm.confirmedValue = vm.value;
    };

    vm.onClick = function() {
      const height = $element.find('.eva-text-box-customer-specific-value-container').height();
      if (!this.isDisabled && !this.readOnlyField) {
        this.value = vm.confirmedValue || this.value;
        this.mode = 'EDIT';
        vm.confirmedValue = vm.value;
        $timeout(()=> {
          if( vm.inputType === 'text'){
            $element.find('textarea').height(height);
            autosize($element.find('textarea'));
            $element.find('textarea').focus();
          }else if( vm.inputType === 'boolean'){
            $element.find('select').focus();
          }else{
            $element.find('input').focus();
          }

        });
      }
    };

    vm.checkIfValid = () =>{
      if(vm.inputType === 'number'){
        return !isNaN(vm.value);
      }
      return true;
    };

    vm.onBlur = function() {
      if(!vm.isValid){
        vm.resetToOriginalValue();
      }else{
        this.mode = 'VIEW';
        vm.confirmedValue = vm.value;
        vm.onUpdate();
      }
    };

    vm.onKeyDown = function(event){

      if (event.keyCode === 13 && event.shiftKey && vm.isValid) {
        vm.onBlur();
        event.preventDefault();
        event.stopPropagation();
      }
    };
    vm.resetToOriginalValue = () => {
      vm.value = vm.confirmedValue;
      vm.onUpdate();
      this.mode = 'VIEW';
    };

    $scope.$watch('$ctrl.value', () => {
      vm.isValid = vm.checkIfValid();
    }, true);

    this.handleAnchorLinkClick =(link)=>{
      const shouldOpenModal= shouldOpenSecurityWarningModal(link, vm.trustedDomains);
      if(shouldOpenModal){
        vm.link=link;
        setIsModalOpen(true);
      } else{
        openLinkInNewTab(link);
      }
    };
    const setIsModalOpen = (isOpen) => {
      vm.isModalOpen = isOpen;
    };

    $scope.$watchGroup(['$ctrl.isModalOpen', '$ctrl.link'], ()=> {
      renderModal();
    });

    const renderModal = () => {
      $timeout(() => {
        const root = createRoot(document.querySelector('#modal-cs'));
        root.render(<Provider store={store}>
          <SecurityWarningModal
            isOpen={vm.isModalOpen}
            setIsOpen={setIsModalOpen}
            link={vm.link}
          />
        </Provider>        
        );
      });
    };

  }
  angular
    .module('eva2-angular')
    .component('evaTextBoxCustomerSpecific', {
      bindings: {
        mode: '@',
        label: '<',
        value: '=',
        isDisabled: '<',
        onUpdate: '&',
        dictionaries: '<',
        trustedDomains: '<'
      },
      template: require('./textBoxCustomerSpecific.html'),
      controller: Controller
    });
})();
