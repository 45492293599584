"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DATE_PICKER_CONFIG = exports.DATE_PICKER_DISPLAY = void 0;
var date_1 = require("@utils/date");
var datePickerUtil_1 = require("@components/DatePicker/datePickerUtil");
var DATEPICKER_ICONS = {
    time: 'date-picker-icon implioicons-clock',
    date: 'date-picker-icon implioicons-calendar',
    up: 'date-picker-icon implioicons-chevron-up',
    down: 'date-picker-icon implioicons-chevron-down',
    previous: 'date-picker-icon implioicons-chevron-left',
    next: 'date-picker-icon implioicons-chevron-right',
    today: 'date-picker-icon implioicons-home',
    clear: 'date-picker-icon implioicons-trash',
    close: 'date-picker-icon implioicons-cross',
};
var DATE_PICKER_BUTTONS = function (allowNull) { return ({
    today: false,
    clear: allowNull,
    close: true,
}); };
var DATE_PICKER_DISPLAY = function (allowNull) { return ({
    icons: DATEPICKER_ICONS,
    buttons: DATE_PICKER_BUTTONS(allowNull),
}); };
exports.DATE_PICKER_DISPLAY = DATE_PICKER_DISPLAY;
var DATE_PICKER_CONFIG = function (parentRef, allowNull, dateFormat) { return ({
    container: parentRef.current,
    localization: {
        format: dateFormat ||
            ((0, date_1.localeUses24HourTime)() // PM-AM vs 24h
                ? datePickerUtil_1.FORMAT_24H_TD
                : datePickerUtil_1.FORMAT_AMPM_TD),
    },
    display: __assign(__assign({}, (0, exports.DATE_PICKER_DISPLAY)(allowNull || false)), { theme: 'light' }),
}); };
exports.DATE_PICKER_CONFIG = DATE_PICKER_CONFIG;
