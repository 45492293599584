(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);

  appConfig.$inject = ['$stateProvider'];

  function appConfig($stateProvider) {
    $stateProvider.state('app', {
      abstract: true,
      views: {
        'main@': {
          template: require('@partial/layouts/layouts.html')
        },
        'navbar@app': {
          template: require('@partial/layouts/navbar.html')
        }
      }
    });
  }
})();
