angular.module('eva2-angular').service('ApiQueue', function(ApiService, $q, ajax) {
  'use strict';

  const ApiQueue = {
    index: function index(options) {
      return $q.all([
        ajax.get(`/sites/${options.domain}/categories`),
        ajax.get(`/sites/${options.domain}/types`)
      ]);
    }
  };
  return ApiQueue;
});
