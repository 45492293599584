(function() {
  'use strict';

  angular.module('eva2-angular').service('checkTeamCreationStatus', function(ApiService, $timeout) {
    'ngInject';


    let isAccountCreatedPromise;

    async function checkTeamCreationStatus(domain, retriesLeft, onSuccess, onFailure, createdTeamData) {
      if(retriesLeft  <= 0) {
        $timeout.cancel(isAccountCreatedPromise);
        onFailure();
      } else {
        try {
          const {status} = await ApiService.getTeamCreationStatus(domain);
          if(status === 'ready'){ 
            $timeout.cancel(isAccountCreatedPromise);
            onSuccess(createdTeamData);
          } else {
            isAccountCreatedPromise = $timeout(() => {
              checkTeamCreationStatus(domain,retriesLeft-1, onSuccess, onFailure, createdTeamData);
            }, 3000);
          }
        } catch (err) {
          onFailure(err);
        }
      }
    }

    return checkTeamCreationStatus;

  });
})();
