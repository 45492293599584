/* This component shows the status of a promise.

There are 1 loading icon and 2 result icons (success icon and error icon)

When a promise is created, the spinning icon starts to show, result icons will be cleared.
When the promise is fulfilled, the spinning icon will be hidden.
    - If the promise is resolved, a success icon will be shown.
    - If the promise is rejected, an error icon will be shown.
    - Result icons will be cleared after 2 seconds.
* */

(function() {
  'use strict';

  function Controller($scope, $timeout) {
    const SHOW_RESULT_ICON_DURATION = 2000;
    const vm = this;
    $scope.$watch('$ctrl.promise', function(newVal, oldVal) {
      if (!newVal || newVal === oldVal) {
        return;
      }
      vm.isLoading = true;
      clearResultIcons();

      vm.promise.then(() => {
        vm.showSuccessIcon = true;
      }, ()=> {
        vm.showErrorIcon = true;
      }).finally(() => {
        vm.isLoading = false;
        $timeout(()=> {
          clearResultIcons();
        }, SHOW_RESULT_ICON_DURATION);
      });
    });

    function clearResultIcons() {
      vm.showSuccessIcon = false;
      vm.showErrorIcon = false;
    }
  }

  angular
    .module('eva2-angular')
    .component('evaLoadingIndicator', {
      bindings: {
        promise: '<'
      },
      template: require('./loadingIndicator.html'),
      controller: Controller
    });
})();
