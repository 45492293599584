(function() {
  'use strict';
  angular.module('eva2-angular').service('getRoles', function(ApiService, getTranslatedRoleLabelFilter) {
    function getRoles() {
      return ApiService.getRoles().then((roles)=> {
        roles.sort(compareHierarchy)
          .forEach((role) => {
            role.label = getTranslatedRoleLabelFilter(role.role);
          });
        return roles;
      });

      function compareHierarchy(a, b) {
        return a.hierarchy - b.hierarchy;
      }
    }

    return getRoles;
  });
})();
