(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);

  appConfig.$inject = ['$stateProvider'];

  function appConfig($stateProvider) {
    $stateProvider.state('app.signup', {
      url: '/signup',
      data: {
        title: 'PAGE_TITLE.SIGN_UP',
        transitionPolicy: {
          requireAuth: false,
          allowLoggedIn: false
        }
      },
      params: {
        email: '',
        teamname: '',
        token: '',
        domain: 'domain',
        inviteParams: {
          email: '',
          teamname: '',
          token: '',
          domain: ''
        }
      },
      views: {
        'main@': {
          template: require('@partial/start/start.html')
        },

        'content@app.signup': {
          template: require('./signup.html'),
          controller: 'SignupCtrl',
          controllerAs: 'vm'
        }
      }
    });
  }
})();
