import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').service('getImageAugNotices', function(IMAGE_AUG_NOTICES) {
    return function(images) {
      const imageAugNotices = [];
      IMAGE_AUG_NOTICES.forEach((NOTICE)=> {
        // for each notice type, check all the images, gather all the augBoxs with the same noticeId
        let count = 0;
        _.forEach(images, (image)=> {
          const matchedAugBoxes = _.filter(_.get(image, 'augBoxes'), (augBox)=> augBox.noticeId === NOTICE.id);
          count = count + matchedAugBoxes.length;
        });
        if (count > 0) {
          imageAugNotices.push({
            id: NOTICE.id,
            message: NOTICE.getNoticeText(count)
          });
        }
      });

      return imageAugNotices;
    };
  });
})();
