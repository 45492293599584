'use strict';
import '@i18n';
import enTranslations from '@translations/en.json';
import settings from '@features/Settings/translations/en.json';
import store from '@store/';

// Register factory for interceptor that will configure a default timeout for any http request without one.
angular.module('eva2-angular').factory('pendingHttpReqTimeoutInterceptor', [() => {
  const second = 1000;
  const defaultTimeout = 20 * second;

  return {
    request: function(config) {
      // If this very request already doesn't have a set timeout than set one to a "default".
      if (!config.timeout) {
        config.timeout = defaultTimeout;
      }

      return config;
    }
  };
}]);

angular.module('eva2-angular').config(function($urlRouterProvider, $httpProvider,
  $locationProvider, $logProvider, DEBUG_ENABLED, $animateProvider, $translateProvider) {
  // Only apply ng-animate to elements with class ".animate-***"
  $animateProvider.classNameFilter(/animate-/);

  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false
  });

  $logProvider.debugEnabled(DEBUG_ENABLED);

  $httpProvider.defaults.withCredentials = true;

  $httpProvider.interceptors.push('RequestTrackerInterceptor');
  $httpProvider.interceptors.push('LoadingTrackerHTTPInterceptor');
  $httpProvider.interceptors.push('ServerSideValidationHTTPInterceptor');
  $httpProvider.interceptors.push('pendingHttpReqTimeoutInterceptor');

  // For any unmatched url, redirect to login
  $urlRouterProvider.otherwise(function($injector) {
    const $state = $injector.get('$state');
    const $timeout = $injector.get('$timeout');

    // Without timeout this causes digest cycles when built
    $timeout(function() {
      $state.go('app.login');
    });
  });

  const currentLanguage = 'en';
  const currentTranslations = {...enTranslations, 'SETTING':settings};
  // add translation table
  $translateProvider
    .useSanitizeValueStrategy('sceParameters')
    .translations(currentLanguage, currentTranslations)
    .preferredLanguage(currentLanguage)
    .addInterpolation('$translateMessageFormatInterpolation');
  moment.locale(currentLanguage);
});

angular.module('eva2-angular')
  .config(['$ngReduxProvider', ($ngReduxProvider) => {
    $ngReduxProvider.provideStore(store);
  }])
  .config(['$qProvider', function ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
  }]);

moment.relativeTimeThreshold('ss', 3);
moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a moment',
    ss: '%d seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    w: 'a week',
    ww: '%d weeks',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years'
  }
});
