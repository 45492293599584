(function() {
  'use strict';

  function Controller() {}

  angular
    .module('eva2-angular')
    .component('evaSecondaryButton', {
      bindings: {
        text: '@',
        onClick: '&',
        heightAsParent: '<'
      },
      template: `<div class="eva-secondary-button" data-ng-click="vm.onClick()" 
        data-ng-class="{'height-as-parent': vm.heightAsParent}">
                    <span class="eva-secondary-button__text">{{vm.text}}</span>
                  </div>`,
      controller: Controller,
      controllerAs: 'vm'
    });
})();


