(function() {
  'use strict';
  function Controller(validator,$element, $timeout, $stateParams) {
    const vm = this;
    vm.domain = $stateParams.domain;
    vm.$onInit = () => {
      vm.mode = vm.mode || 'VIEW';
    };
    vm.onClick = function() {
      if (!this.isDisabled) {
        this.mode = 'EDIT';
        $timeout(()=> {
          $element.find('input').focus();
          vm.confirmedValue = this.value;
        });
      }
    };
    vm.onBlur = function() {
      this.mode = 'VIEW';
      vm.onUpdate();
    };

    this.onKeyDown = function(event){
      if (event.keyCode === 13 && event.shiftKey) {
        event.preventDefault();
        this.onBlur();
      }
    };

    vm.resetToOriginalValue = () => {
      this.value = this.confirmedValue;
      vm.onUpdate();
      this.mode = 'VIEW';
    };




  }
  angular
    .module('eva2-angular')
    .component('evaJobUsername', {
      bindings: {
        label: '@',
        value: '=',
        link: '@',
        searchText: '@',
        isDisabled: '<',
        tooltipName: '@',
        onUpdate: '&',
        dictionaries: '<',
      },

      template: require('./jobUsername.html'),
      controller: Controller

    });
})();
