"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupedDecisionData = void 0;
var groupedDecisionData = function (report) {
    var decisionKeys = ['accepted', 'rejected', 'noDecision'];
    var ALL = 'all';
    var result = {};
    __spreadArray(__spreadArray([], __read(decisionKeys), false), [ALL], false).forEach(function (key) {
        result[key] = {
            auto: 0,
            manual: 0,
        };
    });
    report.forEach(function (dayReport) {
        decisionKeys.forEach(function (key) {
            var autoKey = "".concat(key, "Auto");
            var manualKey = "".concat(key, "Manual");
            var auto = dayReport[autoKey];
            var manual = dayReport[manualKey];
            result[key].auto += auto;
            result[key].manual += manual;
            result[ALL].auto += auto;
            result[ALL].manual += manual;
        });
    });
    return result;
};
exports.groupedDecisionData = groupedDecisionData;
