import RulesAndHistoryModal from '@features/ItemLog/components/RulesAndHistoryModal';
import { dataStoringApi } from '@common/services/dataStoring';
import { minDateFromPeriod } from '@features/ItemLog/util/fromDateUtil';
import store from '@store';
import { default as React } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import _ from 'lodash';
import { formatDateLegacy, getIntervalFromNow } from '@utils/date';
import { HISTORY, MATCHED_RULES } from '@/react-app/features/ItemLog/components/OverallList';
import { getHistoryLinkText } from '@common/utils/getHistoryLinkText';

(function () {
  'use strict';
  const userDefaultKeys = ['id', 'name', 'emailAddresses', 'phoneNumbers', 'ipAddress'];

  function Controller($state, $timeout, $element, tFilter, $stateParams , $interval, $scope,
    UserPermissionManager, $ngRedux, $rootScope) {
    'ngInject';

    const vm = this;
    let interval = null;
    let reactRoot = null;
    vm.domain = $stateParams.domain;

    const resources = {
      subHeader: {
        itemDetails: tFilter('ITEM.ITEM_DETAILS'),
        userDetail: tFilter('ITEM.USER_DETAILS'),
        locationDetail: tFilter('ITEM.LOCATION_DETAILS'),
        userAgent: tFilter('ITEM.USER_AGENT')
      },
      label: {
        userName: tFilter('ITEM.USER_NAME'),
        email: tFilter('ITEM.EMAIL'),
        phone: tFilter('ITEM.PHONE'),
        userDefined: tFilter('ITEM.USER_DEFINED'),
        geolocation: tFilter('ITEM.GEO_LOCATION')
      }
    };



    vm.$onInit = function() {
      vm.resources = resources;
      vm.ipLookUpAddress = vm.job && vm.job.ad && vm.job.ad.current && vm.job.ad.current.user
        ? `http://www.ip-tracker.org/locator/ip-lookup.php?ip=${vm.job.ad.current.user.ipAddress}#map`
        : '';
      vm.numberOfDecisions =  vm.job?.ad?.history?.filter((h) => h.meta.status === 'refused' || h.meta.status === 'approved').length || 0;
      // historyLinkText should be Processing, Awaiting review or No decision if no decision has been made
      if(vm.numberOfDecisions === 0) {
        const status = vm.job?.ad?.current?.meta?.status;
        vm.historyLinkText = getHistoryLinkText(status);
      }
      vm.hasUserContent = userDefaultKeys.some((key) => _.has(vm.job, `ad.current.user.${key}`))
                || _.get(vm.job, 'ad.customerSpecific.user');

      const agent = vm.job.ad.current.meta.agent;

      const agentValues = [_.get(agent, 'device.type'), _.get(agent, 'device.model'), _.get(agent, 'device.brand'), _.get(agent, 'client.name'),
        _.get(agent, 'os.family'), _.get(agent, 'os.version'), _.get(agent, '.cpu.architecture')];
      vm.displayUserAgentInfo = agentValues.map(Boolean).some(b => b === true);

      if (agent && vm.displayUserAgentInfo) {
        if (agent.device && (agent.device.type === 'mobile' || agent.device.type === 'tablet')) {
          vm.emoji = '📱';
        } else {
          vm.emoji = '💻';
        }
      }
      initDatesAndStartInterval();
      $timeout(() => {
        // Remove the horizontal line in the last div
        const result = $element.find('.eva-job-other-information > div').map(function (index) {
          return {index, order: parseInt($(this).css('order'))};
        }).get();
        const sorted = result.sort((a, b) => a.order - b.order);
        $(`.eva-job-other-information > div:nth-child(${sorted[0].index})`).children('hr').remove();
      });
      $ngRedux.dispatch(
        dataStoringApi.endpoints.getDataStoringOption.initiate(vm.domain)
      ).then((response)=>{
        vm.minDate = minDateFromPeriod(response.data.period).getTime();
      });
    };
    vm.$onDestroy = function () {
      cancelIntervals();
    };

    vm.updateUserCustomerSpecific = (key,value) => {
      vm.job.ad.customerSpecific.user[key] = value;
    };
    vm.updateLocationCustomerSpecific = (key,value) => {
      vm.job.ad.customerSpecific.location[key] = value;
    };

    vm.openModalWithHistoryTab = () => {
      vm.selectedTab=HISTORY;
      vm.isModalOpen = true;
    };
    vm.openModalWithRulesTab = () => {
      vm.selectedTab=MATCHED_RULES;
      vm.isModalOpen = true;
    };

    $scope.$watchGroup(['$ctrl.job', '$ctrl.isModalOpen', '$ctrl.selectedTab'], ()=> {
      renderModal();
    },true);

    $scope.$watch('$ctrl.job.ad.history', () => {
      vm.numberOfDecisions=vm.job?.ad?.history?.filter((h) => h.meta.status === 'refused' || h.meta.status === 'approved').length || 0;
    });

    const setIsModalOpen = (isOpen) => {
      vm.isModalOpen = isOpen;
    };

    const setSelectedTab = (tabIndex) => {
      vm.selectedTab = tabIndex;
    };

    const renderModal = () => {
      $timeout(() => {
        if (!reactRoot) {
          reactRoot = createRoot(document.querySelector('#modal-other-info'));
        }

        reactRoot.render(<Provider store={store}>
          <RulesAndHistoryModal
            isOpen={vm.isModalOpen}
            setIsOpen={setIsModalOpen}
            selectedJob={vm.job}
            angularJsDependencies={
              {
                go: $state.go,
                href: $state.href,
                userPermissionManager: UserPermissionManager,
                previousState: $rootScope.previousState
              }
            }
            setSelectedTab={setSelectedTab}
            selectedTab={vm.selectedTab}
            minDate={vm.minDate}
          />
        </Provider>
        );
      });
    };

    vm.getGoogleMapLink = (query) => `https://www.google.com/maps/search/?api=1&query=${query}`;

    const initDatesAndStartInterval = () => {
      // Pure date values returned from the API
      vm.createdAtDate = _.get(vm.job, 'ad.current.meta.statusOnSite.timestamp');
      vm.publishedAtDate = _.get(vm.job, 'ad.current.meta.statusOnSite.publishedAt');
      vm.updatedAtDate = _.get(vm.job, 'ad.current.meta.statusOnSite.updatedAt');
      if (vm.createdAtDate || vm.publishedAtDate || vm.updatedAtDate) {
        const refreshInterval = getRefreshInterval();
        // initial value for the labels and tootips
        if (vm.createdAtDate) {
          vm.createdAt = getDateLabels(vm.createdAtDate);
        }
        if (vm.publishedAtDate) {
          vm.publishedAt = getDateLabels(vm.publishedAtDate);
        }
        if (vm.updatedAtDate) {
          vm.updatedAt = getDateLabels(vm.updatedAtDate);
        }
        startInterval(refreshInterval);
      }
    };


    const getRefreshInterval = () => {
      // For each date, we need to calculate the interval from now
      const {timeUnit: timeUnitCreated} = vm.createdAtDate ? getIntervalFromNow(new Date(vm.createdAtDate)) : {timeUnit: null};
      const {timeUnit: timeUnitPublished} =
                vm.publishedAtDate ? getIntervalFromNow(new Date(vm.publishedAtDate)) : {timeUnit: null};
      const {timeUnit: timeUnitUpdated} = vm.updatedAtDate ? getIntervalFromNow(new Date(vm.updatedAtDate)) : {timeUnit: null};
      // Set the refresh interval based on the time unit
      // By default, we refresh every hour
      let refreshInterval = 1000 * 60 * 60;
      // If one of the dates is in seconds, we refresh every second
      if (timeUnitCreated === 'second' || timeUnitPublished === 'second' || timeUnitUpdated === 'second') {
        refreshInterval = 1000;
        // If one of the dates is in minutes, we refresh every minute
      } else if (timeUnitCreated === 'minute' || timeUnitPublished === 'minute' || timeUnitUpdated === 'minute') {
        refreshInterval = 1000 * 60;
      }
      return refreshInterval;

    };
    const getIntervalLabel = (dateToInterval) => {
      if (dateToInterval) {
        const {timeNum, timeUnit} = getIntervalFromNow(new Date(dateToInterval));
        return tFilter('ITEM.INTERVAL_FROM_NOW', {
          TIME_NUM: timeNum,
          TIME_UNIT: timeUnit
        }, 'messageformat');
      }
      return null;
    };
    const getTooltipLabel = (dateToTooltip) => {
      if (dateToTooltip) {
        const {timestampFormatted, timeZone} = formatDateLegacy(dateToTooltip);
        return tFilter('ITEM.TIMESTAMP_TOOLTIP', {
          TIMESTAMP: timestampFormatted,
          TIMEZONE: timeZone
        }, 'messageformat');
      }
      return null;
    };
    const getDateLabels = (dateToDisplay) => {
      const intervalLabel = getIntervalLabel(dateToDisplay);
      const tooltipLabel = getTooltipLabel(dateToDisplay);
      return {intervalLabel, tooltipLabel};
    };
    const startInterval = (refreshInterval) => {
      cancelIntervals();
      interval = $interval(() => {
        if (vm.createdAtDate) {
          vm.createdAt.intervalLabel = getIntervalLabel(vm.createdAtDate);
        }
        if (vm.updatedAt) {
          vm.updatedAt.intervalLabel = getIntervalLabel(vm.updatedAtDate);
        }
        if (vm.publishedAt) {
          vm.publishedAt.intervalLabel = getIntervalLabel(vm.publishedAtDate);
        }
      }, refreshInterval);
    };

    const cancelIntervals = () => {
      if (interval) {
        $interval.cancel(interval);
      }
    };

  }

  angular
    .module('eva2-angular')
    .component('evaJobOtherInformation', {
      bindings: {
        job: '<',
        template: '<',
        dictionaries: '<',
        editPermissions: '<',
        trustedDomains: '<',
      },

      template: require('./otherInformation.html'),
      controller: Controller
    });
})();