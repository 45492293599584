"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useClickOutside(ref, onClickOutside) {
    (0, react_1.useEffect)(function () {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return function () {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}
exports.default = useClickOutside;
