(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .service('MapperManager', MapperManager);

  function MapperManager() {
    'ngInject';

    this.mandatoryMappers = null;
    this.optionalMappers = null;

    Object.defineProperty(this, 'allMappers', {
      // eslint-disable-next-line getter-return
      get: function() {
        if (this.mandatoryMappers) {
          return this.mandatoryMappers.concat(this.optionalMappers);
        }
      }
    });

    this.isAllMappingValid = function isAllMappingValid() {
      if (!this.allMappers) {
        return false;
      }
      return this.allMappers
        .every((mapper)=> mapper.isValid || mapper.isEmpty);
    };

    this.isMandatoryMappingValid = function isMandatoryMappingValid() {
      if (!this.mandatoryMappers) {
        return false;
      }
      return this.mandatoryMappers.every(
        (mapper)=> mapper.isValid);
    };

    this.getMapping = function getMapping() {
      const mapping = {};
      if (this.allMappers) {
        this.allMappers
          .forEach((mapper) => {
            if (!!mapper.selectedColumn && mapper.selectedColumn !== undefined
              && !!mapper.decidedAttribute && mapper.decidedAttribute.id !== undefined) {
              mapping[mapper.decidedAttribute.id] = mapper.selectedColumn.label;
            }
          });
        return mapping;
      }
    };
    this.reset = function() {
      this.mandatoryMappers = null;
      this.optionalMappers = null;
    };
  }
})();
