import _ from 'lodash';

angular.module('eva2-angular').service('ApiAd', function(ApiDecisions, ajax) {
  'use strict';

  const ApiAd = {
    /**
     * to call ad/process
     * @param  {'queueId':string,'state':string,'limit':number,'skip':number}
     * @return {promise}
     */
    getLockedAds: function getLockedAds(domain, limit, state) {
      return ajax.post(`/sites/${domain}/ads/process`, {limit, state});
    },
    /**
     * @param  {'domain':string,'duplicatesRequests': Array<{'remoteId': string, dedupHash: 'string'}>}
     * @return {Promise<{[key: string] : number}>}
     */
    //[INFO] in a case of missing dedupHash for an item, return 0 duplicates. 
    //there is a need of a new way of calculating dedupHash, so this situation
    //does not occur, for example to utilize image link.
    getDuplicateAdsCount: function(domain, duplicatesRequests) {
      const [validRequests, noDedupHashRequests] = _.partition(duplicatesRequests, (request) => request.dedupHash);
      const duplicatesCountsNoDedupHash = noDedupHashRequests.reduce((acc,curr) => ({...acc,[curr.remoteId] : 0}), {});
      if(validRequests.length) {
        return ajax.post(`/sites/${domain}/ads/duplicates/count`, {duplicatesRequests: validRequests}).then((duplicatesCounts)=> {
          const duplicatesCountsResponse =  duplicatesCounts.reduce((acc,curr)=> ({...acc,...curr} ), {});
          return {...duplicatesCountsResponse,...duplicatesCountsNoDedupHash};
        });
      } else {
        return duplicatesCountsNoDedupHash;
      }
    },
    getAdDecisionSet: function getAdDecisions(domain) {
      return ApiDecisions.getByDomain(domain)
        .then(function(decisionSet) {
          return decisionSet.results;
        });
    },
    getFeedback: function getFeedback(domain) {
      return ajax.get(`/sites/${domain}/feedbacks`);
    },
    getLatest: function getLatest(domain) {
      return ajax.get(`/sites/${domain}/ads/latest`);
    },
    getAvailableQueues: function getLatest(domain) {
      return ajax.get(`/sites/${domain}/queues`);
    }
  };

  return ApiAd;
});
