
(function() {
  'use strict';

  function Controller(TestRegex, $scope, RECAPTCHA_KEY, ApiService, Notifier, tFilter) {
    const vm = this;
    vm.$onInit = () => {
      vm.captchaPublicKey = RECAPTCHA_KEY;
      vm.emailRegex = TestRegex.email;
      vm.selectedRole = vm.defaultRole;
    };

    vm.selectRoleForNewInvite = (item) => {
      vm.selectedRole = item;
    };

    vm.sendInvite = (email, roleId) => {
      vm.sendingInvite = true;
      ApiService.createInvite(vm.domain, {email, roleId, captchaToken: vm.captchaResponse})
        .then(handleSendInviteSuccess, handleSendInviteError)
        .finally(()=> {
          vm.sendingInvite = false;
          grecaptcha.reset();
        });

      function handleSendInviteSuccess(newInvite) {
        if (!vm.inIntroductionSlide) {
          resetForm();
        }
        vm.inviteSentHandler({newInvite});
        Notifier.display(tFilter('SETTING.TEAM_MEMBER.CREATE_INVITE.SUCCESS_MESSAGE'), {type: 'success', ttl: 2000});
        vm.isInvited = true;
      }

      function handleSendInviteError(error) {
        if (!!error.data && error.data.context === 'email' && !!error.data.message) {
          return; // if there is a message in the response payload, it will be shown under the input, then don't show it again
        }
        Notifier.display(tFilter('SETTING.TEAM_MEMBER.CREATE_INVITE.FAILURE_MESSAGE'), {type: 'failure', ttl: 6000}, error);
      }


      function resetForm() {
        vm.emailToInvite = '';
        $scope.emailForm.$setPristine();
        $scope.emailForm.$setUntouched();
        vm.selectedRole = null;
      }
    };
  }

  angular
    .module('eva2-angular')
    .component('evaCreateInvite', {
      bindings: {
        domain: '<',
        roles: '<',
        inviteSentHandler: '&',
        showCaptcha: '<',
        isReadyToCreateNewInvite: '<',
        defaultRole: '<',
        inIntroductionSlide: '<'
      },
      controllerAs: 'vm',
      template: require('./createInvite.html'),
      controller: Controller
    });
})();

