angular.module('eva2-angular').service('ApiSignup', function(ajax) {
  'use strict';

  return {
    signup: function(data) {
      return ajax.post('/signup', {
        teamName: data.teamname,
        name: data.name,
        password: data.password,
        email: data.email,
        captchaToken: data.myRecaptchaResponse,
        region: data.selectedRegion.id,
        company: data.company
      });
    }
  };
});
