'use strict';
import _ from 'lodash';

angular.module('eva2-angular').service('ruleConditionHelper', function(regexValidator, tFilter) {
  'ngInject';
  const TRANSLATE_UI_TO_BLANG_ERRORS = {
    TEXT: {
      CONTAINS_DOUBLE_QUOTE: tFilter('AUTOMATION.DECISION_RULES.ERROR.DOUBLE_QUOTE'),
      EMPTY: tFilter('AUTOMATION.DECISION_RULES.ERROR.EMPTY_NOT_ALLOW')
    }
  };

  //define operators
  const textareaOperators = [
    {id: 'CONTAINS', label: 'Contains'},
    {id: 'NOT CONTAINS', label: 'Not contains'}
  ];
  const equalOperators = [
    {id: 'EQUALS', label: 'Equals'},
    {id: 'NOT EQUALS', label: 'Not equals'}
  ];
  const listOperators = [
    {id: 'CONTAINS LIST', label: 'Contains list'},
    {id: 'NOT CONTAINS LIST', label: 'Not contains list'}
  ];
  const betweenOperators = [
    {id: 'BETWEEN', label: 'Between'},
    {id: 'NOT BETWEEN', label: 'Not between'}
  ];
  const numOperators = [
    {id: '<=', label: 'Less than or equal'},
    {id: '<', label: 'Less than'},
    {id: '>=', label: 'Greater than or equal'},
    {id: '>', label: 'Greater than'}
  ];
  const existsOperators = [
    {id: 'EXISTS', label: 'Exists'},
    {id: 'NOT EXISTS', label: 'Not exists'}
  ];

  const allOperators = [
    ...textareaOperators, ...listOperators, ...equalOperators, ...betweenOperators, ...numOperators, ...existsOperators];

  //define boolean value
  const availableBooleanValue = [
    {id: 'true', label: 'True'},
    {id: 'false', label: 'False'},
  ];

  function isNum(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  /**
   * return data for the value input field, the format of the data depends on the input type
   * @param value could be different data type depends on the type of input
   * @param {String} type of input
   */
  function getInputValue(value, type) {
    let inputValue = '';
    switch (type) {
    case 'regex':
    case 'string':
      inputValue = {text: value};
      break;
    case 'dictionary':
      inputValue = {selectedList: [value]};
      break;
    case 'integer':
      inputValue = {number: Number(value)};
      break;
    case 'integer2':
      inputValue = {number1: Number(value[0]), number2: Number(value[1])};
      break;
    case 'boolean':
      inputValue = {selectedBoolean: _.find(availableBooleanValue,
        (item) => item.id === value.toString().toLowerCase())};
      break;
    }
    return inputValue;
  }

  //check is selectOperator inside the operaterGroup
  function operatorInGroup(selectOperator, operatorGroup) {
    return operatorGroup.some((operator) => operator?.id === selectOperator?.id);
  }

  //get the input type will decide which type of the input it is, and what form of the value input field will show
  function getTheInputType(selectedField, selectedOperator) {
    let inputType = '';
    if (operatorInGroup(selectedOperator, existsOperators)){
      return;
    }
    if (selectedField) {
      const fieldType = selectedField.type;

      if (!!fieldType && !!selectedOperator) {
        switch (fieldType) {
        case 'STRING':
          if (operatorInGroup(selectedOperator, textareaOperators) || operatorInGroup(selectedOperator, equalOperators)) {
            inputType = 'TEXT';
          } else {
            inputType = 'LIST';
          }
          break;
        case 'NUM':
          if (operatorInGroup(selectedOperator, betweenOperators)) inputType = 'BETWEEN';
          else inputType = 'NUM';
          break;
        case 'BOOLEAN':
          inputType = 'BOOLEAN';
          break;
        }
      }
    }
    return inputType;
  }

  //get available operators depends on the fieldType
  function getAvailableOperator(fieldType) {
    let availableOperators = [];

    switch (fieldType) {
    case 'STRING':
      availableOperators = [...textareaOperators, ...listOperators, ...equalOperators, ...existsOperators];
      break;
    case 'NUM':
      availableOperators = [...equalOperators, ...betweenOperators, ...numOperators, ...existsOperators];
      break;
    case 'BOOLEAN':
      availableOperators = [...equalOperators, ...existsOperators];
      break;
    default:
      break;
    }
    return availableOperators;
  }

  /**
   * translate blang to UI
   * @param {Object} args  e.g. { field: { value: 'price' }, operator: 'EQUALS', value: { value: 100, type: 'integer' } }
   * @param {String} domain  e.g. "your-domain.com"
   */
  function translateBlangToUI(args, availableField) {
    let valueArg;
    let typeArg;
    let operatorArg = args.operator;

    if (Array.isArray(args.value)) {
      valueArg = [args.value[0].value, args.value[1].value];
      typeArg = 'integer2';
    } else {
      valueArg = args.value.value;
      typeArg = args.value.type;

      if (typeArg === 'dictionary') {
        operatorArg += ' LIST';
      }
    }

    const field = _.find(availableField, (item) => args.field.value === item.id);
    const operator = _.find(allOperators, (item) => operatorArg === item.id);
    const value = getInputValue(valueArg, typeArg);

    return {field: field, operator: operator, value: value};
  }

  /**
   * translate UI to blang expression
   * @param {Object} selectedField   e.g. {id: "price", label: "Price", type: "NUM"}
   * @param {Object} selectedOperator  e.g. {id: "BETWEEN", label: "Between"}
   * @param {Object} inputValue e.g. {number1: 434, number2: 432432, selectedList: undefined}
   * @param {String} inputType  e.g. "BETWEEN"
   */
  function translateUItoBlang(selectedField, selectedOperator, inputValue, inputType) {
    let operator = '';
    let field = '';
    let value = '';
    let result = '';

    const errors = [];
    if (!!selectedOperator && !!selectedOperator.id) operator = selectedOperator.id.replace(' LIST', '');
    if (!!selectedField && selectedField.id) field = selectedField.id;
    switch (inputType) {
    case 'TEXT':
      value = translateTextToBlang(inputValue.text, errors);
      break;
    case 'LIST':
      value = translateListToBlang(inputValue.selectedList);
      break;
    case 'NUM':
      value = inputValue.number;
      break;
    case 'BETWEEN':
      value = translateBetweenValueToBlang(inputValue.number1, inputValue.number2);
      break;
    case 'BOOLEAN':
      value = translateBooleanValueToBlang(inputValue.selectedBoolean);
      break;
    }
    if (!!field && !!operator && (!!value || value === 0)) result =`$${field} ${operator} ${value}`;
    if (operator==='EXISTS'){
      result =`EXISTS ($${field})`;
    }
    if (operator==='NOT EXISTS'){
      result =`NOT EXISTS ($${field})`;
    }
    if (errors.length > 0) {
      result = null;
    }

    return {
      expression: result,
      errors: errors
    };
  }

  //local function used in translation
  function translateBetweenValueToBlang(num1, num2) {
    if (isNum(num1) && isNum(num2)) {
      return num1.toString() + '-' + num2.toString();
    } else {
      return '';
    }
  }

  function translateListToBlang(value) {
    if (!!value && value.length > 0) return '@' + value[0];
    else return '';
  }

  function translateBooleanValueToBlang(value) {
    if (!!value && !!value.id) return value.id;
    else return '';
  }

  function translateTextToBlang(text, errors) {
    let value = text;

    if (!text) {
      errors.push(TRANSLATE_UI_TO_BLANG_ERRORS.TEXT.EMPTY);
      return value;
    }

    if (regexValidator.isRegExp(text)) {
      return value;
    }

    if (text.indexOf('"') !== -1) {
      errors.push(TRANSLATE_UI_TO_BLANG_ERRORS.TEXT.CONTAINS_DOUBLE_QUOTE);
    }
    value = '"' + text + '"';

    return value;
  }

  //end of local function used for translation

  return {
    availableBooleanValue,
    allOperators,
    getTheInputType,
    getAvailableOperator,
    translateBlangToUI,
    translateUItoBlang
  };
});
