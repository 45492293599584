/*evaTextBox: textbox with both view mode and edit mode*/

(function() {
  'use strict';

  function Controller($scope, $element, $timeout) {
    'ngInject';
    const vm = this;

    this.$onInit = function() {
      //default value
      const placeholderText = 'Enter text...';
      const mode = 'VIEW';
      this.placeholdertext = this.placeholderText || placeholderText;
      this.mode = this.mode || mode;
      this.type = this.type || 'text';
      this.isValid = this.checkIfValid();
    };

    //links
    this.onClick = function() {
      if (!this.isDisabled) {
        this.mode = 'EDIT';
        $timeout(()=> {
          $element.find('input').focus();
          vm.confirmedValue = this.value;
        });
      }
    };
    this.onBlur = function() {
      if(!this.isValid){
        this.resetToOriginalValue();
      }
      this.mode = 'VIEW';
    };

    this.onKeyDown = function(event){
      if (event.keyCode === 13 && event.shiftKey && this.isValid) {
        event.preventDefault();
        this.onBlur();
      }
    };

    this.checkIfValid = () =>{
      if(this.type==='number'){
        return !isNaN(this.value);
      }
      return true;
    };

    this.resetToOriginalValue = () => {
      this.value = this.confirmedValue;
      this.mode = 'VIEW';
    };

    $scope.$watch('$ctrl.value', () => {
      this.isValid = this.checkIfValid();
    }, true);
  }

  angular
    .module('eva2-angular')
    .component('evaTextBox', {
      bindings: {
        mode: '@', //the value should be "VIEW" or "EDIT"
        label: '<',
        name:'@',
        type: '@',
        placeholderText: '@',
        value: '=',
        isDisabled: '<',
        onUpdate: '&'
      },
      template: require('./textBox.html'),
      controller: Controller
    });
})();
