(function(){
  // eslint-disable-next-line no-undef
  var w=window;var ic=w.Intercom;if(typeof ic==='function'){ic('reattach_activator');ic('update',intercomSettings);}else{
    // eslint-disable-next-line no-inner-declarations
    var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;function l(){
      var s=d.createElement('script');s.type='text/javascript';s.async=true;
      s.src= 'https://widget.intercom.io/widget/' + INTERCOM_ID;
      var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);
    }if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}
  }
})();