"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBoundingBoxes = void 0;
var react_1 = require("react");
var utils_1 = require("../components/BoundingBox/utils");
var isImageMetadataSuccess_1 = require("../utils/isImageMetadataSuccess");
var useBoundingBoxes = function (selectedImageId, imageMetadata, showBoundingBox, imageTransformationsStyle, boundingBoxContainerStyle) {
    var _a = __read((0, react_1.useState)([]), 2), boundingBoxesInsideImage = _a[0], setBoundingBoxesInsideImage = _a[1];
    var _b = __read((0, react_1.useState)([]), 2), boundingBoxesOnWholeImage = _b[0], setBoundingBoxesOnWholeImage = _b[1];
    (0, react_1.useEffect)(function () {
        var setBoundingBoxes = function () {
            var _a;
            if (imageMetadata &&
                (0, isImageMetadataSuccess_1.isImageMetadataSuccess)(imageMetadata) &&
                ((_a = imageMetadata === null || imageMetadata === void 0 ? void 0 : imageMetadata.augBoxes) === null || _a === void 0 ? void 0 : _a.length) &&
                showBoundingBox &&
                boundingBoxContainerStyle) {
                var augBoxes = imageMetadata.augBoxes;
                var _b = __read((0, utils_1.transformAugmentationBoxes)(augBoxes, boundingBoxContainerStyle, showBoundingBox), 2), boundingBoxesInsideImage_1 = _b[0], boundingBoxesOnWholeImage_1 = _b[1];
                setBoundingBoxesInsideImage(boundingBoxesInsideImage_1);
                setBoundingBoxesOnWholeImage(boundingBoxesOnWholeImage_1);
            }
            else {
                boundingBoxesInsideImage.length !== 0 && setBoundingBoxesInsideImage([]);
                boundingBoxesOnWholeImage.length !== 0 && setBoundingBoxesOnWholeImage([]);
            }
        };
        setBoundingBoxes();
    }, [selectedImageId, JSON.stringify(showBoundingBox), boundingBoxContainerStyle, imageTransformationsStyle]);
    return [boundingBoxesInsideImage, boundingBoxesOnWholeImage];
};
exports.useBoundingBoxes = useBoundingBoxes;
