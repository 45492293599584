import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Overview from '@/react-app/features/Statistic';
import store from '@store';

angular.module('eva2-angular')
  .controller('StatisticsController', function ($stateParams, $modal, $timeout, $state, CurrentUser) {
    'use strict';

    const vm = this;
    const root = createRoot(document.querySelector('#overview'));

    vm.errorLoadingStats = false;
    vm.domain = $stateParams.domain;
    vm.teamName = CurrentUser.getAssignmentNameFromDomain(vm.domain);

    vm.showIntroduction = $stateParams.showIntroduction;
    if (vm.showIntroduction) {
      $modal.open({
        template: require('@partial/modals/userIntroduction/userIntroduction.html'),
        controller: 'UserIntroductionModalCtrl',
        windowClass: 'no-fade',
        backdrop: 'static',
        resolve: {
          showFullIntroduction: () => true
        }
      });
    }

    //show the join the new team notice
    vm.inviteTeamName = $stateParams.inviteParams && $stateParams.inviteParams.inviteTeamName;
    if (vm.inviteTeamName) {
      $modal.open({
        template: require('@partial/modals/inviteTeamNotice/inviteTeamNotice.html'),
        controller: 'InviteTeamNoticeModal',
        windowClass: 'no-fade',
        backdrop: 'static',
        resolve: {
          teamname: () => vm.inviteTeamName
        }
      });
    }


    const renderOverview = () => {
      $timeout(() => {
        root.render(<Provider store={store}>
          <Overview currentUserEmail={CurrentUser.email} domain={vm.domain} teamName={vm.teamName}
            angularJsDependencies={
              {
                href: $state.href,
                go: $state.go
              }
            }
          />
        </Provider>);
      });
    };

    vm.$onInit = () => {
      renderOverview();
    };
    vm.$onDestroy= () => {
      root.unmount();
    };
  });
