'use strict';

angular.module('eva2-angular')
  .factory('LoadingTrackerHTTPInterceptor', function($q, LoadingTracker) {
    return {
      request: function(config) {
        config.$loadingTrackerDeferred = LoadingTracker.createPromise(config);
        return $q.when(config);
      },
      response: function(response) {
        if (response.config && response.config.$loadingTrackerDeferred) {
          response.config.$loadingTrackerDeferred.resolve();
        }
        return $q.when(response);
      },
      responseError: function(response) {
        if (response.config && response.config.$loadingTrackerDeferred) {
          response.config.$loadingTrackerDeferred.reject();
        }

        return $q.reject(response);
      }
    };
  });
