angular.module('eva2-angular').factory('DatePickerConfig', function($injector, DateFormat) {
  'use strict';

  return function DatePickerConfig(opt) {
    const icons = {
      time: 'date-picker-icon implioicons-clock',
      date: 'date-picker-icon implioicons-calendar',
      up: 'date-picker-icon implioicons-chevron-up',
      down: 'date-picker-icon implioicons-chevron-down',
      previous: 'date-picker-icon implioicons-chevron-left',
      next: 'date-picker-icon implioicons-chevron-right',
      clear: 'date-picker-icon implioicons-trash'
    };

    if (typeof opt !== 'object') {
      opt = {};
    }
    if (!opt.icons) {
      opt.icons = icons;
    }

    const options = {
      allowInputToggle: true,
      showClear: true,
      useCurrent: false,
      format: DateFormat.moment.display, // format
      minDate: '2010-01-01', // set a minimum date
      stepping: 1,
      icons: icons, // selects the css-class for the icons
    };

    const extended = angular.extend({}, options, opt);

    return extended;
  };
});
