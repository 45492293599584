(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('NewPasswordCtrl', NewPasswordCtrl);


  function NewPasswordCtrl($scope, $state, $stateParams, ApiPassword, Notifier, tFilter) {
    let vm = this;
    vm.token = $stateParams.token;

    if (!vm.token) {
      $state.go('app.passwordReset', {});
      return;
    }

    vm.setNewPassword = function setNewPassword() {
      $scope.loading = true;
      const data = {
        token: vm.token,
        password: vm.password,
        confirmPassword: vm.confirmPassword
      };
      ApiPassword.setNewPassword(data)
        .then(() => {
          const success = tFilter('RESET_NEW_PASSWORD.RESET_SUCCESS_TEXT');
          Notifier.display(success, {type: 'success', ttl: 8000});
          $state.go('app.login', {});
        })
        .catch((error) => {
          const message = (error?.data && error?.data.message) || tFilter('RESET_NEW_PASSWORD.ERROR.RESET_FAIL');
          Notifier.display(message, {type: 'error', ttl: 8000}, error);
        })
        .finally(() => {
          $scope.loading = false;
        });
    };
  }
})();
