"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.percentage = exports.bigNumberFormatter = void 0;
var bigNumberFormatter = function (num) {
    if (num > 999999) {
        return "".concat(Math.round(num * 10) / 10000000, "m");
    }
    if (num > 999) {
        return "".concat(Math.round(num * 10) / 10000, "k");
    }
    return Math.round(num * 10) / 10;
};
exports.bigNumberFormatter = bigNumberFormatter;
var percentage = function (input, decimals) {
    if (!input && input !== 0) {
        return '';
    }
    if (Number.isNaN(input) || !Number.isFinite(input)) {
        return '0%';
    }
    var num = input * 100;
    if (num % 1) {
        return "".concat((input * 100).toFixed(decimals), "%");
    }
    return "".concat((input * 100).toFixed(0), "%");
};
exports.percentage = percentage;
