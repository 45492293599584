(function() {
  'use strict';
  angular.module('eva2-angular').service('getNewIndexAfterMove', function() {
    return function(currentFocusIndex, delta, length) {
      if (currentFocusIndex === undefined) {
        if (delta === 1) {
          return 0;
        }
        if (delta === -1) {
          return length - 1;
        }
      }
      let newIndex = currentFocusIndex + delta;
      if (newIndex < 0) {
        newIndex = newIndex + length;
      }
      if (newIndex > length - 1) {
        newIndex = newIndex - length;
      }
      return newIndex;
    };
  });
})();
