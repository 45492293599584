import {
  getPermissionForOperationUtil,
  hasPermissionToEnterRouteUtil,
  setPermissionForOperationUtil
} from '@common/permissions/permissionManager';

(function() {
  'use strict';
  angular.module('eva2-angular').factory('UserPermissionManager', function(ApiService, EvaLogger) {
    'ngInject';

    let rawAuthorizationData = null;
    let operationPermission = {};


    function hasPermissionToIntercom(domain) {
      if (!domain) {
        return true;
      }
      return !!getPermissionForOperation('intercom', domain).canWrite;
    }

    function hasPermissionToSearchDocuments(domain) {
      if (!domain) {
        return true;
      }
      return Object.prototype.hasOwnProperty.call(getPermissionForOperation('search:documents', domain), 'canWrite');
    }

    /**
     * @param routeName {string}
     * @param domain {string}
     * @returns {boolean} if user has permission for a specific domain and a specific route
     */
    function hasPermissionToEnterRoute(routeName, domain) {
      return hasPermissionToEnterRouteUtil(operationPermission,domain, routeName, );
    }

    function hasDomain(domain) {
      return Object.prototype.hasOwnProperty.call(operationPermission, domain);
    }


    /** example of operationPermission:
     * {
     *  domain1:{
     *    operation1: {can_write: true},
     *    operation2: {can_write: false}
     *   },
     *  domain2: {
      *   operation1: {can_write: true},
     *    operation2: {can_write: false}
     *  }
     * }
     */
    function setOperationPermission() {
      operationPermission = setPermissionForOperationUtil(EvaLogger, rawAuthorizationData);
    }

    /**
     * @param operation
     * @param domain
     * @returns {object}
     */
    function getPermissionForOperation(operation, domain) {
      return getPermissionForOperationUtil(operationPermission, operation, domain);
    }

    return {
      initialize(data) {
        rawAuthorizationData = data;
        setOperationPermission();
      },
      destroy() {
        rawAuthorizationData = null;
      },
      hasPermissionToIntercom,
      hasPermissionToEnterRoute,
      hasPermissionToSearchDocuments,
      getPermissionForOperation,
      hasDomain
    };
  });
})();
