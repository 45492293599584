import _ from 'lodash';
angular.module('eva2-angular')
  .service('ApiFilters', ['ajax', 'EvaLogger', '$q', function(ajax) {
    'use strict';

    function cleanFilter(filter) {
      if (filter.result !== 'MANUAL') {
        delete filter.routingQueue;
        delete filter.routingQueueName;
      } else {
        filter.routingQueue = _.get(filter, 'routingQueueObj.id');
        filter.routingQueueName = _.get(filter, 'routingQueueObj.label');
      }

      if (filter.result !== 'REFUSE') {
        filter.decisionId = null;
      }
      if(filter.description === ''){
        filter.description = null;
      }
      return filter;
    }

    const ApiFilters = {

      index: function index(options) {
        return ajax.get(`/sites/${options.domain}/filters`)
          .then((data) => {
            if (data) {
              return data.results;
            }
          });
      },
      show: function index(options) {
        return ajax.get(`/sites/${options.domain}/filters/${options.id}`);
      },
      create: function create(filter, domain) {
        return ajax.post(`/sites/${domain}/filters`, cleanFilter(filter));
      },
      test: function create(options, data) {
        return ajax.post(`/sites/${options.domain}/filters/test`, data);
      },
      edit: function edit(options, filter) {
        return ajax.patch(`/sites/${options.domain}/filters/${filter.id}`, cleanFilter(filter));
      },
      updateStatus: function updateStatus(options, filter) {
        return ajax.patch(`/sites/${options.domain}/filters/${filter.id}`, {enabled : filter.enabled});
      },
      destroy: function remove(options, filter) {
        return ajax.del(`/sites/${options.domain}/filters/${filter.id}`);
      }
    };

    return ApiFilters;
  }]);
