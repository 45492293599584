(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('AdminDictionaryEditCtrl', AdminDictionaryEditCtrl);

  function AdminDictionaryEditCtrl($stateParams, $state, Notifier, ApiDictionaries, tFilter) {
    const vm = this;
    vm.dictionary = {};
    vm.edit = edit;
    vm.afterEdit = afterEdit;

    activate();

    function loadDictionary() {
      function whenError() {
        vm.errorLoadingData = true;
      }

      ApiDictionaries
        .show({domain: $stateParams.domain, name: $stateParams.name})
        .then(function(dictionary) {
          vm.dictionary = dictionary;
          vm.errorLoadingData = false;
        }).catch(whenError);
    }

    vm.reload = function() {
      loadDictionary();
    };

    function whenErrorSaving(err) {
      if (err.status === 401) {
        return;
      }
      Notifier.display(tFilter('AUTOMATION.LISTS.ERROR.SAVE_ERROR'), {type: 'failure', ttl: 6000}, err);
      console.error(err);
    }

    function edit() {
      ApiDictionaries
        .edit({domain: $stateParams.domain}, vm.dictionary)
        .then(afterEdit)
        .catch(whenErrorSaving);
    }

    function afterEdit() {
      Notifier.display(tFilter('AUTOMATION.LISTS.LIST_BUILDER.SUCCEED_EDITED'), {type: 'success', ttl: 1800});
      $state.go('app.assignment.automation.dictionaries', {domain: $stateParams.domain});
    }

    function activate() {
      loadDictionary();
    }
  }
})();
